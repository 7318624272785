import React, { useState } from 'react'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as Styled from './styles'
import { getSpaceLabelName } from '../../utils/spaces'
import { spaceCreate, spaceUpdate } from '../../actions/spaces'
import { locationByIdUpdate } from '../../actions/locations'
import AttachmentPdf from '../../components/attachment-pdf'
import Attachment from '../../components/attachment'
import { spaceImages, proposalResetValues } from '../../helpers/spaces'
import { leadProposalHide } from '../../actions/leads'

const ProposalForm = ({
  spaces,
  activeSpaceId,
  locationId,
  onSubmit = () => {},
  firstName,
  lastName,
  bookingCalendar
}) => {
  const dispatch = useDispatch()
  const [isNewSpace, setIsNewSpace] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const options = [
    {
      label: '+ Add New Space',
      value: 'new'
    },
    ...spaces.map(space => ({
      label: getSpaceLabelName(space),
      value: space.id,
      item: space
    }))
  ]
  const [activeSpace, setActiveSpace] = useState(options.find(item => item.value === activeSpaceId))
  const formik = useFormik({
    initialValues: {
      ...proposalResetValues(
        spaces.find(item => item.id === activeSpace?.item?.id)
      ),
      bookingCalendar,
      isAvailable: true
    },
    onSubmit: (values) => {
      if (values.bookingCalendar !== bookingCalendar) {
        dispatch(
          locationByIdUpdate(locationId, {
            bookingCalendar: values.bookingCalendar
          })
        )
      }

      if (isNewSpace) {
        dispatch(
          spaceCreate({
            ...values,
            locationId
          })
        ).then(id => {
          if (id) {
            onSubmit(id)
            setIsSubmitted(true)
            return
          }
        })

        return
      }

      dispatch(spaceUpdate(values)).then(success => {
        if (success) {
          onSubmit(values.id)
          setIsSubmitted(true)
        }
      })
    },
    validate: values => {
      let errors = {}
      const text = 'Required'

      if (isNewSpace && !values.name) {
        errors.name = text
      }

      if (!values.spacePrice) {
        errors.spacePrice = text
      }

      if (!values.pricePer6Months) {
        errors.pricePer6Months = text
      }

      if (!values.pricePerMonth) {
        errors.pricePerMonth = text
      }

      if (!(values.spaceWindows || values.interior)) {
        errors.windowOrInterior = text
      }

      if (!values.sqFt) {
        errors.sqFt = text
      }

      return errors
    }
  })
  const disabled = !(isNewSpace || formik.values.id)

  return (
    <>
      <Styled.Form onSubmit={formik.handleSubmit} data-inactive={isSubmitted}>
        <Styled.Row>
          <Styled.Label htmlFor="name">
            <Styled.LabelText>Suite Name</Styled.LabelText>
            {isNewSpace ? (
              <>
                <Styled.Input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Suite Name"
                  value={formik.values.name || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
                {formik.touched.name && formik.errors.name && (
                  <Styled.ErrorText>{formik.errors.name}</Styled.ErrorText>
                )}
              </>
            ) : (
              <Select
                placeholder="Choose Space"
                styles={Styled.Select}
                isSearchable={false}
                options={options}
                value={activeSpace}
                onChange={selectValue => {
                  setActiveSpace(selectValue)

                  if (selectValue.value === 'new') {
                    setIsNewSpace(true)
                  }

                  formik.resetForm({
                    values: proposalResetValues(selectValue?.item)
                  })
                }}
              />
            )}
          </Styled.Label>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label htmlFor="spacePrice">
            <Styled.LabelText>Price - 12 Months</Styled.LabelText>
            <Styled.Input
              type="number"
              id="spacePrice"
              name="spacePrice"
              value={formik.values.spacePrice || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
          </Styled.Label>
          {formik.touched.spacePrice && formik.errors.spacePrice && (
            <Styled.ErrorText>{formik.errors.spacePrice}</Styled.ErrorText>
          )}
        </Styled.Row>
        <Styled.Row>
          <Styled.Label htmlFor="pricePer6Months">
            <Styled.LabelText>Price - 6 Months</Styled.LabelText>
            <Styled.Input
              type="number"
              id="pricePer6Months"
              name="pricePer6Months"
              value={formik.values.pricePer6Months || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
          </Styled.Label>
          {formik.touched.pricePer6Months && formik.errors.pricePer6Months && (
            <Styled.ErrorText>{formik.errors.pricePer6Months}</Styled.ErrorText>
          )}
        </Styled.Row>
        <Styled.Row>
          <Styled.Label htmlFor="pricePerMonth">
            <Styled.LabelText>Price - Month to Month</Styled.LabelText>
            <Styled.Input
              type="number"
              id="pricePerMonth"
              name="pricePerMonth"
              value={formik.values.pricePerMonth || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
          </Styled.Label>
          {formik.touched.pricePerMonth && formik.errors.pricePerMonth && (
            <Styled.ErrorText>{formik.errors.pricePerMonth}</Styled.ErrorText>
          )}
        </Styled.Row>
        <Styled.Row>
          <Styled.Label>
            <Styled.LabelText>Window or Interior</Styled.LabelText>
            <Styled.SelectNative
              defaultValue={
                formik.values.spaceWindows
                  ? 'Window'
                  : formik.values.interior
                  ? 'Interior'
                  : '-'
              }
              onChange={e => {
                const value = e.target.value
                formik.setFieldValue('spaceWindows', value === 'Window')
                formik.setFieldValue('interior', value === 'Interior')
                formik.setFieldTouched('windowOrInterior', true)
              }}
              onFocus={() => formik.setFieldTouched('windowOrInterior', true)}
              disabled={disabled}
            >
              <option value="-">-</option>
              <option value="Window">Window</option>
              <option value="Interior">Interior</option>
            </Styled.SelectNative>
          </Styled.Label>
          {(formik.submitCount > 0 || formik.touched.windowOrInterior) && formik.errors.windowOrInterior && (
            <Styled.ErrorText>{formik.errors.windowOrInterior}</Styled.ErrorText>
          )}
        </Styled.Row>
        <Styled.Row>
          <Styled.Label htmlFor="bookingCalendar">
            <Styled.LabelText>
              Your calendar link to book tours
            </Styled.LabelText>
            <Styled.Input
              type="url"
              id="bookingCalendar"
              name="bookingCalendar"
              value={formik.values.bookingCalendar || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
          </Styled.Label>
        </Styled.Row>
        <Styled.Row>
          <Styled.Label htmlFor="sqFt">
            <Styled.LabelText>Suite Size (Sq/Ft)</Styled.LabelText>
            <Styled.Input
              type="number"
              id="sqFt"
              name="sqFt"
              value={formik.values.sqFt || ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
          </Styled.Label>
          {formik.touched.sqFt && formik.errors.sqFt && (
            <Styled.ErrorText>{formik.errors.sqFt}</Styled.ErrorText>
          )}
        </Styled.Row>
        <Styled.Row>
          <AttachmentPdf
            relatedType="Space"
            label="Floorplan"
            initialFileName={formik.values?.floorplanPDFName}
            name="floorplanPDF"
            alignLeft
            smallLabel
            accept="image/png, image/jpeg, application/pdf"
            maxSize={5000}
            maxText="Max file size is 5 Mbs"
            onRemove={() => {
              formik.setFieldValue('floorplanPDFId', null)
              formik.setFieldValue('floorplanPDFName', null)
            }}
            onChange={({ id, name }) => {
              formik.setFieldValue('floorplanPDFId', id)
              formik.setFieldValue('floorplanPDFName', name)
            }}
            disabled={disabled}
          />
        </Styled.Row>
        {spaceImages.map((item, index) => (
          <Styled.Row key={item.name}>
            <Attachment
              imageId={formik.values[`${item.name}Id`]}
              label={`Suite Picture ${index + 1}`}
              name={item.name}
              proposal
              relatedType="Space"
              onRemove={() => {
                formik.setFieldValue(`${item.name}Id`, null)
                formik.setFieldValue(`${item.name}Name`, null)
                if (index === 0 || index === 1) {
                  formik.setFieldTouched(`${item.name}Id`)
                }
              }}
              onChange={({ id, name }) => {
                formik.setFieldValue(`${item.name}Id`, id)
                formik.setFieldValue(`${item.name}Name`, name)
                if (index === 0 || index === 1) {
                  formik.setFieldTouched(`${item.name}Id`)
                }
              }}
              onBlur={() => (index === 0 || index === 1) && formik.setFieldTouched(`${item.name}Id`)}
              disabled={disabled}
            />
            {(index === 0 || index === 1) && formik.errors[`${item.name}Id`] && formik.touched[`${item.name}Id`] && (
              <Styled.ErrorText>{formik.errors[`${item.name}Id`]}</Styled.ErrorText>
            )}
          </Styled.Row>
        ))}
        <Styled.Actions>
          <Styled.Action type="submit" disabled={disabled}>
            Submit
          </Styled.Action>
          <Styled.Action
            data-cancel
            type="button"
            onClick={() => dispatch(leadProposalHide())}
          >
            Cancel
          </Styled.Action>
        </Styled.Actions>
      </Styled.Form>
      {isSubmitted && (
        <Styled.SubmitModal>
          <p>
            Thank you for your submitted proposal to Upsuite! A member of our
            Coworking Advisor team will be in touch regarding {firstName || ''}{' '}
            {lastName || ''} after presenting your proposal.
          </p>
          <Styled.Action
            type="button"
            onClick={() => {
              setIsSubmitted(false)

              setActiveSpace('adfasdfa')

              formik.resetForm({
                values: proposalResetValues({})
              })
            }}
            data-in-modal
          >
            Submit another suite
          </Styled.Action>
        </Styled.SubmitModal>
      )}
    </>
  )
}

export default ProposalForm
