export const getSubscription = flexOfficePro => {
  if (flexOfficePro === 1 || flexOfficePro === '1') return 'Basic'

  if (flexOfficePro === 2 || flexOfficePro === '2') return 'Advanced'

  if (flexOfficePro === 3 || flexOfficePro === '3') return 'Enterprise'

  if (flexOfficePro === 4 || flexOfficePro === '4') return 'Investor'

  if (flexOfficePro === 5 || flexOfficePro === '5') return 'Advisor'

  return 'Free'
}
