import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from '../reducers';

export default function configureStore(initialState, history) {
  const reduxRouterMiddleware = routerMiddleware(history);

  const middleware = applyMiddleware(thunk, reduxRouterMiddleware);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

  const createStoreWithMiddleware = composeEnhancers(middleware);
  const rootReducer = createRootReducer(history);

  return createStoreWithMiddleware(createStore)(rootReducer, initialState);
}
