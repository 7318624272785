import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { sortByName } from '../helpers/sort'

const useRouteParams = () => {
  const { locationId, marketId } = useParams()
  const { list: markets } = useSelector(state => state.markets)
  const { byMarketId } = useSelector(state => state.locations)
  const marketOptions = sortByName(
    (markets || []).filter(market => !market.isSubmarket)
  ).map(market => market.id)
  const firstMarket = marketOptions[0]
  const locationOptions = sortByName(byMarketId[firstMarket] || [])
  const firstLocation = (locationOptions[0] || {}).id
  const marketRoute =
    marketId && marketId !== 'all' ? `${marketId}/` : `${firstMarket}/`
  const locationRoute =
    locationId && locationId !== 'all' ? locationId : firstLocation
  const routeParams = `${marketRoute}${locationRoute}`

  return routeParams
}

export default useRouteParams
