import isArray from 'lodash/isArray';
import { differenceInDays, parseISO, format } from 'date-fns';
import isFinite from 'lodash/isFinite';

export const getDayDifference = dateAvailable =>
  differenceInDays(parseISO(dateAvailable), new Date());

export const spaceAvailabilityFormat = ({ dateAvailable, isAvailable }) => {
  if ((!isAvailable && !dateAvailable) || dateAvailable === '0000-00-00') {
    return null;
  }

  return (getDayDifference(parseISO(dateAvailable)) < 0) || isAvailable ? 'Available Now' : format(parseISO(dateAvailable), 'MMM d, yyyy');
};

export const spaceCapacityFormat = ({ spaceCapacity }) => {
  if (!spaceCapacity || spaceCapacity <= 1) {
    return '1 person';
  }

  return `${spaceCapacity} people`;
};

export const spaceModifiedDate = date => format(parseISO(date), 'MM/dd/yyyy');

export const spacePerPersonFormat = (spacePrice, spaceCapacity = 1) => {
  if (!spacePrice) return null;
  if (!spaceCapacity) return spacePrice;

  return Math.ceil(spacePrice / spaceCapacity);
};

export const spaceSqFtFormat = ({ sqFt }) => {
  if (!sqFt) return null;

  if (!isArray(sqFt)) {
    return `${sqFt} Sq Ft`;
  }

  return `${sqFt[0]} - ${sqFt[1]} Sq Ft`;
};

export const currencyFormat = v => v && v.toLocaleString();

export const currencyBefore = v => (v ? `$${v}` : '');

export const capacityFormat = (capacity) => {
  if (!capacity || capacity <= 1) {
    return '1 Person';
  }

  return `${capacity} People`;
};

export const spaceAvgPriceFormat = ({ VicinityAvgPrice }) => {
  const tryParse = parseFloat(VicinityAvgPrice);
  if (!isFinite(tryParse)) {
    return null;
  }

  return currencyBefore(Math.round(tryParse));
};
