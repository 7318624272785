import orderBy from 'lodash/orderBy'
import isNaN from 'lodash/isNaN'
import mapValues from 'lodash/mapValues'
import flatten from 'lodash/flatten'
import values from 'lodash/values'
import isArray from 'lodash/isArray'
import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import trim from 'lodash/trim'
import range from 'lodash/range'
import { spacePerPersonFormat, getDayDifference } from './format'

export const spaceAmenities = [
  { label: '1 GB WIfi', name: 'wiFi' },
  { label: 'Private', name: 'private' },
  { label: 'Secure', name: 'secure' },
  { label: 'Kitchen in the Unit', name: 'kitchenInUnit' },
  { label: 'Private Conference Rooms in the Unit', name: 'conferenceRooms' },
  { label: 'Furnished', name: 'furnished' },
  {
    label: 'Furniture Available to Rent or Buy',
    name: 'furnitureAvailableToRentOrPurchase'
  },
  { label: 'Cleaning Services Available', name: 'cleaningServicesAvailable' },
  { label: 'Cleaning Services Provided', name: 'cleaningServicesProvided' },
  { label: 'Month to Month Term Available', name: 'monthToMonthTermAvailable' },
  { label: '6 Month Term Available', name: 'sixMonthTermAvailable' },
  { label: '12 Month Term Available', name: 'twelveMonthTermAvailable' }
]

export const spaceImages = range(1, 10).map(item => ({
  label: `Image ${item}`,
  name: `spaceImage${item}`
}))

export const spaceTypes = [
  'Floating Desk',
  'Dedicated Desk',
  'Suite',
  'Virtual Office',
  'Meeting Space'
]

const spaceTypeOrder = space => spaceTypes.indexOf(trim(space.spaceType))
const byPrice = space =>
  isArray(space.spacePrice) ? space.spacePrice[0] : space.spacePrice
const byPerDay = space =>
  isArray(space.pricePerDay) ? space.pricePerDay[0] : space.pricePerDay
const byPerHour = space =>
  isArray(space.pricePerHour) ? space.pricePerHour[0] : space.pricePerHour
const bySqFt = space => (isArray(space.sqFt) ? space.sqFt[0] : space.sqFt)
const byType = space => trim(space.spaceType)
const byPerPerson = space => spacePerPersonFormat(space)
const byCapacity = space => space.spaceCapacity || 1
const byDate = space => getDayDifference(space.dateAvailable)

const alphanumericOrder = (spaces, isDesc) => {
  const numbers = spaces.filter(s => s.name && !isNaN(Number(s.name)))
  const strings = spaces.filter(s => s.name && isNaN(Number(s.name)))
  const empty = spaces.filter(s => !s.name)
  const orderedNumbers = orderBy(numbers, s => Number(s.name), isDesc && 'desc')
  const orderedStrings = orderBy(strings, 'name', isDesc && 'desc')

  if (!isDesc) {
    return [...orderedNumbers, ...orderedStrings, ...empty]
  }

  return [...orderedStrings, ...orderedNumbers, ...empty]
}

export const sortSpacesMain = spaces => {
  const sorted = orderBy(
    spaces,
    [spaceTypeOrder, byCapacity, 'spacePrice'],
    'asc'
  )

  return flatten(
    values(
      mapValues(groupBy(sorted, 'spaceType'), o => alphanumericOrder(o, false))
    )
  )
}

const byLookup = {
  price: byPrice,
  perDay: byPerDay,
  perHour: byPerHour,
  pricePromo: byPrice,
  sqft: bySqFt,
  type: byType,
  perPerson: byPerPerson,
  perPersonPromo: byPerPerson,
  capacity: byCapacity,
  date: byDate
}

export const orderSpacesBy = (spaces, by, isDesc) => {
  if (by === 'name') {
    return alphanumericOrder(spaces, isDesc)
  }

  return orderBy(spaces, byLookup[by], isDesc && 'desc')
}

const spaceKeys = [
  'dateAvailable',
  'deleted',
  'description',
  'isAvailable',
  'locationName',
  'locationId',
  'name',
  'spaceCapacity',
  'spaceFloor',
  'spacePrice',
  'pricePerMonth',
  'pricePer6Months',
  'spacePriceConverted',
  'spacePriceCurrency',
  'pricePerDay',
  'pricePerDayConverted',
  'pricePerDayCurrency',
  'pricePerHour',
  'pricePerHourConverted',
  'pricePerHourCurrency',
  'spaceType',
  'spaceView',
  'spaceWindows',
  'interior',
  'sqFt',
  'dollhouseImageId',
  'dollhouseImageName',
  'floorplanPDFId',
  'floorplanPDFName',
  'spaceVirtualTourUrl',
  ...spaceImages.reduce((acc, item) => [...acc, `${item.name}Id`, `${item.name}Name`], []),
  ...spaceAmenities.map(item => item.name)
]

export const proposalResetValues = (resetValues = {}) => [
  'id',
  'name',
  'spacePrice',
  'pricePerMonth',
  'pricePer6Months',
  'sqFt',
  'spaceWindows',
  'interior',
  'floorplanPDFName',
  'floorplanPDFId',
  ...spaceImages.reduce((acc, item) => [...acc, `${item.name}Id`, `${item.name}Name`], [])
].reduce((acc, item) => ({ ...acc, [item]: resetValues[item] }), {})

export const normalizeSpaceObject = data =>
  reduce(
    spaceKeys,
    (results, item) =>
      data[item] !== null || item === 'dateAvailable'
        ? { ...results, [item]: data[item] }
        : results,
    {}
  )

export function errorHandler(status) {
  if (status === 401) {
    return 'Not authorized'
  }

  if (status === 403) {
    return 'You are not allowed to edit spaces of the location'
  }

  return 'Server Error'
}
