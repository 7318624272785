import styled from 'styled-components/macro'
import { lgUp } from '../../styles/breakpoints'

export const ChartWrap = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 2rem;

  @media (min-width: 970px) {
    flex-direction: row;
  }

  .highcharts-credits {
    display: none;
  }

  div[data-highcharts-chart] {
    width: 100%;
  }
`

export const NumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  @media ${lgUp} {
    justify-content: center;
    max-width: 30%;
    width: 100%;
    flex: 0 0 30%;
    margin-bottom: 0;

    ${props => props.dataHeight && `height: ${props.dataHeight}px;`}
  }
`

export const Legend = styled.span`
  color: #666666;
  text-align: center;
  margin-top: 1rem;
`

export const Heading = styled.h1`
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
`

export const Number = styled.span`
  font-size: 32px;
  color: #f77076;
`

export const SubLabel = styled.span`
  font-size: 16px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &[data-text-transform="capitalize"] {
    text-transform: capitalize;
  }
`

export const TopSelectWrap = styled.div`
  display: block;
  padding: 0 20px;
  width: 100%;

  @media (min-width: 970px) {
    width: 190px;
    margin-top: -50px;
    margin-left: auto;
  }
`
