import styled from 'styled-components/macro';
import { mdUp, mdDown } from '../../styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const List = styled.ul`
  padding-left: 0;
  font-size: 14px;
  color: black;
  margin: 0;

  @media ${mdUp} {
    font-size: 13px;
    border-left: none;
    border-right: none;
  }

  @media ${mdDown} {
    overflow: auto;
  }
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-around;
  padding: 13px 5px;
  border-left: 1px solid rgba(0, 0, 0, .2);
  border-right: 1px solid rgba(0, 0, 0, .2);
  margin: 0;
  min-height: 54px;
  overflow: hidden;

  ${props => props.sticky && `
    position: sticky;
    top: 0;
  `}

  :first-child {
    background-color: lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
  }

  :not(:first-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }

  @media ${mdDown} {
    padding: 5px;
    align-items: center;
    width: 1023px;
  }
`;

export const Col = styled.div`
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  > span, > a span {
    word-break: break-all;
  }

  a[data-email="true"] {
    color: #e7483d;
    text-decoration: underline;
  }

  a[data-phone="true"] {
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Filter = styled.button.attrs({ type: 'button' })`
  border: none;
  padding: 0 5px 0 0;
  margin: 0;
  font-weight: 600;
  color: black;
  background: none;
  cursor: pointer;
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 14px;

  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 3px;
    right: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid gray;

    ${props => props.active && props.desc && `
      border-top: 5px solid gray;
      border-bottom: 0;
    `}
  }
`;

export const FilterArrow = styled.button.attrs({ type: 'button' })`
  border: none;
  padding: 0 5px 0 0;
  margin: 0;
  color: black;
  background: none;
  cursor: pointer;
  text-align: left;
  width: 100%;

  span {
    font-size: 0.85rem;
    font-weight: 600;
    line-height: 1.3;

    :not(:first-child) {
      display: block;
    }

    :first-child {
      white-space: nowrap;
    }

    :first-child::after {
      content: '';
      width: 0;
      height: 0;
      display: inline-block;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid gray;
      margin-left: 5px;
      margin-bottom: 3px;

      ${props => props.active && props.desc && `
        border-top: 5px solid gray;
        border-bottom: 0;
      `}
    }
  }
`;
