import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const uploadFile = (token, data) =>
  fetch(`${apiUrl}${apiBase}/Attachment`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
