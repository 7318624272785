import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'connected-react-router'
import Flex from '../../components/flex'
import { Container, Top, Heading, Text, Link } from '../../components/login'
import Spinner from '../../components/spinner'
import {
  Input,
  LabelText,
  Button,
  ErrorText
} from '../../components/form-fields'
import PoweredBy from '../../components/powered-by'
import Logo, { LogoMarketplace } from '../../components/logo'
import { authSignIn } from '../../actions/auth'
import {
  DASHBOARD_ROUTE as dashboardRoute,
  CHANGE_PASS_ROUTE as changePassRoute,
  FORGOT_PASS_ROUTE as forgotPassword
} from '../../settings/routes'
import { isMarketPlace } from '../../utils/subscription'

const Login = () => {
  const auth = useSelector(state => state.auth)
  const user = useSelector(state => state.user)
  const error = auth.status.error || user.status.error
  const loading = auth.status.loading || user.status.loading

  const dispatch = useDispatch()

  useEffect(() => {
    if (user.isAuthenticated) {
      dispatch(push(`/${dashboardRoute}`))
    }
  }, [dispatch, user])

  useEffect(() => {
    if (auth.newPasswordRequired) {
      dispatch(push(`/${changePassRoute}`))
    }
  }, [dispatch, auth.newPasswordRequired])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: ({ email, password }) => {
      dispatch(authSignIn(email, password))
    }
  })

  return (
    <Flex fullWidth fullScreenHeight middle column>
      <Flex fullWidth full middle center>
        <Flex column middle fullWidth>
          {isMarketPlace && (
            <LogoMarketplace mBottom="50px" />
          )}
          <Container>
            {!isMarketPlace && (
              <Top>
                <Logo />
              </Top>
            )}
            <Heading>Sign in with your email and password</Heading>
            <form onSubmit={formik.handleSubmit}>
              <label htmlFor="email">
                <LabelText>Email</LabelText>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={e => {
                    formik.handleChange(e)
                  }}
                  value={formik.values.email}
                  required
                  disabled={loading}
                />
              </label>

              <label htmlFor="password">
                <LabelText>Password</LabelText>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  required
                  disabled={loading}
                />
              </label>
              <Link href={`/${forgotPassword}`}>Forgot your password?</Link>
              {error && (
                <ErrorText>
                  {auth.status.errorText || user.status.errorText}
                </ErrorText>
              )}
              <Button type="submit" disabled={loading} withSpinner>
                {loading && <Spinner elSize={18} />}
                <span>Sign In</span>
              </Button>
            </form>
            <Text>
              {isMarketPlace
                ? 'The easiest way to fill your space and keep it full'
                : 'Flex Wisely.'}
            </Text>
          </Container>
        </Flex>
      </Flex>
      <Flex fullWidth flexEnd>
        <PoweredBy />
      </Flex>
    </Flex>
  )
}

export default Login
