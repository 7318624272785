import React, { Fragment } from 'react'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'

import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import Flex from '../../components/flex'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import OperatorNavigation from '../OperatorNavigation'
import AdvisorNavigation from '../AdvisorNavigation'
import { sortByName } from '../../helpers/sort'

const Breadcrumbs = props => {
  const { childRoute } = props
  const { locationId, marketId } = useParams()
  const { list: markets } = useSelector(state => state.markets)
  const { byMarketId } = useSelector(state => state.locations)
  const marketOptions = sortByName(
    (markets || []).filter(market => !market.isSubmarket)
  ).map(market => market.id)
  const firstMarket = marketOptions[0]
  const locationOptions = sortByName(byMarketId[firstMarket] || [])
  const firstLocation = (locationOptions[0] || {}).id
  const marketRoute = marketId && marketId !== 'all' ? `/${marketId}/` : `/${firstMarket}/`
  const locationRoute = locationId && locationId !== 'all' ? locationId : firstLocation
  const dashboardRoute = `/${DASHBOARD_ROUTE}${marketRoute}${locationRoute}`
  const { flexOfficePro } = useSelector(state => state.user)
  const isFreeAccess = flexOfficePro === 'Free'
  const isReportsAccess = ['Investor', 'Advisor'].includes(flexOfficePro)

  return (
    <Flex fullWidth spaceBetween flexWrap>
      <Wrapper>
        {childRoute ? (
          <Clickable to={`${dashboardRoute}`}>Dashboard</Clickable>
        ) : (
          <Item bold>Dashboard</Item>
        )}
        {childRoute && (
          <Fragment>
            <Delimeter>&gt;</Delimeter>
            <Item bold>{childRoute}</Item>
          </Fragment>
        )}
      </Wrapper>
      {!(isFreeAccess || isReportsAccess) && (
        <OperatorNavigation />
      )}
      {flexOfficePro === 'Advisor' && (
        <AdvisorNavigation />
      )}
    </Flex>
  )
}

export default Breadcrumbs
