import React, { useState, lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import Tooltip from '../../components/tooltip'
import Select from 'react-select'
import { getPieOptions } from './options'
import Flex from '../flex'
import Spinner from '../spinner'

const Chart = lazy(() => import('../chart'))

const selectDataNames = ['Operator', 'Type', 'Size Class']
const viewDataNames = ['Locations', 'Seats', 'Sq/Ft']
const tooltips = [
  'Operator market share shows the allocation of operators across the market either by number of locations, number of seats, or total square footage.',
  'Type market share shows the allocation of the type of centers there are in the market by number of location, number of seats, or total Sq/Ft. Hybrid Flex = mix of private offices and coworking/shared spaces, Executive Suites = mostly private offices, Coworking = mostly all open shared space/desks and dedicated desks, Agile Suites = private, pre-built spaces, Specialty = focused on a specific industry or demographic, Incubators/Accelerators - spaces housing startup programs, Single Tenant Office - private offices for lease from landlord.',
  'Size Class market share shows the allocation of the size of centers there are in the market by number of location, number of seats, or total Sq/Ft. The classes are broken down into centers below 5,000 Sq/FT, in between 5,000 - 15,000 Sq/FT, and above 15,000 Sq/Ft'
]

const MarketShare = ({ marketData }) => {
  const [selectIndex, setSelectIndex] = useState(0)
  const [viewIndex, setViewIndex] = useState(0)
  const options = getPieOptions(marketData[`${selectIndex}-${viewIndex}`])

  return (
    <Wrap>
      <Title>
        Market Share by {selectDataNames[selectIndex]}{' '}
        <Tooltip
          overlay={
            <div>
              <span>{tooltips[selectIndex]}</span>
            </div>
          }
        >
          <Info>(i)</Info>
        </Tooltip>
      </Title>
      <Container>
        <Row>
          <Select
            options={selectDataNames.map((item, index) => ({
              label: item,
              value: index
            }))}
            value={{
              value: selectIndex,
              label: selectDataNames[selectIndex]
            }}
            onChange={({ value }) => {
              setSelectIndex(value)
              setViewIndex(0)
            }}
            isClearable={false}
            isSearchable={false}
            styles={{
              container: provided => ({
                ...provided,
                width: 135
              }),
              indicatorsContainer: (provided, state) => {
                return {
                  ...provided,
                  minWidth: 20,
                  '> div': {
                    padding: 6
                  },
                  svg: {
                    display: 'none'
                  },
                  '&::after': {
                    content: '""',
                    borderStyle: 'solid',
                    borderColor: `${
                      state.hasValue || state.selectProps.menuIsOpen
                        ? '#e7483d'
                        : 'hsl(0,0%,80%)'
                    } transparent transparent`,
                    borderWidth: '5px 5px 2.5px',
                    display: 'inline-block',
                    height: 0,
                    width: 0,
                    position: 'absolute',
                    right: '10px',
                    top: '14px'
                  }
                }
              },
              indicatorSeparator: provided => ({
                ...provided,
                display: 'none'
              }),
              valueContainer: provided => ({
                ...provided,
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                fontSize: 15
              }),
              control: (provided) => {
                return {
                  ...provided,
                  minHeight: 35,
                  borderRadius: 3,
                  borderColor: '#e7483d',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: '#e7483d'
                  }
                }
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected && 'transparent',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                fontSize: 15,
                color: '#000',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'hsl(0,0%,90%)'
                }
              }),
              menu: (provided) => ({
                ...provided,
                border: '1px solid #e7483d',
                boxShadow: 'none'
              })
            }}
          />
          <BtnGroup>
            {viewDataNames
              .filter((item, index) => !(selectIndex === 2 && index === 2))
              .map((item, index) => (
                <Btn
                  key={`${item}-${index}`}
                  data-active={index === viewIndex}
                  onClick={() => setViewIndex(index)}
                >
                  {item}
                </Btn>
              ))}
          </BtnGroup>
        </Row>
        <ChartWrap>
          <Suspense
            fallback={
              <Flex fullWidth fullHeight center>
                <Spinner elSize={40} />
              </Flex>
            }
          >
            <Chart options={options} />
          </Suspense>
        </ChartWrap>
      </Container>
    </Wrap>
  )
}

const ChartWrap = styled.div`
  width: 100%;

  .highcharts-credits {
    display: none;
  }
`

const BtnGroup = styled.div`
  display: flex;
  flex-wrap: nowrap;
`

const Btn = styled.button.attrs({ type: 'button' })`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  box-shadow: none;
  padding: 7px 10px;
  border-width: 1px;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #e7483d;
  border-bottom-color: #e7483d;
  background-color: white;
  cursor: pointer;
  border-right-color: #e7483d;
  font-size: 15px;

  &:first-child {
    border-left-color: #e7483d;
  }

  &[data-active='true'] {
    background-color: #e7483d;
    color: white;

    &:not(:last-child) {
      border-right-color: #e7483d;
    }
  }

  &:disabled[data-active='false'],
  &[disabled][data-active='false'] {
    color: black;
  }

  &:disabled[data-active='true'],
  &[disabled][data-active='true'] {
    color: white;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Info = styled.span`
  margin-right: 3px;
  font-weight: 700;
  cursor: pointer;
`

const Wrap = styled.div`
  width: 100%;
  border: 1px solid #57ab83;
  border-radius: 10px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  background-color: white;
  max-width: 600px;
  margin-bottom: 10px;
`

const Title = styled.span`
  color: #e7483d;
  font-weight: 700;
  text-align: center;
  display: block;
  font-size: 20px;
  margin-bottom: 14px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export default MarketShare
