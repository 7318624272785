import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  MONTHLY_LEADS_TOTAL_REQUEST,
  MONTHLY_LEADS_TOTAL_SUCCESS,
  MONTHLY_LEADS_TOTAL_FAIL,
  LATEST_LEADS_REQUEST,
  LATEST_LEADS_SUCCESS,
  LATEST_LEADS_FAIL,
  SET_NORTH_AMERICA,
  WORK_LEADS_REQUEST,
  WORK_LEADS_SUCCESS,
  WORK_LEADS_FAIL,
  EDIT_LEAD_SHOW,
  EDIT_LEAD_HIDE,
  LEAD_UPDATE_REQUEST,
  LEAD_UPDATE_SUCCESS,
  LEAD_UPDATE_FAIL,
  ADD_LEAD_SHOW,
  ADD_LEAD_HIDE,
  SHOW_LEAD_SHORTLIST,
  HIDE_LEAD_SHORTLIST,
  SHOW_LEAD_PROPOSALS,
  HIDE_LEAD_PROPOSALS,
  LEAD_ADD_REQUEST,
  LEAD_ADD_SUCCESS,
  LEAD_ADD_FAIL,
  ADD_TO_SHORTLIST,
  BUNCH_ADD_TO_SHORTLIST,
  REMOVE_FROM_SHORTLIST
} from '../constants'
import * as api from '../api/broker-tools'
import { getTokenWithExpiration } from './auth'

const monthlyLeadsGetRequest = () => ({
  type: MONTHLY_LEADS_TOTAL_REQUEST
})

const monthlyLeadsGetSuccess = payload => ({
  type: MONTHLY_LEADS_TOTAL_SUCCESS,
  payload
})

const monthlyLeadsGetFailure = () => ({
  type: MONTHLY_LEADS_TOTAL_FAIL
})

export const monthlyLeadsGet = () => (dispatch, getState) => {
  if (getState().brokerTools.monthlyLeadsCount.payload) {
    return null
  }

  dispatch(monthlyLeadsGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getMonthlyLeadsTotal(token)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(monthlyLeadsGetSuccess(response))
        })
        .catch(error => {
          if (error) {
            dispatch(monthlyLeadsGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const latestLeadsGetRequest = () => ({
  type: LATEST_LEADS_REQUEST
})

const latestLeadsGetSuccess = payload => ({
  type: LATEST_LEADS_SUCCESS,
  payload
})

const latestLeadsGetFailure = () => ({
  type: LATEST_LEADS_FAIL
})

export const latestLeadsGet = count => (dispatch, getState) => {
  if (getState().brokerTools.latestLeads.payload) {
    return null
  }

  dispatch(latestLeadsGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLatestLeads(token, count)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(latestLeadsGetSuccess(response))
        })
        .catch(error => {
          if (error) {
            dispatch(latestLeadsGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

export const setNorthAmerica = payload => ({
  type: SET_NORTH_AMERICA,
  payload
})

const workLeadsGetRequest = payload => ({
  type: WORK_LEADS_REQUEST,
  payload
})

const workLeadsGetSuccess = payload => ({
  type: WORK_LEADS_SUCCESS,
  payload
})

const workLeadsGetFailure = () => ({
  type: WORK_LEADS_FAIL
})

export const workLeadsGet = params => (dispatch, getState) => {
  dispatch(workLeadsGetRequest(params))

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getWorkLeads(token, params)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(workLeadsGetSuccess(response))
        })
        .catch(error => {
          if (error) {
            dispatch(workLeadsGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const leadUpdateRequest = () => ({
  type: LEAD_UPDATE_REQUEST
})

const leadUpdateSuccess = payload => ({
  type: LEAD_UPDATE_SUCCESS,
  payload
})

const leadUpdateFailure = payload => ({
  type: LEAD_UPDATE_FAIL,
  payload
})

export const leadUpdate = formData => (dispatch, getState) => {
  dispatch(leadUpdateRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .updateLead(token, formData)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(() => {
          dispatch(leadUpdateSuccess(formData))

          return true
        })
        .catch(error => {
          if (error) {
            dispatch(leadUpdateFailure(error))
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const leadAddRequest = () => ({
  type: LEAD_ADD_REQUEST
})

const leadAddSuccess = payload => ({
  type: LEAD_ADD_SUCCESS,
  payload
})

const leadAddFailure = payload => ({
  type: LEAD_ADD_FAIL,
  payload
})

export const leadAdd = formData => (dispatch, getState) => {
  dispatch(leadAddRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .addLead(token, formData)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(() => {
          dispatch(leadAddSuccess(formData))

          return true
        })
        .catch(error => {
          if (error) {
            dispatch(leadAddFailure(error))
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

export const leadEditShow = payload => ({
  type: EDIT_LEAD_SHOW,
  payload
})

export const leadEditHide = () => ({
  type: EDIT_LEAD_HIDE
})

export const leadAddShow = payload => ({
  type: ADD_LEAD_SHOW,
  payload
})

export const leadAddHide = () => ({
  type: ADD_LEAD_HIDE
})

export const showLeadShortlist = payload => ({
  type: SHOW_LEAD_SHORTLIST,
  payload
})

export const hideLeadShortlist = () => ({
  type: HIDE_LEAD_SHORTLIST
})

export const showLeadProposals = payload => ({
  type: SHOW_LEAD_PROPOSALS,
  payload
})

export const hideLeadProposals = () => ({
  type: HIDE_LEAD_PROPOSALS
})

export const addToShortlist = (payload) => ({
  type: ADD_TO_SHORTLIST,
  payload
})

export const bunchAddToShortlist = (payload) => ({
  type: BUNCH_ADD_TO_SHORTLIST,
  payload
})

export const removeFromShortlist = (payload) => ({
  type: REMOVE_FROM_SHORTLIST,
  payload
})
