import styled from 'styled-components/macro';
import { lgUp, xlgUp } from '../../styles/breakpoints';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  &[data-widgets="true"] {
    max-width: 1600px;
  }

  &[data-status="Suspended"] {
    filter: blur(5px);
    user-select: none;
    pointer-events: none;
  }
`;

export const FreeAccountHeading = styled.h2`
  color: #e7483d;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  @media ${lgUp} {
    max-width: 1200px;
    padding-left: 300px;
  }
`;

export const Col = styled.div`
  width: 100%;
  max-width: 300px;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 20px;

  @media ${xlgUp} {
    margin-bottom: auto;
    margin-top: auto;

    ${FreeAccountHeading} ~ & {
      margin-top: 0;
    }
  }

  &[data-width='35'] {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;

    @media ${xlgUp} {
      margin-bottom: auto;
      max-width: 35%;
    }
  }

  &[data-width='30'] {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;

    @media ${xlgUp} {
      margin-bottom: auto;
      max-width: 30%;
    }
  }

  &[data-width='40'] {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;

    @media ${xlgUp} {
      margin-bottom: auto;
      max-width: 40%;
    }
  }

  &[data-width='20'] {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;

    @media ${xlgUp} {
      margin-bottom: auto;
      max-width: 20%;
    }
  }
`

export const ClickableArea = styled.div`
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  padding: 25px;
  -webkit-appearance: none;
`;

export const BoxArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 100%;
  background-color: ${props =>
    props.restricted ? 'lightgray' : 'transparent'};
`;

export const Heading = styled.h2`
  text-align: center;
  margin: 0;
  color: ${props => props.color};
`;

export const Text = styled.p`
  color: ${props => (props.color ? props.color : 'black')};
`;

export const IconLink = styled.a`
  color: ${props => (props.color ? props.color : 'black')};

  svg {
    width: 20px;
    height: 20px;
  }
`;
