import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'qs';
import { useParams } from 'react-router';
import Flex from '../../components/flex';
import Spinner from '../../components/spinner';
import { Wrap } from '../../components/chart/wrap';

import { pipelineGet } from '../../actions/pipeline';
import { getPipelineOptions } from '../../settings/chart-settings';

const Chart = lazy(() => import('../../components/chart'));

const Pipeline = () => {
  const { locationId, marketId } = useParams();
  const { token } = useSelector(state => state.auth);
  const { byRoute } = useSelector(state => state.pipeline);
  const { flexOfficePro } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const params = stringify({ market: marketId, location: locationId });

  const isAdvanced =
    flexOfficePro === 'Advanced' || flexOfficePro === 'Enterprise';

  useEffect(() => {
    if (!byRoute[params]) {
      dispatch(pipelineGet(token, params));
    }
  }, [token, dispatch, byRoute, params]);

  return (
    <Flex fullWidth alignStart>
      <Wrap height={isAdvanced ? 500 : 300}>
        <Suspense
          fallback={
            <Flex fullWidth fullHeight center>
              <Spinner elSize={40} />
            </Flex>
          }
        >
          <Chart options={getPipelineOptions(byRoute[params], isAdvanced)} />
        </Suspense>
      </Wrap>
    </Flex>
  );
};

export default Pipeline;
