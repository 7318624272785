import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useMediaQuery } from 'react-responsive'

import Flex from '../../components/flex'

import Sidebar from '../../containers/Sidebar'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Pipeline from '../../containers/Pipeline'
import ViewsInSearch from '../../containers/ViewsInSearch'
import LeadPerformance from '../../containers/LeadPerformance'
import PricePerformance from '../../containers/PricePerformance'
import { smOnly } from '../../styles/breakpoints'

import { locationGet } from '../../actions/locations'

import { DEMAND_AND_PRICING_ROUTE, PIPELINE_ROUTE } from '../../settings/routes'

const DemandAndPricing = () => {
  const { locationId } = useParams()
  const { byId } = useSelector(state => state.location)
  const dispatch = useDispatch()
  const mobile = useMediaQuery({ query: smOnly })

  useEffect(() => {
    if (!byId[locationId]) {
      dispatch(locationGet(locationId))
    }
  }, [dispatch, locationId, byId])

  return (
    <Flex fullWidth fullHeight column>
      <Breadcrumbs childRoute="Market Demand & Pricing" />
      <Flex fullWidth fullHeight alignStart column={mobile}>
        <Sidebar />

        <Switch>
          <Route
            exact
            path={`/${DEMAND_AND_PRICING_ROUTE}/${PIPELINE_ROUTE}/:marketId/:locationId?`}
          >
            <Pipeline />
          </Route>
          <Route
            exact
            path={`/${DEMAND_AND_PRICING_ROUTE}/views-in-search/:marketId/:locationId?`}
          >
            <ViewsInSearch />
          </Route>
          <Route
            exact
            path={`/${DEMAND_AND_PRICING_ROUTE}/lead-performance/:marketId/:locationId?`}
          >
            <LeadPerformance />
          </Route>
          <Route
            exact
            path={[
              `/${DEMAND_AND_PRICING_ROUTE}/price-performance/:marketId/:locationId?`,
              `/${DEMAND_AND_PRICING_ROUTE}/price-performance/:marketId/:locationId/:spaceType/:measure/:unit`
            ]}
          >
            <PricePerformance />
          </Route>

          <Route
            exact
            path={[
              `/${DEMAND_AND_PRICING_ROUTE}/standard-suite-sizes/:marketId/:locationId?`,
              `/${DEMAND_AND_PRICING_ROUTE}/standard-suite-sizes/:marketId/:locationId/:spaceType/:measure/:unit`
            ]}
          >
            <PricePerformance suites />
          </Route>

          <Route
            exact
            path={[
              `/${DEMAND_AND_PRICING_ROUTE}/average-size/:marketId/:locationId?`,
              `/${DEMAND_AND_PRICING_ROUTE}/average-size/:marketId/:locationId/:spaceType/:measure/:unit`
            ]}
          >
            <PricePerformance suites averageSize />
          </Route>

          <Redirect
            exact
            path={`/${DEMAND_AND_PRICING_ROUTE}/:marketId/:locationId?`}
            to={`/${DEMAND_AND_PRICING_ROUTE}/${PIPELINE_ROUTE}/:marketId/:locationId?`}
          />
        </Switch>
      </Flex>
    </Flex>
  )
}

export default DemandAndPricing
