import cloneDeep from 'lodash/cloneDeep'

export const getOptions = ({ selected, type, typeValues = [], marketValues = [], categories }) => {
  const typeData = cloneDeep(typeValues)
  const marketData = cloneDeep(marketValues)

  return {
    chart: {
      type: 'column',
      height: 300
    },
    title: {
      text: ''
    },
    xAxis: {
      tickLength: 0,
      categories: categories
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      title: {
        text: selected
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0
      },
      series: {
        dataLabels: {
          enabled: false,
          allowOverlap: false
        }
      }
    },
    series: [
      {
        name: type,
        color: 'rgb(231, 72, 61)',
        data: typeData
      },
      {
        name: 'Market Average',
        color: 'rgb(191, 191, 191)',
        data: marketData
      }
    ]
  }
}
