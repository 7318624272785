import React from 'react';
import styled from 'styled-components/macro';
import { LogoIcon } from '../../components/icons';
import { lgUp, mdDown, smOnly, mdUp } from '../../styles/breakpoints';
import { LogoMarketplace } from '../../components/logo'

const hmdUp = '(min-width: 970px)';
const hmdDown = '(max-width: 969px)';

const Outer = styled.div`
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid #828282;
  box-shadow: none;
  flex-shrink: 0;
  width: 100%;
  z-index: 20;
`;

const Inner = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  width: 100%;

  @media ${lgUp} {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media ${hmdUp} and ${mdDown} {
    justify-content: space-between;
  }

  @media ${hmdDown} {
    flex-wrap: wrap;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  @media ${hmdUp} {
    height: 64px;
  }

  @media ${smOnly} {
    justify-content: center;
  }
`;

export const Wrapper = ({ children }) => {
  return (
    <Outer>
      <Inner>{children}</Inner>
    </Outer>
  );
};

const LeftWrap = styled.div`
  display: flex;
  align-items: center;

  @media ${hmdUp} {
    width: 33.33%;
  }

  @media ${hmdDown} {
    width: 60%;
    order: 1;
    margin-bottom: 10px;
  }

  @media ${smOnly} {
    width: 100%;
    justify-content: center;
  }
`;

const LeftText = styled.span`
  font-size: 29px;
  font-weight: 700;
  white-space: nowrap;

  @media ${smOnly} {
    font-size: 22px;
  }
`;

const LeftLogo = styled(LogoIcon)`
  width: 30px;
`;

export const Left = ({ text, subscription }) => {
  const isPro = ['Investor', 'Advisor'].includes(subscription)

  return (
    <LeftWrap>
      {isPro ? (
        <>
          <LeftLogo />
          <LeftText>{text}</LeftText>
        </>
      ) : (
        <LogoMarketplace elWidth="320px" mLeft="8px" />
      )}
    </LeftWrap>
  )
};

export const Center = styled.div`
  display: flex;
  justify-content: center;

  @media ${hmdUp} {
    width: 33.33%;
  }

  @media ${hmdDown} {
    width: 100%;
    order: 3;
    flex-direction: column;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;

  @media ${hmdUp} {
    width: 50%;
    margin-bottom: 0;

    &:first-child {
      margin-right: 10px;
    }
  }
`;

export const Status = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin-right: auto;

  @media ${mdUp} {
    margin-left: auto;
  }

  > span {
    display: block;
  }

  &[data-status="Suspended"] {
    > span:last-child {
      color: #e7483d;
    }
  }

  &[data-status="Active"] {
    > span:last-child {
      color: #57AB83;
    }
  }

  &[data-status="Needs Completion"],
  &[data-status="In Review"] {
    > span:last-child {
      color: black;
    }
  }
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${hmdUp} {
    width: 33.33%;
  }

  @media ${hmdDown} {
    width: 40%;
    order: 2;
    margin-bottom: 10px;
  }

  @media ${smOnly} {
    width: 100%;
  }
`;
