import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { Wrapper, Item, SubItem } from '../../components/sidebar'
import {
  SPACES_ROUTE,
  LOCATION_ROUTE,
  LOCATION_SETUP_ROUTE,
  LOCATION_CONTACTS_ROUTE,
  LOCATION_ENVIRONMENT_ROUTE,
  LOCATION_AMENITIES_ROUTE,
  LOCATION_IMAGERY_ROUTE,
  LOCATION_NETWORK_ROUTE,
  LOCATION_REVIEWS_ROUTE,
  LOCATION_PROMO_ROUTE
} from '../../settings/routes'

const Sidebar = () => {
  const { marketId, locationId } = useParams()

  return (
    <Wrapper>
      <Item>Spaces</Item>
      <SubItem>
        <NavLink
          to={`/${SPACES_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Manage Spaces
        </NavLink>
      </SubItem>

      <Item>Location</Item>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_SETUP_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Setup
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_CONTACTS_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Contacts
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_ENVIRONMENT_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Work Environment
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_AMENITIES_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Amenities
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_IMAGERY_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Imagery
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_NETWORK_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Network & IT
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_REVIEWS_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Tenant Reviews
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${LOCATION_ROUTE}/${LOCATION_PROMO_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          Promotions
        </NavLink>
      </SubItem>
    </Wrapper>
  )
}

export default Sidebar
