import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  LEAD_PERFORMANCE_GET_REQUEST,
  LEAD_PERFORMANCE_GET_SUCCESS,
  LEAD_PERFORMANCE_GET_FAILURE,
  LEADS_AND_VIEWS_GET_REQUEST,
  LEADS_AND_VIEWS_GET_SUCCESS,
  LEADS_AND_VIEWS_GET_FAILURE
} from '../constants'
import * as api from '../api/lead-performance'
import { getTokenWithExpiration } from './auth'

const leadPerformanceGetRequest = () => ({
  type: LEAD_PERFORMANCE_GET_REQUEST
})

const leadPerformanceGetSuccess = (payload, config) => ({
  type: LEAD_PERFORMANCE_GET_SUCCESS,
  payload,
  config
})

const leadPerformanceGetFailure = () => ({
  type: LEAD_PERFORMANCE_GET_FAILURE
})

export const leadPerformanceGet = params => (dispatch, getState) => {
  if (getState().leadPerformance.byRoute[params]) {
    return null
  }

  dispatch(leadPerformanceGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLeadPerformance(token, params)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(leadPerformanceGetSuccess(response, params))
        })
        .catch(error => {
          if (error) {
            dispatch(leadPerformanceGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const leadsAndViewsGetRequest = () => ({
  type: LEADS_AND_VIEWS_GET_REQUEST
})

const leadsAndViewsGetSuccess = (payload, config) => ({
  type: LEADS_AND_VIEWS_GET_SUCCESS,
  payload,
  config
})

const leadsAndViewsGetFailure = () => ({
  type: LEADS_AND_VIEWS_GET_FAILURE
})

export const leadsAndViewsGet = id => (dispatch, getState) => {
  if (getState().leadPerformance.leadsAndViews.byId[id]) {
    return null
  }

  dispatch(leadsAndViewsGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLeadsAndViews(token, id)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(leadsAndViewsGetSuccess(response, id))
        })
        .catch(error => {
          if (error) {
            dispatch(leadsAndViewsGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
