import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import Select from 'react-select'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import DatePicker from '../../components/datepicker'
import * as Styled from '../../components/location'
import Spinner from '../../components/spinner'
import TextareaCounter from '../../components/textarea-counter'
import { smOnly } from '../../styles/breakpoints'
import { locationUpdate } from '../../actions/locations'
import { getSetupInitialValues, currencyOptions } from '../../helpers/location'
import LandlordSelect from '../../components/landlord-select'
import HoodSelect from '../../components/hood-select'

const LocationSetup = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)
  const formik = useFormik({
    initialValues: getSetupInitialValues(location),
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      dispatch(locationUpdate(locationId, values))
    }
  })
  const mobile = useMediaQuery({ query: smOnly })
  const handleResetForm = () =>
    formik.resetForm({ values: getSetupInitialValues(location) })

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.Header fullWidth spaceBetween>
            <Styled.Note>
              <p>
                <b>Note:</b> Fill out all fields below and save your
                information. Public facing Fields are indicated with an *.
              </p>
              <p>
                Choose the location you want to edit from the right drop down
                above.
              </p>
            </Styled.Note>
            <Styled.Actions mobile={mobile}>
              {updating && <Spinner elSize={20} />}
              <>
                <Styled.Action
                  disabled={updating}
                  type="button"
                  onClick={() => handleResetForm()}
                >
                  Cancel
                </Styled.Action>
                <Styled.Action disabled={updating} type="submit">
                  Save
                </Styled.Action>
              </>
            </Styled.Actions>
          </Styled.Header>
          <Styled.Section>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  <Styled.Title>Location Overview</Styled.Title>
                </Styled.Col>
              </Styled.Row>
              <Styled.Row>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="name">
                    <Styled.LabelText data-invalid={!formik.values.name}>
                      Location Name*
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="_"
                      value={formik.values.name || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      disabled={updating}
                    />
                  </Styled.Label>
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label>
                    <Styled.LabelText data-invalid={!location?.operatorName}>
                      Operator*
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      placeholder="_"
                      disabled
                      value={location?.operatorName || ''}
                    />
                  </Styled.Label>
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label>
                    <Styled.LabelText data-invalid={!location?.marketName}>
                      Market*
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      placeholder="_"
                      disabled
                      value={location?.marketName || ''}
                    />
                  </Styled.Label>
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  {location && (
                    <HoodSelect
                      value={
                        formik.values.neighborhoodId && {
                          label: formik.values.neighborhoodName,
                          value: formik.values.neighborhoodId
                        }
                      }
                      onChange={value => {
                        formik.setFieldValue('neighborhoodName', value?.name)
                        formik.setFieldValue('neighborhoodId', value?.id)
                      }}
                      disabled={updating}
                    />
                  )}
                </Styled.Col>
              </Styled.Row>
              <Styled.Row>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label>
                    <Styled.LabelText
                      data-invalid={
                        !(
                          formik.values.addressCity &&
                          formik.values.addressCity &&
                          formik.values.addressState &&
                          formik.values.addressPostalCode &&
                          formik.values.addressCountry
                        )
                      }
                    >
                      Address*
                    </Styled.LabelText>
                    <Styled.Col maxWidth="100%" noPad noMar>
                      <Styled.Input
                        type="text"
                        id="addressStreet"
                        name="addressStreet"
                        placeholder="Address Street*"
                        value={formik.values.addressStreet || ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                        disabled={updating}
                      />
                    </Styled.Col>
                    <Styled.Row>
                      <Styled.Col maxWidth="50%" data-padding="right" noMar>
                        <Styled.Input
                          type="text"
                          id="addressCity"
                          name="addressCity"
                          placeholder="Address City*"
                          value={formik.values.addressCity || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          disabled={updating}
                        />
                      </Styled.Col>
                      <Styled.Col maxWidth="50%" data-padding="left" noMar>
                        <Styled.Input
                          type="text"
                          id="addressState"
                          name="addressState"
                          placeholder="Address State*"
                          value={formik.values.addressState || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          disabled={updating}
                        />
                      </Styled.Col>
                    </Styled.Row>
                    <Styled.Row>
                      <Styled.Col maxWidth="50%" data-padding="right" noMar>
                        <Styled.Input
                          type="text"
                          id="addressPostalCode"
                          name="addressPostalCode"
                          placeholder="Postal Code*"
                          value={formik.values.addressPostalCode || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          disabled={updating}
                        />
                      </Styled.Col>
                      <Styled.Col maxWidth="50%" data-padding="left" noMar>
                        <Styled.Input
                          type="text"
                          id="addressCountry"
                          name="addressCountry"
                          placeholder="Country*"
                          value={formik.values.addressCountry || ''}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          disabled={updating}
                        />
                      </Styled.Col>
                    </Styled.Row>
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !(
                      formik.values.addressCity &&
                      formik.values.addressCity &&
                      formik.values.addressState &&
                      formik.values.addressPostalCode &&
                      formik.values.addressCountry
                    ) && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="latitude">
                    <Styled.LabelText data-invalid={!formik.values.latitude}>
                      Latitude*
                    </Styled.LabelText>
                    <Styled.Input
                      type="number"
                      step="any"
                      id="latitude"
                      name="latitude"
                      placeholder="_"
                      value={formik.values.latitude || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 && !formik.values.latitude && (
                    <Styled.FieldError>
                      This field is missing a value
                    </Styled.FieldError>
                  )}
                  <Styled.Label htmlFor="longitude">
                    <Styled.LabelText data-invalid={!formik.values.longitude}>
                      Longitude*
                    </Styled.LabelText>
                    <Styled.Input
                      type="number"
                      step="any"
                      id="longitude"
                      name="longitude"
                      placeholder="_"
                      value={formik.values.longitude || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 && !formik.values.longitude && (
                    <Styled.FieldError>
                      This field is missing a value
                    </Styled.FieldError>
                  )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '50%' : '100%'}>
                  <Styled.Label htmlFor="description">
                    <Styled.LabelText data-invalid={!formik.values.description}>
                      Location Description*
                    </Styled.LabelText>
                    <TextareaCounter
                      id="description"
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                      placeholder="_"
                      value={formik.values.description || ''}
                      rows={5}
                      disabled={updating}
                      withCounter
                      maxLength={500}
                    />
                  </Styled.Label>
                </Styled.Col>
              </Styled.Row>
              <Styled.Row>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="totalSquareFootage">
                    <Styled.LabelText
                      data-invalid={!formik.values.totalSquareFootage}
                    >
                      Total Square Footage
                    </Styled.LabelText>
                    <Styled.Input
                      type="number"
                      step="any"
                      id="totalSquareFootage"
                      name="totalSquareFootage"
                      placeholder="_"
                      value={formik.values.totalSquareFootage || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values.totalSquareFootage && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="operatorTotalSeats">
                    <Styled.LabelText
                      data-invalid={!formik.values.operatorTotalSeats}
                    >
                      Total # of Seats
                    </Styled.LabelText>
                    <Styled.Input
                      type="number"
                      step="any"
                      id="operatorTotalSeats"
                      name="operatorTotalSeats"
                      placeholder="_"
                      value={formik.values.operatorTotalSeats || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values.operatorTotalSeats && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="dateOpened">
                    <Styled.LabelText data-invalid={!formik.values.dateOpened}>
                      Opening Date
                    </Styled.LabelText>
                    <Styled.DateIcon />
                    <DatePicker
                      value={formik.values.dateOpened}
                      id="dateOpened"
                      name="dateOpened"
                      onChange={value =>
                        formik.setFieldValue('dateOpened', value)
                      }
                      disabled={updating}
                      bgInput
                      altInput={false}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 && !formik.values.dateOpened && (
                    <Styled.FieldError>
                      This field is missing a value
                    </Styled.FieldError>
                  )}
                </Styled.Col>
              </Styled.Row>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  <Styled.Title>Location Revenue & Profit</Styled.Title>
                </Styled.Col>
              </Styled.Row>
              <Styled.Row>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  {location && (
                    <LandlordSelect
                      value={
                        formik.values?.landlordDataId && {
                          label: formik.values?.landlordDataName,
                          value: formik.values?.landlordDataId
                        }
                      }
                      onChange={({ id, name }) => {
                        formik.setFieldValue('landlordDataId', id)
                        formik.setFieldValue('landlordDataName', name)
                      }}
                    />
                  )}
                  {formik.submitCount > 0 && !formik.values?.landlordDataId && (
                    <Styled.FieldError>
                      This field is missing a value
                    </Styled.FieldError>
                  )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="freeRentStartDate">
                    <Styled.LabelText
                      data-invalid={!formik.values.freeRentStartDate}
                    >
                      Free Rent Start Date
                    </Styled.LabelText>
                    <Styled.DateIcon />
                    <DatePicker
                      value={formik.values.freeRentStartDate}
                      id="freeRentStartDate"
                      name="freeRentStartDate"
                      onChange={value =>
                        formik.setFieldValue('freeRentStartDate', value)
                      }
                      disabled={updating}
                      bgInput
                      altInput={false}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.freeRentStartDate && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="freeRentEndDate">
                    <Styled.LabelText
                      data-invalid={!formik.values.freeRentEndDate}
                    >
                      Free Rent End Date
                    </Styled.LabelText>
                    <Styled.DateIcon />
                    <DatePicker
                      value={formik.values.freeRentEndDate}
                      id="freeRentEndDate"
                      name="freeRentEndDate"
                      onChange={value =>
                        formik.setFieldValue('freeRentEndDate', value)
                      }
                      disabled={updating}
                      bgInput
                      altInput={false}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.freeRentEndDate && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
              </Styled.Row>

              <Styled.Row>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="monthlyRentPayment">
                    <Styled.LabelText
                      data-invalid={
                        !(
                          formik.values.monthlyRentPayment &&
                          formik.values.monthlyRentPaymentCurrency
                        )
                      }
                    >
                      Monthly Rent Payment
                    </Styled.LabelText>
                  </Styled.Label>
                  <Styled.Col
                    maxWidth="60%"
                    data-padding="right"
                    noMar
                    mPadRight="10px"
                  >
                    <Styled.Input
                      type="number"
                      step="any"
                      id="monthlyRentPayment"
                      name="monthlyRentPayment"
                      value={formik.values.monthlyRentPayment || ''}
                      placeholder="_"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Col>
                  <Styled.Col
                    maxWidth="40%"
                    data-padding="left"
                    noMar
                    mPadLeft="10px"
                  >
                    <Select
                      name="monthlyRentPaymentCurrency"
                      placeholder="_"
                      styles={Styled.Select}
                      isSearchable={false}
                      options={currencyOptions}
                      value={
                        formik.values.monthlyRentPaymentCurrency && {
                          label: formik.values.monthlyRentPaymentCurrency,
                          value: formik.values.monthlyRentPaymentCurrency
                        }
                      }
                      onChange={({ value }) => {
                        formik.setFieldValue(
                          'monthlyRentPaymentCurrency',
                          value
                        )
                      }}
                      mobile={mobile}
                      disabled={updating}
                    />
                  </Styled.Col>
                  {formik.submitCount > 0 &&
                    !(
                      formik.values.monthlyRentPaymentCurrency &&
                      formik.values.monthlyRentPayment
                    ) && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="monthlyOperatingCostPayment">
                    <Styled.LabelText
                      data-invalid={
                        !(
                          formik.values.monthlyOperatingCostPayment &&
                          formik.values.monthlyOperatingCostPaymentCurrency
                        )
                      }
                    >
                      Monthly Operating Cost Payment
                    </Styled.LabelText>
                  </Styled.Label>
                  <Styled.Col
                    maxWidth="60%"
                    data-padding="right"
                    noMar
                    mPadRight="10px"
                  >
                    <Styled.Input
                      type="number"
                      step="any"
                      id="monthlyOperatingCostPayment"
                      name="monthlyOperatingCostPayment"
                      value={formik.values.monthlyOperatingCostPayment || ''}
                      placeholder="_"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Col>
                  <Styled.Col
                    maxWidth="40%"
                    data-padding="left"
                    noMar
                    mPadLeft="10px"
                  >
                    <Select
                      name="monthlyOperatingCostPaymentCurrency"
                      placeholder="_"
                      styles={Styled.Select}
                      isSearchable={false}
                      options={currencyOptions}
                      value={
                        formik.values.monthlyOperatingCostPaymentCurrency && {
                          label:
                            formik.values.monthlyOperatingCostPaymentCurrency,
                          value:
                            formik.values.monthlyOperatingCostPaymentCurrency
                        }
                      }
                      onChange={({ value }) => {
                        formik.setFieldValue(
                          'monthlyOperatingCostPaymentCurrency',
                          value
                        )
                      }}
                      mobile={mobile}
                      disabled={updating}
                    />
                  </Styled.Col>
                  {formik.submitCount > 0 &&
                    !(
                      formik.values.monthlyOperatingCostPaymentCurrency &&
                      formik.values.monthlyOperatingCostPayment
                    ) && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="monthlyRevenueGoal">
                    <Styled.LabelText
                      data-invalid={
                        !(
                          formik.values.monthlyRevenueGoal &&
                          formik.values.monthlyRevenueGoalCurrency
                        )
                      }
                    >
                      Monthly Revenue Goal
                    </Styled.LabelText>
                  </Styled.Label>
                  <Styled.Col
                    maxWidth="60%"
                    data-padding="right"
                    noMar
                    mPadRight="10px"
                  >
                    <Styled.Input
                      type="number"
                      step="any"
                      id="monthlyRevenueGoal"
                      name="monthlyRevenueGoal"
                      value={formik.values.monthlyRevenueGoal || ''}
                      placeholder="_"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Col>
                  <Styled.Col
                    maxWidth="40%"
                    data-padding="left"
                    noMar
                    mPadLeft="10px"
                  >
                    <Select
                      name="monthlyRevenueGoalCurrency"
                      placeholder="_"
                      styles={Styled.Select}
                      isSearchable={false}
                      options={currencyOptions}
                      value={
                        formik.values.monthlyRevenueGoalCurrency && {
                          label: formik.values.monthlyRevenueGoalCurrency,
                          value: formik.values.monthlyRevenueGoalCurrency
                        }
                      }
                      onChange={({ value }) => {
                        formik.setFieldValue(
                          'monthlyRevenueGoalCurrency',
                          value
                        )
                      }}
                      mobile={mobile}
                      disabled={updating}
                    />
                  </Styled.Col>
                  {formik.submitCount > 0 &&
                    !(
                      formik.values.monthlyRevenueGoalCurrency &&
                      formik.values.monthlyRevenueGoal
                    ) && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
              </Styled.Row>
            </Flex>
          </Styled.Section>
        </Styled.Form>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationSetup
