import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  padding: 25px;
  display: flex;
  align-items: center;
  font-size: 18px;

  white-space: nowrap;
  flex-wrap: wrap;
`;

export const Delimeter = styled.span`
  margin-left: 7px;
  margin-right: 7px;
  line-height: 1;
`;

export const Clickable = styled(Link)`
  color: black;
  line-height: 1;

  :hover {
    text-decoration: underline;
  }
`;

export const Item = styled.span`
  font-weight: ${props => (props.bold ? 600 : 400)};
  color: black;
  line-height: 1;
`;
