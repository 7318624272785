import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { push } from 'connected-react-router'
import Select from 'react-select'

import { TopSelectors, SelectorWrap } from '../../components/price-performance'

import { DEMAND_AND_PRICING_ROUTE } from '../../settings/routes'

const spaceTypeOptions = [
  { value: 'Private Office', label: 'Private Office' },
  { value: '2 person Suite', label: '2 person Suite' },
  { value: '3 person Suite', label: '3 person Suite' },
  { value: '4 person Suite', label: '4 person Suite' },
  { value: '5 person Suite', label: '5 person Suite' },
  { value: '6 person Suite', label: '6 person Suite' },
  { value: '10+ person Suite', label: '10+ person Suite' }
]

const measureOptions = [{ value: 'Average Size', label: 'Average Size' }]

const unitOptions = [{ value: 'sqft per suite', label: 'sqft per suite' }]

const Selectors = () => {
  const {
    locationId,
    marketId,
    spaceType: spaceTypeParam,
    measure: measureParam,
    unit: unitParam
  } = useParams()
  const dispatch = useDispatch()

  const [spaceType, setSpaceType] = useState(spaceTypeParam || '4 person Suite')
  const [measure, setMeasure] = useState(measureParam || 'Average Size')
  const [unit, setUnit] = useState(
    unitParam
      ? { value: unitParam, label: unitParam }
      : { value: '$ per suite', label: '$ per suite' }
  )

  useEffect(() => {
    if (
      spaceType &&
      measure &&
      unit &&
      !unitOptions.find(item => item.value === unit.value)
    ) {
      setUnit(unitOptions[0])
    }

    if (spaceType && measure && unit) {
      dispatch(
        push(
          `/${DEMAND_AND_PRICING_ROUTE}/average-size/${marketId}/${locationId}/${spaceType}/${measure}/${unit.value}`
        )
      )
    }
  }, [dispatch, locationId, marketId, spaceType, measure, unit])

  return (
    <TopSelectors>
      <SelectorWrap>
        <Select
          options={spaceTypeOptions}
          value={spaceType ? { value: spaceType, label: spaceType } : null}
          placeholder="Space Type"
          onChange={({ value }) => setSpaceType(value)}
        />
      </SelectorWrap>
      <SelectorWrap>
        <Select
          options={measureOptions}
          value={measure ? { value: measure, label: measure } : null}
          placeholder="Measure"
          onChange={({ value }) => setMeasure(value)}
        />
      </SelectorWrap>
      <SelectorWrap>
        <Select
          options={unitOptions}
          value={unit}
          placeholder="Unit"
          onChange={value => setUnit(value)}
        />
      </SelectorWrap>
    </TopSelectors>
  )
}

export default Selectors
