import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import { filterLocationsAll } from '../../helpers/location-services'
import { mdUp, smOnly, xlgUp } from '../../styles/breakpoints'
import Feed from './Feed'
import {
  locationServicesAllGet,
  mapSummaryGet
} from '../../actions/location-services-all'
import { locationServicesGet } from '../../actions/location-services'
import ViewToggleWrap from './ViewToggleWrap'
import MarketData from './MarketData'

const MapSummary = lazy(() => import('../../containers/MapSummary'))
const Map = lazy(() => import('../../containers/LocationServicesMapAll'))

const MapFeed = ({ onGoogleApiLoaded = () => {} }) => {
  const dispatch = useDispatch()
  const { marketId, search } = useSelector(
    state => state.locationServicesAll.filter
  )
  const market = useSelector(state => state.markets.byId[marketId])
  const mapSummaryList = useSelector(
    state => state.locationServicesAll.mapSummary
  )
  const filter = useSelector(state => state.locationServicesAll.filter)
  const myLocations = useSelector(state => state.locationServices.list)
  const locations = useSelector(state => {
    const marketId = state.locationServicesAll.filter.marketId
    const search = state.locationServicesAll.filter.search

    if (marketId) {
      return state.locationServicesAll.byMarketId[marketId]
    }

    if (search) {
      return state.locationServicesAll.bySearch[search]
    }

    if (!(search || marketId)) {
      return state.locationServicesAll.allLocations
    }
  })
  const mapSummaryLoading = useSelector(
    state => state.locationServicesAll.status.mapSummary
  )
  const loading = useSelector(state => state.locationServicesAll.status.loading)
  const filtered = filterLocationsAll(filter, locations)

  useEffect(() => {
    async function fetchData() {
      await dispatch(
        locationServicesAllGet({
          page: 0,
          pagesize: 40,
          initial: true,
          all: true
        })
      )
      await dispatch(
        locationServicesAllGet({ page: 0, pagesize: 2000, all: true })
      )
      await dispatch(
        locationServicesAllGet({ page: 1, pagesize: 2000, all: true })
      )
      await dispatch(
        locationServicesAllGet({ page: 2, pagesize: 2000, all: true })
      )
    }

    if (marketId || search) {
      dispatch(locationServicesAllGet({ marketId, search }))
    }
    if (!(marketId || search)) {
      fetchData()
      dispatch(mapSummaryGet())
    }
  }, [dispatch, marketId, search])

  useEffect(() => {
    dispatch(locationServicesGet())
  }, [dispatch])

  return (
    <Wrap>
      <FeedWrap>
        {(filter.marketId || filter.search) && <ViewToggleWrap />}
        <Feed locations={filtered} />

        {(filter.marketId || filter.search) && (
          <MarketData locations={filtered} />
        )}
      </FeedWrap>
      <Suspense
        fallback={
          <Flex fullWidth fullHeight center>
            <Spinner elSize={40} />
          </Flex>
        }
      >
        <MapWrap>
          {!(filter.marketId || filter.search) ? (
            <MapSummary
              markets={mapSummaryList}
              onGoogleApiLoaded={maps => maps && onGoogleApiLoaded(true)}
              loading={mapSummaryLoading}
            />
          ) : (
            <Map
              market={market}
              myLocations={myLocations}
              locations={filtered}
              onGoogleApiLoaded={maps => maps && onGoogleApiLoaded(true)}
              loading={loading}
            />
          )}
        </MapWrap>
      </Suspense>
    </Wrap>
  )
}

const FeedWrap = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  @media ${mdUp} {
    border-left: 1px solid #bdbdbd;
    width: 50%;
  }

  @media ${xlgUp} {
    width: 40%;
  }
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  background-color: #f8f8f8;

  @media ${mdUp} {
    flex-direction: row;
    min-height: 500px;
  }
`

const MapWrap = styled.div`
  height: 100%;
  max-height: 250px;
  width: 100%;
  position: relative;

  @media ${smOnly} {
    & > div {
      min-height: 250px !important;
    }
  }

  @media ${mdUp} {
    max-height: unset;
    width: 50%;
    min-height: 500px;
  }

  @media ${xlgUp} {
    width: 60%;
  }
`

export default MapFeed
