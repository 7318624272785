import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { LOGIN_ROUTE as loginRoute } from '../../settings/routes'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import Header from '../Header'
import { MARKETPLACE_URL, PRO_URL, shouldRedirect } from '../../settings/auth'
import { isMarketPlace } from '../../utils/subscription'
import { logout } from '../../actions/auth';
import NotFound from '../../pages/NotFound'

const PrivateRoute = ({
  component: Component,
  pagePath,
  hideSelectors,
  ...rest
}) => {
  const auth = useSelector(state => state.auth)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (shouldRedirect && user.status.completed) {
      if (['Investor', 'Advisor'].includes(user.flexOfficePro) && isMarketPlace) {
        dispatch(logout()).then(() => window.location.replace(`${PRO_URL}/login`))
      }
      if (!['Investor', 'Advisor'].includes(user.flexOfficePro) && !isMarketPlace) {
        dispatch(logout()).then(() => window.location.replace(`${MARKETPLACE_URL}/login`))
      }
    }
  }, [dispatch, user.status.completed, user.flexOfficePro])

  if (
    (user.status.completed &&
      window.location.pathname.includes('/summaries/') &&
      !user.subscriptions.includes('MarketSummaries')) ||
    (user.status.completed &&
      window.location.pathname.includes('/reports/') &&
      !user.subscriptions.includes('MarketReports'))
  ) {
    return <NotFound />
  }

  // Private route or cognito auth error
  if (
    (auth.session.completed && auth.session.error) ||
    (user.status.completed && !user.isAuthenticated)
  ) {
    return <Redirect to={`/${loginRoute}`} />
  }

  // Has FlexOfficePro access
  if (user.isAuthenticated) {
    return (
      <Flex fullWidth fullScreenHeight middle column>
        <Header pagePath={pagePath} hideSelectors={hideSelectors} />
        <Route {...rest} render={props => <Component {...props} />} />
      </Flex>
    )
  }

  // Show loading spinner during auth and auth check
  return (
    <Flex fullWidth fullScreenHeight center middle>
      <Spinner elSize={40} />
    </Flex>
  )
}

export default PrivateRoute
