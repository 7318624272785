import React from 'react'
import { withRouter } from 'react-router'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  LOGIN_ROUTE,
  CHANGE_PASS_ROUTE,
  FORGOT_PASS_ROUTE,
  CONFIRMATION_CODE_ROUTE,
  GET_FLEX_ROUTE,
  DASHBOARD_ROUTE,
  SPACES_ROUTE,
  LOCATION_ROUTE,
  LOCATION_SETUP_ROUTE,
  LOCATION_CONTACTS_ROUTE,
  LOCATION_ENVIRONMENT_ROUTE,
  LOCATION_AMENITIES_ROUTE,
  LOCATION_IMAGERY_ROUTE,
  LOCATION_NETWORK_ROUTE,
  LOCATION_REVIEWS_ROUTE,
  LOCATION_PROMO_ROUTE,
  LEADS_ROUTE,
  PIPELINE_ROUTE,
  PROPOSALS_ROUTE,
  LOGOUT_ROUTE,
  PROFILE_ROUTE,
  DEMAND_AND_PRICING_ROUTE,
  AGREEMENTS_AND_FEES_ROUTE,
  marketReports,
  marketSummaries,
  locationServices,
  WORK_LEDS_ROUTE
} from '../../settings/routes'

import PrivateRoute from '../PrivateRoute'

import GlobalStyle from '../../styles/global'
import ModalRoot from '../ModalRoot'
import Logout from '../Logout'
import Login from '../../pages/Login'
import ChangePass from '../../pages/ChangePass'
import ForgotPass from '../../pages/ForgotPass'
import ConfirmationCode from '../../pages/ForgotPass/confirmation-code'
import NotFound from '../../pages/NotFound'
import Spaces from '../../pages/Spaces'
import Location from '../../pages/Location'
import Leads from '../../pages/Leads'
import Dashboard from '../../pages/Dashboard'
import Proposals from '../../pages/Proposals'
import Pipeline from '../../pages/Pipeline'
import GetFlex from '../../pages/GetFlex'
import Profile from '../../pages/Profile'
import DemandAndPricing from '../../pages/DemandAndPricing'
import Supply from '../../pages/Reports/Supply'
import Forecast from '../../pages/Reports/Forecast'
import InventoryVacancy from '../../pages/Reports/InventoryVacancy'
import Price from '../../pages/Reports/Price'
import DemandDynamics from '../../pages/Reports/DemandDynamics'
import Locations from '../../pages/Reports/Locations'
import Views from '../../pages/Summaries/Views'
import Volume from '../../pages/Summaries/Volume'
import Trends from '../../pages/Summaries/Trends'
// import LocationServices from '../../pages/LocationServices'
import LocationServicesAll from '../../pages/LocationServicesAll'
import WorkLeads from '../../pages/WorkLeads'

const Root = () => (
  <>
    <GlobalStyle />
    <Switch>
      <Route path={`/${LOGIN_ROUTE}`} exact component={Login} />
      <Route path={`/${CHANGE_PASS_ROUTE}`} exact component={ChangePass} />
      <Route
        path={`/${CONFIRMATION_CODE_ROUTE}`}
        exact
        component={ConfirmationCode}
      />
      <Route path={`/${FORGOT_PASS_ROUTE}`} exact component={ForgotPass} />

      <Redirect exact from="/" to={`/${DASHBOARD_ROUTE}`} />

      <PrivateRoute
        exact
        path={[`/${SPACES_ROUTE}/:marketId/:locationId?`, `/${SPACES_ROUTE}`]}
        component={Spaces}
        pagePath={`/${SPACES_ROUTE}`}
      />

      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_SETUP_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_SETUP_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_CONTACTS_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_CONTACTS_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_ENVIRONMENT_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_ENVIRONMENT_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_AMENITIES_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_AMENITIES_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_IMAGERY_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_IMAGERY_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_NETWORK_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_NETWORK_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_REVIEWS_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_REVIEWS_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${LOCATION_ROUTE}/${LOCATION_PROMO_ROUTE}/:marketId/:locationId?`
        ]}
        component={Location}
        pagePath={`/${LOCATION_ROUTE}/${LOCATION_PROMO_ROUTE}`}
      />

      <PrivateRoute
        exact
        path={[`/${LEADS_ROUTE}/:marketId/:locationId?`, `/${LEADS_ROUTE}`]}
        component={Leads}
        pagePath={`/${LEADS_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${PIPELINE_ROUTE}/:marketId/:locationId?`,
          `/${PIPELINE_ROUTE}`
        ]}
        component={Pipeline}
        pagePath={`/${PIPELINE_ROUTE}`}
      />

      <PrivateRoute
        exact
        path={[
          `/${PIPELINE_ROUTE}/${AGREEMENTS_AND_FEES_ROUTE}/:marketId/:locationId?`
        ]}
        component={Pipeline}
        pagePath={`/${PIPELINE_ROUTE}/${AGREEMENTS_AND_FEES_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${DASHBOARD_ROUTE}/:marketId/:locationId?`,
          `/${DASHBOARD_ROUTE}`
        ]}
        component={Dashboard}
        pagePath={`/${DASHBOARD_ROUTE}`}
      />
      <PrivateRoute
        exact
        path={[
          `/${PROPOSALS_ROUTE}/:marketId/:locationId?`,
          `/${PROPOSALS_ROUTE}`
        ]}
        component={Proposals}
        pagePath={`/${PROPOSALS_ROUTE}`}
      />

      <PrivateRoute
        path={[
          `/${marketReports.main}/${marketReports.inventory}/:marketId`,
          `/${marketReports.main}/${marketReports.inventory}`
        ]}
        component={InventoryVacancy}
        pagePath={`/${marketReports.main}/${marketReports.inventory}`}
      />

      <PrivateRoute
        path={[
          `/${marketReports.main}/${marketReports.monitor}/:marketId`,
          `/${marketReports.main}/${marketReports.monitor}`
        ]}
        component={Supply}
        pagePath={`/${marketReports.main}/${marketReports.monitor}`}
      />

      <PrivateRoute
        path={[
          `/${marketReports.main}/${marketReports.forecast}/:marketId`,
          `/${marketReports.main}/${marketReports.forecast}`
        ]}
        component={Forecast}
        pagePath={`/${marketReports.main}/${marketReports.forecast}`}
      />

      <PrivateRoute
        path={[
          `/${marketReports.main}/${marketReports.price}/:marketId`,
          `/${marketReports.main}/${marketReports.price}`
        ]}
        component={Price}
        pagePath={`/${marketReports.main}/${marketReports.price}`}
      />

      <PrivateRoute
        path={[
          `/${marketReports.main}/${marketReports.demand}/:marketId`,
          `/${marketReports.main}/${marketReports.demand}`
        ]}
        component={DemandDynamics}
        pagePath={`/${marketReports.main}/${marketReports.demand}`}
      />

      <PrivateRoute
        path={[
          `/${marketReports.main}/${marketReports.supply}/:marketId`,
          `/${marketReports.main}/${marketReports.supply}`
        ]}
        component={Locations}
        pagePath={`/${marketReports.main}/${marketReports.supply}`}
      />

      <PrivateRoute
        path={[
          `/${marketSummaries.main}/${marketSummaries.summary}/:marketId`,
          `/${marketSummaries.main}/${marketSummaries.summary}`
        ]}
        component={Trends}
        pagePath={`/${marketSummaries.main}/${marketSummaries.summary}`}
      />

      <PrivateRoute
        path={[
          `/${marketSummaries.main}/${marketSummaries.trends}/:marketId`,
          `/${marketSummaries.main}/${marketSummaries.trends}`
        ]}
        component={Volume}
        pagePath={`/${marketSummaries.main}/${marketSummaries.trends}`}
      />

      <PrivateRoute
        path={[
          `/${marketSummaries.main}/${marketSummaries.views}/:marketId`,
          `/${marketSummaries.main}/${marketSummaries.views}`
        ]}
        component={Views}
        pagePath={`/${marketSummaries.main}/${marketSummaries.views}`}
      />

      {/* <PrivateRoute
        exact
        path={[`/${locationServices.main}/${locationServices.map}`]}
        component={LocationServices}
        pagePath={`/${locationServices.main}/${locationServices.map}`}
        hideSelectors
      />

      <PrivateRoute
        exact
        path={[`/${locationServices.main}/${locationServices.list}`]}
        component={LocationServices}
        pagePath={`/${locationServices.main}/${locationServices.list}`}
        hideSelectors
      /> */}

      <PrivateRoute
        exact
        path={[`/${locationServices.main}/${locationServices.mapAll}`]}
        component={LocationServicesAll}
        pagePath={`/${locationServices.main}/${locationServices.mapAll}`}
        hideSelectors
      />

      <PrivateRoute
        exact
        path={[`/${locationServices.main}/${locationServices.listAll}`]}
        component={LocationServicesAll}
        pagePath={`/${locationServices.main}/${locationServices.listAll}`}
        hideSelectors
      />

      <PrivateRoute
        exact
        path={[`/${DEMAND_AND_PRICING_ROUTE}/pipeline/:marketId/:locationId?`]}
        component={DemandAndPricing}
        pagePath={`/${DEMAND_AND_PRICING_ROUTE}/pipeline`}
      />

      <PrivateRoute
        exact
        path={[
          `/${DEMAND_AND_PRICING_ROUTE}/views-in-search/:marketId/:locationId?`
        ]}
        component={DemandAndPricing}
        pagePath={`/${DEMAND_AND_PRICING_ROUTE}/views-in-search`}
      />

      <PrivateRoute
        exact
        path={[
          `/${DEMAND_AND_PRICING_ROUTE}/price-performance/:marketId/:locationId?`,
          `/${DEMAND_AND_PRICING_ROUTE}/price-performance/:marketId/:locationId/:spaceType/:measure/:unit`
        ]}
        component={DemandAndPricing}
        pagePath={`/${DEMAND_AND_PRICING_ROUTE}/price-performance`}
      />

      <PrivateRoute
        exact
        path={[
          `/${DEMAND_AND_PRICING_ROUTE}/standard-suite-sizes/:marketId/:locationId?`,
          `/${DEMAND_AND_PRICING_ROUTE}/standard-suite-sizes/:marketId/:locationId/:spaceType/:measure/:unit`
        ]}
        component={DemandAndPricing}
        pagePath={`/${DEMAND_AND_PRICING_ROUTE}/standard-suite-sizes`}
      />

      <PrivateRoute
        exact
        path={[
          `/${DEMAND_AND_PRICING_ROUTE}/average-size/:marketId/:locationId?`,
          `/${DEMAND_AND_PRICING_ROUTE}/average-size/:marketId/:locationId/:spaceType/:measure/:unit`
        ]}
        component={DemandAndPricing}
        pagePath={`/${DEMAND_AND_PRICING_ROUTE}/average-size`}
      />

      <PrivateRoute
        exact
        path={[
          `/${DEMAND_AND_PRICING_ROUTE}/lead-performance/:marketId/:locationId?`
        ]}
        component={DemandAndPricing}
        pagePath={`/${DEMAND_AND_PRICING_ROUTE}/lead-performance`}
      />

      <PrivateRoute
        exact
        path={[`/${PROFILE_ROUTE}`]}
        component={Profile}
        pagePath={`/${PROFILE_ROUTE}`}
        hideSelectors
      />

      <PrivateRoute
        path={[`/${WORK_LEDS_ROUTE}`]}
        component={WorkLeads}
        pagePath={`/${WORK_LEDS_ROUTE}`}
        hideSelectors
      />
      <Route path={`/${LOGOUT_ROUTE}`} exact component={Logout} />
      <Route path={`/${GET_FLEX_ROUTE}`} exact component={GetFlex} />
      <Route component={NotFound} />
    </Switch>
    <ModalRoot />
  </>
)

export default withRouter(Root)
