import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getInventoryAndVacancy = (token, marketid) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/InventoryAndVacancy?marketid=${marketid}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getForecast = (token, marketid) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/forecast?marketid=${marketid}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getSupplyanddemand = (token, marketid) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/supplyanddemand?marketid=${marketid}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getPrice = (token, marketid) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/askingprice?marketid=${marketid}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getPricePerSpaceType = ({ token, marketId, spaceType, measure, unit }) =>
  fetch(
    `${apiUrl}${apiBase}/flex/invester/askingpriceperspacetype?marketid=${marketId}&suitetype=${spaceType}&measure=${measure}&unit=${unit}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )

export const getDemandDynamix = (token, marketid) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/demanddynamics?marketid=${marketid}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getNewClosedLoc = (token, marketid) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/newandclosedlocations?marketid=${marketid}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getCustomText = (token, page, marketid) =>
  fetch(`${apiUrl}${apiBase}/flex/CustomText?page=${page}&marketid=${marketid}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })
