import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getHoods = marketId =>
  fetch(
    `${apiUrl}${apiBase}/neighborhood?sortBy=name${
      marketId
        ? `&asc=true&where[0][type]=equals&where[0][attribute]=marketId&where[0][value]=${marketId}`
        : ''
    }`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey
      }
    }
  )


export const getAllHoods = (offset) =>
  fetch(
    `${apiUrl}${apiBase}/neighborhood?sortBy=name&maxSize=200&offset=${offset}&where[0][type]=isFalse&where[0][attribute]=hideHoodOnWeb`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey
      }
    }
  )

export const addHood = (token, data) =>
  fetch(`${apiUrl}${apiBase}/neighborhood`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
