import React from 'react'
import styled from 'styled-components/macro'
import { UpsuiteLogo } from '../../components/icons'

const PromoBanner = ({ heading = '' }) => {
  return (
    <Wrapper>
      <Subheading>
        Exclusive
        <UpsuiteLogo />
        Promotion
      </Subheading>
      {heading && <Heading>{heading}</Heading>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-width: 50px;
  min-height: 50px;
  padding: 15px 15px 15px 90px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 750px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 75px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAPCAYAAADtc08vAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAAAlUlEQVQoFWPwcLBQAGIHBhwAKq+AQ5qBhYnhXwJQMgCIDbAr+jcBKg5SgwGYth041fCPgQmrJEg1B8OvBBDG0Dl4BbwcTA08HcxwegkkB1ID8wELjAGj/zMwFgDZCkC8ASaGTP9nYEhgYGD8ABQD0sMDgFKhh4MpTv+AAg1foDIxMvw3YGRgxGkAA8N/BwgeHgGGxRcAnUAfdpfb6LsAAAAASUVORK5CYII=');
  }

  svg {
    fill: #e7483d;
    width: 65px;
    height: 18px;
    margin-left: 8px;
    margin-right: 8px;
  }
`

const Subheading = styled.span`
  display: flex;
  font-weight: 600;
  color: #585756;
  margin-bottom: 10px;
`

const Heading = styled.span`
  color: #e7483d;
  font-size: 22px;
  font-weight: 600;
`

export default PromoBanner
