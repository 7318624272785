import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isNumber from 'lodash/isNumber'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import * as Styled from '../../components/location'
import Spinner from '../../components/spinner'
import { smOnly } from '../../styles/breakpoints'
import { locationUpdate } from '../../actions/locations'
import { getEnvInitialValues } from '../../helpers/location'

const getLeftActive = value => isNumber(value) && value <= 50
const getRightActive = value => isNumber(value) && value > 50

const fields = [
  {
    name: 'environmentQuietEnergetic',
    left: 'Quiet',
    right: 'Energetic'
  },
  {
    name: 'environmentPrivateOpen',
    left: 'Private',
    right: 'Open'
  },
  {
    name: 'environmentLuxuryBudget',
    left: 'Luxury',
    right: 'Budget'
  },
  {
    name: 'environmentProfessionalCasual',
    left: 'Professional',
    right: 'Casual'
  },
  {
    name: 'environmentDimBright',
    left: 'Dim',
    right: 'Bright'
  }
]

const LocationEnv = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)
  const formik = useFormik({
    initialValues: getEnvInitialValues(location),
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      dispatch(locationUpdate(locationId, values))
    }
  })
  const mobile = useMediaQuery({ query: smOnly })
  const handleResetForm = () =>
    formik.resetForm({ values: getEnvInitialValues(location) })

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.Header>
            <Styled.Note>
              <p>
                <b>Note:</b> Use the sliders for each option below to designate
                to your shoppers what type of work environment your location
                has.
              </p>
            </Styled.Note>
            <Styled.Actions mobile={mobile}>
              {updating && <Spinner elSize={20} />}
              <>
                <Styled.Action
                  disabled={updating}
                  type="button"
                  onClick={() => handleResetForm()}
                >
                  Cancel
                </Styled.Action>
                <Styled.Action disabled={updating} type="submit">
                  Save
                </Styled.Action>
              </>
            </Styled.Actions>
          </Styled.Header>
          <Styled.Section>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  {location?.name && (
                    <Styled.Heading>
                      Work Environment at {location.name}
                    </Styled.Heading>
                  )}
                </Styled.Col>
              </Styled.Row>
              <Flex fullWidth middle column>
                {fields.map(item => (
                  <Styled.RangeRow key={item.name} maxWidth="100%">
                    <Styled.RangeText
                      active={getLeftActive(formik.values[item.name])}
                    >
                      {item.left}
                    </Styled.RangeText>
                    <Styled.Range
                      id={item.name}
                      name={item.name}
                      value={
                        isNumber(formik.values[item.name])
                          ? formik.values[item.name]
                          : ''
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                      step={1}
                      min={0}
                      max={100}
                    />
                    <Styled.RangeText
                      active={getRightActive(formik.values[item.name])}
                    >
                      {item.right}
                    </Styled.RangeText>
                  </Styled.RangeRow>
                ))}
              </Flex>
            </Flex>
          </Styled.Section>
        </Styled.Form>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationEnv
