import React, { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components/macro'
import { getWifiSystems, addWifiSystem } from '../../api/wifi-systems'
import { useSelector } from 'react-redux'
import { parseJSON } from '../../utils/api'
import * as Styled from '../location'

const WifiSystemSelect = ({ onChange, value }) => {
  const token = useSelector(state => state.auth.token)
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [selectValue, setSelectValue] = useState()

  useEffect(() => {
    setSelectValue(value)
  }, [value])

  useEffect(() => {
    setLoading(true)
    getWifiSystems(token)
      .then(response => {
        if (response.status === 200) {
          parseJSON(response).then(data => {
            setList(data.list)
          })
        } else {
          setError(`Error ${response.status}`)
        }
        setLoading(false)
      })
      .catch(() => {
        setError('Unknow server error')
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreate = inputValue => {
    setLoading(true)
    addWifiSystem(token, { name: inputValue })
      .then(response => {
        if (response.status === 200) {
          parseJSON(response).then(data => {
            setSelectValue({
              label: data.name,
              value: data.id
            })
            onChange(data)
            setList([data, ...list])
          })
        } else {
          setError(`Error ${response.status}`)
        }
        setLoading(false)
      })
      .catch(() => {
        setError('Unknow server error')
        setLoading(false)
      })
  }

  return (
    <Styled.Label>
      <Styled.LabelText>WiFi System</Styled.LabelText>
      <CreatableSelect
        styles={Styled.Select}
        isDisabled={loading}
        isLoading={loading}
        placeholder="_"
        onChange={value => [
          onChange({ id: value.value, name: value.label }),
          setSelectValue(value)
        ]}
        onCreateOption={handleCreate}
        options={list.map(({ name, id }) => ({ value: id, label: name }))}
        value={selectValue}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Styled.Label>
  )
}

const ErrorText = styled.span`
  color: red;
  text-align: center;
  font-size: 14px;
  display: block;
`

export default WifiSystemSelect
