import React from 'react';
import Flex from '../../components/flex';

const NotFound = () => {
  return (
    <Flex fullWidth fullHeight center middle>
      <h1>404 Page Not Found</h1>
    </Flex>
  );
};

export default NotFound;
