import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  LOCATIONS_WITH_LEADS_REQUEST,
  LOCATIONS_WITH_LEADS_SUCCESS,
  LOCATIONS_WITH_LEADS_FAILURE,
  MARKETS_WITH_SEARCH_REQUEST,
  MARKETS_WITH_SEARCH_SUCCESS,
  MARKETS_WITH_SEARCH_FAILURE
} from '../constants'
import * as api from '../api/market-summaries'
import { getTokenWithExpiration } from './auth'

const marketsWithSearchCountGetRequest = () => ({
  type: MARKETS_WITH_SEARCH_REQUEST
})

const marketsWithSearchCountGetSuccess = (payload, config) => ({
  type: MARKETS_WITH_SEARCH_SUCCESS,
  payload,
  config
})

const marketsWithSearchCountGetFailure = () => ({
  type: MARKETS_WITH_SEARCH_FAILURE
})

export const marketsWithSearchCountGet = quarter => (dispatch, getState) => {
  if (getState().summaries.withSearchCount.byQuarter[quarter]) {
    return null
  }

  dispatch(marketsWithSearchCountGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getFullInvesterData(token, quarter)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(marketsWithSearchCountGetSuccess(response, quarter))
        })
        .catch(error => {
          if (error) {
            dispatch(marketsWithSearchCountGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const locationsWithLeadCountGetRequest = () => ({
  type: LOCATIONS_WITH_LEADS_REQUEST
})

const locationsWithLeadCountGetSuccess = (payload, config) => ({
  type: LOCATIONS_WITH_LEADS_SUCCESS,
  payload,
  config
})

const locationsWithLeadCountGetFailure = () => ({
  type: LOCATIONS_WITH_LEADS_FAILURE
})

export const locationsWithLeadCountGet = quarter => (dispatch, getState) => {
  if (getState().summaries.withLeads.byQuarter[quarter]) {
    return null
  }

  dispatch(locationsWithLeadCountGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLocationsWithLeadCount(token, quarter)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(locationsWithLeadCountGetSuccess(response, quarter))
        })
        .catch(error => {
          if (error) {
            dispatch(locationsWithLeadCountGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
