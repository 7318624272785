import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import { push } from 'connected-react-router'
import styled from 'styled-components/macro'
import { leadsGet } from '../../actions/leads'
import * as Styled from '../widget'
import { LEADS_ROUTE } from '../../settings/routes'
import Spinner from '../spinner'
import Flex from '../flex'

const WidgetLeads = ({ id, marketId }) => {
  const dispatch = useDispatch()
  const {
    list: leads,
    status: { loading },
    error: { loading: error }
  } = useSelector(state => state.leads)

  useEffect(() => {
    if (id) {
      dispatch(
        leadsGet({
          location: id,
          market: marketId,
          page: 1,
          perPage: 5,
          sortBy: 'created_at',
          order: 'desc'
        })
      )
    }
  }, [dispatch, id, marketId])

  return (
    <Styled.Wrap>
      <Styled.Title>Most Recent Leads</Styled.Title>
      <Styled.Box>
        {loading && (
          <Flex fullWidth center middle style={{ marginTop: 20 }}>
            <Spinner elSize={30} />
          </Flex>
        )}
        {!loading && !error && leads.length > 0 && (
          <List>
            {leads.map(item => (
              <Item key={item.email}>
                <button
                  type="button"
                  onClick={() =>
                    dispatch(push(`/${LEADS_ROUTE}/${marketId}/${id}`))
                  }
                >
                  <span>{`${format(
                    new Date(item.created_at),
                    'MM/dd'
                  )} - `}</span>
                  <span>{`${item.first_name || ''} ${item.last_name || ''} ${
                    item.seats
                      ? `(${item.seats} seat${item.seats > 1 ? 's' : ''})`
                      : ''
                  }`}</span>
                </button>
              </Item>
            ))}
          </List>
        )}
        {!loading && !error && leads.length === 0 && (
          <NoData>
            <span>No leads</span>
          </NoData>
        )}
      </Styled.Box>
    </Styled.Wrap>
  )
}

const NoData = styled.div`
  font-size: 18px;
  padding: 20px;
  color: black;
  text-align: center;
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 20px 15px 15px 30px;
`

const Item = styled.li`
  font-size: 18px;

  :not(:last-child) {
    margin-bottom: 15px;
  }

  span {
    font-size: inherit;
  }

  button {
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    display: inline-block;
    padding: 0;
    cursor: pointer;
    text-align: left;

    span:last-child {
      color: blue;
    }
  }
`

export default WidgetLeads
