import styled from 'styled-components';
import { lgUp, mdUp } from '../../styles/breakpoints';

export const TopSelectors = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 20px 25px 20px;
  min-height: 38px;

  @media ${mdUp} {
    flex-direction: row;
    padding: 0 10px 25px 0;
  }

  @media ${lgUp} {
    min-height: 68px;
    margin-top: -30px;
    padding: 0 15px 0 0;
  }

  &[data-spacebelow="true"] {
    margin-bottom: 2rem;
  }
`;

export const SelectorWrap = styled.div`
  width: 100%;
  padding: 0 5px;
  margin-bottom: 10px;

  @media ${mdUp} {
    max-width: 180px;
    margin-bottom: 0;
  }

  > div {
    width: 100%;
  }
`;
