import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

export const Wrap = styled.div`
  position: relative;
  border-radius: $radius;
  z-index: 0;
  vertical-align: middle;

  ${({ isActive }) => isActive && 'z-index: 1'}
`;

export const Dropdown = styled.ul`
  display: none;
  text-align: left;
  background-color: #fff;
  overflow: hidden;
  position: absolute;
  top: 100%;
  min-width: 100%;
  right: 0;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  list-style: none;
  margin: 0;

  a {
    color: #333;

    &:hover {
      color: rgba(0.5, 0.5, 0.5, 0.7);
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      display: block;
      z-index: 2;
      overflow-y: auto;
    `}
`;

export const ToggleButton = styled.button`
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid #ccc;
    `}
`;

export const DropdownItem = styled.li`
  padding-top: 10px;
  padding-bottom: 10px;
`;

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export const DropDown = props => {
  const { onClose, onOpen, children, button: Button } = props;
  const [isActive, setActive] = useState(false);
  const ref = useRef();

  const toggleActive = () => {
    if (isActive) {
      onClose();
      setActive(false);
    } else {
      onOpen();
      setActive(true);
    }
  };

  const historyState = window.history.state;

  useEffect(() => {
    onClose();
    setActive(false);
  }, [historyState, onClose]);

  useOutsideClick(ref, () => {
    if (isActive) {
      onClose();
    }

    setActive(false);
  });

  return (
    <Wrap ref={ref} isActive={isActive}>
      <ToggleButton onClick={toggleActive} isActive={isActive}>
        <Button />
      </ToggleButton>
      <Dropdown isActive={isActive}>{children}</Dropdown>
    </Wrap>
  );
};

DropDown.defaultProps = {
  onClose: () => {},
  onOpen: () => {},
};
