import styled from 'styled-components/macro';
import { smOnly, mdUp } from '../../styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  width: 100%;
  margin-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const Tabs = styled.div`
  display: flex;

  @media ${smOnly} {
    flex-wrap: wrap;
  }
`;

export const Tab = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  width: 100%;
  max-width: 70px;
  padding: 6px 0;
  border: 1px solid #d5d5d5;
  color: #828180;
  font-size: 12px;
  font-weight: 400;
  background-color: #d5d5d5;
  border-bottom: none;
  order: 2;

  @media ${mdUp} {
    border: 1px solid #707070;
    border-bottom: none;
    font-weight: 600;
    font-size: 14px;
    background-color: ${props => (props.active ? 'white' : '#f6f6f6')};
    color: #2e2c2b;
    max-width: 80px;
    order: 0;
  }

  :not(:last-child) {
    margin-right: 9px;
  }

${props => props.active && `
  background-color: #fff;
  position: relative;
  border: 1px solid #707070;

  ::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: -2px;
    background-color: #fff;
    left: 0;
  }`
}
`;

export const List = styled.ul`
  padding-left: 0;
  border-top: 1px solid #707070;
  font-size: 12px;
  color: #828180;
  margin: 0;

  @media ${mdUp} {
    font-size: 13px;
    border-left: none;
    border-right: none;
  }

  @media ${smOnly} {
    overflow: auto;
  }
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-around;
  padding: 13px 5px;
  border-left: 1px solid #707070;
  border-right: 1px solid #707070;
  margin: 0;
  transition: margin $delay ease, max-height $delay ease 0.3s, border-top-width 0s ease $delay;
  height: 54px;
  border-top: 0 solid #707070;
  overflow: hidden;

  span {
    display: block;

    :not(:first-child) {
      text-align: center;
    }
  }

  @media ${mdUp} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  @media ${smOnly} {
    padding: 5px;
    align-items: center;
    width: 670px;

    :last-child {
      border-bottom: 1px solid #707070;
    }
  }
`;

export const Col = styled.div`
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
`;

export const Control = styled.button.attrs({ type: 'button' })`
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 10px;
  background-color: #57AB83;
  color: #fff;
  padding: 10px 20px;

  ${props => props.withShift && 'margin-left: 10px;'}

  svg {
    fill: #fff;
    margin-right: 5px;
  }
`;

export const Filter = styled.button.attrs({ type: 'button' })`
  border: none;
  padding: 0;
  margin: 0;
  font-weight: 600;
  color: #585756;
  background: none;
  cursor: pointer;
  position: relative;
  text-align: left;
  width: 100%;

  ::after {
    ${props => props.onClick && `
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 3px;
      right: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #585756;
    `}

    ${props => props.active && props.desc && `
      border-top: 5px solid #585756;
      border-bottom: 0;
    `}
  }
`;

export const Action = styled.button.attrs({ type: 'button' })`
  border: none;
  color: #e7483d;
  background: none;
  text-decoration: underline;
  cursor: pointer;
`;

export const ModalAction = styled(Action)`
  padding: 0 20px;
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 10px;
`;

export const Form = styled(Item)`
  margin: 15px -15px;
  border-top: 1px solid #707070;
  border-top-width: 1px;
  transition: margin 0.5s ease, max-height 0.5s ease 0.3s, border-top-width 0.5s ease 0s;
  overflow: visible;
  max-height: unset;
  height: auto;

  & + ${Item} {
    border-top: 1px solid #707070;
  }
`;
