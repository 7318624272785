import styled from 'styled-components/macro';
import { Calendar } from '../icons';
import { mdUp, smOnly } from '../../styles/breakpoints';

export const Form = styled.form`
  width: 100%;

  @media ${smOnly} {
    padding-top: 50px;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;

  @media ${smOnly} {
    flex-wrap: wrap;
  }
`;

export const Col = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => (props.noMar ? 0 : '15px')};

  @media ${mdUp} {
    padding: ${props => (props.noPad ? 0 : '0 15px')};
  }

  @media ${smOnly} {
    ${props => props.mPadLeft && `
      padding-left: ${props.mPadLeft};
    `}
    ${props => props.mPadRight && `
      padding-right: ${props.mPadRight};
    `}
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 5px 5px 0;
  height: 30px;
  font-size: 13px;
  color: #828180;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 5px;

  :focus {
    background-color: #f8f8f9;
    border: 1px solid #d5d5d5;
    padding: 5px;
  }

  @media ${smOnly} {
    background-color: #f8f8f9;
    border: 1px solid #d5d5d5;
    padding: 5px;
  }
`;

export const LabelText = styled.span`
  display: block;
  margin-bottom: 10px;
  color: #585756;
  font-weight: 600;
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  position: relative;
`;

export const Select = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    borderColor: state.selectProps.mobile ? '#d5d5d5' : 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    minHeight: '30px',
    backgroundColor: state.selectProps.mobile ? '#f8f8f9' : 'transparent',
    paddingLeft: state.selectProps.mobile ? '5px' : 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    width: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
    color: '#828180',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: 0,
    marginTop: 0,
  }),
};

export const Action = styled.button`
  cursor: pointer;
  border: none;
  font-size: 14px;
  background: none;

  @media ${mdUp} {
    color: #e7483d;
    padding: 0 20px;
    line-height: 1.1;
    text-decoration: underline;
  }

  @media ${smOnly} {
    padding: 11px 20px;
    line-height: 1.3;
    color: #fff;
    font-weight: 600;
    background-color: #e7483d;
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const Footer = styled.div`
  padding-left: ${props => (props.mobile ? 0 : '15px')};
  margin-bottom: ${props => (props.mb > 0 ? `${props.mb}px` : 0)};
  display: flex;
  width: 100%;

  @media ${mdUp} {
    align-items: center;
    justify-content: flex-end;
  }

  @media ${smOnly} {
    flex-wrap: wrap;
  }
`;

export const Updated = styled.span`
  display: block;
  margin-right: auto;
  font-size: 14px;

  @media ${smOnly} {
    color: #585756;
    font-size: 14px;
    position: absolute;
    top: 24px;
  }
`;

export const DateIcon = styled(Calendar)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 6px;
  fill: #636364;
  top: -2px;
`;
