import React, { Fragment } from 'react'
import Select from 'react-select'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import Flex from '../../components/flex'
import SlideToggle from '../../components/slide-toggle'
import DatePicker from '../../components/datepicker'
import * as Styled from '../../components/space-form'
import { spaceTypes, spaceAmenities, spaceImages } from '../../helpers/spaces'
import { spaceModifiedDate } from '../../helpers/format'
import Spinner from '../../components/spinner'
import { smOnly } from '../../styles/breakpoints'
import Attachment from '../../components/attachment'
import AttachmentPdf from '../../components/attachment-pdf'

const Form = props => {
  const formik = useFormik({
    initialValues: {
      isAvailable: false,
      spaceView: false,
      spaceWindows: false,
      interior: false,
      ...props.initialValues
    },
    onSubmit: props.onSubmit
  })
  const { creating, updating, deleting } = useSelector(
    state => state.spaces.status
  )
  const mobile = useMediaQuery({ query: smOnly })

  return (
    <Styled.Form onSubmit={formik.handleSubmit}>
      <Flex flexWrap fullWidth>
        <Styled.Footer mobile={mobile} mb={20}>
          {props.initialValues && props.initialValues.modifiedAt && !mobile && (
            <Styled.Updated>
              Updated: {spaceModifiedDate(props.initialValues.modifiedAt)}
            </Styled.Updated>
          )}
          {(creating || updating || deleting) && <Spinner elSize={20} />}
          {props.onDuplicate && !mobile && (
            <Styled.Action type="button" onClick={props.onDuplicate}>
              Duplicate
            </Styled.Action>
          )}
          {props.onRemove && !mobile && (
            <Styled.Action type="button" onClick={props.onRemove}>
              Remove
            </Styled.Action>
          )}
          {!mobile && (
            <Fragment>
              <Styled.Action type="button" onClick={props.onClose}>
                Cancel
              </Styled.Action>
              <Styled.Action type="submit">Save</Styled.Action>
            </Fragment>
          )}
          {props.onDuplicate && mobile && (
            <Styled.Action type="button" onClick={props.onDuplicate}>
              Duplicate Space
            </Styled.Action>
          )}
          {mobile && <Styled.Action type="submit">Save Space</Styled.Action>}
          {props.onRemove && mobile && (
            <Styled.Action type="button" onClick={props.onRemove}>
              Remove Space
            </Styled.Action>
          )}
        </Styled.Footer>
        {props.initialValues && props.initialValues.modifiedAt && mobile && (
          <Styled.Updated>
            Updated: {spaceModifiedDate(props.initialValues.modifiedAt)}
          </Styled.Updated>
        )}
        <Styled.Row>
          <Styled.Col maxWidth={!mobile ? '20%' : '100%'}>
            <Styled.Label htmlFor="name">
              <Styled.LabelText>Unit</Styled.LabelText>
              <Styled.Input
                type="text"
                id="name"
                name="name"
                placeholder="_"
                value={formik.values.name || ''}
                onChange={formik.handleChange}
              />
            </Styled.Label>
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '20%' : '30%'}>
            <SlideToggle
              label="Available"
              value={formik.values.isAvailable}
              id="isAvailable"
              name="isAvailable"
              onChange={formik.handleChange}
            />
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '22%' : '70%'}>
            <Styled.Label htmlFor="dateAvailable">
              <Styled.LabelText>Next Available</Styled.LabelText>
              <Styled.DateIcon />
              <DatePicker
                value={formik.values.dateAvailable}
                id="dateAvailable"
                name="dateAvailable"
                onChange={value => formik.setFieldValue('dateAvailable', value)}
                disabled={formik.values.isAvailable}
                minDate="today"
              />
            </Styled.Label>
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col maxWidth={!mobile ? '20%' : '100%'}>
            <Styled.Label htmlFor="spaceType">
              <Styled.LabelText>Type</Styled.LabelText>
              <Select
                placeholder="_"
                styles={Styled.Select}
                options={spaceTypes.map(type => ({ label: type, value: type }))}
                defaultValue={
                  formik.values.spaceType && {
                    label: formik.values.spaceType,
                    value: formik.values.spaceType
                  }
                }
                isSearchable={false}
                onChange={({ value }) => {
                  formik.setFieldValue('spaceType', value)
                }}
                mobile={mobile}
              />
            </Styled.Label>
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '20%' : '100%'}>
            <Styled.Label htmlFor="spaceCapacity">
              <Styled.LabelText>Capacity</Styled.LabelText>
              <Styled.Input
                type="text"
                id="spaceCapacity"
                name="spaceCapacity"
                value={formik.values.spaceCapacity || ''}
                placeholder="_"
                onChange={formik.handleChange}
              />
            </Styled.Label>
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '22%' : '100%'}>
            <Styled.Label htmlFor="spacePrice">
              <Styled.LabelText>Price per Month</Styled.LabelText>
            </Styled.Label>
            <Styled.Col maxWidth="50%" noPad noMar mPadRight="10px">
              <Styled.Input
                type="text"
                id="spacePrice"
                name="spacePrice"
                value={formik.values.spacePrice || ''}
                placeholder="_"
                onChange={formik.handleChange}
              />
            </Styled.Col>
            <Styled.Col maxWidth="50%" noPad noMar mPadLeft="10px">
              <Select
                name="spacePriceCurrency"
                placeholder="_"
                styles={Styled.Select}
                isSearchable={false}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'CAD', value: 'CAD' }
                ]}
                defaultValue={
                  formik.values.spacePriceCurrency && {
                    label: formik.values.spacePriceCurrency,
                    value: formik.values.spacePriceCurrency
                  }
                }
                onChange={({ value }) => {
                  formik.setFieldValue('spacePriceCurrency', value)
                }}
                mobile={mobile}
              />
            </Styled.Col>
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '10%' : '50%'} mPadRight="10px">
            <Styled.Label htmlFor="sqFt">
              <Styled.LabelText>Area</Styled.LabelText>
              <Styled.Input
                type="text"
                id="sqFt"
                name="sqFt"
                value={formik.values.sqFt || ''}
                placeholder="_"
                onChange={formik.handleChange}
              />
            </Styled.Label>
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '10%' : '50%'} noPad mPadLeft="10px">
            <Styled.Label htmlFor="units">
              <Styled.LabelText>Units</Styled.LabelText>
              <Select
                name="units"
                placeholder="Sq/Ft"
                styles={Styled.Select}
                options={[{ label: 'Sq/Ft', value: 'sqFt' }]}
                defaultValue={{ label: 'Sq/Ft', value: 'sqFt' }}
                isSearchable={false}
                onChange={({ value }) => {
                  formik.setFieldValue('units', value)
                }}
                mobile={mobile}
              />
            </Styled.Label>
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col maxWidth={!mobile ? '22%' : '100%'}>
            <Styled.Label htmlFor="spacePrice">
              <Styled.LabelText>Price per Day</Styled.LabelText>
            </Styled.Label>
            <Styled.Col maxWidth="50%" noPad noMar mPadRight="10px">
              <Styled.Input
                type="text"
                id="pricePerDay"
                name="pricePerDay"
                value={formik.values.pricePerDay || ''}
                placeholder="_"
                onChange={formik.handleChange}
              />
            </Styled.Col>
            <Styled.Col maxWidth="50%" noPad noMar mPadLeft="10px">
              <Select
                name="pricePerDayCurrency"
                placeholder="_"
                styles={Styled.Select}
                isSearchable={false}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'CAD', value: 'CAD' }
                ]}
                defaultValue={
                  formik.values.pricePerDayCurrency && {
                    label: formik.values.pricePerDayCurrency,
                    value: formik.values.pricePerDayCurrency
                  }
                }
                onChange={({ value }) => {
                  formik.setFieldValue('pricePerDayCurrency', value)
                }}
                mobile={mobile}
              />
            </Styled.Col>
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '22%' : '100%'}>
            <Styled.Label htmlFor="pricePerHour">
              <Styled.LabelText>Price per Hour</Styled.LabelText>
            </Styled.Label>
            <Styled.Col maxWidth="50%" noPad noMar mPadRight="10px">
              <Styled.Input
                type="text"
                id="pricePerHour"
                name="pricePerHour"
                value={formik.values.pricePerHour || ''}
                placeholder="_"
                onChange={formik.handleChange}
              />
            </Styled.Col>
            <Styled.Col maxWidth="50%" noPad noMar mPadLeft="10px">
              <Select
                name="pricePerHourCurrency"
                placeholder="_"
                styles={Styled.Select}
                isSearchable={false}
                options={[
                  { label: 'USD', value: 'USD' },
                  { label: 'CAD', value: 'CAD' }
                ]}
                defaultValue={
                  formik.values.pricePerHourCurrency && {
                    label: formik.values.pricePerHourCurrency,
                    value: formik.values.pricePerHourCurrency
                  }
                }
                onChange={({ value }) => {
                  formik.setFieldValue('pricePerHourCurrency', value)
                }}
                mobile={mobile}
              />
            </Styled.Col>
          </Styled.Col>
        </Styled.Row>
        <Styled.Row>
          <Styled.Col maxWidth={!mobile ? '20%' : '100%'}>
            <Styled.Label htmlFor="spaceFloor">
              <Styled.LabelText>Floor</Styled.LabelText>
              <Styled.Input
                type="text"
                id="spaceFloor"
                name="spaceFloor"
                value={formik.values.spaceFloor || ''}
                placeholder="_"
                onChange={formik.handleChange}
              />
            </Styled.Label>
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '20%' : '50%'}>
            <SlideToggle
              label="Has Windows"
              value={formik.values.spaceWindows}
              id="spaceWindows"
              name="spaceWindows"
              onChange={formik.handleChange}
            />
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '20%' : '50%'}>
            <SlideToggle
              label="Has View"
              value={formik.values.spaceView}
              id="spaceView"
              name="spaceView"
              onChange={formik.handleChange}
            />
          </Styled.Col>
          <Styled.Col maxWidth={!mobile ? '20%' : '50%'}>
            <SlideToggle
              label="Interior Suite"
              value={formik.values.interior}
              id="interior"
              name="interior"
              onChange={formik.handleChange}
            />
          </Styled.Col>
        </Styled.Row>

        {props.initialValues?.isWorkplace && (
          <>
            <Flex flexWrap fullWidth>
              {spaceAmenities.map(item => (
                <Styled.Col key={item.name} maxWidth={!mobile ? '20%' : '50%'}>
                  <SlideToggle
                    label={item.label}
                    value={formik.values[item.name]}
                    id={item.name}
                    name={item.name}
                    onChange={formik.handleChange}
                  />
                </Styled.Col>
              ))}
            </Flex>

            <Flex flexWrap fullWidth>
              <Styled.Col maxWidth={!mobile ? '20%' : '100%'}>
                <Attachment
                  imageId={formik.values?.dollhouseImageId}
                  label="Dollhouse Image"
                  name="dollhouseImage"
                  relatedType="Space"
                  onRemove={() => {
                    formik.setFieldValue('dollhouseImageId', null)
                    formik.setFieldValue('dollhouseImageName', null)
                  }}
                  onChange={({ id, name }) => {
                    formik.setFieldValue('dollhouseImageId', id)
                    formik.setFieldValue('dollhouseImageName', name)
                  }}
                />
              </Styled.Col>

              {spaceImages.map(item => (
                <Styled.Col key={item.name} maxWidth={!mobile ? '20%' : '100%'}>
                  <Attachment
                    imageId={formik.values[`${item.name}Id`]}
                    label={item.label}
                    name={item.name}
                    relatedType="Space"
                    onRemove={() => {
                      formik.setFieldValue(`${item.name}Id`, null)
                      formik.setFieldValue(`${item.name}Name`, null)
                    }}
                    onChange={({ id, name }) => {
                      formik.setFieldValue(`${item.name}Id`, id)
                      formik.setFieldValue(`${item.name}Name`, name)
                    }}
                  />
                </Styled.Col>
              ))}

              <Styled.Col maxWidth={!mobile ? '20%' : '100%'}>
                <AttachmentPdf
                  relatedType="Space"
                  label="Floorplan PDf"
                  initialFileName={formik.values?.floorplanPDFName}
                  name="floorplanPDF"
                  onRemove={() => {
                    formik.setFieldValue('floorplanPDFId', null)
                    formik.setFieldValue('floorplanPDFName', null)
                  }}
                  onChange={({ id, name }) => {
                    formik.setFieldValue('floorplanPDFId', id)
                    formik.setFieldValue('floorplanPDFName', name)
                  }}
                />
              </Styled.Col>

              <Styled.Col maxWidth={!mobile ? '50%' : '100%'}>
                <Styled.Label htmlFor="spaceVirtualTourUrl">
                  <Styled.LabelText>Virtual Tour</Styled.LabelText>
                  <Styled.Input
                    type="url"
                    id="spaceVirtualTourUrl"
                    name="spaceVirtualTourUrl"
                    value={formik.values.spaceVirtualTourUrl || ''}
                    placeholder="_"
                    onChange={formik.handleChange}
                  />
                </Styled.Label>
              </Styled.Col>
            </Flex>
          </>
        )}
      </Flex>
    </Styled.Form>
  )
}

export default Form
