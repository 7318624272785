import React from 'react'
import Geosuggest from 'react-geosuggest'

import './styles.css'

export const GeosuggestWrap = ({ children }) => (
  <div className="geosuggest-wrap">{children}</div>
)

const Main = props => <Geosuggest {...props} />

export default Main
