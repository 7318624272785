import React, { lazy, useState, useEffect, Suspense, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import Spinner from '../../components/spinner'
import Flex from '../../components/flex'
import styled from 'styled-components/macro'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import Sidebar from '../../containers/SidebarSummaries'
import { useMediaQuery } from 'react-responsive'
import { smOnly } from '../../styles/breakpoints'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import { locationsWithLeadCountGet } from '../../actions/market-summaries'
import * as Styled from '../../components/reports'
import { getQuorters } from '../../utils/math'

const Map = lazy(() => import('../../containers/LocationsWithLeadsMap'))
const excludeMarkets = ['Calgary', 'Montreal', 'Ottawa', 'Toronto', 'Vancouver', 'Edmonton']

const Locations = props => {
  const { marketId } = props.match.params
  const mobile = useMediaQuery({ query: smOnly })
  const isNorthAmerica = useSelector(state => state.brokerTools.isNorthAmerica)
  const flexOfficePro = useSelector(state => state.user.flexOfficePro)
  const dispatch = useDispatch()
  const quorters = getQuorters()
  const [quarter, setQuarter] = useState(quorters.reverse()[0])
  const {
    withLeads: { byQuarter: locWithLeadsByQuarter }
  } = useSelector(state => state.summaries)

  const locations = useMemo(() => {
    return (locWithLeadsByQuarter[quarter] || [])
      .filter(
        item =>
          !isNorthAmerica ||
          (isNorthAmerica && item.market_name && !excludeMarkets.includes(item.market_name))
      )
      .map(item => ({
        ...item,
        numData: item.leads
      }))
  }, [quarter, locWithLeadsByQuarter, isNorthAmerica])

  useEffect(() => {
    if (quarter) {
      dispatch(locationsWithLeadCountGet(quarter))
    }
  }, [dispatch, quarter])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {flexOfficePro === 'Advisor' && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          {isNorthAmerica ? (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>North American Summaries</Item>
            </>
          ) : (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Market Summaries</Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            <Wrap>
              <Row>
                <Suspense
                  fallback={
                    <Flex fullWidth fullHeight center>
                      <Spinner elSize={40} />
                    </Flex>
                  }
                >
                  <>
                    <Styled.ChartWrap>
                      <MapWrap>
                        <SelectWrap>
                          <Select
                            placeholder="_"
                            styles={{
                              container: provided => ({
                                ...provided,
                                width: 120
                              })
                            }}
                            options={quorters.map(item => ({
                              value: item,
                              label: item
                            }))}
                            isSearchable={false}
                            onChange={value => [setQuarter(value.label)]}
                            value={{
                              value: quarter,
                              label: quarter
                            }}
                          />
                        </SelectWrap>
                        <Map locations={locations} pinColor="#57AB83" showClusters />
                        <Legend>
                          <span>Upsuite defines Lead Volume as the number of leads introduced to locations in the market</span>
                        </Legend>
                      </MapWrap>
                    </Styled.ChartWrap>
                  </>
                </Suspense>
              </Row>
            </Wrap>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Wrap = styled.div`
  width: 100%;
  max-width: 1000px;
`

const MapWrap = styled.div`
  position: relative;
  width: 100%;
`

const SelectWrap = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
`

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

const Legend = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  span {
    color: #666666;
    text-align: center;
  }
`

export default Locations
