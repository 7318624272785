import React from 'react'
import styled from 'styled-components/macro'

const ViewToggle = ({ disabled = false, active = 0, onClick = () => {}, spaceBelow }) => {
  const options = ['View Locations', 'View Market Data']

  return (
    <Wrap data-space-below={spaceBelow} data-active-view={active}>
      {options.map((item, index) => (
        <Btn
          key={`${item}-${index}`}
          data-active={active === index}
          disabled={disabled}
          onClick={() => onClick(index)}
        >
          {item}
        </Btn>
      ))}
    </Wrap>
  )
}

const Wrap = styled.div`
  background-color: #f8f8f8;
  padding: 10px 16px 0 16px;

  &[data-active-view="1"] {
    padding-bottom: 10px;
  }

  &[data-space-below="true"] {
    margin-bottom: 10px;
  }
`

const Btn = styled.button.attrs({ type: 'button' })`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  box-shadow: none;
  padding: 7px 20px;
  border-width: 1px;
  border-style: solid;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #e7483d;
  border-bottom-color: #e7483d;
  background-color: white;
  cursor: pointer;

  &:first-child {
    border-left-color: #e7483d;
  }

  &:last-child {
    border-right-color: #e7483d;
  }

  &[data-active='true'] {
    background-color: #e7483d;
    color: white;

    &:not(:last-child) {
      border-right-color: #e7483d;
    }
  }

  &:disabled[data-active='false'],
  &[disabled][data-active='false'] {
    color: black;
  }

  &:disabled[data-active='true'],
  &[disabled][data-active='true'] {
    color: white;
  }
`

export default ViewToggle
