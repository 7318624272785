import React from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { Wrapper, Item, SubItem } from '../../components/sidebar'
import {
  ViewsInSearchIcon,
  PipelineIcon,
  PricePerPerson,
  PricePerSuite,
  LeadPerformanceIcon,
  AverageSize
} from '../../components/icons'
import { DEMAND_AND_PRICING_ROUTE, PIPELINE_ROUTE } from '../../settings/routes'

const Sidebar = () => {
  const { marketId, locationId } = useParams()
  return (
    <Wrapper>
      <Item>Market Demand</Item>
      <SubItem>
        <NavLink
          to={`/${DEMAND_AND_PRICING_ROUTE}/${PIPELINE_ROUTE}/${marketId}/${
            locationId || ''
          }`}
        >
          <PipelineIcon /> Pipeline
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${DEMAND_AND_PRICING_ROUTE}/views-in-search/${marketId}/${
            locationId || ''
          }`}
        >
          <ViewsInSearchIcon /> Views in Search
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${DEMAND_AND_PRICING_ROUTE}/lead-performance/${marketId}/${
            locationId || ''
          }`}
        >
          <LeadPerformanceIcon />
          Lead Performance
        </NavLink>
      </SubItem>

      <Item>Pricing & Size</Item>
      <SubItem>
        <NavLink
          to={`/${DEMAND_AND_PRICING_ROUTE}/price-performance/${marketId}/${
            locationId || ''
          }`}
        >
          <PricePerPerson /> Price per Person
        </NavLink>
      </SubItem>

      <SubItem>
        <NavLink
          to={`/${DEMAND_AND_PRICING_ROUTE}/standard-suite-sizes/${marketId}/${
            locationId || ''
          }`}
        >
          <PricePerSuite /> Price per Suite
        </NavLink>
      </SubItem>

      <SubItem>
        <NavLink
          to={`/${DEMAND_AND_PRICING_ROUTE}/average-size/${marketId}/${
            locationId || ''
          }`}
        >
          <AverageSize /> Average Size
        </NavLink>
      </SubItem>
    </Wrapper>
  )
}

export default Sidebar
