import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Control } from '../../components/spaces';
import { spaceUpdate } from '../../actions/spaces';
import { selectByIds } from '../../selectors/spaces';
import { locationChangeStatus } from '../../actions/locations';

const ConfirmData = (props) => {
  const { ids, locationId } = props;
  const dispatch = useDispatch();
  const [status, setStatus] = useState('Confirm Data');
  const spaces = useSelector(state => selectByIds(state, ids));

  return (
    <Control
      onClick={() => {
        setStatus('Confirming');
        Promise.all(
          spaces.map(item => dispatch(spaceUpdate(item))),
        ).then(() => {
          setStatus('Confirmed');
          dispatch(locationChangeStatus(locationId))
        });
      }}
      withShift
    >
      <span>{status}</span>
    </Control>
  );
};

export default ConfirmData;
