export const LOGIN_ROUTE = 'login'
export const FORGOT_PASS_ROUTE = 'forgot-password'
export const CONFIRMATION_CODE_ROUTE = 'confirmation-code'
export const CHANGE_PASS_ROUTE = 'change-password'
export const DASHBOARD_ROUTE = 'dashboard'
export const GET_FLEX_ROUTE = 'getflexofficepro'
export const SPACES_ROUTE = 'spaces'
export const MARKET_REPORTS = 'reports'
export const LOCATION_ROUTE = 'location'
export const LOCATION_SETUP_ROUTE = 'setup'
export const LOCATION_CONTACTS_ROUTE = 'contacts'
export const LOCATION_ENVIRONMENT_ROUTE = 'environment'
export const LOCATION_AMENITIES_ROUTE = 'amenities'
export const LOCATION_IMAGERY_ROUTE = 'imagery'
export const LOCATION_NETWORK_ROUTE = 'network'
export const LOCATION_REVIEWS_ROUTE = 'reviews'
export const LOCATION_PROMO_ROUTE = 'promotions'
export const LEADS_ROUTE = 'leads'
export const WORK_LEDS_ROUTE = 'work-leads'
export const PIPELINE_ROUTE = 'pipeline'
export const PROPOSALS_ROUTE = 'proposals'
export const LOGOUT_ROUTE = 'logout'
export const PROFILE_ROUTE = 'profile'
export const DEMAND_AND_PRICING_ROUTE = 'demand-and-pricing'
export const AGREEMENTS_AND_FEES_ROUTE = 'agreements-and-fees'

export const marketReports = {
  main: 'reports',
  inventory: 'inventory-vacancy',
  price: 'price',
  monitor: 'monitor',
  supply: 'new-closed-locations',
  demand: 'demand',
  forecast: 'forecast'
}

export const marketSummaries = {
  main: 'summaries',
  summary: 'market',
  trends: 'volume',
  views: 'views'
}

export const locationServices = {
  main: 'location-services',
  map: 'map',
  list: 'list',
  mapAll: 'map-all',
  listAll: 'list-all'
}
