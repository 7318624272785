import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  MARKETS_GET_REQUEST,
  MARKETS_GET_SUCCESS,
  MARKETS_GET_FAILURE,
  MARKETS_ALL_GET_REQUEST,
  MARKETS_ALL_GET_SUCCESS,
  MARKETS_ALL_GET_FAILURE
} from '../constants'
import * as api from '../api/markets'

const marketsGetRequest = () => ({
  type: MARKETS_GET_REQUEST
})

const marketsGetSuccess = payload => ({
  type: MARKETS_GET_SUCCESS,
  payload
})

const marketsGetFailure = payload => ({
  type: MARKETS_GET_FAILURE,
  payload
})

export const marketsGet = (token, isReportsAccess) => (dispatch, getState) => {
  const {
    locations: {
      status: { completed, error }
    }
  } = getState()
  if (completed && !error) {
    return null
  }

  dispatch(marketsGetRequest())

  return api
    .getMarkets(token, isReportsAccess)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(marketsGetSuccess(response))
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(message => {
          // eslint-disable-next-line no-alert
          window.alert(err.status)
          dispatch(marketsGetFailure(`${err.status}: ${message}`))
        })
      } else {
        dispatch(marketsGetFailure('Markets get unknown error'))
      }
    })
}

const marketsAllGetRequest = () => ({
  type: MARKETS_ALL_GET_REQUEST
})

const marketsAllGetSuccess = payload => ({
  type: MARKETS_ALL_GET_SUCCESS,
  payload
})

const marketsAllGetFailure = payload => ({
  type: MARKETS_ALL_GET_FAILURE,
  payload
})

export const marketsAllGet = (token) => (
  dispatch,
  getState
) => {
  const {
    markets: {
      all: {
        status: { completed, error }
      }
    }
  } = getState()
  if (completed && !error) {
    return null
  }

  dispatch(marketsAllGetRequest())

  return api
    .getAllMarkets(token)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(marketsAllGetSuccess(response))
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(message => {
          // eslint-disable-next-line no-alert
          window.alert(err.status)
          dispatch(marketsAllGetFailure(`${err.status}: ${message}`))
        })
      } else {
        dispatch(marketsAllGetFailure('Markets get unknown error'))
      }
    })
}
