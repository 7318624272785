import React, { lazy, useState, useEffect, Suspense, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import Spinner from '../../components/spinner'
import Flex from '../../components/flex'
import styled from 'styled-components/macro'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import Sidebar from '../../containers/SidebarReports'
import { useMediaQuery } from 'react-responsive'
import { smOnly } from '../../styles/breakpoints'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import { newClosedLocGet, summeryTextGet } from '../../actions/market-reports'
import { getNewLoc } from '../../helpers/reports'
import * as Styled from '../../components/reports'
import Copyright from './copyright'

const Map = lazy(() => import('../../containers/LocationsMap'))

const Locations = props => {
  const { marketId } = props.match.params
  const market = useSelector(state => state.markets.byId[marketId])
  const mobile = useMediaQuery({ query: smOnly })
  const flexOfficePro = useSelector(state => state.user.flexOfficePro)
  const dispatch = useDispatch()
  const page = 'newandclosedlocations'
  const { byMarketId } = useSelector(state => state.reports.locations)
  const data = useMemo(() => {
    const byMarketIdData = byMarketId[marketId] || []
    return [...byMarketIdData].reverse()
  }, [marketId, byMarketId])
  const [activeQuarter, setActiveQuarter] = useState(null)
  const { byPageMarket } = useSelector(state => state.reports.summeryText)
  const textItems = byPageMarket[`${page}-${marketId}`] || []
  const getTextItem = textItems.find(item => item?.label === activeQuarter)
  const currentIndex = (data || []).findIndex(
    item => item?.quarter === activeQuarter
  )
  const totalValue = ((data || [])[currentIndex] || {})
    .locations_open_list_count
  const closedValue = ((data || [])[currentIndex] || {})
    .locations_closed_list_count
  const newValue = ((data || [])[currentIndex] || {}).locations_new_list_open

  useEffect(() => {
    if (marketId) {
      dispatch(newClosedLocGet(marketId))
      dispatch(summeryTextGet(page, marketId))
    }
  }, [dispatch, marketId])

  useEffect(() => {
    if (data) {
      setActiveQuarter((data[0] || {}).quarter)
    }
  }, [data])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {flexOfficePro === 'Advisor' && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          <>
            <Delimeter>&gt;</Delimeter>
            <Item>Market Reports</Item>
          </>
          {market?.name && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item bold>
                {market.name} {activeQuarter || ''}
              </Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            <Wrap>
              {data && (
                <Styled.TopSelectWrap>
                  <Select
                    options={data.map(item => ({
                      label: item.quarter,
                      value: item.quarter
                    }))}
                    isSearchable={false}
                    value={{
                      value: activeQuarter,
                      label: activeQuarter
                    }}
                    onChange={({ value }) => setActiveQuarter(value)}
                  />
                </Styled.TopSelectWrap>
              )}
              <Row>
                <Suspense
                  fallback={
                    <Flex fullWidth fullHeight center>
                      <Spinner elSize={40} />
                    </Flex>
                  }
                >
                  {data && (
                    <>
                      <Styled.ChartWrap>
                        <Styled.NumbersWrapper dataHeight="100%">
                          <CalloutBox>
                            <CalloutBoxCol>
                              <CalloutBoxText>New</CalloutBoxText>
                              <CalloutBoxNumber data-positive>
                                {newValue > 0 ? `+${newValue}` : newValue}
                              </CalloutBoxNumber>
                            </CalloutBoxCol>
                            <CalloutBoxCol>
                              <CalloutBoxText>Closed</CalloutBoxText>
                              <CalloutBoxNumber data-positive={false}>
                                {closedValue > 0
                                  ? `-${closedValue}`
                                  : closedValue}
                              </CalloutBoxNumber>
                            </CalloutBoxCol>
                          </CalloutBox>
                          <Styled.Number>{totalValue}</Styled.Number>
                          <Styled.SubLabel>Existing Locations <br /> from Previous Quarter</Styled.SubLabel>
                        </Styled.NumbersWrapper>
                        <Flex fullWidth column>
                          <Heading>New & Closed Locations</Heading>
                          <Copyright center mb />
                          <Map
                            newLoc={getNewLoc(data, activeQuarter).newLoc}
                            openedLoc={getNewLoc(data, activeQuarter).openLoc}
                            closedLoc={getNewLoc(data, activeQuarter).closedLoc}
                            marketId={marketId}
                          />
                        </Flex>
                      </Styled.ChartWrap>
                    </>
                  )}
                </Suspense>
              </Row>
            </Wrap>
            {getTextItem && (
              <Content>
                {!getTextItem.isHtml && <p>{getTextItem.text}</p>}
                {getTextItem.isHtml && (
                  <p dangerouslySetInnerHTML={getTextItem.text} />
                )}
              </Content>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Wrap = styled.div`
  width: 100%;
  max-width: 1000px;
`

const Row = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

const Content = styled.div`
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
  margin-bottom: 100px;

  p {
    color: #747474;
    font-size: 24px;
    margin: 0;
  }
`

const Heading = styled(Styled.Heading)`
  margin-bottom: 0.5em;
`

const CalloutBox = styled.div`
  padding-top: 4rem;
  display: flex;
  margin-bottom: 20px;
`

const CalloutBoxNumber = styled.span`
  font-size: 2rem;
  border-top: 1px solid #666666;
  padding: 0.8rem;
  margin-top: 0.4rem;

  &[data-positive='true'] {
    color: #57ab83;
  }

  &[data-positive='false'] {
    color: #f6dc03;
  }
`

const CalloutBoxCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 100px;

  &:first-child {
    ${CalloutBoxNumber} {
      border-right: 1px solid #666666;
    }
  }
`

const CalloutBoxText = styled.span`
  font-size: 16px;
  color: #333;
`

export default Locations
