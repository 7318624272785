import keyBy from 'lodash/keyBy'
import { createReducer } from '../utils/redux'
import {
  MARKETS_GET_REQUEST,
  MARKETS_GET_SUCCESS,
  MARKETS_GET_FAILURE,
  MARKETS_ALL_GET_REQUEST,
  MARKETS_ALL_GET_SUCCESS,
  MARKETS_ALL_GET_FAILURE
} from '../constants'

const initialState = {
  byId: {},
  list: [],
  ids: [],
  fulllist: false,
  status: {
    error: false,
    errorText: null,
    loading: false,
    completed: false
  },
  all: {
    list: [],
    byId: {},
    status: {
      error: false,
      errorText: null,
      loading: false,
      completed: false
    }
  }
}

export default createReducer(initialState, {
  [MARKETS_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true
    }
  }),
  [MARKETS_GET_SUCCESS]: (state, payload) => ({
    ...state,
    list: payload?.markets || payload,
    byId: {
      ...state.byId,
      ...keyBy(payload?.markets || payload, 'id')
    },
    ids: (payload?.markets || payload).map(loc => loc.id),
    status: {
      ...initialState.status,
      loading: false,
      completed: true
    },
    fulllist: payload?.fulllist
  }),
  [MARKETS_GET_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      completed: true,
      error: true,
      errorText: payload
    }
  }),
  [MARKETS_ALL_GET_REQUEST]: state => ({
    ...state,
    all: {
      ...state.all,
      status: {
        ...initialState.status,
        loading: true
      }
    }
  }),
  [MARKETS_ALL_GET_SUCCESS]: (state, payload) => ({
    ...state,
    all: {
      ...state.all,
      list: payload.list,
      byId: {
        ...state.all.byId,
        ...keyBy(payload.list, 'id')
      },
      status: {
        ...initialState.status,
        loading: false,
        completed: true
      }
    }
  }),
  [MARKETS_ALL_GET_FAILURE]: (state, payload) => ({
    ...state,
    all: {
      ...state.all,
      status: {
        ...initialState.status,
        completed: true,
        error: true,
        errorText: payload
      }
    }
  })
})
