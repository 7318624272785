import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useFormik } from 'formik';
import Flex from '../../components/flex';

import * as Styled from '../../components/space-form';
import {
  Wrapper,
  Form as FormWrapper,
  Row,
  Input,
  Submit,
  Error,
} from '../../components/promo-form';
import { Message } from '../../components/profile';
import { smOnly } from '../../styles/breakpoints';

const Form = props => {
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
    validate: values => {
      const errors = {};
      if (values.newPassword !== values.newPasswordRepeat) {
        errors.password = 'Passwords do not match.';
      }
      return errors;
    },
    onSubmit: props.onSubmit,
  });

  const mobile = useMediaQuery({ query: smOnly });
  return (
    <Wrapper>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Flex flexWrap fullWidth>
          <Row>
            <Styled.Col maxWidth={!mobile ? '50%' : '100%'}>
              <Styled.Label htmlFor="oldPassword">
                <Styled.LabelText>Old Password</Styled.LabelText>
                <Input
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  value={formik.values.oldPassword || ''}
                  onChange={formik.handleChange}
                />
              </Styled.Label>
            </Styled.Col>

            <Styled.Col maxWidth={!mobile ? '50%' : '100%'}>
              <Styled.Label htmlFor="newPassword">
                <Styled.LabelText>New Password</Styled.LabelText>
                <Input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={formik.values.newPassword || ''}
                  onChange={formik.handleChange}
                />
              </Styled.Label>
              <Styled.Label htmlFor="newPassword">
                <Styled.LabelText>New Password Repeat</Styled.LabelText>
                <Input
                  type="password"
                  id="newPasswordRepeat"
                  name="newPasswordRepeat"
                  value={formik.values.newPasswordRepeat || ''}
                  onChange={formik.handleChange}
                />
              </Styled.Label>
              {formik.errors.password && formik.touched.newPasswordRepeat && (
                <Error>{formik.errors.password}</Error>
              )}
            </Styled.Col>
          </Row>
          <Row>
            <Styled.Col maxWidth="100%">
              {props.message && <Message>{props.message}</Message>}
              <Submit type="submit">Submit</Submit>
            </Styled.Col>
          </Row>
        </Flex>
      </FormWrapper>
    </Wrapper>
  );
};

export default Form;
