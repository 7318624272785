import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import styled from 'styled-components/macro'
import { useParams } from 'react-router'
import { getHoods, addHood } from '../../api/hoods'
import { useSelector } from 'react-redux'
import { parseJSON } from '../../utils/api'
import * as Styled from '../location'
import Spinner from '../spinner'
import { Wrapper, Area, CounterWrap, Counter } from '../textarea-counter'

const HoodSelect = ({ onChange, value, disabled }) => {
  const { marketId } = useParams()
  const token = useSelector(state => state.auth.token)
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [list, setList] = useState([])
  const [selectValue, setSelectValue] = useState()
  const [addingNew, setAddingNew] = useState(false)
  const [desc, setDesc] = useState(null)
  const nameRef = useRef()
  const descRef = useRef()

  useEffect(() => {
    setSelectValue(value)
  }, [value])

  useEffect(() => {
    setLoading(true)
    getHoods(marketId)
      .then(response => {
        if (response.status === 200) {
          parseJSON(response).then(data => {
            setList(data.list)
          })
        } else {
          setError(`Error ${response.status}`)
        }
        setLoading(false)
      })
      .catch(() => {
        setError('Unknow server error')
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCreate = () => {
    setError()
    const name = nameRef.current.value
    const neighborhoodDescription = descRef.current.value
    if (name && neighborhoodDescription) {
      setUpdating(true)
      addHood(token, { name, neighborhoodDescription, marketId })
        .then(response => {
          if (response.status === 200) {
            parseJSON(response).then(data => {
              setSelectValue({
                label: data.name,
                value: data.id
              })
              onChange(data)
              setList([data, ...list])
              setAddingNew(false)
              setError()
            })
          } else {
            setError(`Error ${response.status}`)
          }
          setUpdating(false)
        })
        .catch(() => {
          setError('Unknow server error')
          setUpdating(false)
        })
    } else {
      setError('Neighborhood name and description are required')
    }
  }

  return (
    <>
      {!addingNew ? (
        <>
          <Styled.Label data-spacebelow>
            <Styled.LabelText data-invalid={!selectValue}>
              Neighborhood*
            </Styled.LabelText>
            <Select
              placeholder="_"
              styles={Styled.Select}
              options={list.map(({ name, id }) => ({
                value: id,
                label: name
              }))}
              isDisabled={loading || disabled}
              isLoading={loading}
              onChange={value => [
                onChange({ id: value.value, name: value.label }),
                setSelectValue(value)
              ]}
              value={selectValue}
            />
            {error && <ErrorText>{error}</ErrorText>}
          </Styled.Label>
          <Styled.Footer>
            <Styled.Action
              disabled={disabled}
              type="button"
              onClick={() => setAddingNew(true)}
            >
              Create New
            </Styled.Action>
          </Styled.Footer>
          {error && <ErrorText>{error}</ErrorText>}
        </>
      ) : (
        <>
          <Styled.Label htmlFor="neighborhood-name">
            <Styled.LabelText>Neighborhood Name*</Styled.LabelText>
            <Styled.Input
              type="text"
              id="neighborhoo-dname"
              ref={nameRef}
              placeholder="_"
              disabled={updating}
            />
          </Styled.Label>
          <Styled.Label htmlFor="neighborhood-description">
            <Styled.LabelText>Neighborhood Description*</Styled.LabelText>
            <Wrapper>
              <Area
                id="neighborhood-description"
                onChange={(event) => setDesc(event.target.value)}
                onBlur={() => {}}
                required
                ref={descRef}
                placeholder="_"
                value={desc || ''}
                rows={5}
                maxLength={500}
                disabled={updating}
                absolute={false}
              />
                <CounterWrap>
                  <Counter>{`${((desc || '').length || 0)}/500`}</Counter>
                </CounterWrap>
            </Wrapper>
          </Styled.Label>
          <Styled.Footer>
            {updating && <Spinner elSize={20} />}
            <>
              <Styled.Action
                disabled={updating}
                type="button"
                onClick={() => setAddingNew(false)}
              >
                Cancel
              </Styled.Action>
              <Styled.Action
                disabled={updating}
                type="button"
                onClick={() => handleCreate()}
              >
                Save
              </Styled.Action>
            </>
          </Styled.Footer>
          {error && <ErrorText>{error}</ErrorText>}
        </>
      )}
    </>
  )
}

const ErrorText = styled.span`
  color: red;
  text-align: center;
  font-size: 14px;
  display: block;
`

export default HoodSelect
