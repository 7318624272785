import { createReducer } from '../utils/redux'
import {
  LOCATION_SERVICES_GET_REQUEST,
  LOCATION_SERVICES_GET_SUCCESS,
  LOCATION_SERVICES_GET_FAILURE,
  LOCATION_SERVICES_FILTER,
  LOCATION_SERVICES_CLEAR,
  LOCATION_SERVICES_LANDLORDS_REQUEST,
  LOCATION_SERVICES_LANDLORDS_SUCCESS,
  LOCATION_SERVICES_LANDLORDS_FAILURE,
  LOCATION_SERVICES_REMOVE,
  LOCATION_SERVICES_OPEN,
  LOCATION_SERVICES_CLOSE,
  LOCATION_SERVICES_CLOSED_GET_REQUEST,
  LOCATION_SERVICES_CLOSED_GET_SUCCESS,
  LOCATION_SERVICES_CLOSED_GET_FAILURE,
  HIGHLIGHT_MARKER,
  MAP_PINS_TO_FEED,
  LOCATION_SERVICES_TOGGLE_VIEW,
  MARKET_SUMMARY_GET_SUCCESS,
  MARKET_LOCATIONS_DATA_SUCCESS,
  LEAD_OWNERS_GET_SUCCESS,
  LEAD_LOCATIONS_GET_SUCCESS,
} from '../constants'

const initialState = {
  list: [],
  listClosed: [],
  status: {
    landlords: false,
    landlordsCompleted: false,
    loading: false,
    closed: false
  },
  error: {
    landlords: false,
    loading: false,
    closed: false
  },
  filter: {
    marketId: null,
    marketName: null,
    search: null,
    neighborhood: [],
    healthStatus: [],
    type: [],
    sizeClass: [],
    landlord: [],
    operator: []
  },
  landlords: [],
  panel: null,
  highlightedId: null,
  pinIds: [],
  activeView: 0,
  marketSummaryById: {},
  marketLocationsDataById: {},
  leadOwners: null,
  leadLocationsById: {}
}

export default createReducer(initialState, {
  [LOCATION_SERVICES_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: true
    },
    error: {
      ...state.error,
      loading: false
    }
  }),
  [LOCATION_SERVICES_GET_SUCCESS]: (state, payload) => ({
    ...state,
    list: payload,
    status: {
      ...state.status,
      loading: false
    },
    error: {
      ...state.error,
      loading: false
    }
  }),
  [LOCATION_SERVICES_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: false
    },
    error: {
      ...state.error,
      loading: true
    }
  }),
  [LOCATION_SERVICES_CLOSED_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      closed: true
    },
    error: {
      ...state.error,
      closed: false
    }
  }),
  [LOCATION_SERVICES_CLOSED_GET_SUCCESS]: (state, payload) => ({
    ...state,
    listClosed: payload,
    status: {
      ...state.status,
      closed: false
    },
    error: {
      ...state.error,
      closed: false
    }
  }),
  [LOCATION_SERVICES_CLOSED_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      closed: false
    },
    error: {
      ...state.error,
      closed: true
    }
  }),
  [LOCATION_SERVICES_FILTER]: (state, payload, config) => {
    const filter = config ? {
      ...state.filter,
      [config]: payload
    } : {
      ...state.filter,
      ...payload
    }

    return ({
      ...state,
      filter
    })
  },
  [LOCATION_SERVICES_CLEAR]: state => ({
    ...state,
    filter: initialState.filter
  }),
  [LOCATION_SERVICES_LANDLORDS_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      landlords: true
    },
    error: {
      ...state.error,
      landlords: false
    }
  }),
  [LOCATION_SERVICES_LANDLORDS_SUCCESS]: (state, payload) => {
    const list = [
      ...state.landlords,
      ...payload.list
    ];
    const loading = list.length < payload.total;
    const completed = list.length === payload.total;

    return ({
      ...state,
      landlords: list,
      status: {
        ...state.status,
        landlords: loading,
        landlordsCompleted: completed
      },
      error: {
        ...state.error,
        landlords: false
      }
    })
  },
  [LOCATION_SERVICES_LANDLORDS_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      landlords: false
    },
    error: {
      ...state.error,
      landlords: true
    }
  }),
  [LOCATION_SERVICES_REMOVE]: (state, payload) => {
    return ({
      ...state,
      filter: {
        ...state.filter,
        [payload.key]: state.filter[payload.key].filter(
          item => item.value !== payload.value
        )
      }
    })
  },
  [LOCATION_SERVICES_OPEN]: (state, payload) => ({
    ...state,
    panel: payload
  }),
  [LOCATION_SERVICES_CLOSE]: (state) => ({
    ...state,
    panel: null
  }),
  [HIGHLIGHT_MARKER]: (state, payload) => ({
    ...state,
    highlightedId: payload || null
  }),
  [MAP_PINS_TO_FEED]: (state, payload) => ({
    ...state,
    pinIds: payload
  }),
  [LOCATION_SERVICES_TOGGLE_VIEW]: (state, payload) => ({
    ...state,
    activeView: payload
  }),
  [MARKET_SUMMARY_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    marketSummaryById: {
      ...state.marketSummaryById,
      [config]: payload
    }
  }),
  [MARKET_LOCATIONS_DATA_SUCCESS]: (state, payload, config) => ({
    ...state,
    marketLocationsDataById: {
      ...state.marketLocationsDataById,
      [config]: payload
    }
  }),
  [LEAD_OWNERS_GET_SUCCESS]: (state, payload) => ({
    ...state,
    leadOwners: payload
  }),
  [LEAD_LOCATIONS_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    leadLocationsById: {
      ...state.leadLocationsById,
      [config]: payload
    }
  })
})
