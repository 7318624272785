import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import styled from 'styled-components/macro'
import Flex from '../../components/flex'
import { mdUp } from '../../styles/breakpoints'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import Sidebar from './Sidebar'
import SearchBar from './SearchBar'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import {
  selectMarketsWithCoords
} from '../../selectors/markets'
import MapFeed from './MapFeed'
import List from './List'
import { locationServices } from '../../settings/routes'
import Panel from './Panel'

const LocationServicesAll = () => {
  const markets = useSelector(state => selectMarketsWithCoords(state))
  const [mapsLoaded, setMapsLoaded] = useState(false)

  return (
    <Flex fullWidth fullHeight middle column>
      <Panel />
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/`}>Dashboard</Clickable>
          <>
            <Delimeter>&gt;</Delimeter>
            <Item>Location Services</Item>
          </>
        </Wrapper>
        <Wrap fullWidth fullHeight alignStart>
          <Sidebar />

          <RightCol>
            <SearchBar mapsLoaded={mapsLoaded} markets={markets} />

            <Switch>
              <Route
                exact
                path={`/${locationServices.main}/${locationServices.mapAll}`}
              >
                <MapFeed onGoogleApiLoaded={loaded => setMapsLoaded(loaded)} />
              </Route>
              <Route
                exact
                path={`/${locationServices.main}/${locationServices.listAll}`}
              >
                <List onGoogleApiLoaded={loaded => setMapsLoaded(loaded)} />
              </Route>
            </Switch>
          </RightCol>
        </Wrap>
      </Flex>
    </Flex>
  )
}

const Wrap = styled.div`
  display: flex;
  flex-direction: column;

  @media ${mdUp} {
    flex-direction: row;
  }
`

const RightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top: 1px solid #bdbdbd;

  @media ${mdUp} {
    height: calc(100vh - 133px);
    width: calc(100% - 240px);
  }
`

export default LocationServicesAll
