import React from 'react'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  min-height: 300px;
`

export const Heading = styled.h2`
  font-size: 19px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #e7483d;
  margin-top: 5px;
  text-align: center;
`

export const Subheading = styled.h3`
  font-size: 14px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 5px;
  text-align: center;
`

export const List = styled.ul`
  list-style: none;
  margin-top: 5px;
  margin-bottom: 8px;
  padding: 0 20px;
  width: 100%;

  &[data-scrollable="true"] {
    height: 175px;
    overflow: auto;
  }
`

export const Item = styled.li`
  padding-left: 30px;
  color: #9a9a9a;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 7px;
  position: relative;

  ${props =>
    props.active &&
    `
    color: black;

    &::before {
      content: 'X';
      font-weight: 700;
      position: absolute;
      left: 0;
    }
  `}
`

export const MatrixCardTop = styled.div`
  border-bottom: 1px solid #ccc;
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0 10px;
`

export const Button = styled.button`
  background-color: #e7483d;
  border: none;
  display: inline-block;
  color: #fff;
  font-weight: 700;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: 10px;
`

export const MatrixCard = ({
  heading,
  subheading,
  buttonTitle,
  onClick,
  children,
  scrollable
}) => (
  <Wrapper>
    <MatrixCardTop>
      <Heading>{heading}</Heading>
      {subheading && (
        <Subheading>{subheading}</Subheading>
      )}
    </MatrixCardTop>
    <List data-scrollable={scrollable}>{children}</List>
    <Button type="button" onClick={onClick}>
      {buttonTitle}
    </Button>
  </Wrapper>
)
