import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import * as Styled from '../../components/location'
import Spinner from '../../components/spinner'
import { smOnly } from '../../styles/breakpoints'
import { locationUpdate } from '../../actions/locations'
import Attachment from '../../components/attachment'

const LocationContacts = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)
  const getInitialValues = {
    leadRegistrationEmail: location?.leadRegistrationEmail,
    leadRegistrationLastName: location?.leadRegistrationLastName,
    leadRegistrationName: location?.leadRegistrationName,
    leadsContactLinkedInProfile: location?.leadsContactLinkedInProfile,
    leadsContactTitle: location?.leadsContactTitle,
    leadsContactHeadshotId: location?.leadsContactHeadshotId,
    leadsContactHeadshotName: location?.leadsContactHeadshotName,
    communityContactEmail: location?.communityContactEmail,
    communityContactLastName: location?.communityContactLastName,
    communityContactFirstName: location?.communityContactFirstName,
    communityContactHeadshotId: location?.communityContactHeadshotId,
    communityContactHeadshotName: location?.communityContactHeadshotName,
    communityContactLinkedInProfile: location?.communityContactLinkedInProfile,
    communityContactTitle: location?.communityContactTitle
  }

  const formik = useFormik({
    initialValues: getInitialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      dispatch(locationUpdate(locationId, values))
    }
  })
  const mobile = useMediaQuery({ query: smOnly })
  const handleResetForm = () => formik.resetForm({ values: getInitialValues })

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.Header>
            <Styled.Actions mobile={mobile}>
              {updating && <Spinner elSize={20} />}
              <>
                <Styled.Action
                  disabled={updating}
                  type="button"
                  onClick={() => handleResetForm()}
                >
                  Cancel
                </Styled.Action>
                <Styled.Action disabled={updating} type="submit">
                  Save
                </Styled.Action>
              </>
            </Styled.Actions>
          </Styled.Header>
          <Styled.Section>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  <Styled.Title>Leads/Sales Contact</Styled.Title>
                </Styled.Col>
              </Styled.Row>
              <Flex flexWrap fullWidth>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="leadRegistrationName">
                    <Styled.LabelText
                      data-invalid={!formik.values?.leadRegistrationName}
                    >
                      Contact First Name
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="leadRegistrationName"
                      name="leadRegistrationName"
                      placeholder="_"
                      value={formik.values?.leadRegistrationName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.leadRegistrationName && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="leadRegistrationLastName">
                    <Styled.LabelText
                      data-invalid={!formik.values?.leadRegistrationLastName}
                    >
                      Contact Last Name
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="leadRegistrationLastName"
                      name="leadRegistrationLastName"
                      placeholder="_"
                      value={formik.values?.leadRegistrationLastName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.leadRegistrationLastName && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="leadRegistrationEmail">
                    <Styled.LabelText
                      data-invalid={!formik.values?.leadRegistrationEmail}
                    >
                      Contact Email
                    </Styled.LabelText>
                    <Styled.Input
                      type="email"
                      id="leadRegistrationEmail"
                      name="leadRegistrationEmail"
                      placeholder="_"
                      value={formik.values?.leadRegistrationEmail || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.leadRegistrationEmail && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="leadsContactTitle">
                    <Styled.LabelText
                      data-invalid={!formik.values?.leadsContactTitle}
                    >
                      Contact Title
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="leadsContactTitle"
                      name="leadsContactTitle"
                      placeholder="_"
                      value={formik.values?.leadsContactTitle || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.leadsContactTitle && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="leadsContactLinkedInProfile">
                    <Styled.LabelText
                      data-invalid={!formik.values?.leadsContactLinkedInProfile}
                    >
                      Contact LinkedIn
                    </Styled.LabelText>
                    <Styled.Input
                      type="url"
                      id="leadsContactLinkedInProfile"
                      name="leadsContactLinkedInProfile"
                      placeholder="_"
                      value={formik.values?.leadsContactLinkedInProfile || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.leadsContactLinkedInProfile && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Attachment
                    imageId={formik.values?.leadsContactHeadshotId}
                    label="Contact Headshot"
                    labelHighlight
                    name="leadsContactHeadshot"
                    onRemove={() => {
                      formik.setFieldValue('leadsContactHeadshotId', null)
                      formik.setFieldValue('leadsContactHeadshotName', null)
                    }}
                    onChange={({ id, name }) => {
                      formik.setFieldValue('leadsContactHeadshotId', id)
                      formik.setFieldValue('leadsContactHeadshotName', name)
                    }}
                  />
                  {formik.submitCount > 0 &&
                    !formik.values?.leadsContactHeadshotId && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
              </Flex>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  <Styled.Title>Listings Manager Contacts</Styled.Title>
                </Styled.Col>
              </Styled.Row>
              <Flex flexWrap fullWidth>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="communityContactFirstName">
                    <Styled.LabelText
                      data-invalid={!formik.values?.communityContactFirstName}
                    >
                      Contact First Name
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="communityContactFirstName"
                      name="communityContactFirstName"
                      placeholder="_"
                      value={formik.values?.communityContactFirstName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.communityContactFirstName && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="communityContactLastName">
                    <Styled.LabelText
                      data-invalid={!formik.values?.communityContactLastName}
                    >
                      Contact Last Name
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="communityContactLastName"
                      name="communityContactLastName"
                      placeholder="_"
                      value={formik.values?.communityContactLastName || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.communityContactLastName && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="communityContactEmail">
                    <Styled.LabelText
                      data-invalid={!formik.values?.communityContactEmail}
                    >
                      Contact Email
                    </Styled.LabelText>
                    <Styled.Input
                      type="email"
                      id="communityContactEmail"
                      name="communityContactEmail"
                      placeholder="_"
                      value={formik.values?.communityContactEmail || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.communityContactEmail && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="communityContactTitle">
                    <Styled.LabelText
                      data-invalid={!formik.values?.communityContactTitle}
                    >
                      Contact Title
                    </Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="communityContactTitle"
                      name="communityContactTitle"
                      placeholder="_"
                      value={formik.values?.communityContactTitle || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.communityContactTitle && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Styled.Label htmlFor="communityContactLinkedInProfile">
                    <Styled.LabelText
                      data-invalid={
                        !formik.values?.communityContactLinkedInProfile
                      }
                    >
                      Contact LinkedIn
                    </Styled.LabelText>
                    <Styled.Input
                      type="url"
                      id="communityContactLinkedInProfile"
                      name="communityContactLinkedInProfile"
                      placeholder="_"
                      value={
                        formik.values?.communityContactLinkedInProfile || ''
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                  {formik.submitCount > 0 &&
                    !formik.values?.communityContactLinkedInProfile && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '33%' : '100%'}>
                  <Attachment
                    imageId={formik.values?.communityContactHeadshotId}
                    label="Contact Headshot"
                    labelHighlight
                    name="communityContactHeadshot"
                    onRemove={() => {
                      formik.setFieldValue('communityContactHeadshotId', null)
                      formik.setFieldValue('communityContactHeadshotName', null)
                    }}
                    onChange={({ id, name }) => {
                      formik.setFieldValue('communityContactHeadshotId', id)
                      formik.setFieldValue('communityContactHeadshotName', name)
                    }}
                  />
                  {formik.submitCount > 0 &&
                    !formik.values?.communityContactHeadshotId && (
                      <Styled.FieldError>
                        This field is missing a value
                      </Styled.FieldError>
                    )}
                </Styled.Col>
              </Flex>
            </Flex>
          </Styled.Section>
        </Styled.Form>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationContacts
