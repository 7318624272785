import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import orderBy from 'lodash/orderBy'
import isNil from 'lodash/isNil'
import * as Styled from '../widget'
import { getQuorters } from '../../utils/math'
import { marketsWithSearchCountGet } from '../../actions/market-summaries'
import Spinner from '../../components/spinner'
import { mdUp, mdDown } from '../../styles/breakpoints'
import { Link } from 'react-router-dom'
import { marketSummaries } from '../../settings/routes'

const WidgetMarketInsights = () => {
  const dispatch = useDispatch()
  const quorters = getQuorters()
  const quarter = quorters.reverse()[0]
  const {
    withSearchCount: {
      byQuarter,
      status: { loading }
    }
  } = useSelector(state => state.summaries)
  const markets = useMemo(() => {
    const quorterMarkets = byQuarter[quarter] || []

    return orderBy(quorterMarkets, 'market_name', 'asc')
  }, [quarter, byQuarter])

  useEffect(() => {
    if (quarter) {
      dispatch(marketsWithSearchCountGet(quarter))
    }
  }, [dispatch, quarter])

  return (
    <Styled.Wrap>
      <Styled.Box data-hidden>
        <Styled.InsideTitle>Market Insights, {quarter.split(' ').reverse().join(' ')}</Styled.InsideTitle>
        {markets.length > 0 && (
          <List>
            <Item data-sticky>
              <Col data-name></Col>
              <Col data-header data-w-fixed>
                <span>Demand Change</span>
                <span>(Views in Search)</span>
              </Col>
              <Col data-header>
                <span>Avg. Lead Size</span>
              </Col>
              <Col data-header>
                <span>Verified Locations</span>
              </Col>
              <Col data-header>
                <span>Total Locations</span>
              </Col>
            </Item>
            {markets.map(item => (
              <Item key={item.market_id}>
                <Col data-name>
                  <Link
                    to={`/${marketSummaries.main}/${marketSummaries.summary}/${item.market_id}`}
                  >
                    {item.market_name}
                  </Link>
                </Col>
                <Col data-center data-w-160>
                  <Indicator data-positive={+item.demand_trend > 0}>
                    {!isNil(item.demand_trend) ? `${item.demand_trend}%` : ''}
                  </Indicator>
                </Col>
                <Col data-center>
                  <span>
                    {!isNil(item.average_lead_size)
                      ? item.average_lead_size
                      : ''}
                  </span>
                </Col>
                <Col data-center>
                  <span>
                    {!isNil(item.locations_verified)
                      ? item.locations_verified
                      : ''}
                  </span>
                </Col>
                <Col data-center>
                  <span>
                    {!isNil(item.locations_total) ? item.locations_total : ''}
                  </span>
                </Col>
              </Item>
            ))}
          </List>
        )}
        {loading && (
          <Loading>
            <Spinner elSize={40} />
          </Loading>
        )}
      </Styled.Box>
    </Styled.Wrap>
  )
}

const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
`

const List = styled.ul`
  padding-left: 0;
  font-size: 14px;
  color: black;
  margin: 0;
  height: 260px;
  overflow: auto;

  @media ${mdUp} {
    font-size: 13px;
    border-left: none;
    border-right: none;
  }
`

const Item = styled.li`
  display: flex;
  justify-content: space-around;
  padding: 3px 5px;
  margin: 0;
  overflow: hidden;

  &[data-sticky='true'] {
    background-color: #fff;
    position: sticky;
    top: 0;
  }

  @media ${mdDown} {
    padding: 5px;
    align-items: center;
    width: 1023px;
  }
`

const Col = styled.div`
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

  &[data-name='true'] {
    min-width: 155px;

    a {
      white-space: nowrap;
      text-decoration: underline;
    }
  }

  &[data-center='true'] {
    span {
      padding-left: calc(50% - 5px);
    }
  }

  &[data-header='true'] {
    text-align: center;

    span {
      display: block;
      color: #e7483d;
      font-weight: 600;
    }
  }

  &[data-w-fixed='true'] {
    min-width: 115px;
  }
`

const Indicator = styled.span`
  color: #e7483d;

  &[data-positive='true'] {
    color: #57ab83;
  }
`

export default WidgetMarketInsights
