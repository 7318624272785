import fetch from 'isomorphic-fetch'
import { stringify } from 'qs'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getAllLocations = (token, market, search, page, pagesize) => {
  const query = stringify({
    market: market,
    address: search || undefined,
    minradius: search ? 5000 : undefined,
    page,
    pagesize
  })

  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/alllocations${query ? `?${query}` : ''}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const mapSummaryGet = (token) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/mapsummary`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const getMarketLocationsData = (token, marketId) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/marketlocationhistoricaldata?closed=false&marketid=${marketId}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}
