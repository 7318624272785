import React from 'react'
import { Body } from '../../components/modal'
import Flex from '../../components/flex'
import listingCardImage from '../../assets/img/image-example-min.jpg'
import interiorImage1 from '../../assets/img/interiorImage1-min.jpg'
import interiorImage2 from '../../assets/img/interiorImage2-min.jpg'
import interiorImage3 from '../../assets/img/interiorImage3-min.jpg'
import image6 from '../../assets/img/image6-min.jpg'
import image7 from '../../assets/img/image7-min.jpg'
import image8 from '../../assets/img/image8-min.jpg'
import image9 from '../../assets/img/image9-min.jpg'

const byField = {
  listingCardImage: {
    text:
      'Listing card image should show people working in a space that highlights a suite or common area.',
    img: listingCardImage
  },
  interiorImage1: {
    text:
      'This interior picture should showcase the amenities or community rooms.',
    img: interiorImage1
  },
  interiorImage2: {
    text:
      'This interior picture should highlight a conference room or meeting space.',
    img: interiorImage2
  },
  interiorImage3: {
    text:
      'This image should highlight a different meeting space or conference room, or a well lit picture of a community area.',
    img: interiorImage3
  },
  image6: {
    text:
      'This image should showcase an office or suite. Ensure that it has good lighting and/or members in the unit. ',
    img: image6
  },
  image7: {
    text:
      'This interior picture should be another photo of an office or suite.',
    img: image7
  },
  image8: {
    text:
      'This image should show off the kitchen area and available services it provides.',
    img: image8
  },
  image9: {
    text:
      'This image should showcase any type of membership option such desks or a private office.',
    img: image9
  }
}

const ImageInfo = ({ fieldName }) => {
  const field = byField[fieldName]

  return (
    <Body small>
      <div style={{ paddingTop: 15 }}>
        <p>{field?.text || ''}</p>
      </div>
      {field?.img && (
        <div>
          <img
            style={{ maxWidth: 'auto', width: '100%', height: 'auto' }}
            src={field.img}
            alt={field?.text || ''}
          />
        </div>
      )}
      <Flex fullWidth flexEnd center></Flex>
    </Body>
  )
}

export default ImageInfo
