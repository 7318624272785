import React, { useState, useEffect, useMemo } from 'react'
import isNil from 'lodash/isNil'
import orderBy from 'lodash/orderBy'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../components/spinner'
import Select from 'react-select'
import { Link } from 'react-router-dom';
import Flex from '../../components/flex'
import styled from 'styled-components/macro'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import { marketReports } from '../../settings/routes'
import Sidebar from '../../containers/SidebarSummaries'
import { useMediaQuery } from 'react-responsive'
import { smOnly } from '../../styles/breakpoints'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import { marketsWithSearchCountGet } from '../../actions/market-summaries'
import * as Styled from '../../components/summary-table'
import { getQuorters } from '../../utils/math'

const Trends = props => {
  const { marketId } = props.match.params
  const flexOfficePro = useSelector(state => state.user.flexOfficePro)
  const isNorthAmerica = useSelector(state => state.brokerTools.isNorthAmerica)
  const mobile = useMediaQuery({ query: smOnly })
  const dispatch = useDispatch()
  const quorters = getQuorters()
  const [quarter, setQuarter] = useState(quorters.reverse()[0])
  const [order, setOrder] = useState('market_name')
  const [orderDesc, setOrderDesc] = useState(false)
  const {
    withSearchCount: {
      byQuarter,
      status: { loading }
    }
  } = useSelector(state => state.summaries)
  const markets = useMemo(() => {
    const quorterMarkets = byQuarter[quarter] || []

    return orderBy(quorterMarkets, (market) => {
      return isNaN(parseFloat(market[order])) ? market[order] : parseFloat(market[order])
    }, orderDesc && 'desc')
  }, [quarter, byQuarter, order, orderDesc])

  useEffect(() => {
    if (quarter) {
      dispatch(marketsWithSearchCountGet(quarter))
    }
  }, [dispatch, quarter])

  const sortBy = by => {
    const isDesc = by === order && !orderDesc ? !orderDesc : false
    setOrder(by)
    setOrderDesc(isDesc)
  }

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {flexOfficePro === 'Advisor' && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          {isNorthAmerica ? (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>North American Summaries</Item>
            </>
          ) : (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Market Summaries</Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            <SelectWrap>
              <Select
                placeholder="_"
                styles={{
                  container: provided => ({
                    ...provided,
                    width: 120
                  })
                }}
                options={quorters.map(item => ({
                  value: item,
                  label: item
                }))}
                isSearchable={false}
                onChange={value => [setQuarter(value.label)]}
                value={{
                  value: quarter,
                  label: quarter
                }}
              />
            </SelectWrap>
            <Styled.Wrapper>
              <Styled.List>
                <Styled.Item sticky>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'market_name'}
                      desc={orderDesc}
                      onClick={() => sortBy('market_name')}
                    >
                      <span>Market</span>
                      <span>Name</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'total_seats'}
                      desc={orderDesc}
                      onClick={() => sortBy('total_seats')}
                    >
                      <span>Total</span>
                      <span>Seats</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'locations_verified'}
                      desc={orderDesc}
                      onClick={() => sortBy('locations_verified')}
                    >
                      <span>Verified</span>
                      <span>Locations</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'locations_total'}
                      desc={orderDesc}
                      onClick={() => sortBy('locations_total')}
                    >
                      <span>Total</span>
                      <span>Locations</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'vacant_seats'}
                      desc={orderDesc}
                      onClick={() => sortBy('vacant_seats')}
                    >
                      <span>Vacant</span>
                      <span>Seats</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'average_asking_price'}
                      desc={orderDesc}
                      onClick={() => sortBy('average_asking_price')}
                    >
                      <span>Average</span>
                      <span>Asking Price</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'average_asking_price_trend'}
                      desc={orderDesc}
                      onClick={() => sortBy('average_asking_price_trend')}
                    >
                      <span>Average</span>
                      <span>Asking Price</span>
                      <span>Trend</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'demand_trend'}
                      desc={orderDesc}
                      onClick={() => sortBy('demand_trend')}
                    >
                      <span>Demand</span>
                      <span>Trends</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'locations_new'}
                      desc={orderDesc}
                      onClick={() => sortBy('locations_new')}
                    >
                      <span>New</span>
                      <span>Locations</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'locations_closed'}
                      desc={orderDesc}
                      onClick={() => sortBy('locations_closed')}
                    >
                      <span>Closed</span>
                      <span>Locations</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                  <Styled.Col>
                    <Styled.FilterArrow
                      active={order === 'average_lead_size'}
                      desc={orderDesc}
                      onClick={() => sortBy('average_lead_size')}
                    >
                      <span>Average</span>
                      <span>Lead Size</span>
                    </Styled.FilterArrow>
                  </Styled.Col>
                </Styled.Item>
                {!loading &&
                  markets
                    .filter(
                      item =>
                        !isNorthAmerica ||
                        (item.country === 'US' && isNorthAmerica)
                    )
                    .map(item => (
                      <Styled.Item key={item.market_id}>
                        <Styled.Col>
                          {item.market_name && (
                            <Link
                              to={`/${marketReports.main}/${marketReports.inventory}/${item.market_id}`}
                            >
                              <span>{item.market_name || ''}</span>
                            </Link>
                          )}
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.total_seats) ? item.total_seats : ''}
                          </span>
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.locations_verified)
                              ? item.locations_verified
                              : ''}
                          </span>
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.locations_total)
                              ? item.locations_total
                              : ''}
                          </span>
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.vacant_seats) ? item.vacant_seats : ''}
                          </span>
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.average_asking_price)
                              ? `$${item.average_asking_price}`
                              : ''}
                          </span>
                        </Styled.Col>
                        <Styled.Col>
                          <Indicator
                            data-positive={+item.average_asking_price_trend > 0}
                          >
                            {!isNil(item.average_asking_price_trend)
                              ? `${item.average_asking_price_trend}%`
                              : ''}
                          </Indicator>
                        </Styled.Col>
                        <Styled.Col>
                          <Indicator data-positive={+item.demand_trend > 0}>
                            {!isNil(item.demand_trend)
                              ? `${item.demand_trend}%`
                              : ''}
                          </Indicator>
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.locations_new)
                              ? item.locations_new
                              : ''}
                          </span>
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.locations_closed)
                              ? item.locations_closed
                              : ''}
                          </span>
                        </Styled.Col>
                        <Styled.Col>
                          <span>
                            {!isNil(item.average_lead_size)
                              ? item.average_lead_size
                              : ''}
                          </span>
                        </Styled.Col>
                      </Styled.Item>
                    ))}
              </Styled.List>
              <Loading>{loading && <Spinner elSize={40} />}</Loading>
            </Styled.Wrapper>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const SelectWrap = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
`

const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
`

const Indicator = styled.span`
  color: #e7483d;

  &[data-positive="true"] {
    color: #57AB83;
  }
`

export default Trends
