import React, { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Select from 'react-select'
import { useSelector, useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import isEmpty from 'lodash/isEmpty'
import Tooltip from '../../components/tooltip'
import { QuestionIcon } from '../../components/icons'

import { locationsGet } from '../../actions/locations'
import { profilePicGet } from '../../actions/user'
import { marketsGet } from '../../actions/markets'
import {
  Wrapper,
  Left,
  Center,
  Right,
  Status,
  SelectWrapper
} from '../../components/header'
import { UserAv } from '../../components/user-personalization'
import { DropDown, DropdownItem } from '../../components/dropdown'
import { sortByName } from '../../helpers/sort'
import {
  getAvailableLocations,
  getActiveMarket
} from '../../selectors/submarkets'
import {
  DASHBOARD_ROUTE,
  LOGOUT_ROUTE,
  PROFILE_ROUTE,
  marketReports
} from '../../settings/routes'
import { setNorthAmerica } from '../../actions/broker-tools'

const Header = ({ pagePath: PAGE_ROUTE, hideSelectors }) => {
  const { pathname } = useLocation()
  const match = matchPath(pathname, {
    path: [
      `${PAGE_ROUTE}/:marketId?/:locationId?`,
      `${PAGE_ROUTE}/:marketId/:locationId/:spaceType/:measure/:unit`
    ],
    exact: true
  })

  const withAllOptions = PAGE_ROUTE === '/leads'
  const { locationId, marketId } = match.params

  const dispatch = useDispatch()
  const { token } = useSelector(state => state.auth)
  const user = useSelector(state => state.user)
  const {
    list: locations,
    byId: locationsById,
    byMarketId,
    status: { loading: locationsLoading, completed: locationsComplete }
  } = useSelector(state => state.locations)
  const {
    list: markets,
    byId: marketsById,
    status: { loading: marketsLoading, completed: marketsComplete }
  } = useSelector(state => state.markets)
  const availableLocations = getAvailableLocations(
    markets,
    locations,
    marketsById,
    byMarketId,
    marketId
  )
  const location = locationsById[locationId]
  let activeMarket = getActiveMarket(marketsById, marketId)

  let marketOptions = sortByName(
    (markets || []).filter(market => !market.isSubmarket)
  ).map(market => ({
    value: market.id,
    label: market.name
  }))
  const isNorthAmerica = useSelector(state => state.brokerTools.isNorthAmerica)

  if (withAllOptions) {
    // TODO: Add conditions if more then one market
    marketOptions = [
      {
        label: 'All Markets',
        value: 'all'
      },
      ...marketOptions
    ]

    if (marketId === 'all') {
      activeMarket = {
        value: 'all',
        label: 'All Markets'
      }
    }
  }

  let locationOptions = sortByName(availableLocations).map(location => ({
    value: location.id,
    label: location.name
  }))

  let activeLocation = {}

  if (withAllOptions && availableLocations.length > 1) {
    locationOptions = [
      {
        label: 'All Locations',
        value: 'all'
      },
      ...locationOptions
    ]
  }

  if (withAllOptions && locationId === 'all') {
    activeLocation = {
      value: 'all',
      label: 'All Locations'
    }
  } else {
    activeLocation = locationsById[locationId] && {
      value: locationsById[locationId].id,
      label: locationsById[locationId].name
    }
  }

  const isReportsAccess = ['Investor', 'Advisor'].includes(user.flexOfficePro)
  const isFreeAccess = user.flexOfficePro === 'Free'
  const hideMarketsReports =
    (PAGE_ROUTE === '/dashboard' || (PAGE_ROUTE || []).includes('summaries')) &&
    isReportsAccess

  useEffect(() => {
    dispatch(profilePicGet(token))
    dispatch(marketsGet(token, isReportsAccess))
    dispatch(locationsGet(token))
  }, [dispatch, token, isReportsAccess])

  useEffect(() => {
    const firstMarketId = (marketOptions || [])[0]?.value
    const firstLocationId = (locationOptions || [])[0]?.value

    if (PAGE_ROUTE === '/dashboard') {
      if (!marketId && firstMarketId) {
        dispatch(push(`/${DASHBOARD_ROUTE}/${marketOptions[0].value}`))
      }

      if (!locationId && firstLocationId && firstMarketId) {
        dispatch(
          push(
            `/${DASHBOARD_ROUTE}/${marketOptions[0].value}/${locationOptions[0].value}`
          )
        )
      }
    }
  }, [
    dispatch,
    marketOptions,
    locationOptions,
    locationId,
    marketId,
    PAGE_ROUTE
  ])

  const marketChangeHandler = ({ value }) => {
    const locationsByMarket = getAvailableLocations(
      markets,
      locations,
      marketsById,
      byMarketId,
      value
    )
    const defaultMarketLocId = ((sortByName(locationsByMarket) || [])[0] || {})
      .id
    if (withAllOptions) {
      if (
        value !== 'all' &&
        !isEmpty(byMarketId[value]) &&
        byMarketId[value].length > 1
      ) {
        dispatch(
          push(
            `${PAGE_ROUTE}/${value}/${(locationsByMarket[0] || {}).id || 'all'}`
          )
        )
      } else if (value === 'all' && locations.length > 1) {
        dispatch(push(`${PAGE_ROUTE}/${value}/all`))
      } else {
        dispatch(push(`${PAGE_ROUTE}/${value}/${defaultMarketLocId}`))
      }
    } else {
      dispatch(push(`${PAGE_ROUTE}/${value}/${defaultMarketLocId}`))
    }
  }

  const locationChangeHandler = ({ value }) => {
    if (marketId === 'all' && value !== 'all') {
      dispatch(push(`${PAGE_ROUTE}/${locationsById[value].marketId}/${value}`))
    } else {
      dispatch(push(`${PAGE_ROUTE}/${marketId}/${value}`))
    }
  }

  return (
    <Wrapper>
      <Left
        text={`Flex Office Pro ${user.flexOfficePro}`}
        subscription={user.flexOfficePro}
      />
      <Center>
        {!hideSelectors && (
          <>
            {!isEmpty(markets) && marketsComplete && !hideMarketsReports && (
              <SelectWrapper>
                <Select
                  options={marketOptions}
                  value={activeMarket}
                  isLoading={marketsLoading}
                  placeholder={
                    marketsLoading ? 'Getting your markets' : 'Select a market'
                  }
                  onChange={marketChangeHandler}
                />
              </SelectWrapper>
            )}
            {!isEmpty(locations) && locationsComplete && !isReportsAccess && (
              <SelectWrapper>
                <Select
                  options={locationOptions}
                  value={activeLocation}
                  isLoading={locationsLoading}
                  placeholder={
                    locationsLoading
                      ? 'Getting your locations'
                      : 'Select a location'
                  }
                  onChange={locationChangeHandler}
                />
              </SelectWrapper>
            )}
            {!isEmpty(markets) &&
              marketsComplete &&
              user.flexOfficePro === 'Advisor' &&
              (PAGE_ROUTE || []).includes('summaries') && (
                <SelectWrapper>
                  <Select
                    options={[
                      { label: 'North America', value: 'us' },
                      ...marketOptions
                    ]}
                    value={
                      isNorthAmerica
                        ? { label: 'North America', value: 'us' }
                        : activeMarket
                    }
                    isLoading={marketsLoading}
                    placeholder={
                      marketsLoading
                        ? 'Getting your markets'
                        : 'Select a market'
                    }
                    onChange={({ value }) => {
                      if (value === 'us') {
                        dispatch(setNorthAmerica(true))
                      } else {
                        dispatch(setNorthAmerica(false))
                        dispatch(
                          push(
                            `/${marketReports.main}/${marketReports.inventory}/${value}`
                          )
                        )
                      }
                    }}
                  />
                </SelectWrapper>
              )}
          </>
        )}
      </Center>
      <Right>
        {location?.listingStatus && !isReportsAccess && !isFreeAccess && (
          <Status data-status={location.listingStatus}>
            <span>Listing Status</span>
            <span>
              {location.listingStatus}
              {location.listingStatus === 'Suspended' && (
                <Tooltip
                  overlay={
                    <InfoText>
                      <span>Account Suspended</span>
                      <span>
                        To reactivate your account please go to Update Spaces
                        and confirm or update your inventory and pricing
                      </span>
                    </InfoText>
                  }
                >
                  <InfoBtn>
                    <InfoIcon />
                  </InfoBtn>
                </Tooltip>
              )}
            </span>
          </Status>
        )}
        <DropDown button={() => <UserAv user={user} />}>
          <DropdownItem>
            <Link to={`/${PROFILE_ROUTE}`}>Profile</Link>
          </DropdownItem>
          <DropdownItem>
            <Link to={`/${LOGOUT_ROUTE}`}>Logout</Link>
          </DropdownItem>
        </DropDown>
      </Right>
    </Wrapper>
  )
}

const InfoText = styled.div`
  span {
    display: block;
  }

  > span:first-child {
    text-align: center;
    font-weight: 600;
    margin-bottom: 5px;
  }
`

const InfoBtn = styled.span`
  width: 2em;
  height: 2em;
  background: red;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  padding: 0.3em;
  display: flex;
  color: white;
  font-size: 9px;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-top: -0.2em;
  display: inline-block;
`

const InfoIcon = styled(QuestionIcon)`
  width: auto;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`

export default Header
