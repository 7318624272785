import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Body } from '../../components/modal'
import { SPACES_ROUTE } from '../../settings/routes'

const Suspended = ({ marketId, locationId }) => {
  return (
    <MessageBody>
      <p>Your pricing is out of date. To view your leads please go to your <Link to={`/${SPACES_ROUTE}/${marketId}/${locationId}`}>space inventory</Link> and confirm/update your pricing.</p>
    </MessageBody>
  )
}

const MessageBody = styled(Body)`
  max-width: 500px;
  width: 100%;
  background-color: white;
  padding: 20px;
  display: inline-block;
  font-size: 18px;
  line-height: 1.3;

  a {
    color: #e7483d;
    text-decoration: underline;
  }
`

export default Suspended
