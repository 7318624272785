import React, { lazy, Suspense, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { uniq } from 'lodash'

import {
  hideLeadShortlist,
  removeFromShortlist
} from '../../actions/broker-tools'
import { locationsGetByMarketId } from '../../actions/locations'

import { Close } from '../../components/icons'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import {
  parseShortlistUrl,
  getSpaceName,
  getShortlistUrl
} from '../../utils/broker-tools'
import { leadUpdate, showLeadProposals } from '../../actions/broker-tools'
import {
  bunchAddToShortlist,
  // showLeadShortlist,
  addToShortlist
} from '../../actions/broker-tools'
import { leadLocationsGet } from '../../actions/location-services'
import { availableSpacesGet } from '../../actions/spaces'
import { openLeadShortlistModal } from '../../actions/modal'

const Map = lazy(() => import('../../containers/WorkLeadMapShortlist'))

const ShortList = () => {
  const dispatch = useDispatch()
  const lead = useSelector(state => state.brokerTools.leads.shortlist.lead)
  const locations = useSelector(
    state => state.brokerTools.locations.byMarketId[lead.market_id]
  )
  const loading = useSelector(
    state => state.brokerTools.locations.status.loading
  )
  const list = useSelector(state => state.brokerTools.leads.shortlist.list)
  const leadLocations = useSelector(
    state => state.locationServices.leadLocationsById[lead.id]
  )
  const [notificationType, setNotificationType] = useState()

  useEffect(() => {
    if (lead.market_id) {
      dispatch(locationsGetByMarketId(lead.market_id))
    }
  }, [dispatch, lead.market_id])

  useEffect(() => {
    if (lead.id) {
      dispatch(leadLocationsGet(lead.id))
    }
  }, [dispatch, lead.id])

  useEffect(() => {
    if (lead.notification_type) {
      setNotificationType(lead.notification_type)
    }
  }, [lead.notification_type])

  const shortlistIds = useMemo(
    () => uniq(parseShortlistUrl(lead.shortlist_url)),
    [lead.shortlist_url]
  )

  useEffect(() => {
    if (locations?.length && shortlistIds.length) {
      const shortlistLocations = shortlistIds.map(id => {
        const location = locations.find(item => id === item.id)
        const leadLocation = [
          ...(leadLocations?.lead_locations || []),
          ...(leadLocations?.sales_locations || [])
        ].find(item => id === item.id)

        return {
          id,
          name: (location || leadLocation)?.name
        }
      })

      shortlistLocations.forEach(item => dispatch(availableSpacesGet(item.id)))

      dispatch(
        bunchAddToShortlist(
          shortlistLocations.map(item => ({ id: item.id, name: item.name }))
        )
      )
    }
  }, [dispatch, shortlistIds, locations, leadLocations])

  useEffect(() => {
    if (leadLocations) {
      [
        ...(leadLocations?.lead_locations || []),
        ...(leadLocations?.sales_locations || [])
      ].forEach(item => dispatch(availableSpacesGet(item.id)))
    }
  }, [dispatch, leadLocations])

  return (
    <Wrap>
      <Header>
        <CloseBtn onClick={() => dispatch(hideLeadShortlist())}>
          <CloseIcon />
        </CloseBtn>
        <Title>Shortlist</Title>
      </Header>
      <Suspense
        fallback={
          <Flex fullWidth fullHeight center>
            <Spinner elSize={40} />
          </Flex>
        }
      >
        <MapWrap>
          {loading ? (
            <Flex fullWidth fullHeight center>
              <Spinner elSize={40} />
            </Flex>
          ) : (
            <Map
              shortlistLocations={list.map(item => item.id)}
              locationConverted={lead?.location_first_converted_on_id}
              onGoogleApiLoaded={() => {}}
              locations={locations}
            />
          )}
        </MapWrap>
      </Suspense>
      {!loading && (
        <>
          <BottomWrap>
            <ToggleBtnWrap>
              <ToggleBtn data-active onClick={() => {}}>
                Broker Shortlist
              </ToggleBtn>
              <ToggleBtn onClick={() => dispatch(showLeadProposals(lead))}>
                Proposed Shortlist
              </ToggleBtn>
              <SelectNative
                defaultValue={notificationType}
                onChange={e => {
                  const value = e.target.value
                  setNotificationType(value || 'None')
                }}
                data-type={true}
              >
                <option>Notification Type</option>
                <option value="None">None</option>
                <option value="UpsuiteManaged">Upsuite Managed</option>
                <option value="OperatorManaged">Operator Managed</option>
                <option value="LeadRegistered">Lead Registered</option>
                <option value="OperatorManagedPass">
                  Operator Managed Pass
                </option>
              </SelectNative>
            </ToggleBtnWrap>
            {(list || leadLocations?.lead_locations.length > 0 ||
              leadLocations?.sales_locations.length > 0) && (
              <LocWrap>
                {uniq([
                  ...(leadLocations?.lead_locations || []),
                  ...(leadLocations?.sales_locations || [])
                ], 'id').filter(item => !list?.find(listItem => listItem.id === item.id)).map((item, index) => (
                  <LocRow key={`${item.id}-${index}`}>
                    <LocCol>
                      <LocTitle>{item.name}</LocTitle>
                    </LocCol>
                    <LocCol>
                      <ChooseSpace id={item.id} />
                      <LocBtn
                        onClick={() => dispatch(addToShortlist({ id: item.id, name: item.name }))}
                      >
                        <span>+</span>
                      </LocBtn>
                    </LocCol>
                  </LocRow>
                ))}
                {uniq(list, 'id').map(item => (
                  <LocRow key={item.id}>
                    <LocCol>
                      <LocTitle>{item.name}</LocTitle>
                    </LocCol>
                    <LocCol>
                      <ChooseSpace id={item.id} />
                      <LocBtn
                        onClick={() => dispatch(removeFromShortlist(item.id))}
                      >
                        <span>-</span>
                      </LocBtn>
                    </LocCol>
                  </LocRow>
                ))}
              </LocWrap>
            )}
            <ActionsWrap>
              <MainBtn
                onClick={() => {
                  const updatedLead = {
                    ...lead,
                    notification_type: notificationType,
                    shortlist_url: getShortlistUrl(list)
                  }

                  dispatch(leadUpdate(updatedLead)).then(leadUpdated => {
                    if (leadUpdated) {
                      dispatch(openLeadShortlistModal({
                        leadName: `${lead.first_name || ''} ${lead.last_name || ''}`
                      }))
                    }
                  })
                  // dispatch(showLeadShortlist(updatedLead))
                }}
              >
                Create Shortlist and Register Lead
              </MainBtn>
            </ActionsWrap>
          </BottomWrap>
        </>
      )}
    </Wrap>
  )
}

const ChooseSpace = ({ id }) => {
  const spaces = useSelector(state => state.spaces.available.byLocationId[id])

  if (!spaces?.length) return null

  return (
    <SelectNative placeholder="Choose Space">
      <option>Choose Space</option>
      {spaces &&
        spaces.map(item => (
          <option key={item.id} value={item.id}>{`${getSpaceName(item)} - $${
            item.spacePriceConverted
          }`}</option>
        ))}
    </SelectNative>
  )
}

const BottomWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 537px);
`

const LocWrap = styled.div`
  height: 100%;
  overflow: auto;
  margin-bottom: 10px;
`

const LocRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`

const LocCol = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 5px;
`

const LocTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
`

const Wrap = styled.div`
  position: absolute;
  width: 800px;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  right: 0;
  z-index: 11;
  background-color: white;
  border-left: 1px solid #828282;
`

const Header = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  overflow: hidden;
  flex-shrink: 0;
`

const Title = styled.h2`
  margin-top: 0;
  font-size: 28px;
  color: #e7483d;
  margin-bottom: 0;
`

const CloseBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  box-shadow: none;
  border: none;
  color: #828282;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  width: 20px;
`

const MapWrap = styled.div`
  height: 100%;
  max-height: 400px;
  width: 100%;
  position: relative;
`

const MainBtn = styled.button.attrs({ type: 'button' })`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
  fill: white;
`

const LocBtn = styled.button.attrs({ type: 'button' })`
  border: none;
  cursor: pointer;
  text-align: center;
  background-color: #e7483d;
  box-shadow: none;
  font-weight: 400;
  font-size: 36px;
  color: white;
  border-radius: 0;
  margin-left: 15px;
  width: 25px;
  height: 25px;
  overflow: hidden;
  padding: 0;

  > span {
    line-height: 0;
    display: block;
    margin-bottom: 4px;
  }
`

const SelectNative = styled.select`
  border-color: #e7483d;

  &[data-type='true'] {
    font-weight: 600;
    border-width: 2px;
    border-left: none;
    padding-left: 10px;
  }
`

const ActionsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ToggleBtnWrap = styled.div`
  margin-bottom: 15px;
  display: flex;
`

const ToggleBtn = styled.button.attrs({ type: 'button' })`
  border: none;
  cursor: pointer;
  text-align: center;
  box-shadow: none;
  border: 2px solid #e7483d;
  font-weight: 600;
  padding: 10px 20px;
  font-weight: 600;
  flex-shrink: 0;

  &[data-active='true'] {
    background-color: #e7483d;
    color: #fff;
  }

  &:not([data-active='true']) {
    background-color: #fff;
    color: black;
  }
`

export default ShortList
