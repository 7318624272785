import { createReducer } from '../utils/redux'
import {
  MONTHLY_LEADS_TOTAL_REQUEST,
  MONTHLY_LEADS_TOTAL_SUCCESS,
  MONTHLY_LEADS_TOTAL_FAIL,
  LATEST_LEADS_REQUEST,
  LATEST_LEADS_SUCCESS,
  LATEST_LEADS_FAIL,
  SET_NORTH_AMERICA,
  WORK_LEADS_REQUEST,
  WORK_LEADS_SUCCESS,
  WORK_LEADS_FAIL,
  EDIT_LEAD_SHOW,
  EDIT_LEAD_HIDE,
  ADD_LEAD_SHOW,
  ADD_LEAD_HIDE,
  SHOW_LEAD_SHORTLIST,
  HIDE_LEAD_SHORTLIST,
  SHOW_LEAD_PROPOSALS,
  HIDE_LEAD_PROPOSALS,
  LOCATIONS_BY_MARKET_ID_GET_REQUEST,
  LOCATIONS_BY_MARKET_ID_GET_SUCCESS,
  LOCATIONS_BY_MARKET_ID_GET_FAILURE,
  LEAD_UPDATE_SUCCESS,
  LEAD_ADD_SUCCESS,
  ADD_TO_SHORTLIST,
  BUNCH_ADD_TO_SHORTLIST,
  REMOVE_FROM_SHORTLIST,
  LOCATION_BY_IDS_GET_REQUEST,
  LOCATION_BY_IDS_GET_SUCCESS,
  LOCATION_BY_IDS_GET_FAIL
} from '../constants'

const initialState = {
  monthlyLeadsCount: {
    payload: null,
    loading: false,
    error: false
  },
  latestLeads: {
    payload: null,
    loading: false,
    error: false
  },
  isNorthAmerica: false,
  leads: {
    list: [],
    total: undefined,
    status: {
      loading: false,
      creating: false,
      updating: false,
      deleting: false
    },
    error: {
      loading: false,
      creating: false,
      updating: false,
      deleting: false
    },
    edit: {
      active: false,
      lead: {}
    },
    add: {
      active: false
    },
    shortlist: {
      active: false,
      lead: {},
      list: []
    },
    proposal: {
      active: false,
      lead: {}
    }
  },
  locations: {
    byMarketId: {},
    byLeadId: {},
    byLeadIdLoading: {},
    byLeadIdError: {},
    status: {
      loading: false,
      error: false
    }
  }
}

export default createReducer(initialState, {
  [MONTHLY_LEADS_TOTAL_REQUEST]: state => ({
    ...state,
    monthlyLeadsCount: {
      ...state.monthlyLeadsCount,
      loading: true,
      error: false
    }
  }),
  [MONTHLY_LEADS_TOTAL_SUCCESS]: (state, payload) => ({
    ...state,
    monthlyLeadsCount: {
      ...state.monthlyLeadsCount,
      payload,
      loading: false,
      error: false
    }
  }),
  [MONTHLY_LEADS_TOTAL_FAIL]: state => ({
    ...state,
    monthlyLeadsCount: {
      ...state.monthlyLeadsCount,
      loading: false,
      error: true
    }
  }),
  [LATEST_LEADS_REQUEST]: state => ({
    ...state,
    latestLeads: {
      ...state.latestLeads,
      loading: true,
      error: false
    }
  }),
  [LATEST_LEADS_SUCCESS]: (state, payload) => ({
    ...state,
    latestLeads: {
      ...state.latestLeads,
      payload,
      loading: false,
      error: false
    }
  }),
  [LATEST_LEADS_FAIL]: state => ({
    ...state,
    latestLeads: {
      ...state.latestLeads,
      loading: false,
      error: true
    }
  }),
  [SET_NORTH_AMERICA]: (state, payload) => ({
    ...state,
    isNorthAmerica: payload
  }),
  [WORK_LEADS_REQUEST]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      list: payload.page === 1 ? [] : state.leads.list,
      total: payload.page === 1 ? undefined : state.leads.total,
      status: {
        ...initialState.leads.status,
        loading: true
      },
      error: {
        ...initialState.leads.error,
        loading: false
      }
    }
  }),
  [WORK_LEADS_SUCCESS]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      list: payload?.leads,
      total: payload?.total,
      status: {
        ...initialState.leads.status,
        loading: false
      },
      error: {
        ...initialState.leads.error,
        loading: false
      }
    }
  }),
  [WORK_LEADS_FAIL]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      status: {
        ...initialState.leads.status,
        loading: false
      },
      error: {
        ...initialState.leads.error,
        loading: payload || true
      }
    }
  }),
  [EDIT_LEAD_SHOW]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      edit: {
        active: true,
        lead: payload
      }
    }
  }),
  [EDIT_LEAD_HIDE]: state => ({
    ...state,
    leads: {
      ...state.leads,
      add: {
        active: false
      },
      edit: {
        active: false,
        lead: {}
      }
    }
  }),
  [ADD_LEAD_SHOW]: state => ({
    ...state,
    leads: {
      ...state.leads,
      add: {
        active: true
      },
      edit: {
        active: false,
        lead: {}
      }
    }
  }),
  [ADD_LEAD_HIDE]: state => ({
    ...state,
    leads: {
      ...state.leads,
      add: {
        active: false
      }
    }
  }),
  [SHOW_LEAD_SHORTLIST]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      shortlist: {
        active: true,
        lead: payload,
        list: []
      },
      proposal: {
        active: false,
        lead: {}
      }
    }
  }),
  [HIDE_LEAD_SHORTLIST]: state => ({
    ...state,
    leads: {
      ...state.leads,
      shortlist: {
        active: false,
        lead: {},
        list: []
      }
    }
  }),
  [SHOW_LEAD_PROPOSALS]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      proposal: {
        active: true,
        lead: payload
      },
      shortlist: {
        active: false,
        lead: {}
      }
    }
  }),
  [HIDE_LEAD_PROPOSALS]: state => ({
    ...state,
    leads: {
      ...state.leads,
      proposal: {
        active: false,
        lead: {}
      }
    }
  }),
  [LOCATIONS_BY_MARKET_ID_GET_REQUEST]: (state) => ({
    ...state,
    locations: {
      ...state.locations,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [LOCATIONS_BY_MARKET_ID_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    locations: {
      ...state.locations,
      byMarketId: {
        ...state.locations.byMarketId,
        [config]: payload.list
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [LOCATIONS_BY_MARKET_ID_GET_FAILURE]: (state) => ({
    ...state,
    locations: {
      ...state.locations,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [LEAD_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      list: state.leads.list.map(item => {
        if (item.id === payload.id) {
          return payload
        }

        return item
      }),
      edit: {
        ...state.leads.edit,
        lead: payload
      }
    }
  }),
  [LEAD_ADD_SUCCESS]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      list: [
        payload,
        ...state.leads.list
      ]
    },
    total: (state.leads.total || 0) + 1
  }),
  [ADD_TO_SHORTLIST]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      shortlist: {
        ...state.leads.shortlist,
        list: [
          ...state.leads.shortlist.list.filter(item => item.id !== payload.id),
          payload
        ]
      }
    }
  }),
  [BUNCH_ADD_TO_SHORTLIST]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      shortlist: {
        ...state.leads.shortlist,
        list: payload
      }
    }
  }),
  [REMOVE_FROM_SHORTLIST]: (state, payload) => ({
    ...state,
    leads: {
      ...state.leads,
      shortlist: {
        ...state.leads.shortlist,
        list: state.leads.shortlist.list.filter(item => item.id !== payload)
      }
    }
  }),
  [LOCATION_BY_IDS_GET_REQUEST]: (state, payload) => ({
    ...state,
    locations: {
      ...state.locations,
      byLeadIdLoading: {
        ...state.locations.byLeadIdLoading,
        [payload]: true
      },
      byLeadIdError: {
        ...state.locations.byLeadIdError,
        [payload]: false
      }
    }
  }),
  [LOCATION_BY_IDS_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    locations: {
      ...state.locations,
      byLeadId: {
        ...state.locations.byLeadId,
        [config]: payload.list
      },
      byLeadIdLoading: {
        ...state.locations.byLeadIdLoading,
        [config]: false
      }
    }
  }),
  [LOCATION_BY_IDS_GET_FAIL]: (state, payload) => ({
    ...state,
    locations: {
      ...state.locations,
      byLeadIdLoading: {
        ...state.locations.byLeadIdLoading,
        [payload]: false
      },
      byLeadIdError: {
        ...state.locations.byLeadIdError,
        [payload]: true
      }
    }
  })
})
