import uniq from 'lodash/uniq'
import trim from 'lodash/trim'
import { createSelector } from 'reselect'
import { mapByLocationId, mapVisible } from './mappers'

const byLocationId = (state, props) =>
  state.spaces.byLocationId[props.locationId]
const byId = state => state.spaces.byId
const getProps = (_, props) => props

const selectByLocationId = createSelector(byLocationId, byId, mapByLocationId)

export const selectTypes = createSelector(selectByLocationId, spaces => [
  'All',
  ...uniq(spaces.map(item => trim(item.spaceType)))
])

export const selectVisibleSpaces = createSelector(
  selectByLocationId,
  getProps,
  mapVisible
)

export const selectByIds = createSelector(getProps, byId, mapByLocationId)
