import React, { lazy, Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import SummeryText from './SummeryText'
import { useMediaQuery } from 'react-responsive'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import { smOnly } from '../../styles/breakpoints'
import { priceGet, priceGetPer } from '../../actions/market-reports'
import { getPriceChart } from './chart-settings'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import Sidebar from '../../containers/SidebarReports'
import * as Styled from '../../components/reports'
import Selectors from './suite-type-selectors'

const Chart = lazy(() => import('../../components/chart'))

const Price = props => {
  const { marketId } = props.match.params
  const { flexOfficePro } = useSelector(state => state.user)
  const isAdvisor = flexOfficePro === 'Advisor'
  const dispatch = useDispatch()
  const { byMarketId, status: priceStatus } = useSelector(
    state => state.reports.price
  )
  const { byMarketSpace, status: perSuiteStatus } = useSelector(
    state => state.reports.pricePerSpace
  )
  const marketName = useSelector(state => state.markets.byId[marketId])
  const mobile = useMediaQuery({ query: smOnly })
  const [{ spaceType, measure, unit }, setSuiteParams] = useState({})
  const data = isAdvisor
    ? byMarketSpace[`${marketId}-${spaceType}-${measure}-${unit}`]
    : byMarketId[marketId]
  const loading = priceStatus.loading || perSuiteStatus.loading

  // For Investor
  useEffect(() => {
    if (marketId && !isAdvisor) {
      dispatch(priceGet(marketId))
    }
  }, [dispatch, marketId, isAdvisor])

  // For Advisor
  useEffect(() => {
    if (marketId && isAdvisor && spaceType && measure && unit) {
      dispatch(priceGetPer({ marketId, spaceType, measure, unit }))
    }
  }, [dispatch, marketId, isAdvisor, spaceType, measure, unit])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {isAdvisor && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          <>
            <Delimeter>&gt;</Delimeter>
            <Item>Market Reports</Item>
          </>
          {marketName?.name && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item bold>
                {marketName.name}{' '}
                {(data ? data[data.length - 1] : {}).Label || ''}
              </Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            {isAdvisor && (
              <Selectors
                spaceBelow
                onChange={params => setSuiteParams(params)}
              />
            )}
            <Suspense
              fallback={
                <Flex fullWidth fullHeight center>
                  <Spinner elSize={40} />
                </Flex>
              }
            >
              {loading && (
                <Flex fullWidth fullHeight center>
                  <Spinner elSize={40} />
                </Flex>
              )}
              {data && (
                <>
                  <Styled.ChartWrap>
                    <Styled.NumbersWrapper dataHeight="330">
                      <Styled.Number>
                        ${(data[data.length - 1] || {}).PricePerSeat}
                      </Styled.Number>
                      {!isAdvisor ? (
                        <Styled.SubLabel>
                          {`${
                            (data[data.length - 1] || {}).Label
                          } Asking Price Per Seat`}
                        </Styled.SubLabel>
                      ) : (
                        <Styled.SubLabel data-text-transform="capitalize">
                          {`${
                            (data[data.length - 1] || {}).Label
                          } Asking Price Per ${unit}`}
                        </Styled.SubLabel>
                      )}
                      <Styled.Number>
                        {(data[data.length - 1] || {}).LocationsOpen || ''}
                      </Styled.Number>
                      <Styled.SubLabel>Total Locations</Styled.SubLabel>
                    </Styled.NumbersWrapper>
                    <div style={{ width: '100%' }}>
                      <Chart options={getPriceChart(data, marketName?.name, unit)} />
                    </div>
                  </Styled.ChartWrap>
                  <SummeryText page="AskingPrice" />
                </>
              )}
            </Suspense>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Price
