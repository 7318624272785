import fetch from 'isomorphic-fetch'
import { stringify } from 'qs'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getLocations = (token, market) =>
  fetch(`${apiUrl}${apiBase}/flex/mylocations?market=${market || ''}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getClosedLocations = (token, market, search) => {
  const query = stringify({
    market: !search ? market : undefined,
    address: search || undefined,
    minradius: search ? 500 : undefined,
    maxradius: search ? 4000 : undefined,
    minresults: search ? 8 : undefined
  })

  return fetch(
    `${apiUrl}${apiBase}/flex/closedlocations${query ? `?${query}` : ''}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const getMarketSummary = (token, marketId) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/marketsummary?marketid=${marketId}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const getMarketLocationsData = (token, marketId) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/marketlocationhistoricaldata?closed=true&marketid=${marketId}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const getLeadOwners = (token) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/leadowners`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const getLeadLocations = (token, leadId) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/leadlocations?leadId=${leadId}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}
