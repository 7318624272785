import { createGlobalStyle } from 'styled-components/macro';

export const FlatpickrLightCss = createGlobalStyle`
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-box-shadow: 0 3px 13px rgba(0,0,0,.08);
  box-shadow: 0 3px 13px rgba(0,0,0,.08)
}

.flatpickr-calendar.inline,.flatpickr-calendar.open {
  opacity: 1;
  max-height: 640px;
  visibility: visible
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown .3s cubic-bezier(.23,1,.32,1);
  animation: fpFadeInDown .3s cubic-bezier(.23,1,.32,1)
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px)
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none!important;
  box-shadow: none!important
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6
}

.flatpickr-calendar .hasTime .dayContainer,.flatpickr-calendar .hasWeeks .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #eceef1
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-innerContainer {
  border-bottom: 0
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  border: 1px solid #eceef1
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto
}

.flatpickr-calendar:after,.flatpickr-calendar:before {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px
}

.flatpickr-calendar.rightMost:after,.flatpickr-calendar.rightMost:before {
  left: auto;
  right: 22px
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px
}

.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before {
  bottom: 100%
}

.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #eceef1
}

.flatpickr-calendar.arrowBottom:after,.flatpickr-calendar.arrowBottom:before {
  top: 100%
}

.flatpickr-calendar.arrowBottom:after,.flatpickr-calendar.arrowBottom:before {
  border-top-color: #eceef1
}

.flatpickr-calendar:focus {
  outline: 0
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block
}

.flatpickr-months {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex
}

.flatpickr-months .flatpickr-month {
  border-radius: 5px 5px 0 0;
  background: #eceef1;
  color: #5a6171;
  fill: #5a6171;
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1 1
}

.flatpickr-months .flatpickr-next-month,.flatpickr-months .flatpickr-prev-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: #5a6171;
  fill: #5a6171
}

.flatpickr-months .flatpickr-next-month.flatpickr-disabled,.flatpickr-months .flatpickr-prev-month.flatpickr-disabled {
  display: none
}

.flatpickr-months .flatpickr-next-month i,.flatpickr-months .flatpickr-prev-month i {
  position: relative
}

.flatpickr-months .flatpickr-next-month.flatpickr-prev-month,.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month {
  left: 0
}

.flatpickr-months .flatpickr-next-month.flatpickr-next-month,.flatpickr-months .flatpickr-prev-month.flatpickr-next-month {
  right: 0
}

.flatpickr-months .flatpickr-next-month:hover,.flatpickr-months .flatpickr-prev-month:hover {
  color: #bbb
}

.flatpickr-months .flatpickr-next-month:hover svg,.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: #f64747
}

.flatpickr-months .flatpickr-next-month svg,.flatpickr-months .flatpickr-prev-month svg {
  width: 14px;
  height: 14px
}

.flatpickr-months .flatpickr-next-month svg path,.flatpickr-months .flatpickr-prev-month svg path {
  -webkit-transition: fill .1s;
  transition: fill .1s;
  fill: inherit
}

.numInputWrapper {
  position: relative;
  height: auto
}

.numInputWrapper input,.numInputWrapper span {
  display: inline-block
}

.numInputWrapper input {
  width: 100%
}

.numInputWrapper input::-ms-clear {
  display: none
}

.numInputWrapper input::-webkit-inner-spin-button,.numInputWrapper input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(72,72,72,.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.numInputWrapper span:hover {
  background: rgba(0,0,0,.1)
}

.numInputWrapper span:active {
  background: rgba(0,0,0,.2)
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(72,72,72,.6);
  top: 26%
}

.numInputWrapper span.arrowDown {
  top: 50%
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(72,72,72,.6);
  top: 40%
}

.numInputWrapper span svg {
  width: inherit;
  height: auto
}

.numInputWrapper span svg path {
  fill: rgba(90,97,113,.5)
}

.numInputWrapper:hover {
  background: rgba(0,0,0,.05)
}

.numInputWrapper:hover span {
  opacity: 1
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: .5ch;
  padding: 0
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0,0,0,.05)
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #5a6171
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #5a6171
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 .5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0
}

.flatpickr-current-month input.cur-year[disabled],.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(90,97,113,.5);
  background: transparent;
  pointer-events: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: #eceef1;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0;
  outline: none;
  padding: 0 0 0 .5ch;
  position: relative;
  vertical-align: baseline;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto
}

.flatpickr-current-month .flatpickr-monthDropdown-months:active,.flatpickr-current-month .flatpickr-monthDropdown-months:focus {
  outline: none
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0,0,0,.05)
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: #eceef1;
  outline: none;
  padding: 0
}

.flatpickr-weekdays {
  background: #eceef1;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1 1
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: #eceef1;
  color: #5a6171;
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1 1;
  font-weight: bolder
}

.dayContainer,.flatpickr-weeks {
  padding: 1px 0 0
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
  border-left: 1px solid #eceef1;
  border-right: 1px solid #eceef1
}

.flatpickr-days:focus {
  outline: 0
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1
}

.dayContainer+.dayContainer {
  -webkit-box-shadow: -1px 0 0 #eceef1;
  box-shadow: -1px 0 0 #eceef1
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #484848;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center
}

.flatpickr-day.inRange,.flatpickr-day.nextMonthDay.inRange,.flatpickr-day.nextMonthDay.today.inRange,.flatpickr-day.nextMonthDay:focus,.flatpickr-day.nextMonthDay:hover,.flatpickr-day.prevMonthDay.inRange,.flatpickr-day.prevMonthDay.today.inRange,.flatpickr-day.prevMonthDay:focus,.flatpickr-day.prevMonthDay:hover,.flatpickr-day.today.inRange,.flatpickr-day:focus,.flatpickr-day:hover {
  cursor: pointer;
  outline: 0;
  background: #e2e2e2;
  border-color: #e2e2e2
}

.flatpickr-day.today {
  border-color: #bbb
}

.flatpickr-day.today:focus,.flatpickr-day.today:hover {
  border-color: #bbb;
  background: #bbb;
  color: #fff
}

.flatpickr-day.endRange,.flatpickr-day.endRange.inRange,.flatpickr-day.endRange.nextMonthDay,.flatpickr-day.endRange.prevMonthDay,.flatpickr-day.endRange:focus,.flatpickr-day.endRange:hover,.flatpickr-day.selected,.flatpickr-day.selected.inRange,.flatpickr-day.selected.nextMonthDay,.flatpickr-day.selected.prevMonthDay,.flatpickr-day.selected:focus,.flatpickr-day.selected:hover,.flatpickr-day.startRange,.flatpickr-day.startRange.inRange,.flatpickr-day.startRange.nextMonthDay,.flatpickr-day.startRange.prevMonthDay,.flatpickr-day.startRange:focus,.flatpickr-day.startRange:hover {
  background: #ff5a5f;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #ff5a5f
}

.flatpickr-day.endRange.startRange,.flatpickr-day.selected.startRange,.flatpickr-day.startRange.startRange {
  border-radius: 50px 0 0 50px
}

.flatpickr-day.endRange.endRange,.flatpickr-day.selected.endRange,.flatpickr-day.startRange.endRange {
  border-radius: 0 50px 50px 0
}

.flatpickr-day.endRange.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.selected.startRange+.endRange:not(:nth-child(7n+1)),.flatpickr-day.startRange.startRange+.endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #ff5a5f;
  box-shadow: -10px 0 0 #ff5a5f
}

.flatpickr-day.endRange.startRange.endRange,.flatpickr-day.selected.startRange.endRange,.flatpickr-day.startRange.startRange.endRange {
  border-radius: 50px
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e2e2e2,5px 0 0 #e2e2e2;
  box-shadow: -5px 0 0 #e2e2e2,5px 0 0 #e2e2e2
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover,.flatpickr-day.nextMonthDay,.flatpickr-day.notAllowed,.flatpickr-day.notAllowed.nextMonthDay,.flatpickr-day.notAllowed.prevMonthDay,.flatpickr-day.prevMonthDay {
  color: rgba(72,72,72,.3);
  background: transparent;
  border-color: transparent;
  cursor: default
}

.flatpickr-day.flatpickr-disabled,.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(72,72,72,.1)
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #ff5a5f,5px 0 0 #ff5a5f;
  box-shadow: -5px 0 0 #ff5a5f,5px 0 0 #ff5a5f
}

.flatpickr-day.hidden {
  visibility: hidden
}

.rangeMode .flatpickr-day {
  margin-top: 1px
}

.flatpickr-weekwrapper {
  float: left
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  border-left: 1px solid #eceef1
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px
}

.flatpickr-weekwrapper span.flatpickr-day,.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(72,72,72,.3);
  background: transparent;
  cursor: default;
  border: none
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  background: #fff;
  border-bottom: 1px solid #eceef1
}

.flatpickr-innerContainer,.flatpickr-rContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  border-radius: 0 0 5px 5px
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both
}

.flatpickr-time .numInputWrapper {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1 1;
  width: 40%;
  height: 40px;
  float: left
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #484848
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #484848
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #484848;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield
}

.flatpickr-time input.flatpickr-hour {
  font-weight: 700
}

.flatpickr-time input.flatpickr-minute,.flatpickr-time input.flatpickr-second {
  font-weight: 400
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0
}

.flatpickr-time .flatpickr-am-pm,.flatpickr-time .flatpickr-time-separator {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #484848;
  font-weight: 700;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400
}

.flatpickr-time .flatpickr-am-pm:focus,.flatpickr-time .flatpickr-am-pm:hover,.flatpickr-time input:focus,.flatpickr-time input:hover {
  background: #eaeaea
}

.flatpickr-input[readonly] {
  cursor: pointer
}

@-webkit-keyframes fpFadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(0,-20px,0);
      transform: translate3d(0,-20px,0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

@keyframes fpFadeInDown {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(0,-20px,0);
      transform: translate3d(0,-20px,0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

span.flatpickr-day.selected {
  font-weight: 700
}

`;
