import styled from 'styled-components/macro';

export const Toggle = styled.div`
  display: block;
  position: relative;
  flex: none;
  width: 40px;
  height: 20px;
  border-radius: 20px;
  background-color: ${(props) => (props.checked ? '#61ab81' : '#979797')};
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  z-index: 1;
  box-shadow: inset 0 1px 1px 0.5px rgba(0, 0, 0, 0.30);
  margin: 10px 0;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 2px;
    border-radius: 20px;
    height: 16px;
    background-color: #fff;
    transition: 0.2s cubic-bezier(0, 1.1, 1, 1.1);
    width: 16px;
    z-index: 1;
    left: 3px;
    box-shadow: 0 1px 1px 0.5px rgba(0, 0, 0, 0.30);
    transform: ${props => (props.checked ? 'translate3d(18px,0,0)' : 'translate3d(0,0,0)')};
  }
`;

export const Input = styled.input`
  display: none;
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const LabelText = styled.span`
  color: #585756;
  font-weight: 600;
`;

export const Label = styled.label`
  display: inline-block;
  width: 100%;
`;
