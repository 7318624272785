import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  LOCATIONS_GET_REQUEST,
  LOCATIONS_GET_SUCCESS,
  LOCATIONS_GET_FAILURE,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_FAILURE,
  LOCATION_GET_REQUEST,
  LOCATION_GET_SUCCESS,
  LOCATION_GET_FAILURE,
  LOCATION_CHANGE_STATUS,
  LOCATIONS_BY_MARKET_ID_GET_REQUEST,
  LOCATIONS_BY_MARKET_ID_GET_SUCCESS,
  LOCATIONS_BY_MARKET_ID_GET_FAILURE,
  LOCATION_BY_IDS_GET_REQUEST,
  LOCATION_BY_IDS_GET_SUCCESS,
  LOCATION_BY_IDS_GET_FAIL
} from '../constants'
import * as api from '../api/locations'
import { getTokenWithExpiration } from './auth'

export const locationChangeStatus = (payload) => ({
  type: LOCATION_CHANGE_STATUS,
  payload
})

const locationsGetRequest = () => ({
  type: LOCATIONS_GET_REQUEST
})

const locationsGetSuccess = payload => ({
  type: LOCATIONS_GET_SUCCESS,
  payload
})

const locationsGetFailure = payload => ({
  type: LOCATIONS_GET_FAILURE,
  payload
})

export const locationsGet = token => (dispatch, getState) => {
  const {
    locations: {
      status: { completed, error }
    }
  } = getState()
  if (completed && !error) {
    return null
  }

  dispatch(locationsGetRequest())

  return api
    .getLocations(token)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(locationsGetSuccess(response))
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(message => {
          dispatch(locationsGetFailure(`${err.status}: ${message}`))
        })
      } else {
        dispatch(locationsGetFailure('Locations get unknown error'))
      }
    })
}

const locationGetRequest = () => ({
  type: LOCATION_GET_REQUEST
})

const locationGetSuccess = payload => ({
  type: LOCATION_GET_SUCCESS,
  payload
})

const locationGetFailure = payload => ({
  type: LOCATION_GET_FAILURE,
  payload
})

export const locationGet = locationId => (dispatch, getState) => {
  const { token } = getState().auth
  dispatch(locationGetRequest())

  return api
    .getLocationById(token, locationId)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(locationGetSuccess(response))
      return response
    })
    .catch(() => {
      dispatch(locationGetFailure('Location get unknown error'))
    })
}

export const locationByIdGet = locationId => (dispatch, getState) => {
  if (getState().location.byId[locationId]) {
    return null
  }

  dispatch(locationGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLocationById(token, locationId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(locationGetSuccess(response))

          return response
        })
        .catch(() => {
          dispatch(locationGetFailure('Location get unknown error'))
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const locationUpdateRequest = () => ({
  type: LOCATION_UPDATE_REQUEST
})

const locationUpdateSuccess = payload => ({
  type: LOCATION_UPDATE_SUCCESS,
  payload
})

const locationUpdateFailure = () => ({
  type: LOCATION_UPDATE_FAILURE
})

export const locationUpdate = (locationId, values) => (dispatch, getState) => {
  const {
    auth: { token }
  } = getState()

  if (!token) {
    return null
  }

  dispatch(locationUpdateRequest())
  return api
    .updateLocation(token, locationId, values)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(locationUpdateSuccess(response))
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(message => {
          window.alert(`${err.status}: ${message}`)
        })
      } else {
        window.alert('Location update unknown error')
      }
      dispatch(locationUpdateFailure())
    })
}

export const locationByIdUpdate =
  (locationId, values) => (dispatch, getState) => {
    dispatch(locationUpdateRequest())

    return dispatch(getTokenWithExpiration(getState()))
      .then(token => {
        return api
          .updateLocation(token, locationId, values)
          .then(checkHttpStatus)
          .then(parseJSON)
          .then(response => {
            dispatch(locationUpdateSuccess(response))
          })
          .catch(err => {
            if (err) {
              parseJSON(err).then(message => {
                window.alert(`${err.status}: ${message}`)
              })
            } else {
              window.alert('Location update unknown error')
            }
            dispatch(locationUpdateFailure())
          })
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        window.alert('Token issue. Please reload the page')
      })
  }

const locationsGetByMarketIdRequest = () => ({
  type: LOCATIONS_BY_MARKET_ID_GET_REQUEST
})

const locationsGetByMarketIdSuccess = (payload, config) => ({
  type: LOCATIONS_BY_MARKET_ID_GET_SUCCESS,
  payload,
  config
})

const locationsGetByMarketIdFailure = payload => ({
  type: LOCATIONS_BY_MARKET_ID_GET_FAILURE,
  payload
})

export const locationsGetByMarketId = id => (dispatch, getState) => {
  if (getState().brokerTools.locations.byMarketId[id]) {
    return null
  }

  dispatch(locationsGetByMarketIdRequest())

  return api
    .locationsByMarketIDGet(id)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(locationsGetByMarketIdSuccess(response, id))
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(message => {
          dispatch(locationsGetByMarketIdFailure(`${err.status}: ${message}`))
        })
      } else {
        dispatch(locationsGetByMarketIdFailure('Locations get unknown error'))
      }
    })
}

const proposalLocationsGetRequest = (payload) => ({
  type: LOCATION_BY_IDS_GET_REQUEST,
  payload
})

const proposalLocationsGetSuccess = (payload, config) => ({
  type: LOCATION_BY_IDS_GET_SUCCESS,
  payload,
  config
})

const proposalLocationsGetFailure = payload => ({
  type: LOCATION_BY_IDS_GET_FAIL,
  payload
})

export const proposalLocationsGet = (ids, leadId) => (dispatch, getState) => {
  if (getState().brokerTools.locations.byLeadId[leadId]) {
    return null
  }

  dispatch(proposalLocationsGetRequest(leadId))

  return api
    .locationsByIdsGet(ids)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(proposalLocationsGetSuccess(response, leadId))
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(() => {
          dispatch(proposalLocationsGetFailure(leadId))
        })
      } else {
        dispatch(proposalLocationsGetFailure(leadId))
      }
    })
}

