import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router'
import styled from 'styled-components/macro'
import {
  LEADS_ROUTE,
  DEMAND_AND_PRICING_ROUTE,
  PIPELINE_ROUTE,
  SPACES_ROUTE
} from '../../settings/routes'
import { mdUp, lgUp } from '../../styles/breakpoints';
import { sortByName } from '../../helpers/sort'

const OperatorNavigation = () => {
  const { locationId, marketId } = useParams()
  const { list: markets } = useSelector(state => state.markets)
  const { byMarketId } = useSelector(state => state.locations)
  const marketOptions = sortByName(
    (markets || []).filter(market => !market.isSubmarket)
  ).map(market => market.id)
  const firstMarket = marketOptions[0]
  const locationOptions = sortByName(byMarketId[firstMarket] || [])
  const firstLocation = (locationOptions[0] || {}).id
  const marketRoute = marketId && marketId !== 'all' ? `${marketId}/` : `${firstMarket}/`
  const locationRoute = locationId && locationId !== 'all' ? locationId : firstLocation
  const routeParams = `${marketRoute}${locationRoute}`
  const { flexOfficePro } = useSelector(state => state.user)

  return (
    <Wrapper>
      <List>
        <Item>
          <NavItem
            to={`/${LEADS_ROUTE}/${routeParams}`}
          >
            Review Leads
          </NavItem>
        </Item>
        <Item>
          <NavItem
            to={`/${SPACES_ROUTE}/${routeParams}`}
          >
            Update Spaces
          </NavItem>
        </Item>
        {['Basic', 'Advanced'].includes(flexOfficePro) && (
          <Item>
            <NavItem
              type="NavItem"
              to={`/${PIPELINE_ROUTE}/${routeParams}`}
            >
              Pipeline Report
            </NavItem>
          </Item>
        )}
        {flexOfficePro === 'Enterprise' && (
          <Item>
            <NavItem
              type="NavItem"
              to={`/${DEMAND_AND_PRICING_ROUTE}/${PIPELINE_ROUTE}/${routeParams}`}
            >
              Market Demand & Pricing
            </NavItem>
          </Item>
        )}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 15px;
  padding-right: 25px;
  padding-left: 25px;
  margin-bottom: 20px;
  width: 100%;

  @media ${lgUp} {
    width: auto;
    margin-bottom: 0;
    padding-right: 50px;
    padding-left: 0;
  }
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`

const Item = styled.li`
  &:not(:last-child) {
    margin-right: 15px;

    @media ${mdUp} {
      margin-right: 40px;
    }
  }
`

const NavItem = styled(NavLink)`
  font-weight: 600;
  color: black;
  line-height: 1;
  font-size: 18px;

  &.active {
    text-decoration: underline;
  }
`

export default OperatorNavigation
