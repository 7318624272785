import isEmpty from 'lodash/isEmpty'
import { createReducer } from '../utils/redux'
import {
  LEAD_PERFORMANCE_GET_REQUEST,
  LEAD_PERFORMANCE_GET_SUCCESS,
  LEAD_PERFORMANCE_GET_FAILURE,
  LEADS_AND_VIEWS_GET_REQUEST,
  LEADS_AND_VIEWS_GET_SUCCESS,
  LEADS_AND_VIEWS_GET_FAILURE
} from '../constants'

const initialState = {
  byRoute: {},
  status: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false
  },
  error: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false
  },
  leadsAndViews: {
    byId: {},
    loading: false,
    error: false
  }
}

export default createReducer(initialState, {
  [LEAD_PERFORMANCE_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true
    },
    error: {
      ...initialState.error,
      loading: false
    }
  }),
  [LEAD_PERFORMANCE_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    byRoute: {
      ...state.byRoute,
      [config]: !isEmpty(payload) ? payload : null
    },
    status: {
      ...initialState.status,
      loading: false
    },
    error: {
      ...initialState.error,
      loading: false
    }
  }),
  [LEAD_PERFORMANCE_GET_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      loading: false
    },
    error: {
      ...initialState.error,
      loading: payload || true
    }
  }),
  [LEADS_AND_VIEWS_GET_REQUEST]: state => ({
    ...state,
    leadsAndViews: {
      ...state.leadsAndViews,
      loading: true,
      error: false
    }
  }),
  [LEADS_AND_VIEWS_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    leadsAndViews: {
      loading: false,
      error: false,
      byId: {
        ...state.leadsAndViews.byId,
        [config]: payload
      }
    }
  }),
  [LEADS_AND_VIEWS_GET_FAILURE]: state => ({
    ...state,
    leadsAndViews: {
      ...state.leadsAndViews,
      loading: false,
      error: true
    }
  })
})
