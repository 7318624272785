import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'
import Flex from '../../components/flex'
import Select from 'react-select';

import { summeryTextGet } from '../../actions/market-reports'

const SummeryText = ({ page }) => {
  const { marketId } = useParams()
  const dispatch = useDispatch()
  const { byPageMarket } = useSelector(state => state.reports.summeryText)
  const items = byPageMarket[`${page}-${marketId}`] || []
  const filteredItems = items.filter(item => Boolean(item.text))
  const [activeItem, setActiveItem] = useState(null)
  const [activeObj, setActiveObj] = useState(null)

  useEffect(() => {
    if (marketId && page) {
      dispatch(summeryTextGet(page, marketId))
    }
  }, [dispatch, page, marketId])

  useEffect(() => {
    if (filteredItems.length > 0 && !activeItem) {
      setActiveItem((filteredItems[0] || {}).label)
    }
  }, [filteredItems, activeItem])

  useEffect(() => {
    if (activeItem) {
      setActiveObj(filteredItems.find(item => item?.label === activeItem) || {})
    }
  }, [filteredItems, activeItem])

  return (
    <Flex fullWidth>
      <Row>
        {filteredItems.length > 0 && (
          <>
            <Sel>
              <Select
                options={filteredItems.map(item => ({
                  label: item.label,
                  value: item.label
                }))}
                isSearchable={false}
                value={{
                  value: activeItem,
                  label: activeItem
                }}
                onChange={({ value }) => setActiveItem(value)}
              />
            </Sel>
            {activeObj && (
              <Content>
                {!activeObj.isHtml && <p>{activeObj.text}</p>}
                {activeObj.isHtml && (
                  <p dangerouslySetInnerHTML={activeObj.text} />
                )}
              </Content>
            )}
          </>
        )}
      </Row>
    </Flex>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 970px) {
    flex-direction: row;
  }
`

const Sel = styled.div`
  display: block;
  margin-bottom: 1rem;
  padding: 0 20px;
  width: 100%;

  @media (min-width: 970px) {
    padding: 0;
    width: 150px;
  }
`

const Content = styled.div`
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 100px;

  p {
    color: #747474;
    font-size: 24px;
    margin: 0;
  }
`

export default SummeryText
