import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  LOCATION_SERVICES_ALL_GET_REQUEST,
  LOCATION_SERVICES_ALL_GET_SUCCESS,
  LOCATION_SERVICES_ALL_GET_FAILURE,
  LOCATION_SERVICES_ALL_FILTER,
  LOCATION_SERVICES_ALL_CLEAR,
  LOCATION_SERVICES_ALL_REMOVE,
  LOCATION_SERVICES_ALL_OPEN,
  LOCATION_SERVICES_ALL_CLOSE,
  LOCATION_SERVICES_ALL_CENTER,
  HIGHLIGHT_MARKER_ALL,
  MAP_PINS_TO_FEED_ALL,
  MAP_SUMMARY_GET_REQUEST,
  MAP_SUMMARY_GET_SUCCESS,
  MAP_SUMMARY_GET_FAILURE,
  LOCATION_SERVICES_ALL_TOGGLE_VIEW,
  MARKET_LOCATIONS_ALL_DATA_REQUEST,
  MARKET_LOCATIONS_ALL_DATA_SUCCESS,
  MARKET_LOCATIONS_ALL_DATA_FAIL
} from '../constants'
import * as api from '../api/location-services-all'
import { getTokenWithExpiration } from './auth'

const locationServicesAllGetRequest = (payload) => ({
  type: LOCATION_SERVICES_ALL_GET_REQUEST,
  payload
})

const locationServicesAllGetSuccess = (payload, config) => ({
  type: LOCATION_SERVICES_ALL_GET_SUCCESS,
  payload,
  config
})

const locationServicesAllGetFailure = () => ({
  type: LOCATION_SERVICES_ALL_GET_FAILURE
})

export const locationServicesAllGet =
  ({ marketId, search, page, pagesize, initial, infinite, all }) =>
  (dispatch, getState) => {
    const { byMarketId, bySearch, byPage } = getState().locationServicesAll

    if (
      (marketId && byMarketId[marketId]) ||
      (search && bySearch[search]) ||
      (((page >= 0) && byPage[page]) || (page === 0 && initial && byPage.initial))
    ) {
      return null;
    }

    dispatch(locationServicesAllGetRequest({
      marketId,
      search,
      page,
      initial,
      infinite,
      all
    }))

    return dispatch(getTokenWithExpiration(getState()))
      .then(token => {
        return api
          .getAllLocations(token, marketId, search, page, pagesize)
          .then(checkHttpStatus)
          .then(parseJSON)
          .then(response => {
            dispatch(
              locationServicesAllGetSuccess(response, {
                marketId,
                search,
                page,
                initial,
                infinite,
                all
              })
            )
          })
          .catch(error => {
            if (error) {
              dispatch(locationServicesAllGetFailure())
              // eslint-disable-next-line no-alert
              window.alert(error.status)
            }
          })
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        window.alert('Token issue. Please reload the page')
      })
  }

const mapSummaryGetRequest = () => ({
  type: MAP_SUMMARY_GET_REQUEST
})

const mapSummaryGetSuccess = payload => ({
  type: MAP_SUMMARY_GET_SUCCESS,
  payload
})

const mapSummaryGetFailure = () => ({
  type: MAP_SUMMARY_GET_FAILURE
})

export const mapSummaryGet = () => (dispatch, getState) => {
  if (getState().locationServicesAll.mapSummary.length !== 0) {
    return null
  }

  dispatch(mapSummaryGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .mapSummaryGet(token)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(mapSummaryGetSuccess(response))
        })
        .catch(error => {
          if (error) {
            dispatch(mapSummaryGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

export const locationServicesAllFilter = (payload, config) => ({
  type: LOCATION_SERVICES_ALL_FILTER,
  payload,
  config
})

export const locationServicesAllClear = () => ({
  type: LOCATION_SERVICES_ALL_CLEAR
})

export const locationServicesAllRemove = payload => ({
  type: LOCATION_SERVICES_ALL_REMOVE,
  payload
})

export const locationServicesAllOpen = payload => ({
  type: LOCATION_SERVICES_ALL_OPEN,
  payload
})

export const locationServicesAllClose = () => ({
  type: LOCATION_SERVICES_ALL_CLOSE
})

export const highlightMarkerAll = (payload, config) => ({
  type: HIGHLIGHT_MARKER_ALL,
  payload,
  config
})

export const mapPinsToFeedAll = payload => ({
  type: MAP_PINS_TO_FEED_ALL,
  payload
})

export const setAddressCenter = payload => ({
  type: LOCATION_SERVICES_ALL_CENTER,
  payload
})

export const toggleView = payload => ({
  type: LOCATION_SERVICES_ALL_TOGGLE_VIEW,
  payload
})

const marketLocationsDataRequest = () => ({
  type: MARKET_LOCATIONS_ALL_DATA_REQUEST
})

const marketLocationsDataSuccess = (payload, config) => ({
  type: MARKET_LOCATIONS_ALL_DATA_SUCCESS,
  payload,
  config
})

const marketLocationsDataFailure = () => ({
  type: MARKET_LOCATIONS_ALL_DATA_FAIL
})

export const marketLocationsDataGet = (market) => (dispatch, getState) => {
  if (getState().locationServicesAll.marketLocationsDataById[market]) {
    return null
  }

  dispatch(marketLocationsDataRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getMarketLocationsData(token, market)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(marketLocationsDataSuccess(response, market))
        })
        .catch(error => {
          if (error) {
            dispatch(marketLocationsDataFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
