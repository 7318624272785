import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  VIEWS_IN_SEARCH_GET_REQUEST,
  VIEWS_IN_SEARCH_GET_SUCCESS,
  VIEWS_IN_SEARCH_GET_FAILURE
} from '../constants'
import * as api from '../api/views-in-search'
import { getTokenWithExpiration } from './auth'

const viewsInSearchGetRequest = () => ({
  type: VIEWS_IN_SEARCH_GET_REQUEST
})

const viewsInSearchGetSuccess = (payload, config) => ({
  type: VIEWS_IN_SEARCH_GET_SUCCESS,
  payload,
  config
})

const viewsInSearchGetFailure = () => ({
  type: VIEWS_IN_SEARCH_GET_FAILURE
})

export const viewsInSearchGet = params => (dispatch, getState) => {
  if (getState().viewsInSearch.byRoute[params]) {
    return null
  }

  dispatch(viewsInSearchGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getViewsInSearch(token, params)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(viewsInSearchGetSuccess(response, params))
        })
        .catch(error => {
          if (error) {
            dispatch(viewsInSearchGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
