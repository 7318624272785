import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getWifiSystems = token =>
  fetch(`${apiUrl}${apiBase}/WifiSystem`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    }
  })

export const addWifiSystem = (token, data) =>
  fetch(`${apiUrl}${apiBase}/WifiSystem`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
