import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import {
  WORK_LEDS_ROUTE,
  marketSummaries,
  locationServices
} from '../../settings/routes'
import { mdUp, lgUp } from '../../styles/breakpoints'
import useRouteParams from '../../hooks/use-route-params'

const AdvisorNavigation = () => {
  const routeParams = useRouteParams()
  const { subscriptions } = useSelector(state => state.user)

  return (
    <Wrapper>
      <List>
        <Item>
          {subscriptions?.includes('Leads') && (
            <NavItem to={`/${WORK_LEDS_ROUTE}/${routeParams}`}>Work Leads</NavItem>
          )}
        </Item>
        <Item>
          <NavItem to={`/${marketSummaries.main}/${marketSummaries.summary}/${routeParams}`}>
            Markets
          </NavItem>
        </Item>
        <Item>
          <NavItem type="NavItem" to={`/${locationServices.main}/${locationServices.listAll}`}>
            Locations
          </NavItem>
        </Item>
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 15px;
  padding-right: 25px;
  padding-left: 25px;
  margin-bottom: 20px;
  width: 100%;

  @media ${lgUp} {
    width: auto;
    margin-bottom: 0;
    padding-right: 50px;
    padding-left: 0;
  }
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`

const Item = styled.li`
  &:not(:last-child) {
    margin-right: 15px;

    @media ${mdUp} {
      margin-right: 40px;
    }
  }
`

const NavItem = styled(NavLink)`
  font-weight: 600;
  color: black;
  line-height: 1;
  font-size: 18px;

  &.active {
    text-decoration: underline;
  }
`

export default AdvisorNavigation
