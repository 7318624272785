import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import Flex from '../../components/flex'
import styled from 'styled-components/macro'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import Sidebar from '../../containers/SidebarReports'
import { useMediaQuery } from 'react-responsive'
import { smOnly } from '../../styles/breakpoints'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import { ArrowUp, ArrowDown } from '../../components/icons'
import { supplyAndDemandGet } from '../../actions/market-reports'
import {
  getDemandValues,
  getSupplyValues,
  getPriceValues
} from '../../helpers/reports'
import { summeryTextGet } from '../../actions/market-reports'
import * as Styled from '../../components/reports'
import Copyright from './copyright'

const Supply = props => {
  const { marketId } = props.match.params
  const marketName = useSelector(state => state.markets.byId[marketId])
  const mobile = useMediaQuery({ query: smOnly })
  const flexOfficePro = useSelector(state => state.user.flexOfficePro)
  const dispatch = useDispatch()
  const page = 'supplyanddemand'
  const { byMarketId } = useSelector(state => state.reports.supplyAndDemand)
  const data = byMarketId[marketId]
  const [activeQuarter, setActiveQuarter] = useState(null)
  const { byPageMarket } = useSelector(state => state.reports.summeryText)
  const textItems = byPageMarket[`${page}-${marketId}`] || []
  const getTextItem = textItems.find(item => item?.label === activeQuarter)
  const demand = getDemandValues({ data, activeQuarter })
  const supply = getSupplyValues({ data, activeQuarter })
  const prices = getPriceValues({ data, activeQuarter })

  useEffect(() => {
    if (marketId) {
      dispatch(supplyAndDemandGet(marketId))
      dispatch(summeryTextGet(page, marketId))
    }
  }, [dispatch, marketId])

  useEffect(() => {
    if (data) {
      setActiveQuarter((data[0] || {}).quarter)
    }
  }, [data])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {flexOfficePro === 'Advisor' && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          <>
            <Delimeter>&gt;</Delimeter>
            <Item>Market Reports</Item>
          </>
          {marketName?.name && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item bold>
                {marketName.name} {activeQuarter || ''}
              </Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            <Wrap>
              {data && (
                <Styled.TopSelectWrap>
                  <Select
                    options={data.map(item => ({
                      label: item.quarter,
                      value: item.quarter
                    }))}
                    isSearchable={false}
                    value={{
                      value: activeQuarter,
                      label: activeQuarter
                    }}
                    onChange={({ value }) => setActiveQuarter(value)}
                  />
                </Styled.TopSelectWrap>
              )}
              <Styled.Heading>Supply, Demand & Pricing Trends</Styled.Heading>
              <Copyright center/>
              <Row>
                {demand && (
                  <Col>
                    {demand?.isPositive ? (
                      <StyledArrowUp />
                    ) : (
                      <StyledArrowDown />
                    )}
                    <Heading>Demand</Heading>
                    <Sub data-positive={demand?.isPositive}>
                      {demand?.text || ''}
                    </Sub>
                  </Col>
                )}
                {supply && (
                  <Col>
                    {supply?.isPositive ? (
                      <StyledArrowUp />
                    ) : (
                      <StyledArrowDown />
                    )}
                    <Heading>Supply</Heading>
                    <Sub>
                      <span data-positive={supply?.newLoc > 0}>{`${
                        supply?.newLoc > 0 ? '+' : ''
                      }${supply?.newLoc} new`}</span>
                      ,{' '}
                      <span data-positive={supply?.closedLoc <= 0}>{`${
                        supply?.closedLoc > 0 ? '-' : ''
                      }${supply?.closedLoc} closed`}</span>
                    </Sub>
                  </Col>
                )}
                {prices && (
                  <Col>
                    {prices?.isPositive ? (
                      <StyledArrowUp />
                    ) : (
                      <StyledArrowDown />
                    )}
                    <Heading>Seat Prices</Heading>
                    <Sub data-positive={prices?.isPositive}>
                      {prices?.text || ''}
                    </Sub>
                  </Col>
                )}
              </Row>
            </Wrap>
            {getTextItem && (
              <Content>
                {!getTextItem.isHtml && <p>{getTextItem.text}</p>}
                {getTextItem.isHtml && (
                  <p dangerouslySetInnerHTML={getTextItem.text} />
                )}
              </Content>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const StyledArrowUp = styled(ArrowUp)`
  color: #57ab83;
  width: 150px;
`

const StyledArrowDown = styled(ArrowDown)`
  color: #e7483d;
  width: 150px;
`

const Row = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  max-width: 100%;
  flex: 0 0 100%;

  @media (min-width: 970px) {
    max-width: calc(100% / 3);
    flex: 0 0 calc(100% / 3);
  }
`

const Heading = styled.h2`
  font-size: 2rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #555;
`

const Sub = styled.span`
  font-weight: 600;
  color: #555;
  font-size: 1rem;

  &[data-positive='true'],
  span[data-positive='true'] {
    color: #57ab83;
  }

  &[data-positive='false'],
  span[data-positive='false'] {
    color: #e7483d;
  }
`

const Content = styled.div`
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 50px;
  margin-bottom: 100px;

  p {
    color: #747474;
    font-size: 24px;
    margin: 0;
  }
`

const Wrap = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`

export default Supply
