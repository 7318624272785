import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import { mdUp } from '../../styles/breakpoints'

const selectStyles = {
  valueContainer: provided => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    fontSize: 15
  }),
  option: provided => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    fontSize: 15
  })
}

const Selectors = ({
  spaceTypeOptions,
  measureOptions,
  spaceType,
  measure,
  setSpaceType,
  setMeasure
}) => {
  return (
    <TopSelectors>
      <SelectorWrap>
        <Select
          options={spaceTypeOptions}
          value={{ value: spaceType, label: spaceType }}
          onChange={({ value }) => setSpaceType(value)}
          isSearchable={false}
          styles={selectStyles}
        />
      </SelectorWrap>
      <SelectorWrap>
        <Select
          options={measureOptions}
          value={{ value: measure, label: measure }}
          onChange={({ value }) => setMeasure(value)}
          isSearchable={false}
          styles={selectStyles}
        />
      </SelectorWrap>
    </TopSelectors>
  )
}

export const TopSelectors = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media ${mdUp} {
    flex-direction: row;
  }
`

export const SelectorWrap = styled.div`
  width: 100%;
  padding: 0 5px;
  margin-bottom: 10px;

  @media ${mdUp} {
    max-width: 180px;
    margin-bottom: 0;
  }

  > div {
    width: 100%;
  }
`

export default Selectors
