import React from 'react';
import styled from 'styled-components/macro';
import { UpsuiteLogo } from '../../components/icons'

const Wrapper = styled.div`
  width: 160px;
  height: auto;
`;

const Link = styled.a`
  display: flex;
  width: 100%;
`;

const Logo = styled(UpsuiteLogo)`
  fill: #e7483d;
`

const UpsuiteLogoLink = ({ url }) => {
  return (
    <Wrapper>
      <Link href={url} target="_blank" rel="noopener">
        <Logo />
      </Link>
    </Wrapper>
  );
};

export default UpsuiteLogoLink;

