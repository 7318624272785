import fetch from 'isomorphic-fetch';
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey,
} from '../settings/api';

export const authcheckInfo = token =>
  fetch(`${apiUrl}${apiBase}/authcheck?info`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
  });

export const getProfilePic = token =>
  fetch(`${apiUrl}${apiBase}/user/profilepic`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      Accept: 'image/png, image/jpeg',
    },
  });

export const updateProfilePic = (token, binary, contentType) =>
  fetch(`${apiUrl}${apiBase}/user/profilepic`, {
    method: 'put',
    headers: {
      'Content-Type': contentType,
      'x-api-key': apiKey,
      Authorization: token,
    },
    body: binary,
    redirect: 'follow',
  });
