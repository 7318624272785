import styled, { createGlobalStyle, css } from 'styled-components/macro';
import { smOnly, mdUp } from '../../styles/breakpoints';
import { FlatpickrLightCss } from './lightcss';

export const Wrapper = styled.div`
  ${FlatpickrLightCss}

  display: flex;

  .form-control.input {
    width: 100%;
    padding: 8px 5px 5px 5px;
    height: 30px;
    font-size: 13px;
    border: 1px solid transparent;
    color: #828180;
  }

  .form-control.input:focus {
    background-color: #f8f8f9;
    border: 1px solid #d5d5d5;
  }

  @media ${smOnly} {
    .form-control.input {
      background-color: #f8f8f9;
      border: 1px solid #d5d5d5;
    }
  }

  @media ${mdUp} {
    .form-control.input {
      padding: 0;
    }
  }

  ${({ bgInput }) =>
    bgInput &&
    css`
      .form-control.input, .flatpickr-input {
        background-color: #f8f8f9;
        border: 1px solid #d5d5d5;
        padding: 5px;
        font-size: 13px;
        font-weight: 600;
        width: 100%;
        color: #585756;
      }
    `}

  ${({ bordered }) =>
    bordered &&
    css`
      .form-control.input {
        padding: 8px;
        height: 36px;
        color: #333;
        max-width: 100%;
        width: 100%;
        box-shadow: none;
        box-sizing: border-box;
        margin-bottom: 5px;
        border: 1px solid #d5d5d5;
        border-radius: 4px;
        background-color: transparent;
        border: 1px solid #d5d5d5;

        &:focus {
          background-color: transparent;
        }
      }
    `}
`

export const PickrStyles = createGlobalStyle`
.flatpickr-day {
  border-radius: 0;
  border-bottom: 0;
  border-right: 0;
  max-width: none;
  height: 42px;
  line-height: 42px;
  border-color: #d5d5d5;
  color: #585756;
  font-weight: 600
}

.flatpickr-day.today:hover,.flatpickr-day:hover {
  color: #585756;
  background-color: #d5d5d5
}

.flatpickr-day.disabled,.flatpickr-day.disabled:hover {
  color: #d5d5d5;
  border-color: #d5d5d5
}

.flatpickr-day:after,.flatpickr-day:before {
  content: "";
  position: absolute;
  display: block;
  background-color: #d5d5d5
}

.flatpickr-day:after {
  width: calc(100% + 2px);
  height: 1px;
  bottom: -1px;
  left: -1px
}

.flatpickr-day:before {
  height: calc(100% + 2px);
  width: 1px;
  bottom: -1px;
  right: -1px
}

.flatpickr-day.prevMonthDay {
  visibility: hidden
}

.flatpickr-day.nextMonthDay {
  display: none
}

.flatpickr-day.today {
  border-color: #d5d5d5
}

.flatpickr-day.selected,.flatpickr-day.today {
  border-radius: 0
}

.flatpickr-day.selected {
  color: #fff;
  background-color: #e7483d
}

.flatpickr-months .flatpickr-month,.flatpickr-weekdays {
  background-color: transparent
}

.flatpickr-weekdays .flatpickr-weekday {
  color: #585756;
  font-size: 14px;
  background-color: transparent
}

.flatpickr-current-month span.cur-month {
  font-size: 14px;
  color: #585756;
  font-weight: 600
}

.flatpickr-current-month input.cur-year .arrowUp {
  display: none
}

.flatpickr-calendar {
  width: 335px;
  border: 1px solid #d5d5d5;
  border-radius: 0;
  background-color: #f8f8f9
}

.flatpickr-innerContainer {
  background-color: transparent
}

.flatpickr-days {
  border: none;
  width: 100%;
  overflow: visible
}

.dayContainer {
  width: 100%;
  min-width: 100%;
  justify-content: flex-start
}

.flatpickr-rContainer {
  padding: 15px 15px 30px
}

.numInputWrapper {
  font-size: 14px;
  color: #585756
}

.numInputWrapper:hover {
  background-color: transparent
}

.numInputWrapper span.arrowDown,.numInputWrapper span.arrowUp {
  display: none
}

.flatpickr-current-month input.cur-year {
  font-weight: 600
}

.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before {
  bottom: 100%
}

.flatpickr-calendar.arrowTop:after,.flatpickr-calendar.arrowTop:before {
  border-bottom-color: transparent;
}

.flatpickr-calendar.arrowBottom:after, .flatpickr-calendar.arrowBottom:before {
  border-top-color: transparent;
}
`;
