import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { leadEditHide } from '../../actions/broker-tools'
import { spaceModifiedDate } from '../../helpers/format'
import { leadLocationsGet } from '../../actions/location-services'

import { Close } from '../../components/icons'
import Form from './EditForm'

const EditLead = () => {
  const dispatch = useDispatch()
  const lead = useSelector(state => state.brokerTools.leads.edit.lead)
  const leadOwners = useSelector(state => state.locationServices.leadOwners)
  const [leadOwnerId, setLeadOwnerId] = useState(lead.contact_owner_id)
  const [leadOwnerName, setLeadOwnerName] = useState(lead.contact_owner_name)

  useEffect(() => {
    if (lead.id) {
      dispatch(leadLocationsGet(lead.id))
    }
  }, [dispatch, lead.id])

  return (
    <Wrap>
      <Header>
        <CloseBtn onClick={() => dispatch(leadEditHide())}>
          <CloseIcon />
        </CloseBtn>
        <Title>
          {lead?.first_name || ''} {lead?.last_name || ''}
        </Title>
      </Header>
      <Info>
        <p>
          Owner:{' '}
          <select
            defaultValue={lead.contact_owner_id}
            onChange={e => {
              setLeadOwnerId(leadOwners[e.target?.selectedIndex - 1]?.id)
              setLeadOwnerName(leadOwners[e.target?.selectedIndex - 1]?.name)
            }}
          >
            <option></option>
            {(leadOwners || []).map(item => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </p>
        {lead?.market && <p>Market: {lead?.market}</p>}
        {lead?.seats && <p>Seats: {lead?.seats}</p>}
        {lead?.move_in_date && (
          <p>Move in date: {spaceModifiedDate(lead?.move_in_date)}</p>
        )}
        {lead?.created_at && (
          <p>Date Registered: {spaceModifiedDate(lead?.created_at)}</p>
        )}
      </Info>
      <Form
        lead={lead}
        contactOwnerId={leadOwnerId}
        contactOwnerName={leadOwnerName}
      />
    </Wrap>
  )
}

const Wrap = styled.div`
  position: absolute;
  width: 300px;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  right: 0;
  z-index: 11;
  background-color: white;
  border-left: 1px solid #828282;
`

const Header = styled.div`
  width: 100%;
  background-color: #e7483d;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  flex-shrink: 0;
`

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 28px;
  color: white;
`

const CloseBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  box-shadow: none;
  border: none;
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  width: 20px;
`

const Info = styled.div`
  padding: 10px;
  border-bottom: 1px solid #828282;

  p {
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    color: #2e2c2b;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default EditLead
