import Amplify, { Auth } from 'aws-amplify'
import { authCheckInfo } from './user'
import {
  AUTH_SIGNIN_REQUEST,
  AUTH_SIGNIN_SUCCESS,
  AUTH_SIGNIN_FAILURE,
  AUTH_SESSION_REQUEST,
  AUTH_SESSION_SUCCESS,
  AUTH_SESSION_FAILURE,
  NEW_PASSWORD_REQUIRED,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  PASSWORD_RECOVERY_REQUEST,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_FAILURE
} from '../constants'
import { USER_POOL_ID, USER_CLIENT_ID } from '../settings/api'

export const configureAmplify = () =>
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: 'us-east-2',
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: USER_CLIENT_ID
    }
  })

const authSigninRequest = () => ({
  type: AUTH_SIGNIN_REQUEST
})

export const authSigninSuccess = payload => ({
  type: AUTH_SIGNIN_SUCCESS,
  payload
})

const authSigninFailure = payload => ({
  type: AUTH_SIGNIN_FAILURE,
  payload
})

const authSessionRequest = () => ({
  type: AUTH_SESSION_REQUEST
})

export const authSessionSuccess = payload => ({
  type: AUTH_SESSION_SUCCESS,
  payload
})

const authSessionFailure = payload => ({
  type: AUTH_SESSION_FAILURE,
  payload
})

const authNewPasswordRequired = payload => ({
  type: NEW_PASSWORD_REQUIRED,
  payload
})

export const authSignIn = (email, password) => dispatch => {
  dispatch(authSigninRequest())

  return Auth.signIn(email, password)
    .then(response => {
      if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(authNewPasswordRequired(response))
      }

      if (response.signInUserSession) {
        dispatch(
          authSessionSuccess(response.signInUserSession.idToken.jwtToken)
        )
        dispatch(authSigninSuccess(response.signInUserSession.idToken.jwtToken))
        dispatch(authCheckInfo(response.signInUserSession.idToken.jwtToken))
      }
    })
    .catch(error => {
      dispatch(
        authSigninFailure(error && error.message ? error.message : error)
      )
    })
}

export const getAuthCurrentSession = () => dispatch => {
  dispatch(authSessionRequest())

  return Auth.currentSession()
    .then(session => {
      dispatch(authSessionSuccess(session.idToken.jwtToken))
      dispatch(authCheckInfo(session.idToken.jwtToken))
    })
    .catch(error => {
      dispatch(
        authSessionFailure(error && error.message ? error.message : error)
      )
    })
}

export const getTokenWithExpiration = state => dispatch => {
  return new Promise((resolve) => {
    Auth.currentAuthenticatedUser()
      .then(cognitoUser => {
        const currentSession = cognitoUser.signInUserSession;
        const idTokenExpire = currentSession.getIdToken().getExpiration();
        const jwtToken = currentSession.getIdToken().getJwtToken();
        const refreshToken = currentSession.getRefreshToken();
        const currentTimeSeconds = Math.round(+new Date() / 1000);

        if (idTokenExpire < currentTimeSeconds) {
          cognitoUser.refreshSession(refreshToken, (err, data) => {
            if (err) {
              resolve(jwtToken)
            } else {
              dispatch(authSessionSuccess(data.getIdToken().getJwtToken()))

              resolve(data.getIdToken().getJwtToken());
            }
          });
        } else {
          resolve(jwtToken)
        }
      })
      .catch((error) => {
        dispatch(
          authSessionFailure(error && error.message ? error.message : error)
        )

        resolve(state.auth.token)
      });
    });
}

export const logout = () => dispatch => {
  return Auth.signOut()
    .then(() => dispatch({ type: LOGOUT_SUCCESS }))
    .catch(() => dispatch({ type: LOGOUT_FAILURE }))
}

export const changePassword = values => () => {
  return Auth.currentAuthenticatedUser().then(user => {
    return Auth.changePassword(user, values.oldPassword, values.newPassword)
  })
}

const authPasswordRecoveryRequest = () => ({
  type: PASSWORD_RECOVERY_REQUEST
})

const authPasswordRecoverySuccess = payload => ({
  type: PASSWORD_RECOVERY_SUCCESS,
  payload
})

const authPasswordRecoveryFailure = payload => ({
  type: PASSWORD_RECOVERY_FAILURE,
  payload
})

export const authPasswordRecovery = email => dispatch => {
  dispatch(authPasswordRecoveryRequest())

  return Auth.forgotPassword(email)
    .then(() => {
      dispatch(authPasswordRecoverySuccess(email))
    })
    .catch(error => {
      dispatch(
        authPasswordRecoveryFailure(
          error && error.message ? error.message : error
        )
      )
    })
}
