import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'
import { stringify } from 'qs'

export const getLocations = token =>
  fetch(`${apiUrl}${apiBase}/flex/locations`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const updateLocation = (token, locationId, data) =>
  fetch(`${apiUrl}${apiBase}/location/${locationId}`, {
    method: 'put',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

export const getLocationById = (token, locationId) =>
  fetch(`${apiUrl}${apiBase}/location/${locationId}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getPublicLocByMarketSlug = slug =>
  fetch(
    `${apiUrl}${apiBase}/location/bymarketslug?slug=${slug}&fields=id,latitude,longitude`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey
      }
    }
  )

export const locationsByMarketIDGet = id => {
  const marketQuery = {
    'where[0][type]': 'equals',
    'where[0][attribute]': 'marketId',
    'where[0][value]': id,
    'where[1][type]': 'isTrue',
    'where[1][attribute]': 'isOpen'
  }

  const where = stringify(marketQuery, { encode: false })

  return fetch(`${apiUrl}${apiBase}/location?${where}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}

export const locationsByIdsGet = ids => {
  const config = stringify(
    {
      'where[0][type]': 'in',
      'where[0][attribute]': 'id'
    },
    { encode: false }
  )

  const params = stringify(
    {
      'where[0][value]': ids
    },
    {
      arrayFormat: 'index',
      encode: false
    }
  )

  return fetch(`${apiUrl}${apiBase}/location?${config}&${params}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}
