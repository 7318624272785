import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import styled from 'styled-components/macro'
import Select from 'react-select'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import {
  getFilterList,
  sortingOptionsAll
} from '../../helpers/location-services'
import { locationServicesAllRemove } from '../../actions/location-services-all'
import { mdUp, smOnly } from '../../styles/breakpoints'
import LocationCard from './LocationCard'
import { useWindowResize } from '../../hooks/use-window-resize'

const Row = ({ data, index, style, setSize, windowWidth }) => {
  const rowRef = useRef()

  useEffect(() => {
    setSize(index, rowRef.current.getBoundingClientRect().height + 15)
  }, [setSize, index, windowWidth])

  return (
    <div style={style} ref={rowRef}>
      <LocationCard location={(data || [])[index]} />
    </div>
  )
}

const Feed = ({ locations }) => {
  const listRef = useRef()
  const sizeMap = useRef({})
  const dispatch = useDispatch()
  const query = useSelector(state => state.locationServicesAll.filter)
  const activeView = useSelector(state => state.locationServicesAll.activeView)
  const allTotal = useSelector(state => state.locationServicesAll.total)
  const loading = useSelector(state => {
    const queryLoading = state.locationServicesAll.status.loading
    const allLoading = state.locationServicesAll.status.all

    return (
      ((query.marketId || query.search) && queryLoading) ||
      (!(query.marketId || query.search) && !allTotal && allLoading)
    )
  })
  const pinIds = useSelector(state => state.locationServicesAll.pinIds)
  const filterList = getFilterList(query)
  const [sort, setSort] = useState(sortingOptionsAll[0])
  const filtered = filter(
    locations,
    item => pinIds.length === 0 || pinIds.some(id => item.id === id)
  )
  const ordered = orderBy(
    filtered,
    item => {
      const value = item[sort.value.split('-')[0]]

      return value ? parseInt(value) : false
    },
    sort.value.split('-')[1]
  )
  const total =
    query.marketId || query.search || filterList.length > 0
      ? ordered.length
      : allTotal

  const setSize = useCallback((index, size) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    listRef.current.resetAfterIndex(index)
  }, [])

  const getSize = index => sizeMap.current[index] || 500
  const [windowWidth] = useWindowResize()

  return (
    <FeedWrap
      data-has-toggle={!!(query.marketId || query.search)}
      data-visible={!activeView || !(query.marketId || query.search)}
    >
      <FilterSticky data-has-toggle={!!(query.marketId || query.search)}>
        <FilterList>
          {filterList.map(item => (
            <FilterItem key={`${item.key}-${item.value}`}>
              <FilterBtn
                onClick={() => dispatch(locationServicesAllRemove(item))}
              >
                x
              </FilterBtn>
              <span>{item.label}</span>
            </FilterItem>
          ))}
        </FilterList>
        <SortingWrap>
          <NumberOfLocations>
            {!loading && (
              <span>{`${total} Location${
                total > 1 || total === 0 ? 's' : ''
              } Found`}</span>
            )}
          </NumberOfLocations>
          <SortingSelect>
            <Select
              isDisabled={loading}
              onChange={value => setSort(value)}
              options={sortingOptionsAll}
              value={sort}
              styles={{
                container: provided => ({
                  ...provided,
                  width: '100%'
                }),
                menu: provided => ({
                  ...provided,
                  zIndex: 3
                }),
                option: provided => ({
                  ...provided,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  '> svg': {
                    flexShrink: 0
                  }
                }),
                valueContainer: provided => ({
                  ...provided,
                  color: '#000',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  paddingRight: 0,
                  '> span': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    display: 'block'
                  }
                }),
                control: provided => {
                  return {
                    ...provided,
                    minHeight: 35,
                    borderRadius: 3,
                    borderColor: 'hsl(0,0%,80%)',
                    boxShadow: 'none'
                  }
                },
                indicatorsContainer: provided => {
                  return {
                    ...provided,
                    minWidth: 20,
                    '> div': {
                      padding: 6
                    },
                    svg: {
                      display: 'none'
                    },
                    '&::after': {
                      content: '""',
                      borderStyle: 'solid',
                      borderColor: 'hsl(0,0%,80%) transparent transparent',
                      borderWidth: '5px 5px 2.5px',
                      display: 'inline-block',
                      height: 0,
                      width: 0,
                      position: 'absolute',
                      right: '10px',
                      top: '14px'
                    }
                  }
                },
                indicatorSeparator: provided => ({
                  ...provided,
                  display: 'none'
                })
              }}
            />
          </SortingSelect>
        </SortingWrap>
      </FilterSticky>
      <FilterListWrap data-has-toggle={!!(query.marketId || query.search)}>
        {loading && (
          <SpinnerWrap>
            <Flex fullWidth fullHeight center>
              <Spinner elSize={40} />
            </Flex>
          </SpinnerWrap>
        )}
        {!loading && (
          <AutoSizer>
            {({ height, width }) => (
              <List
                ref={listRef}
                height={height}
                itemCount={total}
                itemSize={getSize}
                width={width}
                itemData={ordered}
              >
                {({ data, index, style }) => (
                  <div style={style}>
                    <Row
                      data={data}
                      index={index}
                      setSize={setSize}
                      windowWidth={windowWidth}
                    />
                  </div>
                )}
              </List>
            )}
          </AutoSizer>
        )}
      </FilterListWrap>
    </FeedWrap>
  )
}

const SpinnerWrap = styled.div`
  margin-top: 30px;
`

const FeedWrap = styled.div`
  width: 100%;

  @media ${mdUp} {
    height: 100%;
    overflow: hidden;

    &[data-has-toggle='true'] {
      height: calc(100% - 44px);
    }
  }

  &[data-visible='false'] {
    &[data-has-toggle='true'] {
      @media ${smOnly} {
        display: none;
      }

      @media ${mdUp} {
        transform: translate3d(0, -100%, 0);
      }
    }
  }

  &[data-visible='true'] {
    &[data-has-toggle='true'] {
      @media ${smOnly} {
        display: block;
      }

      @media ${mdUp} {
        transform: translate3d(0, 0, 0);
        transition: transform 0.3s ease-in;
      }
    }
  }

  &[data-space-above='true'] {
    padding-top: 16px;
  }
`

const FilterListWrap = styled.div`
  width: 100%;
  height: 2000px;

  @media ${mdUp} {
    &[data-has-toggle='true'] {
      height: calc(100% - 57px);
    }
  }
`

const FilterList = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
`

const FilterItem = styled.li`
  white-space: nowrap;
  background-color: #e5e5e5;
  padding: 5px 8px 5px 5px;
  margin-bottom: 10px;
  font-size: 14px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 10px;
  }
`

const FilterBtn = styled.button.attrs({ type: 'button' })`
  background-color: transparent;
  border: none;
  cursor: pointer;
  line-height: 11px;
  line-height: 16px;
  padding: 2px 10px 4px;
  font-size: 16px;
  background: none;
  box-shadow: none;
  border: none;
`

const FilterSticky = styled.div`
  width: 100%;
  position: sticky;
  top: 44px;
  z-index: 3;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #f8f8f8;
  padding-bottom: 10px;
  padding-top: 10px;

  @media ${mdUp} {
    top: 0;
  }
`

const SortingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NumberOfLocations = styled.div`
  span {
    display: block;
    color: #2e2c2b;
    font-weight: 600;
    font-size: 16px;
  }
`

const SortingSelect = styled.div`
  width: 150px;
  flex: 0 0 150px;

  @media (min-width: 1240px) {
    width: 250px;
    flex: 0 0 250px;
  }
`

export default Feed
