import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { useFormik } from 'formik'
import { leadEditHide, leadUpdate, leadAdd } from '../../actions/broker-tools'
import { format, parseISO } from 'date-fns'
import DatePicker from '../../components/datepicker-leads'
import MultiLineInput from '../../components/multiline-input'
import { leadInitialValues } from '../../utils/broker-tools'

const EditForm = ({ lead, isNew, contactOwnerId, contactOwnerName }) => {
  const dispatch = useDispatch()
  const markets = useSelector(state => state.markets.list);
  const formik = useFormik({
    initialValues: isNew ? leadInitialValues : (lead || {}),
    onSubmit: values => {
      if (!isNew) {
        dispatch(leadUpdate({
          ...values,
          contact_owner_id: contactOwnerId,
          contact_owner: contactOwnerName
        }))
        return;
      }

      dispatch(leadAdd({
        ...values,
        contact_owner_id: contactOwnerId,
        contact_owner: contactOwnerName
      }))
    },
    validate: (values) => {
      let errors = {}
      const text = 'Required'

      if (!values.email) {
        errors.email = text
      }

      if (!values.first_name) {
        errors.first_name = text
      }

      if (!(values.seats && values.seats > 0)) {
        errors.seats = text
      }

      if (!values.market) {
        errors.market = text
      }

      if (!values.move_in_date) {
        errors.move_in_date = text
      }

      if (!values.contact_channel) {
        errors.contact_channel = text
      }

      return errors
    }
  })
  const disabled = false

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Label htmlFor="first_name">
          <LabelText data-required>First Name</LabelText>
          <Input
            type="text"
            id="first_name"
            name="first_name"
            value={formik.values?.first_name || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.first_name && formik.errors.first_name && (
          <ErrorText>{formik.errors.first_name}</ErrorText>
        )}
      </Row>
      <Row>
        <Label htmlFor="last_name">
          <LabelText>Last Name</LabelText>
          <Input
            type="text"
            id="last_name"
            name="last_name"
            value={formik.values?.last_name || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.last_name && formik.errors.last_name && (
          <ErrorText>{formik.errors.last_name}</ErrorText>
        )}
      </Row>
      <Row>
        <Label htmlFor="email">
          <LabelText data-required>Email</LabelText>
          <Input
            type="email"
            id="email"
            name="email"
            value={formik.values?.email || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.email && formik.errors.email && (
          <ErrorText>{formik.errors.email}</ErrorText>
        )}
      </Row>
      <Row>
        <Label htmlFor="phone_number">
          <LabelText>Phone</LabelText>
          <Input
            type="text"
            id="phone_number"
            name="phone_number"
            value={formik.values?.phone_number || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.phone && formik.errors.phone && (
          <ErrorText>{formik.errors.phone}</ErrorText>
        )}
      </Row>
      <Row>
        <Label htmlFor="seats">
          <LabelText data-required># of Seats</LabelText>
          <Input
            type="number"
            id="seats"
            name="seats"
            value={formik.values?.seats || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.seats && formik.errors.seats && (
          <ErrorText>{formik.errors.seats}</ErrorText>
        )}
      </Row>
      <Row>
        <Label>
          <LabelText data-required>Market</LabelText>
          <SelectNative
            defaultValue={formik.values?.market_id}
            onChange={e => {
              formik.setFieldValue(
                'market_id',
                markets[e.target?.selectedIndex - 1]?.id
              )
              formik.setFieldValue(
                'market',
                markets[e.target?.selectedIndex - 1]?.name
              )
            }}
            onFocus={() => formik.setFieldTouched('market', true)}
            disabled={disabled}
          >
            <option></option>
            {markets.map(market => (
              <option key={market.id} value={market.id}>
                {market.name}
              </option>
            ))}
          </SelectNative>
        </Label>
        {(formik.submitCount > 0 || formik.touched.market) &&
          formik.errors.market && <ErrorText>{formik.errors.market}</ErrorText>}
      </Row>
      <Row>
        <Label htmlFor="move_in_date">
          <LabelText data-required>Move in Date</LabelText>
          <DatePicker
            value={
              formik.values.move_in_date &&
              format(parseISO(formik.values.move_in_date), 'MM/dd/yyyy')
            }
            id="move_in_date"
            name="move_in_date"
            onChange={value => {
              formik.setFieldValue(
                'move_in_date',
                value ? `${format(new Date(value), 'yyyy-MM-dd')}T00:00:00` : null
              )
            }}
            disabled={disabled}
            bgInput
            altInput={false}
          />
        </Label>
        {formik.touched.move_in_date && formik.errors.move_in_date && (
          <ErrorText>{formik.errors.move_in_date}</ErrorText>
        )}
      </Row>
      <Row>
        <Label>
          <LabelText>Lead State</LabelText>
          <SelectNative
            defaultValue={formik.values?.conversion_stage}
            onChange={e => {
              const value = e.target.value
              formik.setFieldValue('conversion_stage', value)
            }}
            onFocus={() => formik.setFieldTouched('conversion_stage', true)}
            disabled={disabled}
          >
            <option></option>
            <option value="0">Unqualified</option>
            <option value="1">Bounced</option>
            <option value="2">New</option>
            <option value="3">Do Not Contact</option>
            <option value="5">Working</option>
            <option value="6">Put Off Search</option>
            <option value="7">Engaged</option>
            <option value="8">Referred</option>
            <option value="9">Re-Engaged</option>
            <option value="10">Qualified</option>
            <option value="20">Tour Requested</option>
            <option value="30">Completed a Tour</option>
            <option value="40">Closed Won</option>
            <option value="100">Closed-Not Converted</option>
            <option value="150">Closed Lost</option>
            <option value="200">Bad Data</option>
          </SelectNative>
        </Label>
        {(formik.submitCount > 0 || formik.touched.conversion_stage) &&
          formik.errors.conversion_stage && (
            <ErrorText>{formik.errors.conversion_stage}</ErrorText>
          )}
      </Row>
      <Row>
        <Label>
          <LabelText>Notification Type</LabelText>
          <SelectNative
            defaultValue={formik.values?.notification_type}
            onChange={e => {
              const value = e.target.value
              formik.setFieldValue('notification_type', value)
            }}
            onFocus={() => formik.setFieldTouched('notification_type', true)}
            disabled={disabled}
          >
            <option></option>
            <option value="None">None</option>
            <option value="UpsuiteManaged">Upsuite Managed</option>
            <option value="OperatorManaged">Operator Managed</option>
            <option value="LeadRegistered">Lead Registered</option>
            <option value="OperatorManagedPass">Operator Managed Pass</option>
          </SelectNative>
        </Label>
        {(formik.submitCount > 0 || formik.touched.notification_type) &&
          formik.errors.notification_type && (
            <ErrorText>{formik.errors.notification_type}</ErrorText>
          )}
      </Row>
      <Row>
        <Label>
          <LabelText data-required>Contact Channel</LabelText>
          <SelectNative
            defaultValue={formik.values?.contact_channel}
            onChange={e => {
              const value = e.target.value
              formik.setFieldValue('contact_channel', value)
            }}
            onFocus={() => formik.setFieldTouched('contact_channel', true)}
            disabled={disabled}
          >
            <option></option>
            <option value="Marketplace">Marketplace</option>
            <option value="Marketplace - Lead Feeder">
              Marketplace - Lead Feeder
            </option>
            <option value="Prospecting">Prospecting</option>
            <option value="Network Referral">Network Referral</option>
            <option value="Broker Referral">Broker Referral</option>
            <option value="Coworker.com">Coworker.com</option>
            <option value="Other">Other</option>
            <option value="Squarefoot">Squarefoot</option>
            <option value="Davinci">Davinci</option>
            <option value="Broker Registration">Broker Registration</option>
          </SelectNative>
        </Label>
        {(formik.submitCount > 0 || formik.touched.contact_channel) &&
          formik.errors.contact_channel && (
            <ErrorText>{formik.errors.contact_channel}</ErrorText>
          )}
      </Row>
      <Row>
        <Label htmlFor="job_title">
          <LabelText>Job Title</LabelText>
          <Input
            type="text"
            id="job_title"
            name="job_title"
            value={formik.values?.job_title || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.job_title && formik.errors.job_title && (
          <ErrorText>{formik.errors.job_title}</ErrorText>
        )}
      </Row>
      <Row>
        <Label htmlFor="company">
          <LabelText>Company</LabelText>
          <Input
            type="text"
            id="company"
            name="company"
            value={formik.values?.company || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.company && formik.errors.company && (
          <ErrorText>{formik.errors.company}</ErrorText>
        )}
      </Row>
      {!isNew && (
        <>
          <Row>
            <Label htmlFor="proposal_count">
              <LabelText># of Proposals Received</LabelText>
              <Input
                type="number"
                id="proposal_count"
                name="proposal_count"
                value={formik.values?.proposal_count || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
              />
            </Label>
            {formik.touched.proposal_count && formik.errors.proposal_count && (
              <ErrorText>{formik.errors.proposal_count}</ErrorText>
            )}
          </Row>
          <Row>
            <Label htmlFor="proposal_rejected_count">
              <LabelText># of Proposals Rejected</LabelText>
              <Input
                type="number"
                id="proposal_rejected_count"
                name="proposal_rejected_count"
                value={formik.values?.proposal_rejected_count || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
              />
            </Label>
            {formik.touched.proposal_rejected_count &&
              formik.errors.proposal_rejected_count && (
                <ErrorText>{formik.errors.proposal_rejected_count}</ErrorText>
              )}
          </Row>
        </>
      )}
      <Row>
        <Label htmlFor="shortlist_url">
          <LabelText>Shortlist</LabelText>
          <Input
            type="url"
            id="shortlist_url"
            name="shortlist_url"
            value={formik.values?.shortlist_url || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.shortlist_url && formik.errors.shortlist_url && (
          <ErrorText>{formik.errors.shortlist_url}</ErrorText>
        )}
      </Row>
      {!isNew && (
        <>
          <Row>
            <Label htmlFor="location_first_converted_on">
              <LabelText>Location Preferred/Converted On</LabelText>
              <Input
                type="text"
                id="location_first_converted_on"
                name="location_first_converted_on"
                value={formik.values?.location_first_converted_on || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
              />
            </Label>
            {formik.touched.location_first_converted_on &&
              formik.errors.location_first_converted_on && (
                <ErrorText>
                  {formik.errors.location_first_converted_on}
                </ErrorText>
              )}
          </Row>
          <MultiLineInput
            id="lead-locations"
            name="lead-locations"
            // leadId={formik.values?.id}
            // value={formik.values?.locations || ''}
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            disabled
          />
        </>
      )}
      <Row>
        <Label htmlFor="status_note">
          <LabelText>Status Note</LabelText>
          <Input
            type="text"
            id="status_note"
            name="status_note"
            value={formik.values?.status_note || ''}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </Label>
        {formik.touched.status_note && formik.errors.status_note && (
          <ErrorText>{formik.errors.status_note}</ErrorText>
        )}
      </Row>
      <Row>
        <Label htmlFor="last_login_date">
          <LabelText>Last Login Date</LabelText>
          <DatePicker
            value={
              formik.values.last_login_date &&
              format(parseISO(formik.values.last_login_date), 'MM/dd/yyyy')
            }
            id="last_login_date"
            name="last_login_date"
            onChange={value => {
              formik.setFieldValue(
                'last_login_date',
                `${format(new Date(value), 'yyyy-MM-dd')}T00:00:00`
              )
            }}
            disabled={disabled}
            bgInput
            altInput={false}
          />
        </Label>
        {formik.touched.last_login_date && formik.errors.last_login_date && (
          <ErrorText>{formik.errors.last_login_date}</ErrorText>
        )}
      </Row>
      <Actions>
        <Action type="submit" disabled={disabled}>
          Save
        </Action>
        <Action
          data-cancel
          type="button"
          onClick={() => dispatch(leadEditHide())}
        >
          Cancel
        </Action>
      </Actions>
    </Form>
  )
}

export const Form = styled.form`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const Row = styled.div`
  width: 100%;
  margin-bottom: 12px;
`

const inputShared = `
  padding: 8px 5px 5px 5px;
  font-size: 13px;
  font-weight: 600;
  color: #585756;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 5px;
  background-color: #f8f8f9;
  border: 1px solid #d5d5d5;
`

export const Input = styled.input`
  ${inputShared}
`
export const SelectNative = styled.select`
  ${inputShared}

  height: 30px;
  padding: 5px;
`

export const LabelText = styled.span`
  display: inline-block;
  color: #585756;
  font-weight: 600;
  font-size: 13px;

  &[data-invalid='false'] {
    color: #57ab83;
  }

  &[data-invalid='true'] {
    color: #e7483d;
  }

  &[data-required] {
    &::after {
      content: '*';
      color: red;
      margin-left: 4px;
    }
  }
`

export const ErrorText = styled.span`
  display: block;
  color: #e7483d;
  font-weight: 400;
  font-size: 13px;
  margin-top: 3px;
`

export const FieldError = styled.span`
  color: #e7483d;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
`

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  position: relative;
`

export const Select = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  placeholder: provided => ({
    ...provided,
    color: '#585756',
    fontWeight: 600,
    fontSize: 13,
    paddingTop: 2
  }),
  control: provided => ({
    ...provided,
    width: '100%',
    borderColor: '#d5d5d5',
    boxShadow: 'none',
    borderRadius: 0,
    minHeight: '30px',
    backgroundColor: '#f8f8f9',
    paddingLeft: '5px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0
  }),
  indicatorSeparator: () => ({
    width: 0
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0
  }),
  singleValue: provided => ({
    ...provided,
    transform: 'unset',
    marginLeft: 0,
    color: '#585756',
    fontSize: 13,
    fontWeight: 600,
    top: 3
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0,
    marginTop: 0,
    fontSize: 13
  })
}

export const Action = styled.button`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 12px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  margin: 0 10px;
  border-radius: 3px;
  white-space: nowrap;

  &[data-cancel='true'] {
    background-color: white;
    color: #e7483d;
  }

  &[data-in-modal='true'] {
    width: 100%;
    margin: 0;
  }
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Error = styled.span`
  display: block;
  font-size: 14px;
  color: #e6473d;
  margin-top: 5px;
`

export const SubmitModal = styled.div`
  position: absolute;
  width: 90%;
  left: 5%;
  background: white;
  z-index: 3;
  top: 100px;
  bottom: 0;
  margin: auto;
  height: 190px;
  padding: 0 15px 15px;
  border: 1px solid gray;

  p {
    text-align: center;
  }
`

export default EditForm
