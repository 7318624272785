import { createReducer } from '../utils/redux'
import {
  LEADS_BY_LOC_ID_GET_REQUEST,
  LEADS_BY_LOC_ID_GET_SUCCESS,
  LEADS_BY_LOC_ID_GET_FAILURE,
  LEAD_PROPOSAL_SHOW,
  LEAD_PROPOSAL_HIDE,
  LEAD_REJECT_REQUEST,
  LEAD_REJECT_SUCCESS,
  LEAD_REJECT_FAILURE,
  PROPOSAL_LEAD_CREATED
} from '../constants'

const initialState = {
  list: [],
  total: undefined,
  status: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false
  },
  error: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false
  },
  proposal: {
    active: false,
    lead: {}
  },
  reject: {
    loading: false,
    error: false
  }
}

export default createReducer(initialState, {
  [LEADS_BY_LOC_ID_GET_REQUEST]: (state, payload) => ({
    ...state,
    list: payload.page === 1 ? [] : state.list,
    total: payload.page === 1 ? undefined : state.total,
    status: {
      ...initialState.status,
      loading: true
    },
    error: {
      ...initialState.error,
      loading: false
    }
  }),
  [LEADS_BY_LOC_ID_GET_SUCCESS]: (state, payload) => ({
    ...state,
    list: payload?.results,
    total: payload?.total,
    status: {
      ...initialState.status,
      loading: false
    },
    error: {
      ...initialState.error,
      loading: false
    }
  }),
  [LEADS_BY_LOC_ID_GET_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      loading: false
    },
    error: {
      ...initialState.error,
      loading: payload || true
    }
  }),
  [LEAD_PROPOSAL_SHOW]: (state, payload) => ({
    ...state,
    proposal: {
      ...state.proposal,
      active: true,
      lead: payload
    }
  }),
  [LEAD_PROPOSAL_HIDE]: state => ({
    ...state,
    proposal: {
      ...state.proposal,
      active: false,
      lead: {}
    }
  }),
  [LEAD_REJECT_REQUEST]: state => ({
    ...state,
    reject: {
      loading: true,
      error: false
    }
  }),
  [LEAD_REJECT_SUCCESS]: (state, payload) => ({
    ...state,
    list: (state.list || []).map(item => {
      if (item?.email === payload?.email) {
        return {
          ...item,
          is_rejected: true
        }
      }

      return item
    }),
    reject: {
      loading: false,
      error: false
    }
  }),
  [LEAD_REJECT_FAILURE]: state => ({
    ...state,
    reject: {
      loading: false,
      error: true
    }
  }),
  [PROPOSAL_LEAD_CREATED]: (state, payload) => ({
    ...state,
    list: (state.list || []).map(item => {
      if (item?.id === payload) {
        return {
          ...item,
          proposal_created: true
        }
      }

      return item
    })
  })
})
