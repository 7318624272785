import {
  currencyBefore,
  currencyFormat,
} from '../helpers/format';

export const getSpaceLabel = ({ spaceCapacity, spaceType, spacePrice, sqFt }) => {
  const label = [
    `${spaceCapacity > 1 ? `${spaceCapacity} Person` : '1 Person'} ${spaceType}`,
    spacePrice && currencyBefore(currencyFormat(spacePrice)),
    sqFt && `${sqFt} Sq/ft`,
  ];

  return label.filter(l => l).join(', ');
};

export const getSpaceLabelName = ({ spaceCapacity, spaceType, spacePrice, name }) => {
  const label = [
    `${spaceCapacity > 1 ? `${spaceCapacity} Person` : '1 Person'} ${spaceType}`,
    name,
    spacePrice && currencyBefore(currencyFormat(spacePrice)),
  ];

  return label.filter(l => l).join(', ');
};
