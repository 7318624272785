import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Flex from '../../components/flex';
import { Container, Top, Heading, Link } from '../../components/login';
import Spinner from '../../components/spinner';
import {
  Input,
  LabelText,
  Button,
  ErrorText,
} from '../../components/form-fields';
import PoweredBy from '../../components/powered-by';
import Logo from '../../components/logo';
import { authPasswordRecovery } from '../../actions/auth';
import {
  DASHBOARD_ROUTE as dashboardRoute,
  LOGIN_ROUTE as loginRoute,
  CONFIRMATION_CODE_ROUTE as confirmationCodeRoute,
} from '../../settings/routes';

const ForgotPassword = () => {
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);
  const error = auth.status.error || user.status.error;
  const loading = auth.status.loading || user.status.loading;

  const dispatch = useDispatch();

  useEffect(() => {
    if (user.isAuthenticated) {
      dispatch(push(`/${dashboardRoute}`));
    }
  }, [dispatch, user]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: ({ email }) => {
      dispatch(authPasswordRecovery(email)).then(() =>
        dispatch(push(`/${confirmationCodeRoute}`)),
      );
    },
  });

  return (
    <Flex fullWidth fullScreenHeight middle column>
      <Flex fullWidth full middle center>
        <Container>
          <Top>
            <Logo />
          </Top>
          <Heading fontSize="28px">Password recovery</Heading>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="email">
              <LabelText>Email</LabelText>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                onChange={e => {
                  formik.handleChange(e);
                }}
                value={formik.values.email}
                required
                disabled={loading}
              />
            </label>

            <Link href={`/${loginRoute}`}>Return to login form</Link>
            {error && (
              <ErrorText>
                {auth.status.errorText || user.status.errorText}
              </ErrorText>
            )}
            <Button type="submit" disabled={loading} withSpinner>
              {loading && <Spinner elSize={18} />}
              <span>Send </span>
            </Button>
          </form>
        </Container>
      </Flex>
      <Flex fullWidth flexEnd>
        <PoweredBy />
      </Flex>
    </Flex>
  );
};

export default ForgotPassword;
