import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getLocationsWithLeadCount = (token, quarter = '') =>
  fetch(`${apiUrl}${apiBase}/flex/invester/LocationsWithLeadCount?quarter=${quarter}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })


export const getLocationsWithViewsInSear = (token, quarter) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/locationswithviewsinsear?quarter=${quarter}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getFullInvesterData = (token, quarter) =>
  fetch(`${apiUrl}${apiBase}/flex/invester/FullInvesterData?quarter=${quarter}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })
