import isEmpty from 'lodash/isEmpty'
import { format } from 'date-fns'
import { roundDecimals, getPct, addPctSign } from '../utils/math'

const roundArrayValues = arr => arr.map(Math.round)
const convertDatesArray = dates =>
  dates.map(date => {
    const arr = date.split('-')
    return format(new Date(arr[0], arr[1] - 1, 1), "MMM. ''yy")
  })

const getSeriesNames = location => {
  const locationName = (location && location.name) || 'Location Name'
  const neighborhoodName =
    (location && location.neighborhoodName) || 'Neighborhood'
  const marketName = (location && location.marketName) || 'Market'

  return [locationName, neighborhoodName, marketName]
}

export const getPipelineOptions = (data, advanced) => {
  if (isEmpty(data)) {
    return {}
  }

  return {
    chart: {
      type: 'bar',
      marginBottom: 0,
      marginTop: 0,
      plotBackgroundColor: '#f6f8fa'
    },
    title: {
      text: ''
    },
    tooltip: {
      enabled: false
    },
    legend: { enabled: false },
    xAxis: [
      {
        colorAxis: 0,
        categories: advanced
          ? [
              'Views in Search',
              'Leads',
              'Qualified Leads',
              'Tours Completed',
              'Agreements Signed'
            ]
          : ['Views in Search', 'Leads', 'Qualified Leads'],
        lineColor: 'transparent',
        labels: {
          style: {
            fontSize: 16,
            fontFamily: "'Montserrat', sans-serif"
          }
        }
      },
      {
        linkedTo: 0,
        opposite: true,
        lineColor: 'transparent',
        gridLineColor: 'transparent',
        tickColor: 'transparent',
        labels: {
          formatter: axisData => {
            if (axisData.pos === 1) {
              return addPctSign(
                roundDecimals(getPct(data.leads, data.listingViews))
              )
            }

            if (axisData.pos === 2) {
              return addPctSign(
                roundDecimals(getPct(data.qualifiedLead, data.leads))
              )
            }

            return null
          },
          style: {
            fontSize: 16,
            fontFamily: "'Montserrat', sans-serif"
          }
        }
      }
    ],
    yAxis: {
      reversed: true,
      gridLineWidth: 0
    },
    series: [
      {
        pointWidth: 90,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          style: { textOutline: 'none', fontSize: 16 }
        },
        data: advanced
          ? [
              { y: data.listingViews, color: '#e6473d' },
              { y: data.leads, color: '#e75f57' },
              { y: data.qualifiedLead, color: '#e86c64' },
              { y: data.tours, color: '#e87169' },
              { y: data.agreements, color: '#ea807b' }
            ]
          : [
              { y: data.listingViews, color: '#e7483d' },
              { y: data.leads, color: '#e74850' },
              { y: data.qualifiedLead, color: '#e7485e' }
            ],
        states: {
          hover: {
            color: '#e7483d'
          }
        }
      }
    ]
  }
}

export const getViewsInSearchOptions = (data, location, marketName) => {
  if (isEmpty(data)) {
    return {}
  }

  const [locationName, neighborhoodName] = getSeriesNames(location)

  const series = [
    data.location
      ? {
          name: locationName,
          color: '#82b2f2',
          data: roundArrayValues(data.location)
        }
      : null,
    data.vicinity
      ? {
          name: '3 closest',
          color: '#000',
          data: roundArrayValues(data.vicinity)
        }
      : null,
    data.neighborhood
      ? {
          name: neighborhoodName,
          color: '#94ed6b',
          data: roundArrayValues(data.neighborhood)
        }
      : null,
    data.market
      ? {
          name: marketName,
          color: '#f0a84e',
          data: roundArrayValues(data.market)
        }
      : null
  ].filter(item => item !== null)

  return {
    title: {
      text: 'Views in Search'
    },

    subtitle: {
      text: `Location vs ${marketName || ''}`
    },
    yAxis: {
      title: {
        text: 'Number of Searches'
      }
    },
    xAxis: {
      categories: convertDatesArray(data.period)
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle'
    },
    series,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom'
            }
          }
        }
      ]
    }
  }
}

export const getLeadPerformanceOptions = data => {
  if (isEmpty(data)) {
    return {}
  }

  const series = [
    data.leads
      ? {
          name: 'Leads',
          color: '#82b2f2',
          data: roundArrayValues(data.leads)
        }
      : null,
    data.qualifiedLeads
      ? {
          name: 'Qualified Leads',
          color: '#000',
          data: roundArrayValues(data.qualifiedLeads)
        }
      : null
  ].filter(item => item !== null)

  return {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Lead Conversion'
    },
    yAxis: {
      title: {
        text: 'Number of Leads'
      }
    },
    plotOptions: {
      column: {
        stacking: 'value'
      }
    },
    xAxis: {
      categories: convertDatesArray(data.period)
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },
    series
  }
}

export const getPricePerformanceOptions = (data, location, marketName) => {
  if (isEmpty(data)) {
    return {}
  }

  const [locationName, neighborhoodName] = getSeriesNames(location)

  const series = [
    data.location
      ? {
          name: locationName,
          color: '#82b2f2',
          data: roundArrayValues(data.location)
        }
      : null,
    data.vicinity
      ? {
          name: '3 closest',
          color: '#000',
          data: roundArrayValues(data.vicinity)
        }
      : null,
    data.neighborhood
      ? {
          name: neighborhoodName,
          color: '#94ed6b',
          data: roundArrayValues(data.neighborhood)
        }
      : null,
    data.market
      ? {
          name: marketName,
          color: '#f0a84e',
          data: roundArrayValues(data.market)
        }
      : null
  ].filter(item => item !== null)

  return {
    yAxis: {
      title: {
        text: '$ Price'
      }
    },
    xAxis: {
      categories: convertDatesArray(data.period)
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },
    series
  }
}

export const getSuiteSizeOptions = (data, location, marketName) => {
  if (isEmpty(data)) {
    return {}
  }

  const [locationName, neighborhoodName] = getSeriesNames(location)

  const series = [
    data.location
      ? {
          name: locationName,
          color: '#82b2f2',
          data: roundArrayValues(data.location)
        }
      : null,
    data.vicinity
      ? {
          name: '3 closest',
          color: '#000',
          data: roundArrayValues(data.vicinity)
        }
      : null,
    data.neighborhood
      ? {
          name: neighborhoodName,
          color: '#94ed6b',
          data: roundArrayValues(data.neighborhood)
        }
      : null,
    data.market
      ? {
          name: marketName,
          color: '#f0a84e',
          data: roundArrayValues(data.market)
        }
      : null
  ].filter(item => item !== null)

  return {
    yAxis: {
      title: {
        text: 'Sq Ft'
      }
    },
    xAxis: {
      categories: convertDatesArray(data.period)
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom'
    },
    series
  }
}
