import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  SPACES_BY_LOC_ID_GET_REQUEST,
  SPACES_BY_LOC_ID_GET_SUCCESS,
  SPACES_BY_LOC_ID_GET_FAILURE,
  SPACES_CREATE_REQUEST,
  SPACES_CREATE_SUCCESS,
  SPACES_CREATE_FAILURE,
  SPACES_UPDATE_REQUEST,
  SPACES_UPDATE_SUCCESS,
  SPACES_UPDATE_FAILURE,
  SPACES_DELETE_REQUEST,
  SPACES_DELETE_SUCCESS,
  SPACES_DELETE_FAILURE,
  AVAILABLE_SPACES_REQUEST,
  AVAILABLE_SPACES_SUCCESS,
  AVAILABLE_SPACES_FAILURE
} from '../constants'
import * as api from '../api/spaces'
import { errorHandler, normalizeSpaceObject } from '../helpers/spaces'
import { getTokenWithExpiration } from './auth'

const spacesByLocationIdRequest = () => ({
  type: SPACES_BY_LOC_ID_GET_REQUEST
})

const spacesByLocationIdSuccess = (config, payload) => ({
  type: SPACES_BY_LOC_ID_GET_SUCCESS,
  payload,
  config
})

const spacesByLocationIdFailure = payload => ({
  type: SPACES_BY_LOC_ID_GET_FAILURE,
  payload
})

export const spacesByLocationIdGet = id => (dispatch, getState) => {
  const state = getState()
  if (state.spaces.byLocationId[id]) {
    return null
  }

  dispatch(spacesByLocationIdRequest())

  return api
    .spacesByLocationIdGet(id)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(spacesByLocationIdSuccess(id, response))
    })
    .catch(error => {
      if (error) {
        dispatch(spacesByLocationIdFailure(`${error.status}`))
      }
    })
}

const spacesCreateRequest = () => ({
  type: SPACES_CREATE_REQUEST
})

const spacesCreateSuccess = payload => ({
  type: SPACES_CREATE_SUCCESS,
  payload
})

const spacesCreateFailure = payload => ({
  type: SPACES_CREATE_FAILURE,
  payload
})

export const spaceCreate = formData => (dispatch, getState) => {
  dispatch(spacesCreateRequest())

  const data = normalizeSpaceObject(formData)

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .spaceCreate(token, data)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(spacesCreateSuccess(response))

          return response.id
        })
        .catch(error => {
          if (error) {
            dispatch(spacesCreateFailure(errorHandler(error)))
            // eslint-disable-next-line no-alert
            window.alert(errorHandler(error))
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const spacesUpdateRequest = () => ({
  type: SPACES_UPDATE_REQUEST
})

const spacesUpdateSuccess = payload => ({
  type: SPACES_UPDATE_SUCCESS,
  payload
})

const spacesUpdateFailure = payload => ({
  type: SPACES_UPDATE_FAILURE,
  payload
})

export const spaceUpdate = formData => (dispatch, getState) => {
  dispatch(spacesUpdateRequest())
  const data = normalizeSpaceObject(formData)

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .spaceUpdate(token, formData.id, data)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(spacesUpdateSuccess(response))

          return true
        })
        .catch(error => {
          if (error) {
            dispatch(spacesUpdateFailure(errorHandler(error)))
            // eslint-disable-next-line no-alert
            window.alert(errorHandler(error))
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const spacesDeleteRequest = () => ({
  type: SPACES_DELETE_REQUEST
})

const spacesDeleteSuccess = payload => ({
  type: SPACES_DELETE_SUCCESS,
  payload
})

const spacesDeleteFailure = () => ({
  type: SPACES_DELETE_FAILURE
})

export const spaceDelete = space => (dispatch, getState) => {
  dispatch(spacesDeleteRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .spaceDelete(token, space.id)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(() => {
          dispatch(spacesDeleteSuccess(space))

          return true
        })
        .catch(error => {
          if (error) {
            dispatch(spacesDeleteFailure(errorHandler(error)))
            // eslint-disable-next-line no-alert
            window.alert(errorHandler(error))
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const availableSpacesGetRequest = (payload) => ({
  type: AVAILABLE_SPACES_REQUEST,
  payload
})

const availableSpacesGetSuccess = (config, payload) => ({
  type: AVAILABLE_SPACES_SUCCESS,
  payload,
  config
})

const availableSpacesGetFailure = payload => ({
  type: AVAILABLE_SPACES_FAILURE,
  payload
})

export const availableSpacesGet = id => (dispatch, getState) => {
  if (getState().spaces.available.byLocationId[id]) {
    return null
  }

  dispatch(availableSpacesGetRequest(id))

  return api
    .availableSpacesByLocIdGet(id)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(availableSpacesGetSuccess(id, response))
    })
    .catch(error => {
      if (error) {
        dispatch(availableSpacesGetFailure(id, `${error.status}`))
      }
    })
}
