import cloneDeep from 'lodash/cloneDeep'

const colors = [
  '#e7483d',
  '#d04137',
  '#bb3b32',
  '#a6342c',
  '#952f28',
  '#852a23',
  '#782620',
  '#6a221c',
  '#5f1f19',
  '#551b16',
  '#4d1814',
  '#441612',
  '#66322d',
  '#774945',
  '#220b09',
  '#d04137',
  '#d5544b',
  '#d9675f',
  '#de7a73',
  '#e38d87',
  '#e8a09b',
  '#150605'
]

export const getPieOptions = data => {
  const seriesData = cloneDeep(data)
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
      height: 'auto'
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: 'pointer',
        colors: colors,
        dataLabels: {
          enabled: true,
          align: 'left',
          format: '{point.value:,.0f}<br/>{point.name}',
          filter: {
            property: 'percentage',
            operator: '>',
            value: 1
          }
        },
        showInLegend: true
      }
    },
    series: [seriesData]
  }
  return options
}
