import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import Flex from '../../components/flex'
import Breadcrumbs from '../../containers/Breadcrumbs'
import { leadsGet } from '../../actions/leads'
import * as Styled from '../../components/leads'
import Spinner from '../../components/spinner'
import { leadsDate } from '../../helpers/leads'
import usePrevious from '../../hooks/use-previous'
import LeadActions from '../../components/lead-actions'
import Proposal from '../../containers/Proposal'
import { openSuspendedModal, hideModal } from '../../actions/modal'

const Leads = props => {
  const dispatch = useDispatch()
  const colRef = useRef()
  const tableRef = useRef()
  const { locationId, marketId } = props.match.params
  const prevLocId = usePrevious(locationId)
  const prevMarId = usePrevious(marketId)
  const [orderBy, setOrderBy] = useState(undefined)
  const [orderDesc, setOrderDesc] = useState(true)
  const { flexOfficePro } = useSelector(state => state.user)
  const isAdvancedView =
    flexOfficePro === 'Enterprise' || flexOfficePro === 'Advanced'
  const isBasicView = flexOfficePro === 'Basic'
  const {
    list: leads,
    total,
    status: { loading },
    error: { loading: error }
  } = useSelector(state => state.leads)
  const showActionsCol = locationId && locationId !== 'all'
  const { byId: locationsById } = useSelector(state => state.locations)
  const location = locationsById[locationId]
  // const isSuspended = location?.listingStatus === 'Suspended'
  // Excluding from Operator Response deploy
  const isSuspended = false

  const perPage = 20
  const [page, setPage] = useState(1)

  useEffect(() => {
    const sortBy = orderBy
    const order = orderDesc ? 'desc' : 'asc'
    const market = marketId === 'all' ? undefined : marketId
    const location = locationId === 'all' ? undefined : locationId

    if (prevMarId !== marketId || prevLocId !== locationId) {
      dispatch(
        leadsGet({
          location,
          market,
          page: 1,
          perPage,
          sortBy: undefined,
          order: 'desc'
        })
      )
      return
    }

    dispatch(leadsGet({ location, market, page, perPage, sortBy, order }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locationId, marketId, page, perPage, orderBy, orderDesc])

  useEffect(() => {
    if (isSuspended) {
      dispatch(openSuspendedModal({ marketId, locationId }))
    }

    return () => dispatch(hideModal())
  }, [dispatch, isSuspended, marketId, locationId])

  const sortBy = by => {
    const isDesc = by === orderBy && !orderDesc ? !orderDesc : false
    setOrderBy(by)
    setOrderDesc(isDesc)
  }

  const fixActionColumns = scrollPos => {
    if (colRef.current) {
      colRef.current.style.right = `${scrollPos - 1}px`
    }

    tableRef.current.querySelectorAll('[data-attach-sticky-ref]').forEach(e => {
      e.style.right = `${scrollPos - 1}px`
    })
  }

  // Fixed right column implementation
  useEffect(() => {
    let lastKnownScrollPosition = 0
    let ticking = false

    if ((loading || total > 0) && !error && showActionsCol) {
      const element = tableRef.current
      const handleScrollPosition = e => {
        lastKnownScrollPosition =
          e.target.scrollWidth - e.target.clientWidth - e.target.scrollLeft

        if (!ticking) {
          window.requestAnimationFrame(() => {
            fixActionColumns(lastKnownScrollPosition)
            ticking = false
          })

          ticking = true
        }
      }

      const handleResizePosition = () => {
        lastKnownScrollPosition =
          element.scrollWidth - element.clientWidth - element.scrollLeft

        if (!ticking) {
          window.requestAnimationFrame(() => {
            fixActionColumns(lastKnownScrollPosition)
            ticking = false
          })

          ticking = true
        }
      }

      // initial set
      lastKnownScrollPosition =
        element.scrollWidth - element.clientWidth - element.scrollLeft
      fixActionColumns(lastKnownScrollPosition)

      // on scroll
      element.addEventListener('scroll', handleScrollPosition)

      // on resize
      window.addEventListener('resize', handleResizePosition)

      return () => [
        element.removeEventListener('scroll', handleScrollPosition),
        window.removeEventListener('resize', handleResizePosition)
      ]
    }
  }, [loading, total, error, showActionsCol])

  return (
    <Flex fullWidth column full data-suspended={isSuspended}>
      <Proposal locationId={locationId} />
      <Breadcrumbs childRoute="Leads" />
      <Flex
        fullWidth
        column
        center
        middle
        data-relative={isSuspended}
        data-status={location?.listingStatus || false}
      >
        {!loading && total === 0 && (
          <Styled.Wrapper>
            <Flex fullWidth center>
              <span>This location doesn{"'"}t have any leads</span>
            </Flex>
          </Styled.Wrapper>
        )}
        {(loading || total > 0) && !error && (
          <Styled.Wrapper>
            <Styled.List ref={tableRef}>
              <Styled.Item sticky>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'notification_type'}
                    desc={orderDesc}
                    onClick={() => sortBy('notification_type')}
                  >
                    Lead Owner
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'first_name'}
                    desc={orderDesc}
                    onClick={() => sortBy('first_name')}
                  >
                    Name
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'email'}
                    desc={orderDesc}
                    onClick={() => sortBy('email')}
                  >
                    Email
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'phone_number'}
                    desc={orderDesc}
                    onClick={() => sortBy('phone_number')}
                  >
                    Phone
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'company'}
                    desc={orderDesc}
                    onClick={() => sortBy('company')}
                  >
                    Company
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'created_at'}
                    desc={orderDesc}
                    onClick={() => sortBy('created_at')}
                  >
                    Date registered
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'seats'}
                    desc={orderDesc}
                    onClick={() => sortBy('seats')}
                  >
                    Seats needed
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'move_in_date'}
                    desc={orderDesc}
                    onClick={() => sortBy('move_in_date')}
                  >
                    Move in Date
                  </Styled.Filter>
                </Styled.Col>
                {isBasicView && (
                  <>
                    <Styled.Col>
                      <Styled.Filter
                        active={orderBy === 'lead_status'}
                        desc={orderDesc}
                        onClick={() => sortBy('lead_status')}
                      >
                        Lead State
                      </Styled.Filter>
                    </Styled.Col>
                    <Styled.Col>
                      <Styled.Filter
                        active={orderBy === 'location_names'}
                        desc={orderDesc}
                        onClick={() => sortBy('location_names')}
                      >
                        Location Name
                      </Styled.Filter>
                    </Styled.Col>
                    <Styled.Col>
                      <Styled.Filter
                        active={orderBy === 'modified_at'}
                        desc={orderDesc}
                        onClick={() => sortBy('modified_at')}
                      >
                        Last Modified Date
                      </Styled.Filter>
                    </Styled.Col>
                  </>
                )}
                {isAdvancedView && (
                  <>
                    <Styled.Col>
                      <Styled.Filter
                        active={orderBy === 'leadStatus'}
                        desc={orderDesc}
                        onClick={() => sortBy('leadStatus')}
                      >
                        Lead Status
                      </Styled.Filter>
                    </Styled.Col>
                    <Styled.Col>
                      <Styled.Filter
                        active={orderBy === 'location_count'}
                        desc={orderDesc}
                        onClick={() => sortBy('location_count')}
                      >
                        # of locations considering
                      </Styled.Filter>
                    </Styled.Col>
                    <Styled.Col>
                      <Styled.Filter
                        active={orderBy === 'location_names'}
                        desc={orderDesc}
                        onClick={() => sortBy('location_names')}
                      >
                        Location Name
                      </Styled.Filter>
                    </Styled.Col>
                  </>
                )}
                {showActionsCol && (
                  <>
                    <Styled.Col data-fixed data-column-prop="status_note" data-mobile-hidden></Styled.Col>
                    <Styled.Col data-fixed data-mobile-hidden></Styled.Col>
                    <Styled.Col
                      data-fixed
                      data-mobile-hidden
                      data-fixed-cell-actions
                    ></Styled.Col>
                    <Styled.Fixed data-header ref={colRef}>
                      <Styled.Col
                        data-fixed
                        data-fixed-header
                        data-column-prop="status_note"
                      >
                        <Styled.Filter
                          active={orderBy === 'status_note'}
                          desc={orderDesc}
                          onClick={() => sortBy('status_note')}
                        >
                          Lead Notes
                        </Styled.Filter>
                      </Styled.Col>
                      <Styled.Col
                        data-fixed-actions
                        data-fixed
                        data-fixed-header
                      >
                        <span>Propose</span>
                      </Styled.Col>
                      <Styled.Col
                        data-fixed-actions
                        data-fixed
                        data-fixed-header
                      >
                        <span>Reject</span>
                      </Styled.Col>
                    </Styled.Fixed>
                  </>
                )}
              </Styled.Item>
              {leads.map((item, index) => (
                <Styled.Item key={`${item.email}-${index}`}>
                  <Styled.Col data-column-prop="notification_type">
                    <span>
                      {(item.notification_type || '').replace('Managed', '')}
                    </span>
                  </Styled.Col>
                  <Styled.Col data-column-prop="first_name last_name">
                    {(!isEmpty(item.first_name) ||
                      !isEmpty(item.last_name)) && (
                      <span>{`${item.first_name || ''} ${
                        item.last_name || ''
                      }`}</span>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="email">
                    {item.email && (
                      <a data-email href={`mailto:${item.email}`}>
                        <span>{item.email}</span>
                      </a>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="phone_number">
                    {item.phone_number && (
                      <a data-phone href={`tel:${item.phone_number}`}>
                        <span>{item.phone_number}</span>
                      </a>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="company">
                    {!isEmpty(item.company) && (
                      <span>{item.company || ''}</span>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="created_at">
                    {!isEmpty(item.created_at) && (
                      <span>{leadsDate(item.created_at)}</span>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="seats">
                    {isNumber(item.seats) && <span>{item.seats || ''}</span>}
                  </Styled.Col>
                  <Styled.Col data-column-prop="move_in_date">
                    {!isEmpty(item.move_in_date) && (
                      <span>{leadsDate(item.move_in_date)}</span>
                    )}
                  </Styled.Col>
                  {isBasicView && (
                    <>
                      <Styled.Col data-column-prop="lead_status">
                        <span>{item.lead_status || ''}</span>
                      </Styled.Col>
                      <Styled.Col data-column-prop="location_names">
                        <span>{item.location_names || ''}</span>
                      </Styled.Col>
                      <Styled.Col data-column-prop="modified_at">
                        {!isEmpty(item.modified_at) && (
                          <span>{leadsDate(item.modified_at)}</span>
                        )}
                      </Styled.Col>
                    </>
                  )}
                  {isAdvancedView && (
                    <>
                      <Styled.Col data-column-prop="lead_status">
                        <span>{item.lead_status || ''}</span>
                      </Styled.Col>
                      <Styled.Col data-column-prop="location_count">
                        {isNumber(item.location_count) && (
                          <span>{item.location_count || ''}</span>
                        )}
                      </Styled.Col>
                      <Styled.Col data-column-prop="location_names">
                        <span>{item.location_names || ''}</span>
                      </Styled.Col>
                    </>
                  )}
                  {showActionsCol && (
                    <>
                      <Styled.Col data-fixed data-column-prop="status_note" data-hidden data-mobile-hidden>
                        <span>{item.status_note || ''}</span>
                      </Styled.Col>
                      <Styled.Col
                        data-fixed
                        data-hidden
                        data-mobile-hidden
                      ></Styled.Col>
                      <Styled.Col
                        data-fixed
                        data-fixed-cell-actions
                        data-hidden
                        data-mobile-hidden
                      ></Styled.Col>
                      <Styled.Fixed data-attach-sticky-ref>
                        <Styled.Col
                          data-fixed
                          data-fixed-cell
                          data-column-prop="status_note"
                        >
                          <span>{item.status_note || ''}</span>
                        </Styled.Col>
                        <Styled.Col
                          data-fixed
                          data-fixed-cell
                          data-fixed-cell-actions
                          data-column-prop="actions"
                        >
                          {item.notification_type === 'UpsuiteManaged' &&
                            !item.is_rejected && (
                              <LeadActions
                                isProposal
                                lead={item}
                                locationId={locationId}
                              />
                            )}
                        </Styled.Col>
                        <Styled.Col
                          data-fixed
                          data-fixed-cell
                          data-fixed-cell-actions
                          data-column-prop="actions"
                        >
                          {item.is_rejected ? (
                            <span>Lead Rejected</span>
                          ) : (
                            <LeadActions lead={item} locationId={locationId} />
                          )}
                        </Styled.Col>
                      </Styled.Fixed>
                    </>
                  )}
                </Styled.Item>
              ))}
            </Styled.List>
          </Styled.Wrapper>
        )}
      </Flex>

      <Loading>{loading && <Spinner elSize={30} />}</Loading>

      {total > perPage && (
        <Pagination>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'is-break'}
            pageCount={Math.ceil((total || 0) / perPage)}
            initialPage={0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => [setPage(selected + 1)]}
            disableInitialCallback
            containerClassName={'pagination-leads'}
            activeClassName={'is-active'}
          />
        </Pagination>
      )}
    </Flex>
  )
}

const Loading = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
`

const Pagination = styled.div`
  width: 100%;

  .pagination-leads {
    list-style: none;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    font-size: 1.3rem;

    a {
      min-width: 30px;
      width: 100%;
      display: block;
      text-align: center;
      cursor: pointer;
      padding: 5px;
    }

    .previous.disabled,
    .next.disabled {
      display: none;
    }

    .is-active a {
      color: red;
    }
  }
`

export default Leads
