import React from 'react';
import styled from 'styled-components/macro';
import { lgUp } from '../../styles/breakpoints';
import { ClickableArea, BoxArea, Heading, Text } from '../landing';

const Wrapper = styled.div`
  width: 100%;
`;

const Box = styled.div`
  position: relative;
  z-index: 1;
  padding: 0;
  margin: 0;
  backface-visibility: hidden;
`;

const Inner = styled.div`
  position: relative;
  perspective: 1000px;
`;

export const Front = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  transition-duration: 0.4s;
  position: absolute;
  transition: transform .4s cubic-bezier(.2,.85,.4,1.275);
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  perspective: 1000px;

  @media ${lgUp} {
    transform: rotateY(0);
    z-index: 10;

    ${Box}:hover & {
      z-index: -1;
      transform: rotateY(-180deg);
    }
  }

  svg {
    position: absolute;
    width: calc(100% - 50px);
    bottom: 50px;
  }

  backface-visibility: hidden;
  background-clip: padding-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
`;

export const Back = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : 'white')};
  transition-duration: 0.4s;
  bottom: 0px;
  transition: transform .4s cubic-bezier(.2,.85,.4,1.275);
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  perspective: 1000px;

  @media ${lgUp} {
    z-index: -1;
    transform: rotateY(-180deg);

    ${Box}:hover & {
      z-index: 1;
      transform: rotateY(0);
    }
  }

  ${BoxArea} > ${Text} {
    color: black;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  ${ClickableArea} > ${Text} {
    color: white;
  }

  backface-visibility: hidden;
  background-clip: padding-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
`;

export const FlipBox = ({ children, title, icon: Icon, locked, onClick }) => (
  <Wrapper>
    <Box>
      <Inner>
        <Front bgColor={locked ? 'lightgray' : '#57AB83'}>
          <BoxArea>
            <Heading color={locked ? 'black' : 'white'}>{title}</Heading>
            <Icon color={locked ? 'black' : 'white'} />
          </BoxArea>
        </Front>
        <Back bgColor={locked ? 'lightgray' : '#57AB83'}>
          {locked ? (
            <BoxArea>
              <Heading color="black">{title}</Heading>
              {children}
            </BoxArea>
          ) : (
            <ClickableArea type="button" onClick={onClick}>
              <Heading color="white">{title}</Heading>
              {children}
            </ClickableArea>
          )}
        </Back>
      </Inner>
    </Box>
  </Wrapper>
);
