import React from 'react';
import Flex from '../../components/flex';
import { Container } from '../../components/login';

const GetFlex = () => {
  return (
    <Flex fullWidth fullHeight center middle>
      <Container>
        <h3>Thank you for your interest in FlexOfficePro. Please contact sales at <a href="mailto:support@upsuite.com">support@upsuite.com</a> to purchase a subscription or for more information about the product.</h3>
      </Container>
    </Flex>
  );
};

export default GetFlex;
