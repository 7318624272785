import React from 'react'
import { Toggle, Input, Label, Wrapper, LabelText } from './styles'

const SlideToggle = props => {
  const { id, label, name, value, onChange, required, disabled } = props

  return (
    <Wrapper>
      <Label htmlFor={id}>
        {label && (
          <LabelText>{label}</LabelText>
        )}
        <Input
          type="checkbox"
          checked={!!value}
          name={name}
          id={id}
          value={!!value}
          onChange={onChange}
          required={required}
          disabled={disabled}
        />
        <Toggle checked={value} />
      </Label>
    </Wrapper>
  )
}

export default SlideToggle
