import styled from 'styled-components/macro';
import { mdUp } from '../../styles/breakpoints';

export const Wrapper = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0 25px 25px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 50px);
  flex-shrink: 0;

  @media ${mdUp} {
    width: 240px;

    &[data-width="md"] {
      width: 260px;
    }

    &[data-width="sm"] {
      width: 190px;
    }
  }

  li {
    min-height: 44px;
    display: flex;
    align-items: center;
    position: relative;

    a,
    button,
    span {
      color: #000;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      border: 1px solid transparent;

      &:not(span):hover {
        border-radius: 22px;
        background-color: #f5f5f5;
      }

      &:not(span).active {
        border-radius: 22px;
        background-color: #e4e4e4;
        border-color: #828282;
      }
    }
  }
`;

export const Item = styled.li`
  font-size: 20px;
  font-weight: 800;
  padding: 10px;

  button {
    font-weight: 800;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  a,
  button {
    padding: 0 10px;
  }

  svg {
    height: 20px;
    margin-right: 10px;
  }
`;

export const SubItem = styled.li`
  font-size: 16px;
  padding-left: 30px;

  a,
  button,
  span {
    padding: 0 20px;
  }

  svg {
    height: 20px;
    width: 25px;
    margin-right: 10px;
  }

  &[data-indent="true"] {
    margin-left: 20px;
  }
`;
