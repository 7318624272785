import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useFormik } from 'formik';
import Flex from '../../components/flex';

import * as Styled from '../../components/space-form';
import {
  Wrapper,
  Form as FormWrapper,
  Row,
  Submit,
  Error,
} from '../../components/promo-form';
import { smOnly } from '../../styles/breakpoints';

const Form = props => {
  const formik = useFormik({
    initialValues: {
      picture: '',
    },
    validate: values => {
      const errors = {};
      if (values.picture.size > 102400) {
        errors.picture = 'Maximum picture size 100kb';
      }

      return errors;
    },
    onSubmit: props.onSubmit,
  });

  const mobile = useMediaQuery({ query: smOnly });
  return (
    <Wrapper>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Flex flexWrap fullWidth>
          <Row>
            <Styled.Col maxWidth={!mobile ? '50%' : '100%'}>
              <Styled.Label htmlFor="picture">
                <Styled.LabelText>Profile Picture</Styled.LabelText>
                <input
                  type="file"
                  id="picture"
                  name="picture"
                  accept=".jpg, .jpeg, .png"
                  onChange={event => {
                    formik.setFieldValue(
                      'picture',
                      event.currentTarget.files[0],
                    );
                  }}
                />
                {formik.errors.picture && (
                  <Error>{formik.errors.picture}</Error>
                )}
              </Styled.Label>
            </Styled.Col>
          </Row>
          <Row>
            <Styled.Col maxWidth="100%">
              <Submit type="submit">Submit</Submit>
            </Styled.Col>
          </Row>
        </Flex>
      </FormWrapper>
    </Wrapper>
  );
};

export default Form;
