import styled from 'styled-components';
import { lgUp } from '../../styles/breakpoints';

export const Wrap = styled.div`
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;

  ${props => props.height && `height: ${props.height}px;`}

  > div {
    height: 100%;
  }

  .highcharts-credits {
    display: none;
  }

  div[data-highcharts-chart] {
    width: 100%;
  }
`;

export const Col = styled.div`
  ${props => props.center && `
    @media ${lgUp} {
      margin-left: auto;
      margin-right: auto;
    }
  `}

  @media ${lgUp} {
    ${props => props.withWidth && `max-width: ${props.withWidth};`}
  }
`;
