import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { stringify } from 'qs'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import { Wrap } from '../../components/chart/wrap'
import {
  NumbersWrapper,
  Number,
  SubLabel
} from '../../components/views-in-search'

import { viewsInSearchGet } from '../../actions/views-in-search'
import { getViewsInSearchOptions } from '../../settings/chart-settings'
import { smOnly, mdOnly } from '../../styles/breakpoints'

const Chart = lazy(() => import('../../components/chart'))
const sum = arr => arr.reduce((a, b) => a + b, 0)

const ViewsInSearch = () => {
  const { locationId, marketId } = useParams()
  const { byRoute } = useSelector(state => state.viewsInSearch)
  const market = useSelector(state => state.markets.byId[marketId])
  const { byId } = useSelector(state => state.locations)
  const dispatch = useDispatch()
  const params = stringify({ market: marketId, location: locationId })
  const mobile = useMediaQuery({ query: smOnly })
  const tablet = useMediaQuery({ query: mdOnly })

  useEffect(() => {
    if (!byRoute[params]) {
      dispatch(viewsInSearchGet(params))
    }
  }, [dispatch, byRoute, params])

  const diff =
    byRoute[params] && sum(byRoute[params].vicinity) !== 0
      ? ((sum(byRoute[params].location) - sum(byRoute[params].vicinity)) /
          sum(byRoute[params].vicinity)) *
        100
      : 0

  const rounded = Math.round(diff)

  return (
    <Flex fullWidth>
      <Wrap>
        <Suspense
          fallback={
            <Flex fullWidth fullHeight center>
              <Spinner elSize={40} />
            </Flex>
          }
        >
          <Flex
            fullWidth
            alignStart={!mobile && !tablet}
            column={mobile || tablet}
          >
            {byRoute[params] && byRoute[params].vicinity && (
              <NumbersWrapper height={330}>
                <Number>{Math.round(sum(byRoute[params].location))}</Number>
                <SubLabel>views in search for location</SubLabel>

                <Number>{Math.round(sum(byRoute[params].market))}</Number>
                <SubLabel>
                  average views per location, for all locations in the market
                </SubLabel>

                {rounded > 0 && isFinite(rounded) && (
                  <>
                    <Number>{rounded}%</Number>
                    <SubLabel>
                      more views in search than 3 nearest
                      <br />
                      locations
                    </SubLabel>
                  </>
                )}
                {rounded < 0 && isFinite(rounded) && (
                  <>
                    <Number>{rounded * -1}%</Number>
                    <SubLabel>
                      less views in search than 3 nearest
                      <br />
                      locations
                    </SubLabel>
                  </>
                )}
              </NumbersWrapper>
            )}
            <Chart
              options={getViewsInSearchOptions(
                byRoute[params],
                byId[locationId],
                market?.name
              )}
            />
          </Flex>
        </Suspense>
      </Wrap>
    </Flex>
  )
}

export default ViewsInSearch
