import { createReducer } from '../utils/redux';
import {
  PIPELINE_GET_REQUEST,
  PIPELINE_GET_SUCCESS,
  PIPELINE_GET_FAILURE,
} from '../constants';

const initialState = {
  byRoute: {},
  status: {
    error: false,
    errorText: null,
    loading: false,
    completed: false,
  },
};

export default createReducer(initialState, {
  [PIPELINE_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true,
    },
  }),
  [PIPELINE_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byRoute: {
      ...state.byRoute,
      [payload.params]: payload.data,
    },
    status: {
      ...initialState.status,
      loading: false,
      completed: true,
    },
  }),
  [PIPELINE_GET_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      completed: true,
      error: true,
      errorText: payload,
    },
  }),
});
