/* global URL */
import { checkHttpStatus, parseJSON } from '../utils/api';
import {
  AUTHCHECK_REQUEST,
  AUTHCHECK_SUCCESS,
  AUTHCHECK_FAILURE,
  PROFILE_PIC_GET_REQUEST,
  PROFILE_PIC_GET_SUCCESS,
  PROFILE_PIC_GET_FAILURE,
  PROFILE_PIC_UPDATE_REQUEST,
  PROFILE_PIC_UPDATE_SUCCESS,
  PROFILE_PIC_UPDATE_FAILURE,
} from '../constants';
import * as api from '../api/users';

const authCheckRequest = () => ({
  type: AUTHCHECK_REQUEST,
});

const authCheckSuccess = payload => ({
  type: AUTHCHECK_SUCCESS,
  payload,
});

const authCheckFailure = payload => ({
  type: AUTHCHECK_FAILURE,
  payload,
});

export const authCheckInfo = (token) =>
  (dispatch) => {
    dispatch(authCheckRequest());

    return api.authcheckInfo(token)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        dispatch(authCheckSuccess(response));
      })
      .catch((error) => {
        if (error) {
          parseJSON(error).then(message => {
            dispatch(authCheckFailure(`${error.status}: ${message}`));
          });
        } else {
          dispatch(authCheckFailure('AuthcheckInfo unknown error'));
        }
      });
  };

const profilePicGetRequest = () => ({
  type: PROFILE_PIC_GET_REQUEST,
});

const profilePicGetSuccess = payload => ({
  type: PROFILE_PIC_GET_SUCCESS,
  payload,
});

const profilePicGetFailure = () => ({
  type: PROFILE_PIC_GET_FAILURE,
});

export const profilePicGet = token => (dispatch, getState) => {
  if (getState().user.completed) {
    return null;
  }

  dispatch(profilePicGetRequest());

  return api
    .getProfilePic(token)
    .then(checkHttpStatus)
    .then(response => response.blob())
    .then(blob => {
      const objectURL = URL.createObjectURL(blob);
      dispatch(profilePicGetSuccess(objectURL));
    })
    .catch(() => {
      dispatch(profilePicGetFailure());
    });
};

const profilePicUpdateRequest = () => ({
  type: PROFILE_PIC_UPDATE_REQUEST,
});

const profilePicUpdateSuccess = payload => ({
  type: PROFILE_PIC_UPDATE_SUCCESS,
  payload,
});

const profilePicUpdateFailure = () => ({
  type: PROFILE_PIC_UPDATE_FAILURE,
});

export const profilePicUpdate = (file, contentType) => (dispatch, getState) => {
  const {
    auth: { token },
  } = getState();

  if (!token) {
    return null;
  }

  dispatch(profilePicUpdateRequest());
  return api
    .updateProfilePic(token, file, contentType)
    .then(checkHttpStatus)
    .then(() => {
      dispatch(profilePicUpdateSuccess(file));
    })
    .catch(() => {
      dispatch(profilePicUpdateFailure());
    });
};
