import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  LOCATION_SERVICES_GET_REQUEST,
  LOCATION_SERVICES_GET_SUCCESS,
  LOCATION_SERVICES_GET_FAILURE,
  LOCATION_SERVICES_FILTER,
  LOCATION_SERVICES_CLEAR,
  LOCATION_SERVICES_LANDLORDS_REQUEST,
  LOCATION_SERVICES_LANDLORDS_SUCCESS,
  LOCATION_SERVICES_LANDLORDS_FAILURE,
  LOCATION_SERVICES_REMOVE,
  LOCATION_SERVICES_OPEN,
  LOCATION_SERVICES_CLOSE,
  LOCATION_SERVICES_CLOSED_GET_REQUEST,
  LOCATION_SERVICES_CLOSED_GET_SUCCESS,
  LOCATION_SERVICES_CLOSED_GET_FAILURE,
  HIGHLIGHT_MARKER,
  MAP_PINS_TO_FEED,
  LOCATION_SERVICES_TOGGLE_VIEW,
  MARKET_SUMMARY_GET_REQUEST,
  MARKET_SUMMARY_GET_SUCCESS,
  MARKET_SUMMARY_GET_FAIL,
  MARKET_LOCATIONS_DATA_REQUEST,
  MARKET_LOCATIONS_DATA_SUCCESS,
  MARKET_LOCATIONS_DATA_FAIL,
  LEAD_OWNERS_GET_REQUEST,
  LEAD_OWNERS_GET_SUCCESS,
  LEAD_OWNERS_GET_FAILURE,
  LEAD_LOCATIONS_GET_REQUEST,
  LEAD_LOCATIONS_GET_SUCCESS,
  LEAD_LOCATIONS_GET_FAILURE
} from '../constants'
import * as api from '../api/location-services'
import { getLandlordsAll } from '../api/landlord'
import { getTokenWithExpiration } from './auth'

const locationServicesGetRequest = () => ({
  type: LOCATION_SERVICES_GET_REQUEST
})

const locationServicesGetSuccess = payload => ({
  type: LOCATION_SERVICES_GET_SUCCESS,
  payload
})

const locationServicesGetFailure = () => ({
  type: LOCATION_SERVICES_GET_FAILURE
})

export const locationServicesGet = market => (dispatch, getState) => {
  dispatch(locationServicesGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLocations(token, market)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(locationServicesGetSuccess(response))
        })
        .catch(error => {
          if (error) {
            dispatch(locationServicesGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const closedLocationsGetRequest = () => ({
  type: LOCATION_SERVICES_CLOSED_GET_REQUEST
})

const closedLocationsGetSuccess = payload => ({
  type: LOCATION_SERVICES_CLOSED_GET_SUCCESS,
  payload
})

const closedLocationsGetFailure = () => ({
  type: LOCATION_SERVICES_CLOSED_GET_FAILURE
})

export const closedLocationsGet = (market, search) => (dispatch, getState) => {
  dispatch(closedLocationsGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getClosedLocations(token, market, search)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(closedLocationsGetSuccess(response))
        })
        .catch(error => {
          if (error) {
            dispatch(closedLocationsGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

export const locationServicesFilter = (payload, config) => ({
  type: LOCATION_SERVICES_FILTER,
  payload,
  config
})

export const locationServicesClear = () => ({
  type: LOCATION_SERVICES_CLEAR
})

const landlordsGetRequest = () => ({
  type: LOCATION_SERVICES_LANDLORDS_REQUEST
})

const landlordsGetSuccess = payload => ({
  type: LOCATION_SERVICES_LANDLORDS_SUCCESS,
  payload
})

const landlordsGetFailure = () => ({
  type: LOCATION_SERVICES_LANDLORDS_FAILURE
})

export const landlordsGet = () => (dispatch, getState) => {
  if (getState().locationServices.status.landlordsCompleted) {
    return null
  }

  dispatch(landlordsGetRequest())

  const getRecursive = (offset, token) => {
    return getLandlordsAll(offset, token)
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        dispatch(landlordsGetSuccess(response))

        if (offset + 200 >= response.total) {
          return null
        }

        getRecursive(offset + 200, token)
      })
      .catch(error => {
        if (error) {
          dispatch(landlordsGetFailure())
          // eslint-disable-next-line no-alert
          window.alert(error.status)
        }

        return null
      })
  }

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => getRecursive(0, token))
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
      return null
    })
}

export const locationServicesRemove = payload => ({
  type: LOCATION_SERVICES_REMOVE,
  payload
})

export const locationServicesOpen = payload => ({
  type: LOCATION_SERVICES_OPEN,
  payload
})

export const locationServicesClose = () => ({
  type: LOCATION_SERVICES_CLOSE
})

export const highlightMarker = payload => ({
  type: HIGHLIGHT_MARKER,
  payload
})

export const mapPinsToFeed = payload => ({
  type: MAP_PINS_TO_FEED,
  payload
})

export const toggleView = payload => ({
  type: LOCATION_SERVICES_TOGGLE_VIEW,
  payload
})

const marketSummaryRequest = () => ({
  type: MARKET_SUMMARY_GET_REQUEST
})

const marketSummarySuccess = (payload, config) => ({
  type: MARKET_SUMMARY_GET_SUCCESS,
  payload,
  config
})

const marketSummaryFailure = () => ({
  type: MARKET_SUMMARY_GET_FAIL
})

export const marketSummaryGet = market => (dispatch, getState) => {
  if (getState().locationServices.marketSummaryById[market]) {
    return null
  }

  dispatch(marketSummaryRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getMarketSummary(token, market)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(marketSummarySuccess(response, market))
        })
        .catch(error => {
          if (error) {
            dispatch(marketSummaryFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const marketLocationsDataRequest = () => ({
  type: MARKET_LOCATIONS_DATA_REQUEST
})

const marketLocationsDataSuccess = (payload, config) => ({
  type: MARKET_LOCATIONS_DATA_SUCCESS,
  payload,
  config
})

const marketLocationsDataFailure = () => ({
  type: MARKET_LOCATIONS_DATA_FAIL
})

export const marketLocationsDataGet = market => (dispatch, getState) => {
  if (getState().locationServices.marketLocationsDataById[market]) {
    return null
  }

  dispatch(marketLocationsDataRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getMarketLocationsData(token, market)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(marketLocationsDataSuccess(response, market))
        })
        .catch(error => {
          if (error) {
            dispatch(marketLocationsDataFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const leadOwnersGetRequest = () => ({
  type: LEAD_OWNERS_GET_REQUEST
})

const leadOwnersGetSuccess = payload => ({
  type: LEAD_OWNERS_GET_SUCCESS,
  payload
})

const leadOwnersGetFailure = () => ({
  type: LEAD_OWNERS_GET_FAILURE
})

export const leadOwnersGet = market => (dispatch, getState) => {
  if (getState().locationServices.leadOwners) {
    return null
  }

  dispatch(leadOwnersGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLeadOwners(token, market)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(leadOwnersGetSuccess(response, market))
        })
        .catch(error => {
          if (error) {
            dispatch(leadOwnersGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const leadLocationsGetRequest = () => ({
  type: LEAD_LOCATIONS_GET_REQUEST
})

const leadLocationsGetSuccess = (payload, config) => ({
  type: LEAD_LOCATIONS_GET_SUCCESS,
  payload,
  config
})

const leadLocationsGetFailure = () => ({
  type: LEAD_LOCATIONS_GET_FAILURE
})

export const leadLocationsGet = id => (dispatch, getState) => {
  if (getState().locationServices.leadLocationsById[id]) {
    return null
  }

  dispatch(leadLocationsGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLeadLocations(token, id)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(leadLocationsGetSuccess(response, id))
        })
        .catch(error => {
          if (error) {
            dispatch(leadLocationsGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
