import React, { lazy, Suspense, useEffect } from 'react'
import styled from 'styled-components/macro'
import * as Styled from '../widget'
import { useSelector, useDispatch } from 'react-redux'
import Flex from '../flex'
import Spinner from '../spinner'
import { getOptions } from './options'
import { monthlyLeadsGet } from '../../actions/broker-tools'

const Chart = lazy(() => import('../chart'))

const WidgetMonthlyLeads = () => {
  const dispatch = useDispatch()
  const { payload: monthlyLeadsYears, loading } = useSelector(state => state.brokerTools.monthlyLeadsCount)
  const monthlyLeadsLast = monthlyLeadsYears?.slice(-3)
  const count = monthlyLeadsLast?.map(item => item.count) || []
  const months = monthlyLeadsLast?.map(item => item.month) || []

  useEffect(() => {
    dispatch(monthlyLeadsGet())
  }, [dispatch])

  return (
    <Styled.Wrap>
      <Styled.Box data-hidden>
        <Styled.InsideTitle>Monthly Leads</Styled.InsideTitle>
        {loading && (
          <Flex fullWidth fullHeight center>
            <Spinner elSize={40} />
          </Flex>
        )}
        {count.length > 0 && (
          <ChartWrap>
            <Suspense
              fallback={
                <Flex fullWidth fullHeight center>
                  <Spinner elSize={40} />
                </Flex>
              }
            >
              <Chart options={getOptions(count, months)} />
            </Suspense>
          </ChartWrap>
        )}
      </Styled.Box>
    </Styled.Wrap>
  )
}

const ChartWrap = styled.div`
  width: 100%;

  .highcharts-credits {
    display: none;
  }
`

export default React.memo(WidgetMonthlyLeads)
