import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  INVENTORY_VACANCY_REQUEST,
  INVENTORY_VACANCY_SUCCESS,
  INVENTORY_VACANCY_FAILURE,
  FORECAST_REQUEST,
  FORECAST_SUCCESS,
  FORECAST_FAILURE,
  SUPPLYANDDEMAND_REQUEST,
  SUPPLYANDDEMAND_SUCCESS,
  SUPPLYANDDEMAND_FAILURE,
  SUMMERY_TEXT_REQUEST,
  SUMMERY_TEXT_SUCCESS,
  SUMMERY_TEXT_FAILURE,
  ASKING_PRICE_REQUEST,
  ASKING_PRICE_SUCCESS,
  ASKING_PRICE_FAILURE,
  ASKING_PRICE_PER_REQUEST,
  ASKING_PRICE_PER_SUCCESS,
  ASKING_PRICE_PER_FAILURE,
  DEMAND_DYNAMIX_REQUEST,
  DEMAND_DYNAMIX_SUCCESS,
  DEMAND_DYNAMIX_FAILURE,
  NEW_CLOSED_LOC_REQUEST,
  NEW_CLOSED_LOC_SUCCESS,
  NEW_CLOSED_LOC_FAILURE
} from '../constants'
import * as api from '../api/market-reports'
import { getTokenWithExpiration } from './auth'

const inventoryVacancyGetRequest = () => ({
  type: INVENTORY_VACANCY_REQUEST
})

const inventoryVacancyGetSuccess = (payload, config) => ({
  type: INVENTORY_VACANCY_SUCCESS,
  payload,
  config
})

const inventoryVacancyGetFailure = () => ({
  type: INVENTORY_VACANCY_FAILURE
})

export const inventoryVacancyGet = marketId => (dispatch, getState) => {
  if (getState().reports.inventory.byMarketId[marketId]) {
    return null
  }

  dispatch(inventoryVacancyGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getInventoryAndVacancy(token, marketId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(inventoryVacancyGetSuccess(response, marketId))
        })
        .catch(error => {
          if (error) {
            dispatch(inventoryVacancyGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const forecastGetRequest = () => ({
  type: FORECAST_REQUEST
})

const forecastGetSuccess = (payload, config) => ({
  type: FORECAST_SUCCESS,
  payload,
  config
})

const forecastGetFailure = () => ({
  type: FORECAST_FAILURE
})

export const forecastGet = marketId => (dispatch, getState) => {
  if (getState().reports.forecast.byMarketId[marketId]) {
    return null
  }

  dispatch(forecastGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getForecast(token, marketId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(forecastGetSuccess(response, marketId))
        })
        .catch(error => {
          if (error) {
            dispatch(forecastGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const supplyAndDemandGetRequest = () => ({
  type: SUPPLYANDDEMAND_REQUEST
})

const supplyAndDemandGetSuccess = (payload, config) => ({
  type: SUPPLYANDDEMAND_SUCCESS,
  payload,
  config
})

const supplyAndDemandGetFailure = () => ({
  type: SUPPLYANDDEMAND_FAILURE
})

export const supplyAndDemandGet = marketId => (dispatch, getState) => {
  if (getState().reports.supplyAndDemand.byMarketId[marketId]) {
    return null
  }

  dispatch(supplyAndDemandGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getSupplyanddemand(token, marketId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(supplyAndDemandGetSuccess(response, marketId))
        })
        .catch(error => {
          if (error) {
            dispatch(supplyAndDemandGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const priceGetRequest = () => ({
  type: ASKING_PRICE_REQUEST
})

const priceGetSuccess = (payload, config) => ({
  type: ASKING_PRICE_SUCCESS,
  payload,
  config
})

const priceGetFailure = () => ({
  type: ASKING_PRICE_FAILURE
})

export const priceGet = marketId => (dispatch, getState) => {
  if (getState().reports.price.byMarketId[marketId]) {
    return null
  }

  dispatch(priceGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getPrice(token, marketId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(priceGetSuccess(response, marketId))
        })
        .catch(error => {
          if (error) {
            dispatch(priceGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const demandDynamixGetRequest = () => ({
  type: DEMAND_DYNAMIX_REQUEST
})

const demandDynamixGetSuccess = (payload, config) => ({
  type: DEMAND_DYNAMIX_SUCCESS,
  payload,
  config
})

const demandDynamixGetFailure = () => ({
  type: DEMAND_DYNAMIX_FAILURE
})

export const demandDynamixGet = marketId => (dispatch, getState) => {
  if (getState().reports.demanddynamix.byMarketId[marketId]) {
    return null
  }

  dispatch(demandDynamixGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getDemandDynamix(token, marketId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(demandDynamixGetSuccess(response, marketId))
        })
        .catch(error => {
          if (error) {
            dispatch(demandDynamixGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const newClosedLocGetRequest = () => ({
  type: NEW_CLOSED_LOC_REQUEST
})

const newClosedLocGetSuccess = (payload, config) => ({
  type: NEW_CLOSED_LOC_SUCCESS,
  payload,
  config
})

const newClosedLocGetFailure = () => ({
  type: NEW_CLOSED_LOC_FAILURE
})

export const newClosedLocGet = marketId => (dispatch, getState) => {
  if (getState().reports.locations.byMarketId[marketId]) {
    return null
  }

  dispatch(newClosedLocGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getNewClosedLoc(token, marketId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(data => {
          dispatch(newClosedLocGetSuccess(data, marketId))
        })
        .catch(error => {
          if (error) {
            dispatch(newClosedLocGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const summeryTextGetRequest = () => ({
  type: SUMMERY_TEXT_REQUEST
})

const summeryTextGetSuccess = (payload, config) => ({
  type: SUMMERY_TEXT_SUCCESS,
  payload,
  config
})

const summeryTextGetFailure = () => ({
  type: SUMMERY_TEXT_FAILURE
})

export const summeryTextGet = (page, marketId) => (dispatch, getState) => {
  if (getState().reports.summeryText.byPageMarket[`${page}-${marketId}`]) {
    return null
  }

  dispatch(summeryTextGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getCustomText(token, page, marketId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(summeryTextGetSuccess(response, { page, marketId }))
        })
        .catch(error => {
          if (error) {
            dispatch(summeryTextGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const priceGetPerRequest = () => ({
  type: ASKING_PRICE_PER_REQUEST
})

const priceGetPerSuccess = (payload, config) => ({
  type: ASKING_PRICE_PER_SUCCESS,
  payload,
  config
})

const priceGetPerFailure = () => ({
  type: ASKING_PRICE_PER_FAILURE
})

export const priceGetPer = ({ marketId, spaceType, measure, unit }) => (
  dispatch,
  getState
) => {
  if (getState().reports.pricePerSpace.byMarketSpace[`${marketId}-${spaceType}-${measure}-${unit}`]) {
    return null
  }

  dispatch(priceGetPerRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getPricePerSpaceType({ token, marketId, spaceType, measure, unit })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(priceGetPerSuccess(response, `${marketId}-${spaceType}-${measure}-${unit}`))
        })
        .catch(error => {
          if (error) {
            dispatch(priceGetPerFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
