import React from 'react'
import styled, { css } from 'styled-components/macro'
import { smOnly, mdUp } from '../../styles/breakpoints'

const TextareaCounter = ({
  className,
  disabled,
  name,
  id,
  onChange,
  onBlur,
  value,
  maxLength,
  rows,
  placeholder,
  required,
  withCounter,
  absolute
}) => {
  return (
    <Wrapper>
      <Area
        className={className}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        placeholder={placeholder}
        value={value || ''}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
        absolute={absolute}
      />
      {withCounter && (
        <CounterWrap>
          <Counter>{`${(value || '').length || 0}/${maxLength}`}</Counter>
        </CounterWrap>
      )}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: ${props => props.withCounter ? 'inline-flex' : 'block'};
  max-width: 100%;
  width: 100%;
`

export const CounterWrap = styled.div`
  position: absolute;
  bottom: 1px;
  text-align: right;
  font-size: 13px;
  color: #585756;
  padding-top: 5px;
  padding-bottom: 2px;
  width: calc(100% - 20px);
  max-width: 100%;
  background: white;
  margin-left: 2px;
  background-color: transparent;
`

export const Counter = styled.span`
  font-size: inherit;
`

export const Area = styled.textarea`
  background-color: #fff;
  border: 1px solid transparent;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: ${props => props.withCounter ? '20px' : '5px'};
  padding-left: 0;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  color: #585756;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 0;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: 100%;
  background-color: #f8f8f9;
  border: 1px solid #d5d5d5;
  padding: 5px;

  @media ${smOnly} {
    background-color: #f8f8f9;
    border: 1px solid #d5d5d5;
    padding: 5px;
  }

  ${props => props.absolute && css`
    min-height: 190px;

    @media ${mdUp} {
      position: absolute;
      max-height: 350px;
    }
  `}
`

export default TextareaCounter
