import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

const MultiLineInput = (id, name) => {
  const lead = useSelector(state => state.brokerTools.leads.edit.lead)
  const leadLocations = useSelector(state => state.locationServices.leadLocationsById[lead.id])

  return (
    <>
      {leadLocations?.lead_locations.length > 0 && (
        <Row>
          <Label htmlFor="locations-added">
            <LabelText>Locations Added by Lead</LabelText>
            {(leadLocations?.lead_locations || []).map((item, inputIndex) => (
              <Row key={`${item}-${inputIndex}-added`}>
                <Input
                  type="text"
                  // id={`${id || ''}-${inputIndex}`}
                  name={`${name || ''}-${inputIndex}`}
                  defaultValue={item.name}
                  // onChange={}
                  // onBlur={formik.handleBlur}
                  disabled
                />
                {/* <Btn onClick={() => {
                  setInputs([...inputs, ''])
                }}>+</Btn>
                {inputs.length !== 1 && (
                  <Btn onClick={() => {
                    setInputs(inputs.filter((item, index) => index !== inputIndex))
                  }}>-</Btn>
                )} */}
              </Row>
            ))}
          </Label>
        </Row>
      )}
      {leadLocations?.sales_locations.length > 0 && (
        <Row>
          <Label htmlFor="locations-added">
            <LabelText>Locations Added by Lead</LabelText>
            {(leadLocations?.sales_locations || []).map((item, inputIndex) => (
              <Row key={`${item}-${inputIndex}-sales`}>
                <Input
                  type="text"
                  // id={`${id || ''}-${inputIndex}`}
                  name={`${name || ''}-${inputIndex}`}
                  defaultValue={item.name}
                  // onChange={}
                  // onBlur={formik.handleBlur}
                  disabled
                />
                {/* <Btn onClick={() => {
                  setInputs([...inputs, ''])
                }}>+</Btn>
                {inputs.length !== 1 && (
                  <Btn onClick={() => {
                    setInputs(inputs.filter((item, index) => index !== inputIndex))
                  }}>-</Btn>
                )} */}
              </Row>
            ))}
          </Label>
        </Row>
      )}
    </>
  )
}

const Label = styled.label`
  display: inline-block;
  width: 100%;
  position: relative;
`

const LabelText = styled.span`
  display: block;
  color: #585756;
  font-weight: 600;
  font-size: 13px;

  &[data-invalid='false'] {
    color: #57ab83;
  }

  &[data-invalid='true'] {
    color: #e7483d;
  }
`

const Row = styled.div`
  display: flex;
  margin-bottom: 5px;
`

// const Btn = styled.button.attrs({ type: 'button' })`
//   border: none;
//   box-shadow: none;
//   background: transparent;
//   font-size: 30px;
//   height: 30px;
//   line-height: 30px;
//   width: 50px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 0;

//   &:hover {
//     color: #e7483d;
//   }
// `

const Input = styled.input`
  padding: 8px 5px 5px 5px;
  font-size: 13px;
  font-weight: 600;
  color: #585756;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  background-color: #f8f8f9;
  border: 1px solid #d5d5d5;
  height: 30px;
`

export default MultiLineInput
