import { checkHttpStatus, parseJSON } from '../utils/api';
import {
  PRICE_PERFORMANCE_GET_REQUEST,
  PRICE_PERFORMANCE_GET_SUCCESS,
  PRICE_PERFORMANCE_GET_FAILURE,
} from '../constants';
import * as api from '../api/price-performance';
import { getTokenWithExpiration } from './auth';

const pricePerformanceGetRequest = () => ({
  type: PRICE_PERFORMANCE_GET_REQUEST,
});

const pricePerformanceGetSuccess = (payload, config) => ({
  type: PRICE_PERFORMANCE_GET_SUCCESS,
  payload,
  config,
});

const pricePerformanceGetFailure = () => ({
  type: PRICE_PERFORMANCE_GET_FAILURE,
});

export const pricePerformanceGet = params => (dispatch, getState) => {
  if (getState().pricePerformance.byRoute[params]) {
    return null;
  }

  dispatch(pricePerformanceGetRequest());

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getPricePerformance(token, params)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(pricePerformanceGetSuccess(response, params));
        })
        .catch(error => {
          if (error) {
            dispatch(pricePerformanceGetFailure());
            // eslint-disable-next-line no-alert
            window.alert(error.status);
          }
        });
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page');
    });
};
