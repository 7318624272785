import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useFormik } from 'formik'
import { useMediaQuery } from 'react-responsive'
import mapValues from 'lodash/mapValues'
import { locationUpdate } from '../../actions/locations'
import * as Styled from '../../components/location'
import { smOnly } from '../../styles/breakpoints'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import TextareaCounter from '../../components/textarea-counter'
import PromoBanner from '../../components/promo-banner'
import DatePicker from '../../components/datepicker'

const getInitialValues = location => ({
  promoText: location?.promoText,
  promoExpiration: location?.promoExpiration
})

const LocationPromo = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)

  const formik = useFormik({
    initialValues: getInitialValues(location),
    enableReinitialize: true,
    onSubmit: values => {
      dispatch(
        locationUpdate(
          locationId,
          mapValues(values, o => (o === '' ? null : o))
        )
      )
    }
  })

  const mobile = useMediaQuery({ query: smOnly })
  const handleResetForm = () =>
    formik.resetForm({ values: getInitialValues(location) })

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.Header>
            <Styled.Note>
              <p>
                <b>Note:</b> Enter you promotion for the location below and
                choose an expiration date.
              </p>
            </Styled.Note>
            <Styled.Actions mobile={mobile}>
              {updating && <Spinner elSize={20} />}
              <>
                <Styled.Action
                  disabled={updating}
                  type="button"
                  onClick={() => handleResetForm()}
                >
                  Cancel
                </Styled.Action>
                <Styled.Action disabled={updating} type="submit">
                  Save
                </Styled.Action>
              </>
            </Styled.Actions>
          </Styled.Header>

          <Styled.Section>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  {location?.name && (
                    <Styled.Heading>Promotions</Styled.Heading>
                  )}
                </Styled.Col>
              </Styled.Row>
              <Flex flexWrap fullWidth>
                <Styled.Col maxWidth={!mobile ? '50%' : '100%'}>
                  <Styled.Label htmlFor="promoText">
                    <Styled.LabelText>Promotion</Styled.LabelText>
                    <TextareaCounter
                      id="promoText"
                      name="promoText"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter Promotion for this location"
                      value={formik.values.promoText || ''}
                      rows={3}
                      disabled={updating}
                    />
                  </Styled.Label>
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="promoExpiration">
                    <Styled.LabelText>Promotion Expiration</Styled.LabelText>
                    <DatePicker
                      id="promoExpiration"
                      name="promoExpiration"
                      onChange={value => {
                        formik.setFieldValue('promoExpiration', value)
                      }}
                      value={formik.values.promoExpiration}
                      disabled={updating}
                      bgInput
                      altInput={false}
                      placeholder="_"
                    />
                  </Styled.Label>
                </Styled.Col>
              </Flex>
              {formik.values.promoText && (
                <Flex fullWidth column>
                  <Styled.Col>
                    <Flex fullWidth column>
                      <h3>Promotion Banner</h3>
                      <PromoBanner heading={formik.values.promoText} />
                    </Flex>
                  </Styled.Col>
                </Flex>
              )}
            </Flex>
          </Styled.Section>
        </Styled.Form>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationPromo
