import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

const rotate = keyframes`
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
`;

const Outer = styled.div`
  position: relative;
  width: ${(props) => `${props.elSize}px`};
  height: ${(props) => `${props.elSize}px`};
  margin-left: 10px;
  margin-right: 10px;
`;

const Inner = styled.span`
  box-sizing: border-box;
  border-radius: 50%;
  border-style: solid;
  border-top-color: #e5e5e5;
  border-right-color: #1779ba;
  border-bottom-color: #e5e5e5;
  border-left-color: #e5e5e5;
  display: inline-block;
  position: absolute;
  animation: ${rotate} 800ms infinite linear;
  width: ${(props) => `${props.elSize}px`};
  height: ${(props) => `${props.elSize}px`};
  border-width: ${(props) => `${props.elSize / 10}px`};
  top: calc(50% - ${(props) => `${props.elSize / 2}px`});
  left: calc(50% - ${(props) => `${props.elSize / 2}px`});
`;

const Spinner = (props) => (
  <Outer {...props}>
    <Inner {...props} />
  </Outer>
);

Spinner.defaultProps = {
  elSize: 20,
};

export default Spinner;
