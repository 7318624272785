import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import * as Styled from '../widget'
import Flex from '../flex'
import Spinner from '../spinner'
import cameraImg from '../../assets/img/camera.png'
import editImg from '../../assets/img/edit.png'
import plusImg from '../../assets/img/plus.png'
import plushImg from '../../assets/img/plush.png'

const WidgetOpportunities = ({ id }) => {
  const location = useSelector(state => state.locations.byId[id])
  const { loading, error } = useSelector(state => state.locations.status)

  return (
    <Styled.Wrap>
      {!loading && !error && (
        <Styled.Title>Opportunities for {location?.name || ''}</Styled.Title>
      )}
      <Styled.Box>
        {loading && (
          <Flex fullWidth center middle style={{ marginTop: 20 }}>
            <Spinner elSize={30} />
          </Flex>
        )}
        {!loading && !error && (
          <List>
            <Item>
              <Img>
                <img
                  src={cameraImg}
                  alt="Listing cards with active people perform 3x better."
                />
              </Img>
              <span>
                Listing cards with active people in the picture perform 3x
                better than images without people.
              </span>
            </Item>
            <Item>
              <Img>
                <img
                  src={editImg}
                  alt="Update your inventory so Upsuite can present the most recent pricing to leads."
                />
              </Img>
              <span>
                Update your inventory so Upsuite can present the most recent
                pricing to leads.
              </span>
            </Item>
            <Item>
              <Img>
                <img
                  src={plusImg}
                  alt="Add to your inventory to get ranked higher in the search
                  results."
                />
              </Img>
              <span>
                Add to your inventory to get ranked higher in the search
                results.
              </span>
            </Item>
            <Item>
              <Img>
                <img
                  src={plushImg}
                  alt="Add Cleaning Policies and a Virtual Tour Url"
                />
              </Img>
              <span>
                Add Cleaning Policies and a Virtual Tour Url to get marked as a
                “healthy space” and get ranked higher in the search results.
              </span>
            </Item>
          </List>
        )}
      </Styled.Box>
    </Styled.Wrap>
  )
}

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 20px;
`

const Item = styled.li`
  font-size: 17px;
  display: flex;

  :not(:last-child) {
    margin-bottom: 25px;
  }

  span {
    font-size: inherit;
  }
`

const Img = styled.div`
  width: 30px;
  margin-right: 15px;
  padding-top: 3px;
  flex: 0 0 30px;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
`

export default WidgetOpportunities
