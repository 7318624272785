import React from 'react'
import styled from 'styled-components/macro'
import Tooltip from '../../components/tooltip'

const MarketScore = ({ title, data, showSuitability }) => {
  const {
    ovarall,
    demandOverall,
    demandViews,
    leads,
    seats,
    competition,
    pricingDiff,
    suitability
  } = data

  return (
    <Wrap>
      <Title>Market Score: {title}</Title>
      <Container>
        <LeftCol>
          <LargeText
            style={{
              color: ovarall >= 50 ? '#57AB83' : '#e7483d'
            }}
          >
            {ovarall}
          </LargeText>
          <Caption>Overall</Caption>
        </LeftCol>
        <RightCol>
          <Row>
            <RowStart>
              <Tooltip
                overlay={
                  <div>
                    <span>
                      Demand Overall is an average of Demand Views, # of Leads
                      and # of Seats.
                    </span>
                  </div>
                }
              >
                <Info>(i)</Info>
              </Tooltip>
              <BarText>Demand Overall:</BarText>
            </RowStart>
            <BarRow>
              <Bar>
                <BarValue
                  style={{
                    width: `${demandOverall}%`,
                    backgroundColor: demandOverall >= 50 ? '#57AB83' : '#e7483d'
                  }}
                >
                  <ValueText>{demandOverall}</ValueText>
                </BarValue>
              </Bar>
            </BarRow>
          </Row>
          <Row>
            <RowStart>
              <Tooltip
                overlay={
                  <div>
                    <span>
                      Demand Views is an average of all the locations in the
                      market or selected area’s Views in Search. Upsuite defines
                      “Views in Search” as the number of listings views per
                      location.
                    </span>
                  </div>
                }
              >
                <Info>(i)</Info>
              </Tooltip>
              <BarText>Demand Views:</BarText>
            </RowStart>
            <BarRow>
              <Bar>
                <BarValue
                  style={{
                    width: `${demandViews}%`,
                    backgroundColor: demandViews >= 50 ? '#57AB83' : '#e7483d'
                  }}
                >
                  <ValueText>{demandViews}</ValueText>
                </BarValue>
              </Bar>
            </BarRow>
          </Row>
          <Row>
            <RowStart>
              <Tooltip
                overlay={
                  <div>
                    <span>
                      # of Leads score is an average of all the individual
                      location’s in the selected market, neighborhood or area’s
                      # of Leads score. # of Leads is defined as the number of
                      leads Upsuite introduces to the individual location.
                    </span>
                  </div>
                }
              >
                <Info>(i)</Info>
              </Tooltip>
              <BarText># of Leads:</BarText>
            </RowStart>
            <BarRow>
              <Bar>
                <BarValue
                  style={{
                    width: `${leads}%`,
                    backgroundColor: leads >= 50 ? '#57AB83' : '#e7483d'
                  }}
                >
                  <ValueText>{leads}</ValueText>
                </BarValue>
              </Bar>
            </BarRow>
          </Row>
          <Row>
            <RowStart>
              <Tooltip
                overlay={
                  <div>
                    <span>
                      # of Seats score is an average of all the individual
                      location’s in the selected market, neighborhood or area’s
                      # of Leads score. # of Seats is defined as the total
                      number of seats Upsuite introduces to the individual
                      location.
                    </span>
                  </div>
                }
              >
                <Info>(i)</Info>
              </Tooltip>
              <BarText># of Seats:</BarText>
            </RowStart>
            <BarRow>
              <Bar>
                <BarValue
                  style={{
                    width: `${seats}%`,
                    backgroundColor: seats >= 50 ? '#57AB83' : '#e7483d'
                  }}
                >
                  <ValueText>{seats}</ValueText>
                </BarValue>
              </Bar>
            </BarRow>
          </Row>
          <Row>
            <RowStart>
              <Tooltip
                overlay={
                  <div>
                    <span>
                      Competition score is an average of all the individual
                      location’s in the selected market, neighborhood or area’s
                      Competition score. Competition score is defined as the
                      number of competing locations found within a .25 mile
                      radius.
                    </span>
                  </div>
                }
              >
                <Info>(i)</Info>
              </Tooltip>
              <BarText>Competition:</BarText>
            </RowStart>
            <BarRow>
              <Bar>
                <BarValue
                  style={{
                    width: `${competition}%`,
                    backgroundColor: competition >= 50 ? '#57AB83' : '#e7483d'
                  }}
                >
                  <ValueText>{competition}</ValueText>
                </BarValue>
              </Bar>
            </BarRow>
          </Row>
          <Row>
            <RowStart>
              <Tooltip
                overlay={
                  <div>
                    <span>
                      Pricing Differential is derived from the ability of a
                      location to maintain asking pricing above market averages.
                      This tool provides an average of all the individual
                      locations in the selected market, compared to the overall
                      market average.
                    </span>
                  </div>
                }
              >
                <Info>(i)</Info>
              </Tooltip>
              <BarText>Pricing Differential:</BarText>
            </RowStart>
            <BarRow>
              <Bar>
                <BarValue
                  style={{
                    width: `${pricingDiff}%`,
                    backgroundColor: pricingDiff >= 50 ? '#57AB83' : '#e7483d'
                  }}
                >
                  <ValueText>{pricingDiff}</ValueText>
                </BarValue>
              </Bar>
            </BarRow>
          </Row>
          {showSuitability && (
            <Row>
              <RowStart>
                <Tooltip
                  overlay={
                    <div>
                      <span>
                        Demographic suitability score is an average of all the
                        individual locations in the selected market, neighborhood
                        or area Demographic Suitability score. Demographic
                        Suitability score is defined as the total of number of
                        Employees In Key Industries, to total number of Employees
                        in Key Occupations, and the Average 2021 Daytime
                        Population within 15 miles of the location.
                      </span>
                    </div>
                  }
                >
                  <Info>(i)</Info>
                </Tooltip>
                <BarText>Demographic Suitability:</BarText>
              </RowStart>
              <BarRow>
                <Bar>
                  <BarValue
                    style={{
                      width: `${suitability}%`,
                      backgroundColor: suitability >= 50 ? '#57AB83' : '#e7483d'
                    }}
                  >
                    <ValueText>{suitability}</ValueText>
                  </BarValue>
                </Bar>
              </BarRow>
            </Row>
          )}
        </RightCol>
      </Container>
    </Wrap>
  )
}

const RowStart = styled.div`
  width: 170px;
  flex: 0 0 170px;
`

const Info = styled.span`
  margin-right: 3px;
  font-weight: 700;
  cursor: pointer;
  color: #606060;
`

const Wrap = styled.div`
  width: 100%;
  border: 1px solid #57ab83;
  border-radius: 10px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  background-color: white;
  max-width: 600px;
  margin-bottom: 10px;
`

const Title = styled.span`
  color: #e7483d;
  font-weight: 700;
  text-align: center;
  display: block;
  font-size: 20px;
  margin-bottom: 14px;
`

const Container = styled.div`
  display: flex;
  padding-right: 5px;
`

const LeftCol = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 14px;
  min-width: 75px;
`

const LargeText = styled.span`
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  display: block;
  color: #57ab83;
`

const Caption = styled.span`
  margin-top: -7px;
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 27px;
`

const RightCol = styled.span`
  width: 100%;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`

const BarText = styled.span`
  font-size: 17px;
  font-weight: 700;
  color: #606060;
`

const BarRow = styled.div`
  width: 100%;
  padding-left: 10px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const Bar = styled.div`
  height: 12px;
  border: 1px solid red;
  border-radius: 6px;
  position: relative;
  box-sizing: border-box;
`

const BarValue = styled.div`
  height: 10px;
  border-radius: 5px;
  position: absolute;
  background-color: green;
  box-sizing: border-box;
  top: 0;
  left: 0;
`

const ValueText = styled.span`
  position: absolute;
  right: 0;
  top: -16px;
  display: block;
  font-weight: 600;
  line-height: 1;
  transform: translateX(50%);
`

export default MarketScore
