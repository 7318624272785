import { checkHttpStatus, parseJSON } from '../utils/api';
import {
  AGREEMENTS_AND_FEES_GET_REQUEST,
  AGREEMENTS_AND_FEES_GET_SUCCESS,
  AGREEMENTS_AND_FEES_GET_FAILURE,
} from '../constants';
import * as api from '../api/agreements-and-fees';

const agreementsGetRequest = () => ({
  type: AGREEMENTS_AND_FEES_GET_REQUEST,
});

const agreementsGetSuccess = payload => ({
  type: AGREEMENTS_AND_FEES_GET_SUCCESS,
  payload,
});

const agreementsGetFailure = payload => ({
  type: AGREEMENTS_AND_FEES_GET_FAILURE,
  payload,
});

export const agreementsAndFeesGet = (token, params) => dispatch => {
  dispatch(agreementsGetRequest());

  return api
    .getAgreeementsAndFees(token, params)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(agreementsGetSuccess({ params, data: response }));
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(message => {
          dispatch(agreementsGetFailure(`${err.status}: ${message}`));
        });
      } else {
        dispatch(agreementsGetFailure('Get unknown error'));
      }
    });
};
