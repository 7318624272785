import styled from 'styled-components/macro';

export const Wrap = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  width: 100%;
  min-height: 300px;
  border-radius: 20px;
  border: 1px solid lightgray;

  &[data-hidden="true"] {
    overflow: hidden;
  }
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
  display: block;
  text-align: center;
`;

export const InsideTitle = styled.span`
  color: #e7483d;
  font-size: 18px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 5px;
  font-weight: 600;
  margin-bottom: 10px;
`
