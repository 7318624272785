import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'qs';
import { useParams } from 'react-router';
import Flex from '../../components/flex';
import { Heading } from '../../components/landing';
import { Wrap, AgreemetCard } from '../../components/agreements';

import { agreementsAndFeesGet } from '../../actions/agreements-and-fees';

const AgreementsAndFees = () => {
  const { locationId, marketId } = useParams();
  const { token } = useSelector(state => state.auth);
  const { byRoute } = useSelector(state => state.agreementsAndFees);
  const dispatch = useDispatch();
  const params = stringify({ market: marketId, location: locationId });

  useEffect(() => {
    if (!byRoute[params]) {
      dispatch(agreementsAndFeesGet(token, params));
    }
  }, [token, dispatch, byRoute, params]);

  return (
    <Flex fullWidth fullHeight center>
      <Wrap>
        <AgreemetCard>
          <Heading>
            ${(byRoute[params] && byRoute[params].agreementsValue) || 0}
          </Heading>
          <Heading>Total Agreement Value</Heading>
        </AgreemetCard>
        <AgreemetCard>
          <Heading>
            ${(byRoute[params] && byRoute[params].upsuiteFees) || 0}
          </Heading>
          <Heading>Fees Paid to Upsuite</Heading>
        </AgreemetCard>
      </Wrap>
    </Flex>
  );
};

export default AgreementsAndFees;
