import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Body } from '../../components/modal'
import Flex from '../../components/flex'
import { leadProposalShow } from '../../actions/leads'
import { hideModal } from '../../actions/modal'
import { leadReject } from '../../actions/leads'
import { Close } from '../../components/icons'

const LeadReject = ({ lead, locationId }) => {
  const [showPromt, setShowPromt] = useState(false)
  const dispatch = useDispatch()
  const {
    reject: { loading },
    list
  } = useSelector(state => state.leads)
  const isRejected = (list.find(item => item?.email === lead.email) || {})
    .is_rejected
  const formik = useFormik({
    initialValues: {
      reason: '',
      other: ''
    },
    onSubmit: values => {
      const reason =
        values.reason === 'Other'
          ? `${values.reason} ${values.other}`
          : values.reason

      dispatch(leadReject(lead.email, reason, locationId))
    },
    validate: values => {
      let errors = {}

      if (!values.reason && values.reason !== 'Other') {
        errors.reason = 'Required'
      }

      if (values.reason === 'Other' && !values.other) {
        errors.other = 'Required'
      }

      return errors
    }
  })

  return (
    <ModalBody small data-with-form={showPromt}>
      {!isRejected ? (
        <>
          {showPromt ? (
            <>
              <Text>
                <p>Why are you rejecting this lead?</p>
              </Text>
              <Form onSubmit={formik.handleSubmit}>
                <Radio>
                  <input
                    type="radio"
                    name="reason"
                    value="My space is full"
                    checked={formik.values.reason === 'My space is full'}
                    onChange={() =>
                      formik.setFieldValue('reason', 'My space is full')
                    }
                  />
                  My space is full
                </Radio>
                <Radio>
                  <input
                    type="radio"
                    name="reason"
                    value="Came from another source or directly to our site"
                    checked={
                      formik.values.reason ===
                      'Came from another source or directly to our site'
                    }
                    onChange={() =>
                      formik.setFieldValue(
                        'reason',
                        'Came from another source or directly to our site'
                      )
                    }
                  />
                  Came from another source or directly to our site
                </Radio>
                <Radio>
                  <input
                    type="radio"
                    name="reason"
                    value="Already toured"
                    checked={formik.values.reason === 'Already toured'}
                    onChange={() =>
                      formik.setFieldValue('reason', 'Already toured')
                    }
                  />
                  Already toured
                </Radio>
                <Radio>
                  <input
                    type="radio"
                    name="reason"
                    value="Other"
                    checked={formik.values.reason === 'Other'}
                    onChange={() => formik.setFieldValue('reason', 'Other')}
                  />
                  Other
                </Radio>
                {formik.errors.reason && <Error>{formik.errors.reason}</Error>}
                {formik.values.reason === 'Other' && (
                  <>
                    <Textarea
                      id="other"
                      name="other"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.other}
                      placeholder="If other, what?"
                    />
                    {formik.touched.other && formik.errors.other && (
                      <Error>{formik.errors.other}</Error>
                    )}
                  </>
                )}
                <Flex fullWidth center>
                  <MainBtn
                    onClick={() => {
                      dispatch(hideModal())
                      dispatch(leadProposalShow(lead))
                    }}
                    disabled={loading}
                    data-in-row
                  >
                    Propose to lead
                  </MainBtn>
                  <SecondaryBtn type="submit" disabled={loading}>
                    Reject this lead
                  </SecondaryBtn>
                </Flex>
              </Form>
            </>
          ) : (
            <>
              <Text>
                <p>
                  Rejecting a lead means Upsuite will not present your space in
                  our presentations to this member. Is this what you want?
                </p>
              </Text>
              <Flex fullWidth column middle>
                <MainBtn
                  onClick={() => {
                    dispatch(hideModal())
                    dispatch(leadProposalShow(lead))
                  }}
                  disabled={loading}
                >
                  No, propose to lead
                </MainBtn>
                <SecondaryBtn
                  onClick={() => setShowPromt(true)}
                  disabled={loading}
                  type="button"
                >
                  Yes, reject this lead
                </SecondaryBtn>
              </Flex>
            </>
          )}
        </>
      ) : (
        <>
          <Message>
            <p>Lead rejected!</p>
          </Message>
          <CloseBtn onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </CloseBtn>
        </>
      )}
    </ModalBody>
  )
}

const Text = styled.div`
  font-size: 18px;
  margin-bottom: 20px;

  p {
    margin: 0;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Error = styled.span`
  display: block;
  color: red;
  margin-bottom: 10px;
  font-size: 13px;
`

const Radio = styled.label`
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & + div {
    padding-top: 10px;
  }

  input {
    margin-right: 5px;
    width: 16px;
    height: 16px;
  }
`

const Textarea = styled.textarea`
  width: 100%;
  padding: 5px;
  resize: none;
  margin-bottom: 10px;

  & + div {
    padding-top: 10px;
  }
`

const CloseBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  box-shadow: none;
  border: none;
  color: red;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  width: 15px;
`

const Message = styled(Text)`
  margin-bottom: 0;
`

const ModalBody = styled(Body)`
  max-width: 456px;
  padding: 20px 50px 30px;

  &[data-with-form='true'] {
    max-width: 600px;
  }
`

const MainBtn = styled.button.attrs({ type: 'button' })`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  width: 100%;
  max-width: 200px;

  &:not([data-in-row='true']) {
    margin-bottom: 20px;
  }

  &[data-in-row='true'] {
    margin-right: 20px;
  }
`

const SecondaryBtn = styled.button`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 10px;
  background-color: white;
  font-weight: 600;
  color: #e7483d;
  border-radius: 4px;
  white-space: nowrap;
  width: 100%;
  max-width: 200px;
`

export default LeadReject
