import orderBy from 'lodash/orderBy'
import isEmpty from 'lodash/isEmpty'
import toLower from 'lodash/toLower'
import { parseISO, format } from 'date-fns'

export const leadsDate = date => format(parseISO(date), 'MM/dd/yyyy')
export const workLeadsDate = date => format(parseISO(date), 'MM/dd')

const byName = lead =>
  (!isEmpty(lead.first_name) && toLower(lead.first_name)) ||
  (!isEmpty(lead.last_name) && toLower(lead.last_name))
const byEmail = lead => lead.email
const byCompany = lead => lead.company
const byDateRegistered = lead => lead.created_at
const bySeats = lead => lead.seats
const byMoveInDate = lead => lead.move_in_date
const byModifiedData = lead => lead.modified_at
const byLeadStatus = lead => lead.lead_status
const byIndustry = lead => lead.industry
const byLocationCount = lead => lead.location_count
const byStatusNote = lead => lead.status_note
const byLocationName = lead => lead.location_names
const byJobTitle = lead => lead.job_title || null
const byLinkedIn = lead => lead.linked_in || null
const byOwner = lead => lead.notification_type || null
const byPhone = lead => lead.phone_number || null

const byLookup = {
  modifiedDate: byModifiedData,
  owner: byOwner,
  name: byName,
  email: byEmail,
  phone: byPhone,
  company: byCompany,
  dateRegistered: byDateRegistered,
  seats: bySeats,
  moveInDate: byMoveInDate,
  leadStatus: byLeadStatus,
  industry: byIndustry,
  locationCount: byLocationCount,
  statusNote: byStatusNote,
  locationName: byLocationName,
  jobTitle: byJobTitle,
  linkedIn: byLinkedIn
}

export const orderLeadsBy = (leads, by, isDesc) => {
  return orderBy(leads, byLookup[by], isDesc && 'desc')
}
