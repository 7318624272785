import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { hideLeadProposals, showLeadShortlist } from '../../actions/broker-tools'

import { Close } from '../../components/icons'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import { proposalLeadGet } from '../../actions/proposal'
import { proposalLocationsGet } from '../../actions/locations'
import { getProposalSpaceName } from '../../utils/broker-tools'

const Map = lazy(() => import('../../containers/WorkLeadMapShortlist'))

const Proposal = () => {
  const dispatch = useDispatch()
  const active = useSelector(state => state.brokerTools.leads.proposal.active)
  const lead = useSelector(state => state.brokerTools.leads.proposal.lead)
  const leadId = lead?.id
  const proposal = useSelector(
    state => state.proposal.byLeadId[leadId]
  )
  const proposalLoading = useSelector(
    state => state.proposal.status.byLeadLoading
  )
  const locations = useSelector(state => state.brokerTools.locations.byLeadId[leadId])
  const locationsLoading = useSelector(state => state.brokerTools.locations.byLeadIdLoading[leadId])

  useEffect(() => {
    if (active && leadId) {
      dispatch(proposalLeadGet(leadId))
    }
  }, [dispatch, active, leadId])

  useEffect(() => {
    if (leadId && proposal?.length > 0) {
      dispatch(proposalLocationsGet(proposal.map(item => item.LocationId), leadId))
    }
  }, [dispatch, leadId, proposal])

  if (!active) {
    return null
  }

  const list = proposal?.reduce((acc, item) => {
    return [
      ...acc,
      ...item.Spaces.map(space => ({
        id: `${item.LocationId}-${space.spaceid}`,
        name: item.locationName,
        space: [getProposalSpaceName(space), space.space_name, space.space_price && `$${space.space_price}`].filter(Boolean).join(', ')
      }))
    ]
  }, [])

  return (
    <Wrap>
      <Header>
        <CloseBtn onClick={() => dispatch(hideLeadProposals())}>
          <CloseIcon />
        </CloseBtn>
        <Title>Proposals</Title>
      </Header>
      <Suspense
        fallback={
          <Flex fullWidth fullHeight center>
            <Spinner elSize={40} />
          </Flex>
        }
      >
        <MapWrap>
          {(proposalLoading || locationsLoading) ? (
            <Flex fullWidth fullHeight center>
              <Spinner elSize={40} />
            </Flex>
          ) : (
            <Map
              locationConverted={lead?.location_first_converted_on_id}
              onGoogleApiLoaded={() => {}}
              locations={locations}
              isProposal
            />
          )}
        </MapWrap>
      </Suspense>
      {!proposalLoading && (
        <>
          <BottomWrap>
            <ToggleBtnWrap>
              <ToggleBtn onClick={() => dispatch(showLeadShortlist(lead))}>Broker Shortlist</ToggleBtn>
              <ToggleBtn data-active onClick={() => {}}>
                Proposed Shortlist
              </ToggleBtn>
            </ToggleBtnWrap>
            {list?.length > 0 && (
              <LocWrap>
                <LocCol data-shift><span>Proposed Spaces</span></LocCol>
                {list.map(item => (
                  <LocRow key={item.id}>
                    <LocCol>
                      <LocTitle>{item.name}</LocTitle>
                    </LocCol>
                    <LocCol data-secondary>
                      <span>{item.space}</span>
                    </LocCol>
                  </LocRow>
                ))}
              </LocWrap>
            )}
          </BottomWrap>
        </>
      )}
    </Wrap>
  )
}

const BottomWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 537px);
`

const LocWrap = styled.div`
  height: 100%;
  overflow: auto;
  margin-bottom: 10px;
  margin-top: 0;
`

const LocRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`

const LocCol = styled.div`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 8px;
  width: 50%;
  flex: 0 0 50%;

  &[data-shift='true'] {
    margin-left: 50%;
    margin-bottom: 15px;

    > span {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &[data-secondary='true'] {
    > span {
      font-size: 20px;
    }
  }
`

const LocTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
`

const Wrap = styled.div`
  position: absolute;
  width: 800px;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  right: 0;
  z-index: 11;
  background-color: white;
  border-left: 1px solid #828282;
`

const Header = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  overflow: hidden;
  flex-shrink: 0;
`

const Title = styled.h2`
  margin-top: 0;
  font-size: 28px;
  color: #e7483d;
  margin-bottom: 0;
`

const CloseBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  box-shadow: none;
  border: none;
  color: #828282;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  width: 20px;
`

const MapWrap = styled.div`
  height: 100%;
  max-height: 400px;
  width: 100%;
  position: relative;
`

const ToggleBtnWrap = styled.div`
  margin-bottom: 5px;
`

const ToggleBtn = styled.button.attrs({ type: 'button' })`
  border: none;
  cursor: pointer;
  text-align: center;
  box-shadow: none;
  border: 2px solid #e7483d;
  font-weight: 600;
  padding: 10px 20px;
  font-weight: 600;

  &[data-active='true'] {
    background-color: #e7483d;
    color: #fff;
  }

  &:not([data-active='true']) {
    background-color: #fff;
    color: black;
  }
`

export default Proposal
