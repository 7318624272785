import fetch from 'isomorphic-fetch'
import { stringify } from 'qs'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getLeads = (token, params) =>
  fetch(`${apiUrl}${apiBase}/flex/leads?${params}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getLeadsPaginated = (token, params) => {
  const query = stringify(params)

  return fetch(`${apiUrl}${apiBase}/flex/leads?${query}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })
}

export const rejectLead = (token, email, reason, locationId) =>
  fetch(`${apiUrl}${apiBase}/flex/leads/reject?email=${encodeURIComponent(email)}${locationId ? `&reason=${reason}&locationId=${locationId}` : ''}`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

