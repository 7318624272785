import React from 'react'
import Flatpickr from 'react-flatpickr'
import { format, parseISO } from 'date-fns'
import { Wrapper, PickrStyles } from './styles'
import { FlatpickrLightCss } from './lightcss'

const DatePicker = props => {
  const { disabled, onChange, name, bordered, minDate, bgInput, altInput = true } = props

  const placeholder =
    !props.value || props.value === '0000-00-00'
      ? '_'
      : format(parseISO(props.value), 'MMMM dd, yyyy')

  return (
    <Wrapper bordered={bordered} bgInput={bgInput}>
      <FlatpickrLightCss />
      <PickrStyles />
      {disabled && (
        <Flatpickr
          id={name}
          name={name}
          options={{
            minDate,
            dateFormat: 'Y-m-d',
            altInput
          }}
          value={props.value !== '0000-00-00' && props.value}
          onChange={(dateString, formatedDate) => {
            onChange(formatedDate === '' ? null : formatedDate)
          }}
          placeholder={placeholder}
          disabled
        />
      )}
      {!disabled && (
        <Flatpickr
          id={name}
          name={name}
          options={{
            minDate,
            dateFormat: 'Y-m-d',
            altInput
          }}
          value={props.value !== '0000-00-00' && props.value}
          onChange={(dateString, formatedDate) => {
            onChange(formatedDate === '' ? null : formatedDate)
          }}
          placeholder={placeholder}
        />
      )}
    </Wrapper>
  )
}

export default DatePicker
