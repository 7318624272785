import { createReducer } from '../utils/redux'
import groupBy from 'lodash/groupBy'
import {
  HOODS_GET_REQUEST,
  HOODS_GET_SUCCESS,
  HOODS_GET_FAILURE,
  HOODS_ALL_GET_REQUEST,
  HOODS_ALL_GET_SUCCESS,
  HOODS_ALL_GET_FAILURE
} from '../constants'

const initialState = {
  byMarketId: {},
  list: [],
  status: {
    error: false,
    completed: false,
    loading: false
  }
}

export default createReducer(initialState, {
  [HOODS_GET_REQUEST]: state => ({
    ...state,
    status: {
      loading: true,
      error: false
    }
  }),
  [HOODS_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byMarketId: {
      ...state.byMarketId,
      ...groupBy(
        payload.list
          .filter(item => item.marketId)
          .map(({ name, id, marketId }) => ({
            label: name,
            value: id,
            marketId
          })),
        'marketId'
      )
    },
    status: {
      loading: false,
      error: false
    }
  }),
  [HOODS_GET_FAILURE]: state => ({
    ...state,
    status: {
      loading: false,
      error: true
    }
  }),
  [HOODS_ALL_GET_REQUEST]: state => ({
    ...state,
    status: {
      loading: true,
      error: false
    }
  }),
  [HOODS_ALL_GET_SUCCESS]: (state, payload) => {
    const list = [
      ...state.list,
      ...payload.list
    ];
    const loading = list.length < payload.total;
    const completed = list.length === payload.total;

    return ({
      ...state,
      list,
      byMarketId: completed ? {
        ...state.byMarketId,
        ...groupBy(
          list
            .filter(item => item.marketId)
            .map(({ name, id, marketId }) => ({
              label: name,
              value: id,
              marketId
            })),
          'marketId'
        )
      } : state.byMarketId,
      status: {
        loading,
        completed,
        error: false
      }
    })
  },
  [HOODS_ALL_GET_FAILURE]: state => ({
    ...state,
    status: {
      loading: true,
      error: false
    }
  })
})
