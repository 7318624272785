import React, { lazy, useState, useEffect, Suspense, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import Spinner from '../../components/spinner'
import Flex from '../../components/flex'
import styled from 'styled-components/macro'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import Sidebar from '../../containers/SidebarSummaries'
import { useMediaQuery } from 'react-responsive'
import { smOnly } from '../../styles/breakpoints'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import {
  marketsWithSearchCountGet
} from '../../actions/market-summaries'
import { marketsAllGet } from '../../actions/markets'
import * as Styled from '../../components/reports'
import { getQuorters } from '../../utils/math'

const Map = lazy(() => import('../../containers/LocationsWithLeadsMap'))

const Locations = props => {
  const { marketId } = props.match.params
  const flexOfficePro = useSelector(state => state.user.flexOfficePro)
  const isNorthAmerica = useSelector(state => state.brokerTools.isNorthAmerica)
  const mobile = useMediaQuery({ query: smOnly })
  const dispatch = useDispatch()
  const quorters = getQuorters()
  const [quarter, setQuarter] = useState(quorters.reverse()[0])
  const {
    withSearchCount: { byQuarter: withSearchByQuarter }
  } = useSelector(state => state.summaries)
  const marketById = useSelector(state => state.markets.all.byId)
  const locations = useMemo(() => {
    return (withSearchByQuarter[quarter] || [])
      .filter(
        item => !isNorthAmerica || (item.country === 'US' && isNorthAmerica)
      )
      .map(item => {
        const market = marketById[item.market_id] || {}

        return {
          ...item,
          id: item.market_id,
          lat: market.centerLat,
          lon: market.centerLon,
          numData: item.demand
        }
      })
  }, [quarter, withSearchByQuarter, marketById, isNorthAmerica])

  useEffect(() => {
    dispatch(marketsAllGet())

    if (quarter) {
      dispatch(marketsWithSearchCountGet(quarter))
    }
  }, [dispatch, quarter])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {flexOfficePro === 'Advisor' && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          {isNorthAmerica ? (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>North American Summaries</Item>
            </>
          ) : (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Market Summaries</Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            <Wrap>
              <Row>
                <Suspense
                  fallback={
                    <Flex fullWidth fullHeight center>
                      <Spinner elSize={40} />
                    </Flex>
                  }
                >
                  <>
                    <Styled.ChartWrap>
                      <MapWrap>
                        <SelectWrap>
                          <Select
                            placeholder="_"
                            styles={{
                              container: provided => ({
                                ...provided,
                                width: 120
                              })
                            }}
                            options={quorters.map(item => ({
                              value: item,
                              label: item
                            }))}
                            isSearchable={false}
                            onChange={value => [setQuarter(value.label)]}
                            value={{
                              value: quarter,
                              label: quarter
                            }}
                          />
                        </SelectWrap>
                        <Map
                          locations={locations}
                          pinColor="purple"
                        />
                        <Legend>
                          <span>Upsuite defines demand as average listing views per location</span>
                        </Legend>
                      </MapWrap>
                    </Styled.ChartWrap>
                  </>
                </Suspense>
              </Row>
            </Wrap>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

const Wrap = styled.div`
  width: 100%;
  max-width: 1000px;
`

const MapWrap = styled.div`
  position: relative;
  width: 100%;
`

const SelectWrap = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-end;
`

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`

const Legend = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  span {
    color: #666666;
    text-align: center;
  }
`

export default Locations
