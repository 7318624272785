import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'

import MultiSelect from '../../components/multi-select'
import styled from 'styled-components/macro'
import Geosuggest from '../../components/geosuggest'
import { mdUp } from '../../styles/breakpoints'
import { updateSuggests } from '../../selectors/markets'
import {
  locationServicesAllFilter,
  locationServicesAllClear,
  locationServicesAllClose,
  mapPinsToFeedAll,
  setAddressCenter,
  toggleView
} from '../../actions/location-services-all'

// const healthStatusOptions = [
//   { value: 'Stable', label: 'Stable' },
//   { value: 'Growth', label: 'Growth' },
//   { value: 'Survival', label: 'Survival' },
//   {
//     value: 'Survival - Available Sublease',
//     label: 'Survival - Available Sublease'
//   },
//   { value: 'Exit', label: 'Exit' },
//   { value: 'Exit Available - Sublease', label: 'Exit Available - Sublease' },
//   { value: 'Closed', label: 'Closed' },
//   {
//     value: 'Closed - Available Sublease',
//     label: 'Closed - Available Sublease'
//   },
//   { value: 'Closed - New Operator', label: 'Closed - New Operator' },
//   {
//     value: 'Closed - Leased to Private Occupier',
//     label: 'Closed - Leased to Private Occupier'
//   },
//   { value: 'Own Building', label: 'Own Building' }
// ]

const isOpenOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]

const typeOptions = [
  { value: 'Coworking', label: 'Coworking' },
  { value: 'Executive Suites', label: 'Executive Suites' },
  { value: 'Hybrid Flex', label: 'Hybrid Flex' },
  { value: 'Incubators / Accelerators', label: 'Incubators / Accelerators' },
  { value: 'Specialty', label: 'Specialty' },
  { value: 'Agile Suites', label: 'Agile Suites' }
]

const sizeOptions = [
  { value: 'Under 5000', label: 'Under 5,000 SqFt' },
  { value: '5000 - 15000', label: '5,000 to 15,000 SqFt' },
  { value: 'Over 15000', label: 'Over 15,000 SqFt' }
]

const sortableOptions = (value, options) => [
  ...value,
  ...(options || []).filter(
    item => !value.some(option => option.value === item.value)
  )
]

const SearchBar = ({ mapsLoaded, markets }) => {
  const dispatch = useDispatch()
  const isMapAll = window.location.pathname.includes('map-all');
  const {
    allLocations,
    byMarketId,
    bySearch,
    filter: {
      marketId,
      marketName,
      search,
      // neighborhood,
      operator,
      type,
      sizeClass,
      // landlord,
      // healthStatus,
      isOpen
    }
  } = useSelector(state => state.locationServicesAll)
  const loading = useSelector(state => {
    const queryLoading = state.locationServicesAll.status.loading
    const total = state.locationServicesAll.total
    const allLoading = state.locationServicesAll.status.all

    return ((marketId || search) && queryLoading) || (!(marketId || search) && !total && allLoading)
  })
  const disableFilters = isMapAll && !(marketId || search)
  const locations = marketId ? (byMarketId[marketId] || []) : search ? (bySearch[search] || []) : allLocations;
  // const neighborhoodOptions = sortBy(
  //   uniqBy(
  //     locations
  //       .map(item => ({
  //         value: item.nbh_id,
  //         label: item.nbh_name
  //       }))
  //       .filter(item => item.label),
  //     'value'
  //   ),
  //   'label'
  // )
  const operatorOptions = sortBy(
    uniqBy(
      locations
        .map(item => ({
          value: item.op_name,
          label: item.op_name
        }))
        .filter(item => item.label),
      'value'
    ),
    'label'
  )
  // const landlordOptions = sortBy(
  //   uniqBy(
  //     locations
  //       .map(item => ({
  //         value: item.ldl_id,
  //         label: [
  //           item.ldl_company_name,
  //           item.ldl_first_name,
  //           item.ldl_last_name
  //         ]
  //           .filter(Boolean)
  //           .join(' ')
  //       }))
  //       .filter(item => item.label),
  //     'value'
  //   ),
  //   'label'
  // )
  const onSuggestChange = value => {
    dispatch(
      locationServicesAllFilter({
        marketName: value?.isFixture && value ? value?.label : null,
        marketId: value?.isFixture && value ? value?.id : null,
        search: !value?.isFixture && value ? value?.label : null
      })
    )

    dispatch(setAddressCenter(value?.isFixture ? {
      lat: value?.lat,
      lng: value?.lng,
      isMarket: true
    } : value?.location))
    dispatch(locationServicesAllFilter([], 'neighborhood'))
    dispatch(locationServicesAllClose())
    dispatch(mapPinsToFeedAll([]))
    dispatch(toggleView(0))
  }

  return (
    <Row>
      <Col>
        {mapsLoaded && (
          <Geosuggest
            placeholder="Market"
            initialValue={marketName || search}
            fixtures={markets}
            maxFixtures={150}
            location={
              new window.google.maps.LatLng(
                39.68852382512386,
                -104.96101620869143
              )
            }
            radius="20"
            country={['ca', 'us']}
            onSuggestSelect={onSuggestChange}
            onSelect={onSuggestChange}
            renderSuggestItem={item => (
              <span className={item.isSubmarket ? 'isSubmarket' : ''}>
                {item.label}
              </span>
            )}
            updateSuggests={suggests => updateSuggests(suggests, markets)}
            onChange={value => {
              const el = document.querySelector('.geosuggest__input-wrapper')

              if (el) {
                el.classList.toggle('geosuggest__active', value)
              }
            }}
            onFocus={() => {
              const el = document.querySelector('.geosuggest__input-wrapper')

              if (el) {
                el.classList.add('geosuggest__active')
              }
            }}
            onBlur={value => {
              const el = document.querySelector('.geosuggest__input-wrapper')

              if (el) {
                el.classList.toggle('geosuggest__active', value)
              }
            }}
          />
        )}
      </Col>
      {/* <Col>
        <MultiSelect
          options={sortableOptions(neighborhood, neighborhoodOptions)}
          placeholder="Neighborhood"
          loading={loading && !disableFilters}
          value={neighborhood}
          onChange={value => [
            dispatch(locationServicesAllFilter(value, 'neighborhood')),
            dispatch(locationServicesAllClose()),
            dispatch(mapPinsToFeedAll([]))
          ]}
          disabled={search || !marketId || loading}
        />
      </Col>
      <Col>
        <MultiSelect
          options={sortableOptions(healthStatus, healthStatusOptions)}
          placeholder="Status"
          value={healthStatus}
          onChange={value => [
            dispatch(locationServicesAllFilter(value, 'healthStatus')),
            dispatch(locationServicesAllClose()),
            dispatch(mapPinsToFeedAll([]))
          ]}
          disabled={loading || disableFilters}
        />
      </Col> */}
      <Col>
        <MultiSelect
          options={sortableOptions(isOpen, isOpenOptions)}
          placeholder="Is Open"
          value={isOpen}
          onChange={value => [
            dispatch(locationServicesAllFilter(value, 'isOpen')),
            dispatch(locationServicesAllClose()),
            dispatch(mapPinsToFeedAll([]))
          ]}
          disabled={loading || disableFilters}
        />
      </Col>
      <Col>
        <MultiSelect
          value={type}
          options={sortableOptions(type, typeOptions)}
          placeholder="Type"
          onChange={value => [
            dispatch(locationServicesAllFilter(value, 'type')),
            dispatch(locationServicesAllClose()),
            dispatch(mapPinsToFeedAll([]))
          ]}
          disabled={loading || disableFilters}
        />
      </Col>
      <Col>
        <MultiSelect
          value={sizeClass}
          options={sortableOptions(sizeClass, sizeOptions)}
          placeholder="Size Class"
          onChange={value => [
            dispatch(locationServicesAllFilter(value, 'sizeClass')),
            dispatch(locationServicesAllClose()),
            dispatch(mapPinsToFeedAll([]))
          ]}
          disabled={loading || disableFilters}
        />
      </Col>
      {/* <Col>
        <MultiSelect
          value={landlord}
          loading={loading && !disableFilters}
          options={sortableOptions(landlord, landlordOptions)}
          placeholder="Landlord"
          onChange={value => [
            dispatch(locationServicesAllFilter(value, 'landlord')),
            dispatch(locationServicesAllClose()),
            dispatch(mapPinsToFeedAll([]))
          ]}
          disabled={loading || disableFilters}
        />
      </Col> */}
      <Col>
        <MultiSelect
          loading={loading && !disableFilters}
          options={sortableOptions(operator, operatorOptions)}
          placeholder="Operator"
          value={operator}
          onChange={value => [
            dispatch(locationServicesAllFilter(value, 'operator')),
            dispatch(locationServicesAllClose()),
            dispatch(mapPinsToFeedAll([]))
          ]}
          disabled={loading || disableFilters}
        />
      </Col>
      <Col>
        <ClearBtn
          onClick={() => {
            dispatch(locationServicesAllClear())
            dispatch(locationServicesAllClose())
            dispatch(mapPinsToFeedAll([]))
            dispatch(toggleView(0))

            const el = document.querySelector('.geosuggest__input-wrapper')

            if (el) {
              el.classList.remove('geosuggest__active')
            }
          }}
          disabled={loading || disableFilters}
        >
          Clear Filters
        </ClearBtn>
      </Col>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-top: 25px;
  width: 100%;
  border-bottom: 1px solid #bdbdbd;
  padding-left: 8px;
  padding-right: 8px;
  border-left: 1px solid #bdbdbd;
  overflow: auto;
  flex-shrink: 0;

  @media ${mdUp} {
    padding-top: 15px;
    flex-wrap: wrap;
    overflow: visible;
  }
`

const Col = styled.div`
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 25px;
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;

  @media ${mdUp} {
    align-items: center;
    margin-bottom: 15px;
  }
`

const ClearBtn = styled.button.attrs({ type: 'button' })`
  box-shadow: unset;
  font-size: 14px;
  cursor: pointer;
  color: #000;
  border: none;
  background: none;
  font-weight: 400;
  text-decoration: underline;
  margin-top: 8px;

  @media ${mdUp} {
    margin-top: 0;
  }
`

export default SearchBar
