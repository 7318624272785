import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import { openLeadRejectModal } from '../../actions/modal'
import { leadProposalShow } from '../../actions/leads'

const LeadActions = ({ lead, locationId, isProposal }) => {
  const dispatch = useDispatch()
  return (
    <Wrap>
      {isProposal && (
        <ToggleButton onClick={() => dispatch(leadProposalShow(lead))}>
          {lead.proposal_created ? 'Edit Proposal' : 'Create Proposal'}
        </ToggleButton>
      )}
      {!lead.proposal_created && !isProposal && (
        <SecondaryBtn onClick={() => dispatch(openLeadRejectModal({ lead, locationId }))}>
          Reject Lead
        </SecondaryBtn>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
`

const SecondaryBtn = styled.button.attrs({ type: 'button' })`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 10px;
  background-color: white;
  font-weight: 600;
  color: #e7483d;
  margin-right: 10px;
  border-radius: 4px;
  white-space: nowrap;
`

const ToggleButton = styled.button.attrs({ type: 'button' })`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  margin-right: 10px;
  border-radius: 4px;
  white-space: nowrap;
`

export default LeadActions
