import React, { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Wrapper } from '../../components/modal'
import { hideModal } from '../../actions/modal'

import SpacesForm from './spaces-form'
import SpaceDelete from './space-remove'
import Iframe from './iframe'
import InteractiveTour from './interactive-tour'
import ImageInfo from './image-info'
import LeadReject from './lead-reject'
import Suspended from './suspended'
import ImagePreview from './image-preview'
import LeadShortlist from './lead-shortlist'

const MODAL_COMPONENTS = {
  SPACES_FORM: SpacesForm,
  SPACE_REMOVE: SpaceDelete,
  IFRAME: Iframe,
  IMAGE_INFO: ImageInfo,
  LEAD_REJECT: LeadReject,
  SUSPENDED: Suspended,
  IMAGE_PREVIEW: ImagePreview,
  INTERACTIVE_TOUR: InteractiveTour,
  LEAD_SHORTLIST: LeadShortlist
}

const ModalRoot = () => {
  const { modals } = useSelector(state => state.modal)
  const dispatch = useDispatch()

  return (
    <Fragment>
      {modals.map(item => {
        const { modalType, modalProps, modalOptions } = item
        if (!modalType) {
          return null
        }
        const ModalComponent = MODAL_COMPONENTS[modalType]

        return (
          <Wrapper
            key={modalType}
            {...modalProps}
            hideModal={() => dispatch(hideModal())}
          >
            <ModalComponent {...modalOptions} />
          </Wrapper>
        )
      })}
    </Fragment>
  )
}

export default ModalRoot
