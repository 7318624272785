import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { stringify } from 'qs'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import { Wrap } from '../../components/chart/wrap'
import {
  NumbersWrapper,
  Number,
  SubLabel
} from '../../components/views-in-search'

import { leadPerformanceGet } from '../../actions/lead-performance'
import { getLeadPerformanceOptions } from '../../settings/chart-settings'
import { smOnly, mdOnly } from '../../styles/breakpoints'

const Chart = lazy(() => import('../../components/chart'))
const sum = arr => arr.reduce((a, b) => a + b, 0)

const LeadPerformance = () => {
  const { locationId, marketId } = useParams()
  const { byRoute } = useSelector(state => state.leadPerformance)
  const dispatch = useDispatch()
  const params = stringify({ market: marketId, location: locationId })
  const mobile = useMediaQuery({ query: smOnly })
  const tablet = useMediaQuery({ query: mdOnly })

  useEffect(() => {
    if (!byRoute[params]) {
      dispatch(leadPerformanceGet(params))
    }
  }, [dispatch, byRoute, params])

  const leadsPerMonth =
    byRoute[params] && byRoute[params].leads.length
      ? sum(byRoute[params].leads) / byRoute[params].leads.length
      : 0
  const qualifiedLeadsPerMonth =
    byRoute[params] && byRoute[params].qualifiedLeads.length > 0
      ? sum(byRoute[params].qualifiedLeads) /
        byRoute[params].qualifiedLeads.length
      : 0

  const diff =
    byRoute[params] && qualifiedLeadsPerMonth > 0 && leadsPerMonth > 0
      ? (qualifiedLeadsPerMonth / leadsPerMonth) * 100
      : 0

  return (
    <Flex fullWidth>
      <Wrap>
        <Suspense
          fallback={
            <Flex fullWidth fullHeight center>
              <Spinner elSize={40} />
            </Flex>
          }
        >
          <Flex
            fullWidth
            alignStart={!mobile && !tablet}
            column={mobile || tablet}
          >
            {byRoute[params] && (
              <NumbersWrapper height={280}>
                <Number>{Math.round(leadsPerMonth)}</Number>
                <SubLabel>average leads per month</SubLabel>

                <Number>{Math.round(qualifiedLeadsPerMonth)}</Number>
                <SubLabel>qualified leads per month</SubLabel>

                {diff > 0 && (
                  <>
                    <Number>{Math.round(diff)}%</Number>
                    <SubLabel>conversion rate</SubLabel>
                  </>
                )}
              </NumbersWrapper>
            )}
            <Chart options={getLeadPerformanceOptions(byRoute[params])} />
          </Flex>
        </Suspense>
      </Wrap>
    </Flex>
  )
}

export default LeadPerformance
