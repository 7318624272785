import cloneDeep from 'lodash/cloneDeep'

export const getPriceOptions = ({ spaceType, selectedType, typeValue, selectedValue }) => {
  const typeData = cloneDeep(typeValue)
  const selectedData = cloneDeep(selectedValue)

  return {
    chart: {
      type: 'column',
      height: 300
    },
    title: {
      text: ''
    },
    xAxis: {
      crosshair: false,
      tickLength: 0,
      labels: {
        enabled: false
      }
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Price ($)'
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        pointWidth: 50,
        borderWidth: 0
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          // eslint-disable-next-line no-template-curly-in-string
          format: '${y:,.0f}'
        }
      }
    },
    series: [
      {
        color: 'rgb(231, 72, 61)',
        name: spaceType,
        data: [typeData]
      },
      {
        name: `${selectedType} Average`,
        color: 'rgb(191, 191, 191)',
        data: [selectedData]
      }
    ]
  }
}
