import styled from 'styled-components/macro'

export const Form = styled.form`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  overflow: auto;

  &[data-inactive="true"] {
    filter: blur(3px);
    user-select: none;
    pointer-events: none;
  }
`

export const Row = styled.div`
  width: 100%;
  margin-bottom: 12px;
`

const inputShared = `
  padding: 8px 5px 5px 5px;
  font-size: 13px;
  font-weight: 600;
  color: #585756;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 5px;
  background-color: #f8f8f9;
  border: 1px solid #d5d5d5;
`

export const Input = styled.input`${inputShared}`
export const SelectNative = styled.select`
  ${inputShared}

  height: 30px;
  padding: 5px;
`

export const LabelText = styled.span`
  display: block;
  color: #585756;
  font-weight: 600;
  font-size: 13px;

  &[data-invalid="false"] {
    color: #57AB83;
  }

  &[data-invalid="true"] {
    color: #e7483d;
  }
`

export const ErrorText = styled.span`
  display: block;
  color: #e7483d;
  font-weight: 400;
  font-size: 13px;
  margin-top: 3px;
`

export const FieldError = styled.span`
  color: #e7483d;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
`

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  position: relative;
`

export const Select = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  placeholder: provided => ({
    ...provided,
    color: '#585756',
    fontWeight: 600,
    fontSize: 13,
    paddingTop: 2
  }),
  control: provided => ({
    ...provided,
    width: '100%',
    borderColor: '#d5d5d5',
    boxShadow: 'none',
    borderRadius: 0,
    minHeight: '30px',
    backgroundColor: '#f8f8f9',
    paddingLeft: '5px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0
  }),
  indicatorSeparator: () => ({
    width: 0
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0
  }),
  singleValue: provided => ({
    ...provided,
    transform: 'unset',
    marginLeft: 0,
    color: '#585756',
    fontSize: 13,
    fontWeight: 600,
    top: 3
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0,
    marginTop: 0,
    fontSize: 13
  })
}

export const Action = styled.button`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 12px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  margin: 0 10px;
  border-radius: 3px;
  white-space: nowrap;

  &[data-cancel="true"] {
    background-color: white;
    color: #e7483d;
  }

  &[data-in-modal="true"] {
    width: 100%;
    margin: 0;
  }
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Error = styled.span`
  display: block;
  font-size: 14px;
  color: #e6473d;
  margin-top: 5px;
`;

export const SubmitModal = styled.div`
  position: absolute;
  width: 90%;
  left: 5%;
  background: white;
  z-index: 3;
  top: 100px;
  bottom: 0;
  margin: auto;
  height: 190px;
  padding: 0 15px 15px;
  border: 1px solid gray;

  p {
    text-align: center;
  }
`
