import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { TopSelectors, SelectorWrap } from '../../components/price-performance'

const spaceTypeOptions = [
  { value: 'Floating Desk', label: 'Floating Desk', isDesk: true },
  { value: 'Dedicated Desk', label: 'Dedicated Desk', isDesk: true },
  { value: 'Private Office', label: 'Private Office' },
  { value: '2 person Suite', label: '2 person Suite' },
  { value: '3 person Suite', label: '3 person Suite' },
  { value: '4 person Suite', label: '4 person Suite' },
  { value: '5 person Suite', label: '5 person Suite' },
  { value: '6 person Suite', label: '6 person Suite' },
  { value: '10+ person Suite', label: '10+ person Suite' }
]

const measureOptions = [
  { value: 'Starting Price', label: 'Starting Price' },
  { value: 'Average Price', label: 'Average Price' }
]

const unitOptions = [
  { value: 'seat', label: '$ per seat' },
  { value: 'suite', label: '$ per suite' }
]

const Selectors = ({ onChange = () => {}, spaceBelow }) => {
  const [spaceType, setSpaceType] = useState(spaceTypeOptions[0].value)
  const [measure, setMeasure] = useState(measureOptions[0].value)
  const [unit, setUnit] = useState(unitOptions[0].value)

  useEffect(() => {
    if (spaceType && measure && unit) {
      onChange({ spaceType, measure, unit })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceType, measure, unit])

  return (
    <TopSelectors data-spacebelow={spaceBelow}>
      <SelectorWrap>
        <Select
          options={spaceTypeOptions}
          value={spaceType ? { value: spaceType, label: spaceType } : null}
          placeholder="Space Type"
          onChange={({ value, isDesk }) => {
            setSpaceType(value)
            setUnit(unitOptions[isDesk ? 0 : 1].value)
          }}
        />
      </SelectorWrap>
      <SelectorWrap>
        <Select
          options={measureOptions}
          value={measure ? { value: measure, label: measure } : null}
          placeholder="Measure"
          onChange={({ value }) => setMeasure(value)}
        />
      </SelectorWrap>
      <SelectorWrap>
        <Select
          options={unitOptions.filter(item => item.value === unit)}
          value={unit ? unitOptions.find(item => item.value === unit) : null}
          placeholder="Unit"
          onChange={({ value }) => setUnit(value)}
        />
      </SelectorWrap>
    </TopSelectors>
  )
}

export default Selectors
