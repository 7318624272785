import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { Wrapper, SubItem } from '../../components/sidebar';
import { PipelineIcon, AgreementIcon } from '../../components/icons';
import {
  PIPELINE_ROUTE,
  AGREEMENTS_AND_FEES_ROUTE,
} from '../../settings/routes';

const Sidebar = () => {
  const { marketId, locationId } = useParams();
  return (
    <Wrapper>
      <SubItem>
        <NavLink to={`/${PIPELINE_ROUTE}/${marketId}/${locationId || ''}`}>
          <PipelineIcon />
          Pipeline
        </NavLink>
      </SubItem>
      <SubItem>
        <NavLink
          to={`/${PIPELINE_ROUTE}/${AGREEMENTS_AND_FEES_ROUTE}/${marketId}/${locationId ||
            ''}`}
        >
          <AgreementIcon />
          Agreements and Fees
        </NavLink>
      </SubItem>
    </Wrapper>
  );
};

export default Sidebar;
