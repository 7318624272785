import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'
import { getTokenWithExpiration } from '../../actions/auth'
import { IMAGES_URL_BASE } from '../../settings/images-url'
import { uploadFile } from '../../api/upload-file'
import { parseJSON } from '../../utils/api'
import { mdUp } from '../../styles/breakpoints'
import { Paperclip } from '../icons'
import Flex from '../flex'
import Spinner from '../spinner'
import { QuestionIcon } from '../icons'

const Attachment = ({
  imageId,
  onRemove = () => {},
  label,
  name,
  onChange,
  center,
  relatedType = 'Location',
  labelHighlight,
  onImageInfo,
  proposal,
  onBlur = () => {}
}) => {
  const dispatch = useDispatch()
  const [file, setFile] = useState()
  const [imageUrl, setImageUrl] = useState()
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState()
  const token = useSelector(state => state.auth.token)
  const handleUpload = event => {
    if (event && event.currentTarget.files[0]) {
      setFile(event.currentTarget.files[0])
    }
  }

  useEffect(() => {
    if (imageId) {
      setImageUrl(`${IMAGES_URL_BASE}/${imageId}`)
    }
  }, [imageId])

  useEffect(() => {
    if (file) {
      setUploading(true)
      setError(null)

      if (file.size > 1024 * 5000) {
        setError('Max image size is 5 Mbs')
        setUploading(false)
        return
      }
      // eslint-disable-next-line no-undef
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const formData = {
          name: file.name,
          type: file.type,
          size: file.size,
          role: 'Attachment',
          relatedType,
          field: name,
          file: reader.result
        }

        dispatch(getTokenWithExpiration({ auth: { token }}))
          .then((updatedToken) => {
            uploadFile(updatedToken, formData)
              .then(response => {
                if (response.status === 200) {
                  parseJSON(response).then(data => {
                    onChange({
                      id: data?.id,
                      name: data?.name
                    })
                    setImageUrl(reader.result)
                  })
                } else {
                  setError(`Error ${response.status}`)
                }
                setUploading(false)
              })
              .catch(() => {
                setError('Unknow server error')
                setUploading(false)
              })
          })
      }
      reader.onerror = () => [
        setError('Error reading file'),
        setUploading(false)
      ]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <Wrap center={center} data-proposal={proposal}>
      <Flex>
        <Label data-invalid={labelHighlight && !imageId} data-proposal={proposal}>{label}</Label>
        {onImageInfo && (
          <div>
            <InfoBtn onClick={onImageInfo}>
              <InfoIcon />
            </InfoBtn>
          </div>
        )}
      </Flex>
      {imageId ? (
        <Preview>
          {imageUrl && <img src={imageUrl} alt={imageId} />}
          <RemoveBtn onClick={() => onRemove()} data-proposal={proposal}>Remove</RemoveBtn>
        </Preview>
      ) : (
        <>
          {uploading ? (
            <Flex fullWidth fullHeight center>
              <Spinner elSize={30} />
            </Flex>
          ) : (
            <>
              <FileLabel htmlFor={name}>
                <Flex fullWidth center={center} middle>
                  <Icon>
                    <Paperclip />
                  </Icon>
                </Flex>
                <File
                  id={name}
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleUpload}
                  onBlur={onBlur}
                ></File>
              </FileLabel>
              {error && <ErrorText>{error}</ErrorText>}
            </>
          )}
        </>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;

  &:not([data-proposal="true"]) {
    min-height: 100px;
  }

  ${props =>
    props.center &&
    `
    @media ${mdUp} {
      padding-left: 30px;
      padding-right: 30px;
    }

    ${Label} {
      text-align: center;
    }

    ${Preview} {
      justify-content: center;
    }
  `}
`

const ErrorText = styled.span`
  color: red;
  text-align: center;
  font-size: 14px;
  display: block;
`

const Icon = styled.div`
  width: 36px;
  height: 33px;
  background-color: #e8eced;
  border-color: #e8eced;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  svg {
    width: 26px;
    height: 26px;
  }
`

const File = styled.input`
  opacity: 0;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
`

const FileLabel = styled.label`
  overflow: hidden;
  width: 50px;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
`

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #585756;
  font-weight: 600;

  &[data-invalid='false'] {
    color: #57ab83;
  }

  &[data-invalid='true'] {
    color: #e7483d;
  }

  &[data-proposal='true'] {
    font-size: 13px;
  }
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    object-fit: cover;
    height: 100px;
  }
`

const RemoveBtn = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
  padding: 0;
  font-weight: 600;
  margin-top: 5px;
  color: #585756;
  font-size: 15px;

  :focus {
    outline: none;
  }

  :hover {
    color: black;
  }

  &[data-proposal='true'] {
    font-size: 13px;
  }
`

const InfoBtn = styled.button.attrs({ type: 'button' })`
  width: 2em;
  height: 2em;
  background: red;
  border: none;
  box-shadow: none;
  border-radius: 50%;
  padding: 0.3em;
  display: flex;
  color: white;
  font-size: 10px;
  cursor: pointer;
  margin-left: 0.5rem;
  margin-top: -0.2em;
`

const InfoIcon = styled(QuestionIcon)`
  width: auto;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
`


export default Attachment
