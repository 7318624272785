import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import uniq from 'lodash/uniq'

import { leadProposalHide } from '../../actions/leads'
import { spacesByLocationIdGet } from '../../actions/spaces'
import { locationByIdGet } from '../../actions/locations'
import { proposalGet, proposalUpdate, proposalLeadCreated } from '../../actions/proposal'
import { selectVisibleSpaces } from '../../selectors/spaces'
import Spinner from '../../components/spinner'
import { spaceModifiedDate } from '../../helpers/format'

import Form from './form'
import ProposalNameComponent from './name'
import ProposalSelect from './select'

import { Close } from '../../components/icons'

const Proposal = ({ locationId }) => {
  const [activeSpaceId, setActiveSpaceId] = useState(null)
  const dispatch = useDispatch()
  const {
    active,
    lead: { first_name, last_name, email, seats, proposal_created, id: leadId, company, move_in_date, contact_owner }
  } = useSelector(state => state.leads.proposal)
  const locationName = useSelector(
    state => (state.locations.byId[locationId] || {}).name
  )
  const spaces = useSelector(state =>
    selectVisibleSpaces(state, {
      locationId,
      activeTab: 'All',
      orderBy: undefined,
      orderDesc: true
    })
  )
  const {
    status: { loading },
    error: { loading: error },
    byLocLeadId
  } = useSelector(state => state.proposal)
  const { Name, ProposalId, SpaceIds } =
    byLocLeadId[`${locationId}${leadId}`] || {}
  const [proposalName, setProposalName] = useState()
  const bookingCalendar = useSelector(state => state.location.byId[locationId]?.bookingCalendar)

  useEffect(() => {
    if (locationId && leadId && !loading && !error) {
      setProposalName(proposal_created ? Name || '' : `${locationName || ''} - Proposal for ${first_name || ''}`)
      return;
    }
    setProposalName()
  }, [locationId, leadId, proposal_created, Name, locationName, first_name, loading, error])

  useEffect(() => {
    if (active && locationId) {
      dispatch(spacesByLocationIdGet(locationId))
      dispatch(locationByIdGet(locationId))

      return () => dispatch(leadProposalHide())
    }
  }, [active, locationId, dispatch])

  useEffect(() => {
    if (proposal_created && leadId && locationId && active) {
      dispatch(proposalGet(locationId, leadId))
    }
  }, [dispatch, active, proposal_created, leadId, locationId])

  useEffect(() => {
    setActiveSpaceId(null)
  }, [active])

  const onRemove = id =>
    dispatch(
      proposalUpdate({
        ProposalId,
        LocationId: locationId,
        FlexLeadId: leadId,
        Name,
        SpaceIds: uniq(SpaceIds).filter(spaceId => spaceId !== id)
      })
    )

  const onChange = name => {
    setProposalName(name)

    if (proposal_created) {
      dispatch(
        proposalUpdate({
          ProposalId,
          LocationId: locationId,
          FlexLeadId: leadId,
          Name: name,
          SpaceIds
        })
      )
    }
  }

  const onSubmit = id => {
    if (!proposal_created) {
      // creating proposal
      dispatch(
        proposalUpdate({
          LocationId: locationId,
          FlexLeadId: leadId,
          Name: proposalName,
          SpaceIds: [id]
        })
      )

      dispatch(proposalLeadCreated(leadId))
      return;
    }

    if (!SpaceIds.includes(id)) {
      // updating proposal if id added
      dispatch(
        proposalUpdate({
          ProposalId,
          LocationId: locationId,
          FlexLeadId: leadId,
          Name: proposalName,
          SpaceIds: [...uniq(SpaceIds), id]
        })
      )
    }
  }

  if (!(active && email && locationId)) {
    return null
  }

  return (
    <Wrap>
      <Header>
        <CloseBtn onClick={() => dispatch(leadProposalHide())}>
          <CloseIcon />
        </CloseBtn>
        <Title>Propose</Title>
        {!(loading || error) && (
          <ProposalNameComponent
            onChange={onChange}
            title={proposalName}
            locationId={locationId}
            leadId={leadId}
          />
        )}
      </Header>
      <Info>
        {first_name && <p>Lead Name: {first_name}</p>}
        {seats && <p># of Seats: {seats}</p>}
        {company && <p>Company Name: {company}</p>}
        {move_in_date && <p>Move-in Date: {spaceModifiedDate(move_in_date)}</p>}
        {contact_owner && <p>Upsuite Contact: {contact_owner}</p>}
      </Info>
      {loading && (
        <Loadingwrap>
          <Spinner elSize={24} />
        </Loadingwrap>
      )}
      {!loading && proposal_created && !activeSpaceId && (
        <ProposalSelect
          spaceIds={SpaceIds || []}
          onRemove={id => onRemove(id)}
          onAdd={() => setActiveSpaceId('add')}
          onSelect={id => setActiveSpaceId(id)}
        />
      )}
      {!loading && !(proposal_created && !activeSpaceId) && (
        <Form
          spaces={spaces}
          activeSpaceId={activeSpaceId}
          locationId={locationId}
          onSubmit={id => onSubmit(id)}
          firstName={first_name}
          lastName={last_name}
          bookingCalendar={bookingCalendar}
        />
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  position: absolute;
  width: 300px;
  height: calc(100vh - 65px);
  display: flex;
  flex-direction: column;
  right: 0;
  z-index: 2;
  background-color: white;
  border-left: 1px solid #828282;
`

const Header = styled.div`
  width: 100%;
  background-color: #e7483d;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  flex-shrink: 0;
`

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 28px;
  color: white;
`

const CloseBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  box-shadow: none;
  border: none;
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  width: 20px;
`

const Info = styled.div`
  padding: 10px;
  border-bottom: 1px solid #828282;

  p {
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    color: #2e2c2b;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Loadingwrap = styled.div`
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: center;
`

export default Proposal
