import styled from 'styled-components/macro';
import { lgUp } from '../../styles/breakpoints';

export const NumbersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${lgUp} {
    margin-top: 50px;
    justify-content: center;

    ${props => props.height && `height: ${props.height}px;`}
  }
`;

export const Number = styled.span`
  font-size: 32px;
  color: #f77076;
`;

export const SubLabel = styled.span`
  font-size: 16px;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
