import styled from 'styled-components/macro';
import { mdUp, mdDown } from '../../styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const List = styled.ul`
  padding-left: 0;
  font-size: 14px;
  color: black;
  margin: 0;

  @media ${mdUp} {
    font-size: 13px;
    border-left: none;
    border-right: none;
    max-height: calc(100vh - 301px);
    height: 100%;
    position: relative;
    overflow: auto;
  }

  @media ${mdDown} {
    overflow: auto;
  }
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-around;
  border-left: 1px solid rgba(0, 0, 0, .2);
  border-right: 1px solid rgba(0, 0, 0, .2);
  margin: 0;
  min-height: 70px;
  overflow: hidden;
  z-index: 1;

  ${props => props.sticky ? `
    position: sticky;
    top: 0;
  ` : `
    position: relative;
    z-index: 0;
  `}

  :first-child {
    background-color: lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    height: 54px;
  }

  :not(:first-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .2);
  }

  @media ${mdUp} {
    width: 2000px;
    flex: 0 0 2000px;
  }

  @media ${mdDown} {
    padding: 5px;
    align-items: center;
    width: 2000px;
  }
`;

export const Col = styled.div`
  width: 100%;
  padding: 13px 5px;

  > span, > a span {
    word-break: break-all;
  }

  a[data-email="true"] {
    color: #e7483d;
    text-decoration: underline;
  }

  a[data-phone="true"] {
    color: black;

    &:hover {
      text-decoration: underline;
    }
  }

  &[data-fixed="true"] {
    width: 140px;
    flex: 0 0 140px;
  }

  &[data-column-prop="status_note"] {
    width: 200px;
    flex: 0 0 200px;
  }

  &[data-fixed-actions="true"] {
    font-weight: 600;
    color: black;
    text-align: left;
    font-size: 14px;
    width: 140px;
    flex: 0 0 140px;
  }

  &[data-fixed-cell-actions="true"] {
    text-align: left;
    width: 140px;
    flex: 0 0 140px;
    display: flex;
    align-items: center;
  }

  &[data-fixed-cell="true"] {
    background-color: white;
  }

  &[data-hidden="true"] {
    visibility: hidden;
  }

  &[data-mobile-hidden="true"] {
    @media ${mdDown} {
      display: none;
    }
  }

  &[data-fixed-header="true"] {
    background-color: lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
  }
`;

export const Filter = styled.button.attrs({ type: 'button' })`
  border: none;
  padding: 0 5px 0 0;
  margin: 0;
  font-weight: 600;
  color: black;
  background: none;
  cursor: pointer;
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 14px;

  ::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 3px;
    right: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid gray;

    ${props => props.active && props.desc && `
      border-top: 5px solid gray;
      border-bottom: 0;
    `}
  }
`;

export const Fixed = styled.div`
  display: flex;
  z-index: 5;

  @media ${mdUp} {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;

    &[data-header="true"] {
      height: 54px;
    }
  }

  @media ${mdDown} {
    right: unset !important;
  }
`
