import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import SummeryText from './SummeryText'
import { useMediaQuery } from 'react-responsive'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import { smOnly } from '../../styles/breakpoints'
import { demandDynamixGet } from '../../actions/market-reports'
import { getDynamixChart } from './chart-settings'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import Sidebar from '../../containers/SidebarReports'
import { getDemandString } from '../../helpers/reports'
import * as Styled from '../../components/reports'

const Chart = lazy(() => import('../../components/chart'))

const DemandDynamics = props => {
  const { marketId } = props.match.params
  const dispatch = useDispatch()
  const { byMarketId } = useSelector(state => state.reports.demanddynamix)
  const data = byMarketId[marketId]
  const flexOfficePro = useSelector(state => state.user.flexOfficePro)
  const marketName = useSelector(state => state.markets.byId[marketId])
  const mobile = useMediaQuery({ query: smOnly })
  const callout = getDemandString(data, marketName?.name)

  useEffect(() => {
    if (marketId) {
      dispatch(demandDynamixGet(marketId))
    }
  }, [dispatch, marketId])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {flexOfficePro === 'Advisor' && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          <>
            <Delimeter>&gt;</Delimeter>
            <Item>Market Reports</Item>
          </>
          {marketName?.name && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item bold>
                {marketName.name}{' '}
                {(data ? data[data.length - 1] : {}).label || ''}
              </Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            <Suspense
              fallback={
                <Flex fullWidth fullHeight center>
                  <Spinner elSize={40} />
                </Flex>
              }
            >
              {data && (
                <>
                  <Styled.ChartWrap>
                    <Styled.NumbersWrapper dataHeight="330">
                      {callout && (
                        <>
                          <Styled.Number>{callout.value}</Styled.Number>
                          <Styled.SubLabel>{callout.text}</Styled.SubLabel>
                          <Styled.Number>{callout.percent}%</Styled.Number>
                          {marketName?.country === 'US' && (
                            <Styled.SubLabel>of US Average</Styled.SubLabel>
                          )}
                          {marketName?.country === 'Canada' && (
                            <Styled.SubLabel>of Canadian Average</Styled.SubLabel>
                          )}
                        </>
                      )}
                    </Styled.NumbersWrapper>
                    <Flex column fullWidth>
                      <Chart
                        options={getDynamixChart(data, marketName?.name, marketName?.country)}
                      />
                      <Styled.Legend>
                        Upsuite defines demand as average listing views per
                        location
                      </Styled.Legend>
                    </Flex>
                  </Styled.ChartWrap>
                  <SummeryText page="DemandDynamics" />
                </>
              )}
            </Suspense>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default DemandDynamics
