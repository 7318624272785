import React from 'react'
import styled from 'styled-components/macro'
import { Calendar } from '../icons'
import { mdUp, smOnly } from '../../styles/breakpoints'
import range from 'lodash/range'
import { Star } from '../../components/icons'

export const Wrap = styled.div`
  max-width: 1200px;
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
`

export const Section = styled.div`
  border: 1px solid black;
  width: 100%;
  padding-top: 15px;

  ${props => !props.noPad && `
    padding-bottom: 15px;
  `}

  @media ${smOnly} {
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const Note = styled.div`
  p {
    margin-top: 0;
    margin-bottom: 5px;
  }

  ${props =>
    props.withBtn && `
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `}
`

export const Form = styled.form`
  width: 100%;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;

  @media ${smOnly} {
    flex-wrap: wrap;
  }
`

export const Col = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth};
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${props => (props.noMar ? 0 : '15px')};

  @media ${mdUp} {
    padding: ${props => (props.noPad ? 0 : '0 15px')};
  }

  @media ${smOnly} {
    ${props =>
      props.mPadLeft &&
      `
      padding-left: ${props.mPadLeft};
    `}
    ${props =>
      props.mPadRight &&
      `
      padding-right: ${props.mPadRight};
    `}
  }

  &[data-padding="left"] {
    padding: 0 0 0 3px;
  }

  &[data-padding="right"] {
    padding: 0 3px 0 0;
  }
`

export const Title = styled.h3`
  margin-top: 0;
`

export const Heading = styled.h2`
  margin-top: 0;
`

export const Input = styled.input`
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 5px 5px 0;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  color: #585756;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 5px;
  background-color: #f8f8f9;
  border: 1px solid #d5d5d5;
  padding: 5px;

  @media ${smOnly} {
    background-color: #f8f8f9;
    border: 1px solid #d5d5d5;
    padding: 5px;
  }
`

export const LabelText = styled.span`
  display: block;
  margin-bottom: 10px;
  color: #585756;
  font-weight: 600;

  &[data-invalid="false"] {
    color: #57AB83;
  }

  &[data-invalid="true"] {
    color: #e7483d;
  }
`

export const FieldError = styled.span`
  color: #e7483d;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
`

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  position: relative;

  &[data-spacebelow="true"] {
    margin-bottom: 0.5rem;
  }
`

export const Select = {
  container: provided => ({
    ...provided,
    width: '100%'
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    borderColor: '#d5d5d5',
    boxShadow: 'none',
    borderRadius: 0,
    minHeight: '30px',
    backgroundColor: '#f8f8f9',
    paddingLeft:'5px'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    padding: 0
  }),
  indicatorSeparator: () => ({
    width: 0
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0
  }),
  singleValue: provided => ({
    ...provided,
    marginLeft: 0,
    color: '#585756',
    fontSize: 15,
    fontWeight: 600
  }),
  menu: provided => ({
    ...provided,
    borderRadius: 0,
    marginTop: 0
  })
}

export const StarsSelect = {
  ...Select,
  indicatorsContainer: () => ({
    display: 'none'
  })
}

export const Action = styled.button`
  cursor: pointer;
  border: none;
  font-size: 14px;
  background: none;

  @media ${mdUp} {
    color: #e7483d;
    padding: 0 20px;
    line-height: 1.1;
    text-decoration: underline;
  }

  @media ${smOnly} {
    padding: 11px 20px;
    line-height: 1.3;
    color: #fff;
    font-weight: 600;
    background-color: #e7483d;
    margin-bottom: 10px;
    width: 100%;
  }
`

export const Footer = styled.div`
  padding-left: ${props => (props.mobile ? 0 : '15px')};
  margin-bottom: ${props => (props.mb > 0 ? `${props.mb}px` : 0)};
  display: flex;
  width: 100%;

  @media ${mdUp} {
    align-items: center;
    justify-content: flex-end;
  }

  @media ${smOnly} {
    flex-wrap: wrap;
  }
`

export const Actions = styled.div`
  padding-left: ${props => (props.mobile ? 0 : '15px')};
  display: flex;
  width: 100%;
  flex: 1;

  @media ${mdUp} {
    align-items: center;
    justify-content: flex-end;
  }

  @media ${smOnly} {
    flex-wrap: wrap;
  }
`

export const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ${mdUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Updated = styled.span`
  display: block;
  margin-right: auto;
  font-size: 14px;

  @media ${smOnly} {
    color: #585756;
    font-size: 14px;
    position: absolute;
    top: 24px;
  }
`

export const DateIcon = styled(Calendar)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 6px;
  fill: #636364;
  top: -2px;
`

const rangeThumb = `
  -webkit-appearance: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #ced0da;
  background-color: #5bab85;
  margin-top: -12px;
`

const rangeTrack = `
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: #e2e7ee;
`

export const Range = styled.input.attrs({
  type: 'range'
})`
  -webkit-appearance: none;
  width: 100%;
  background: transparent;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  :focus {
    outline: none;
  }

  ::-ms-track {
    width: 100%;
    cursor: pointer;

    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  ::-webkit-slider-thumb {
    ${rangeThumb}
  }

  ::-moz-range-thumb {
    ${rangeThumb}
  }

  ::-ms-thumb {
    ${rangeThumb}
  }

  ::-webkit-slider-runnable-track {
    ${rangeTrack}
  }

  ::-moz-range-track {
    ${rangeTrack}
  }

  ::-ms-track {
    ${rangeTrack}
  }

  :focus::-webkit-slider-runnable-track {
    background: lightgray;
  }
`

export const RangeText = styled.div`
  font-size: 14px;
  width: 150px;

  @media ${mdUp} {
    width: 200px;
    font-size: 17px;
  }
  text-align: center;
  font-weight: ${props => props.active ? '800' : '400'};
  color: ${props => props.active ? '#000' : '#474747'};
`

export const RangeRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 30px 0;
  margin-bottom: 20px;
`

export const DateLabel = styled.label`
  display: flex;
  width: 137px;
  max-width: 100%;
  min-width: 137px;
  position: relative;
  margin-right: 10px;

  .form-control.input {
    width: 100%;
    padding: 8px;
    height: 36px;
    border: none;
    padding-right: 42px;
  }
`;

export const Error = styled.span`
  display: block;
  font-size: 14px;
  color: #e6473d;
  margin-top: 5px;
`;

export const DateIconWrap = styled.button`
  position: absolute;
  top: 0;
  right: 6px;
  top: 2px;
  border: none;
  padding: 0;
  background-color: transparent;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    fill: #636364;
  }
`;

export const ReviewWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  padding-bottom: 15px;

  :not(:last-child) {
    border-bottom: 1px solid black;
  }
`;

export const ReviewEdit = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  background: none;
  border: none;
  color: #e7483d;
  line-height: 1.1;
  width: 100%;
  text-align: right;
  font-size: 14px;
  text-decoration: underline;
`

export const Control = styled.button.attrs({ type: 'button' })`
  border: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 10px;
  background-color: #57AB83;
  color: #fff;
  padding: 10px 20px;

  ${props => props.withShift && 'margin-left: 10px;'}

  svg {
    fill: #fff;
    margin-right: 5px;
  }
`;

const StyledStar = styled(Star)`
  width: 25px;
  margin-right: 5px;
  cursor: pointer;
  color: #FDCC0D;
`

const StyledOption = styled.div`
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
`

export const Stars = props => {
  return (
    <StyledOption {...props.innerProps}>
      {range(props?.value || props?.data?.value).map(item => (
        <StyledStar key={item} />
      ))}
    </StyledOption>
  )
}
