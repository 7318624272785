import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getMarkets = (token, isReportsAccess) =>
  fetch(`${apiUrl}${apiBase}/flex/${isReportsAccess ? 'invester/usermarkets' : 'markets'}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getAllMarkets = () =>
  fetch(`${apiUrl}${apiBase}/market?asc=true&sortBy=name&where[0][attribute]=hideMarketOnWeb&where[0][type]=isFalse`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })

