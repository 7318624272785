export const getOptions = (count, months) => {
  return {
    chart: {
      type: 'column',
      height: 260
    },
    title: {
      text: ''
    },
    xAxis: {
      tickLength: 0,
      categories: months.map(item => new window.Intl.DateTimeFormat('en-US', { month: 'long' }).format((new Date()).setMonth(item - 1)))
    },
    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      title: {
        text: '# of Leads'
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        pointWidth: 30,
        borderWidth: 0
      },
      series: {
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        color: 'rgb(231, 72, 61)',
        showInLegend: false,
        data: count
      }
    ]
  }
}
