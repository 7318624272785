import React, { lazy, Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import SummeryText from './SummeryText'
import { useMediaQuery } from 'react-responsive'
import {
  Wrapper,
  Delimeter,
  Clickable,
  Item
} from '../../components/breadcrumbs'
import { smOnly } from '../../styles/breakpoints'
import { inventoryVacancyGet } from '../../actions/market-reports'
import { getInventoryChart } from './chart-settings'
import { DASHBOARD_ROUTE } from '../../settings/routes'
import Sidebar from '../../containers/SidebarReports'
import * as Styled from '../../components/reports'

const Chart = lazy(() => import('../../components/chart'))

const InventoryVacancy = props => {
  const { marketId } = props.match.params
  const dispatch = useDispatch()
  const flexOfficePro = useSelector(state => state.user.flexOfficePro)
  const { byMarketId } = useSelector(state => state.reports.inventory)
  const data = byMarketId[marketId]
  const marketName = useSelector(state => state.markets.byId[marketId])
  const mobile = useMediaQuery({ query: smOnly })

  useEffect(() => {
    dispatch(inventoryVacancyGet(marketId))
  }, [dispatch, marketId])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Wrapper>
          <Clickable to={`/${DASHBOARD_ROUTE}/${marketId}`}>
            Dashboard
          </Clickable>
          {flexOfficePro === 'Advisor' && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item>Markets</Item>
            </>
          )}
          <>
            <Delimeter>&gt;</Delimeter>
            <Item>Market Reports</Item>
          </>
          {marketName?.name && (
            <>
              <Delimeter>&gt;</Delimeter>
              <Item bold>
                {marketName.name}{' '}
                {(data ? data[data.length - 1] : {}).Label || ''}
              </Item>
            </>
          )}
        </Wrapper>
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth column>
            <Suspense
              fallback={
                <Flex fullWidth fullHeight center>
                  <Spinner elSize={40} />
                </Flex>
              }
            >
              {data && (
                <>
                  <Styled.ChartWrap>
                    <Styled.NumbersWrapper dataHeight="330">
                      <Styled.Number>
                        {(data[data.length - 1] || {}).TotalSeats}
                      </Styled.Number>
                      <Styled.SubLabel>
                        {`${(data[data.length - 1] || {}).Label} Total Seats`}
                      </Styled.SubLabel>
                      <Styled.Number>
                        {`${(data[data.length - 1] || {}).VacantSeats}`}
                      </Styled.Number>
                      <Styled.SubLabel>
                        {`${(data[data.length - 1] || {}).Label} Vacant Seats`}
                      </Styled.SubLabel>
                    </Styled.NumbersWrapper>
                    <div style={{ width: '100%' }}>
                      <Chart options={getInventoryChart(data)} />
                    </div>
                  </Styled.ChartWrap>
                  <SummeryText page="InventoryAndVacancy" />
                </>
              )}
            </Suspense>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default InventoryVacancy
