import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

const ProposalSelect = ({
  spaceIds,
  onSelect = () => {},
  onAdd = () => {},
  onRemove = () => {}
}) => {
  const { byId } = useSelector(state => state.spaces)

  return (
    <Wrap>
      <p>
        Choose from the below suites that have been proposed to review, edit, or
        add a new suite.
      </p>
      {spaceIds.length > 0 && (
        <List>
          {spaceIds
            .filter(item => byId[item])
            .map((item, index) => (
              <li key={`${item}-${index}`}>
                <Btn onClick={() => onSelect(item)}>
                  {(byId[item] || {}).name || ''}
                </Btn>
                <RemoveBtn onClick={() => onRemove(item)}>x</RemoveBtn>
              </li>
            ))}
        </List>
      )}
      <Btn data-on-add onClick={onAdd}>
        + Add Suite
      </Btn>
    </Wrap>
  )
}

const Wrap = styled.div`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  overflow: auto;

  p {
    margin-top: 0;
  }
`

const List = styled.ul`
  list-style: none;
  padding-left: 30px;
  margin-bottom: 0;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
`

const Btn = styled.button.attrs({ type: 'button' })`
  border: none;
  box-shadow: none;
  background: none;
  font-weight: 600;
  font-size: 17px;
  padding: 0;
  text-align: left;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &[data-on-add] {
    margin-left: 30px;
  }
`

const RemoveBtn = styled.button.attrs({ type: 'button' })`
  border: none;
  box-shadow: none;
  background: none;
  font-weight: 600;
  width: 20px;
  height: 20px;
  color: white;
  background-color: red;
  border-radius: 50%;
  margin-left: 10px;
  padding: 0;
  display: inline-flex;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
`

export default ProposalSelect
