import { createReducer } from '../utils/redux'
import {
  LOCATIONS_WITH_LEADS_REQUEST,
  LOCATIONS_WITH_LEADS_SUCCESS,
  LOCATIONS_WITH_LEADS_FAILURE,
  MARKETS_WITH_SEARCH_REQUEST,
  MARKETS_WITH_SEARCH_SUCCESS,
  MARKETS_WITH_SEARCH_FAILURE
} from '../constants'

const initialState = {
  withLeads: {
    byQuarter: {},
    status: {
      loading: false,
      error: false
    }
  },
  withSearchCount: {
    byQuarter: {},
    status: {
      loading: false,
      error: false
    }
  }
}

export default createReducer(initialState, {
  [LOCATIONS_WITH_LEADS_REQUEST]: state => ({
    ...state,
    withLeads: {
      ...state.withLeads,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [LOCATIONS_WITH_LEADS_SUCCESS]: (state, payload, config) => ({
    ...state,
    withLeads: {
      ...state.withLeads,
      byQuarter: {
        ...state.withLeads.byQuarter,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [LOCATIONS_WITH_LEADS_FAILURE]: state => ({
    ...state,
    withLeads: {
      ...state.withLeads,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [MARKETS_WITH_SEARCH_REQUEST]: state => ({
    ...state,
    withSearchCount: {
      ...state.withSearchCount,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [MARKETS_WITH_SEARCH_SUCCESS]: (state, payload, config) => ({
    ...state,
    withSearchCount: {
      ...state.withSearchCount,
      byQuarter: {
        ...state.withSearchCount.byQuarter,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [MARKETS_WITH_SEARCH_FAILURE]: state => ({
    ...state,
    withSearchCount: {
      ...state.withSearchCount,
      status: {
        loading: false,
        error: true
      }
    }
  })
})
