import { createReducer } from '../utils/redux'
import { SHOW_MODAL, HIDE_MODAL, HIDE_ALL_MODALS } from '../constants'

const initialState = {
  modals: []
}

export default createReducer(initialState, {
  [SHOW_MODAL]: (state, payload) => ({
    ...state,
    modals: [
      ...state.modals,
      {
        modalType: payload.modalType,
        modalProps: payload.modalProps,
        modalOptions: payload.modalOptions
      }
    ]
  }),
  [HIDE_MODAL]: state => ({
    ...state,
    modals: state.modals.filter(
      (item, index) => index !== state.modals.length - 1
    )
  }),
  [HIDE_ALL_MODALS]: () => ({
    ...initialState
  })
})
