import React from 'react'
import styled from 'styled-components/macro'
import { Logo as Icon, LogoMarketplace as IconMarketplace } from '../icons'

const Wrapper = styled.div`
  width: ${props => props.elWidth};
  height: ${props => props.elHeight};
  display: flex;
`

const StyledIcon = styled(Icon)`
  width: 100%;
  height: 100%;
`

const Logo = props => {
  return (
    <Wrapper {...props}>
      <StyledIcon />
    </Wrapper>
  )
}

Logo.defaultProps = {
  elWidth: '100%',
  elHeight: 'auto'
}

export const LogoMarketplace = ({ mBottom, elWidth, elHeight, mLeft }) => {
  return (
    <MarketPlaceWrap mBottom={mBottom} elWidth={elWidth} elHeight={elHeight} mLeft={mLeft}>
      <IconMarketplace />
    </MarketPlaceWrap>
  )
}

const MarketPlaceWrap = styled.div`
  width: ${props => props.elWidth || '450px'};
  height: ${props => props.elHeight || 'auto'};
  margin-bottom: ${props => props.mBottom || 'auto'};
  margin-left: ${props => props.mLeft};
  color: #E7483D;
`

export default Logo
