import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import orderBy from 'lodash/orderBy'
import slice from 'lodash/slice'
import sumBy from 'lodash/sumBy'
import round from 'lodash/round'

const getDataByParam = (by, param) => {
  const withValue = reduce(
    by,
    (result, value, key) => {
      return [
        ...result,
        {
          name: key,
          value: !param
            ? value.length
            : sumBy(value, item => parseInt(item[param], 10) || 0)
        }
      ]
    },
    []
  )
  const withLimit = slice(orderBy(withValue, 'value', 'desc'), 0, 10)
  const sum = sumBy(withLimit, 'value')

  return withLimit.map(item => ({
    ...item,
    y: round((item.value / sum) * 100, 2)
  }))
}

export const getOperatorData = (locations = [], seatsProp, sqftProp, operatorProp) => {
  const byOperator = groupBy(
    locations.filter(item => item && item[operatorProp]),
    operatorProp
  )

  return ({
    locations: getDataByParam(byOperator),
    seats: getDataByParam(byOperator, seatsProp),
    sqft: getDataByParam(byOperator, sqftProp)
  })
}

export const getTypeData = (initialLoc, seatsProp, sqftProp) => {
  const byType = groupBy(
    initialLoc.filter(item => item.type && item.type !== 'None'),
    'type'
  )
  const locations = getDataByParam(byType)
  const seats = getDataByParam(byType, seatsProp)
  const sqft = getDataByParam(byType, sqftProp)

  const withType = {
    locations,
    seats,
    sqft
  }

  return withType
}

export const getDemandShareData = (locations = [], operatorProp) => {
  const byOperator = groupBy(
    locations.filter(item => item && item[operatorProp]),
    operatorProp
  )

  const withProps = reduce(
    byOperator,
    (result, value, key) => {
      return [
        ...result,
        {
          name: key,
          viewsInSearch: value.reduce((acc, item) => {
            return (item?.viewsInSearch || []).map(
              (mapValue, index) => (mapValue || 0) + (acc[index] || 0)
            )
          }, []).reduce((acc, item, index) => {
            return {
              ...acc,
              [index]: item || 0
            }
          }, {}),
          numberOfLeads: value.reduce((acc, item) => {
            return (item?.numberOfLeads || []).map(
              (mapValue, index) => (mapValue || 0) + (acc[index] || 0)
            ).reduce((acc, item, index) => {
              return {
                ...acc,
                [index]: item || 0
              }
            }, {})
          }, []),
          numberOfSeats: value.reduce((acc, item) => {
            return (item?.numberOfSeats || []).map(
              (mapValue, index) => (mapValue || 0) + (acc[index] || 0)
            ).reduce((acc, item, index) => {
              return {
                ...acc,
                [index]: item || 0
              }
            }, {})
          }, [])
        }
      ]
    },
    []
  )

  const qLength = 12

  const getByIndex = (index, prop) => {
    const withLimit = slice(orderBy(withProps, `${prop}.${index}`, 'desc'), 0, 10)
    const sum = sumBy(withLimit, `${prop}.${index}`)

    return withLimit.map(item => ({
      name: item.name,
      value: item[prop][index] || 0,
      y: round(((item[prop][index] || 0) / sum) * 100, 2)
    }))
  }

  const data = Array.from({ length: qLength }).reduce((qAcc, _, qIndex) => {
    const selectors = ['viewsInSearch', 'numberOfLeads', 'numberOfSeats'];

    return {
      ...qAcc,
      ...selectors.reduce((acc, item, index) => {
        return {
          ...acc,
          [`${index}-${qIndex}`]: getByIndex(qIndex, item)
        }
      }, {})
    }
  }, {})

  return data
}

export const getSizeData = (initialLoc, seatsProp, sqftProp) => {
  const byType = groupBy(
    initialLoc.filter(item => item.size_class && item.size_class !== 'None').map(item => {
      if (item.size_class === '5000 to 15000' || item.size_class === '5000 - 15000') {
        return ({
          ...item,
          size_class: '5,000 - 15,000 SF'
        })
      }

      if (item.size_class === 'Over 15000') {
        return ({
          ...item,
          size_class: '> 15,000 SF'
        })
      }

      return ({
        ...item,
        size_class: '< 5,000 SF'
      })
    }),
    'size_class'
  )
  const locations = getDataByParam(byType)
  const seats = getDataByParam(byType, seatsProp)
  const sqft = getDataByParam(byType, sqftProp)

  const withType = {
    locations,
    seats,
    sqft
  }

  return withType
}

export const getPriceByIndex = (locations, prop, index) => {
  const priceLocations = locations.filter(item => (item[prop] || [])[index])
  const priceSum = priceLocations.reduce((acc, item) => acc + item[prop][index], 0)

  return priceSum / priceLocations.length
}

export const getTitle = ({ query, pinIds }) => {
  if (query.healthStatus.length || pinIds.length || !query.marketName) {
    return 'Selected Area'
  }

  if (query.neighborhood.length) {
    return `${query.neighborhood
      .map(item => item.label)
      .join(', ')}`
  }

  return `${query.marketName}`
}
