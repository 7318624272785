import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import Breadcrumbs from '../../containers/Breadcrumbs'
import Sidebar from '../../containers/SidebarSpaces'
import { useMediaQuery } from 'react-responsive'
import { smOnly } from '../../styles/breakpoints'
import { locationGet } from '../../actions/locations'
import {
  LOCATION_ROUTE,
  LOCATION_SETUP_ROUTE,
  LOCATION_CONTACTS_ROUTE,
  LOCATION_ENVIRONMENT_ROUTE,
  LOCATION_AMENITIES_ROUTE,
  LOCATION_IMAGERY_ROUTE,
  LOCATION_NETWORK_ROUTE,
  LOCATION_REVIEWS_ROUTE,
  LOCATION_PROMO_ROUTE
} from '../../settings/routes'
import LocationPromo from '../../containers/LocationPromo'
import LocationSetup from '../../containers/LocationSetup'
import LocationEnv from '../../containers/LocationEnv'
import LocationAmenities from '../../containers/LocationAmenities'
import LocationImg from '../../containers/LocationImg'
import LocationNetwork from '../../containers/LocationNetwork'
import LocationContacts from '../../containers/LocationContacts'
import LocationReviews from '../../containers/LocationReviews'

const Location = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const mobile = useMediaQuery({ query: smOnly })

  useEffect(() => {
    if (locationId) {
      dispatch(locationGet(locationId))
    }
  }, [locationId, dispatch])

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Breadcrumbs childRoute="Location and Spaces" />
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Switch>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_SETUP_ROUTE}/:marketId/:locationId`}
            >
              <LocationSetup />
            </Route>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_CONTACTS_ROUTE}/:marketId/:locationId`}
            >
              <LocationContacts />
            </Route>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_ENVIRONMENT_ROUTE}/:marketId/:locationId`}
            >
              <LocationEnv />
            </Route>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_AMENITIES_ROUTE}/:marketId/:locationId`}
            >
              <LocationAmenities />
            </Route>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_IMAGERY_ROUTE}/:marketId/:locationId`}
            >
              <LocationImg />
            </Route>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_NETWORK_ROUTE}/:marketId/:locationId`}
            >
              <LocationNetwork />
            </Route>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_REVIEWS_ROUTE}/:marketId/:locationId`}
            >
              <LocationReviews />
            </Route>
            <Route
              exact
              path={`/${LOCATION_ROUTE}/${LOCATION_PROMO_ROUTE}/:marketId/:locationId`}
            >
              <LocationPromo />
            </Route>
          </Switch>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Location
