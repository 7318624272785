import React, { Suspense, lazy, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { stringify } from 'qs'
import last from 'lodash/last'
import mean from 'lodash/mean'
import { useMediaQuery } from 'react-responsive'

import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import { Wrap, Col } from '../../components/chart/wrap'

import { pricePerformanceGet } from '../../actions/price-performance'
import {
  getPricePerformanceOptions,
  getSuiteSizeOptions
} from '../../settings/chart-settings'
import { smOnly, mdOnly } from '../../styles/breakpoints'

import Selectors from './selectors'
import SuiteSizeSelectors from './suite-size-selectors'
import AverageSizeSelectors from './average-size-selectors'

import {
  NumbersWrapper,
  Number,
  SubLabel
} from '../../components/views-in-search'

const Chart = lazy(() => import('../../components/chart'))

const getPercentageRatio = (loction, secondValue) => {
  const ratio = Math.round(((loction - secondValue) / secondValue) * 100)

  return isFinite(ratio) && !isNaN(ratio) ? ratio : 0
}

const PricePerformance = ({ suites, averageSize }) => {
  const dispatch = useDispatch()
  const { byRoute } = useSelector(state => state.pricePerformance)
  const { byId } = useSelector(state => state.locations)
  const { locationId, marketId, spaceType, measure, unit } = useParams()
  const market = useSelector(state => state.markets.byId[marketId])

  const mobile = useMediaQuery({ query: smOnly })
  const tablet = useMediaQuery({ query: mdOnly })

  const unitValues = {
    '$ per person': 'person',
    'sqft per person': 'person',
    '$ per sqft': 'sqft',
    '$ per suite': 'suite',
    'sqft per suite': 'suite'
  }

  const params = stringify({
    market: marketId,
    location: locationId,
    suitetype: spaceType,
    measure,
    unit: unit ? unitValues[unit] : ''
  })
  const isSuiteSpaceType =
    spaceType === 'Floating Desk' ||
    spaceType === 'Dedicated Desk' ||
    spaceType === 'Suite'

  const rightParams =
    (isSuiteSpaceType &&
      unitValues[unit] !== 'suite' &&
      ((measure === 'Average Size' && unitValues[unit] !== 'sqft') ||
        measure !== 'Average Size')) ||
    (!isSuiteSpaceType && unitValues[unit] === 'suite')

  useEffect(() => {
    if (rightParams && spaceType && measure && unit && unitValues[unit]) {
      dispatch(pricePerformanceGet(params))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const nearestRatio =
    byRoute[params] &&
    getPercentageRatio(
      last(byRoute[params].location),
      last(byRoute[params].vicinity)
    )

  const marketRatio =
    byRoute[params] &&
    getPercentageRatio(
      last(byRoute[params].location),
      last(byRoute[params].market)
    )

  const marketAverageRatio =
    byRoute[params] &&
    getPercentageRatio(
      mean(byRoute[params].location),
      mean(byRoute[params].market)
    )

  const options =
    measure !== 'Average Size'
      ? getPricePerformanceOptions(byRoute[params], byId[locationId], market?.name)
      : getSuiteSizeOptions(byRoute[params], byId[locationId], market?.name)

  return (
    <Flex fullWidth alignStart column>
      <Wrap>
        {!suites && <Selectors />}
        {suites && !averageSize && <SuiteSizeSelectors />}
        {suites && averageSize && <AverageSizeSelectors />}
        <Suspense
          fallback={
            <Flex fullWidth fullHeight center>
              <Spinner elSize={40} />
            </Flex>
          }
        >
          <Flex
            fullWidth
            alignStart={!mobile && !tablet}
            column={mobile || tablet}
          >
            <Col withWidth="20%">
              {byRoute[params] && byRoute[params].vicinity && (
                <NumbersWrapper height={330}>
                  {/* Suite and Starting Price selected */}
                  {(measure === 'Starting Price' ||
                    measure === 'Average Price') && (
                    <>
                      {isFinite(last(byRoute[params].location)) &&
                        last(byRoute[params].location) >= 0 && (
                          <>
                            <Number>
                              ${Math.round(last(byRoute[params].location))}
                            </Number>
                            {measure === 'Starting Price' && (
                              <SubLabel>Starting price</SubLabel>
                            )}
                            {measure === 'Average Price' && (
                              <SubLabel>Average price</SubLabel>
                            )}

                            <Number>{nearestRatio}%</Number>
                            {nearestRatio >= 0 && (
                              <SubLabel>
                                Higher cost compared to 3 closest
                              </SubLabel>
                            )}
                            {nearestRatio < 0 && (
                              <SubLabel>
                                Lower cost compared to 3 closest
                              </SubLabel>
                            )}
                            <Number>{marketRatio}%</Number>
                            {marketRatio >= 0 && (
                              <SubLabel>
                                Higher cost compared to market average
                              </SubLabel>
                            )}
                            {marketRatio < 0 && (
                              <SubLabel>
                                Lower cost compared to market average
                              </SubLabel>
                            )}
                          </>
                      )}
                    </>
                  )}
                  {mean(byRoute[params].location) >= 0 &&
                    measure === 'Average Size' && (
                      <>
                        <Number>
                          {Math.round(mean(byRoute[params].location))} sqft
                        </Number>
                        <SubLabel>Average Size</SubLabel>
                        {marketAverageRatio !== 0 && (
                          <Number>{marketAverageRatio}%</Number>
                        )}
                        {marketAverageRatio > 0 && (
                          <SubLabel>
                            More area compared to market average
                          </SubLabel>
                        )}
                        {marketAverageRatio < 0 && (
                          <SubLabel>
                            Less area compared to market average
                          </SubLabel>
                        )}
                      </>
                  )}
                </NumbersWrapper>
              )}
            </Col>

            <Col withWidth="80%" center>
              <Chart
                options={{
                  ...options,
                  title: {
                    text: averageSize
                      ? 'Average Size per Suite'
                      : suites
                        ? 'Price per Suite'
                        : 'Price per Person'
                  },
                  tooltip: {
                    valuePrefix: measure !== 'Average Size' && '$',
                    valueSuffix: measure === 'Average Size' && ' sqft'
                  }
                }}
              />
            </Col>
          </Flex>
        </Suspense>
      </Wrap>
    </Flex>
  )
}

export default PricePerformance
