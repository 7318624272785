import React from 'react'
import styled from 'styled-components/macro'
import { Body } from '../../components/modal'

const LeadShortlist = ({ leadName }) => {
  return (
    <MessageBody>
      <p>Nice work!</p>
      <p>You{"'"}ve registered ${leadName || ''} with operators. They will be notified at :47 after the hour.</p>
    </MessageBody>
  )
}

const MessageBody = styled(Body)`
  max-width: 400px;
  width: 100%;
  background-color: white;
  padding: 20px;
  display: inline-block;
  font-size: 18px;
  line-height: 1.3;
  border-radius: 15px;
  border: 3px solid #e7483d;

  a {
    color: #e7483d;
    text-decoration: underline;
  }

  p {
    font-size: 20px;
  }
`

export default LeadShortlist
