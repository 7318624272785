import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  spacesByLocationIdGet,
  spaceCreate,
  spaceDelete,
  spaceUpdate,
} from '../../actions/spaces';
import { openSpacesFormModal } from '../../actions/modal';
import * as Styled from '../../components/spaces';
import Flex from '../../components/flex';
import {
  spaceAvailabilityFormat,
  spaceCapacityFormat,
  currencyBefore,
  spaceSqFtFormat,
  currencyFormat,
  spaceAvgPriceFormat,
} from '../../helpers/format';
import { selectVisibleSpaces, selectTypes } from '../../selectors/spaces';
import { Plus } from '../../components/icons';
import Spinner from '../../components/spinner';
import Form from '../SpaceForm';
import ConfirmData from '../ConfirmSpaceData';

import { smOnly } from '../../styles/breakpoints';
import { locationChangeStatus } from '../../actions/locations';

const SpaceList = (props) => {
  const { locationId } = props;
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState(undefined);
  const [orderDesc, setOrderDesc] = useState(true);
  const [activeTab, setActiveTab] = useState('All');
  const [editItemId, setEditItemId] = useState(null);
  const [duplicateId, setDuplicateId] = useState(null);
  const spaces = useSelector(state =>
    selectVisibleSpaces(state, { locationId, activeTab, orderBy, orderDesc }));
  const types = useSelector(state => selectTypes(state, { locationId }));
  const { loading } = useSelector(state => state.spaces.status);
  const error = useSelector(state => state.spaces.error.loading);
  const ids = useSelector(state => state.spaces.byLocationId[locationId]);
  const mobile = useMediaQuery({ query: smOnly });
  const { flexOfficePro } = useSelector(state => state.user);
  const showAveragePrice =
    flexOfficePro === 'Enterprise' || flexOfficePro === 'Advanced';
  const showPerHour = activeTab === 'Meeting Space';

  useEffect(() => {
    dispatch(spacesByLocationIdGet(locationId));
  }, [dispatch, locationId]);

  const sortBy = (by) => {
    const isDesc = by === orderBy && !orderDesc ? !orderDesc : false;
    setOrderBy(by);
    setOrderDesc(isDesc);
  };

  if (loading) {
    return (
      <Styled.Wrapper>
        <Flex fullWidth center>
          <Spinner elSize={40} />
        </Flex>
      </Styled.Wrapper>
    );
  }

  if (!error && !ids) {
    return (
      <Styled.Wrapper>
        <Flex fullWidth center>
          <span>This location doesn{"'"}t have any spaces</span>
        </Flex>
      </Styled.Wrapper>
    );
  }

  return (
    <Styled.Wrapper>
      <Flex fullWidth flexEnd>
        <Styled.Control
          onClick={() =>
            mobile
              ? dispatch(
                  openSpacesFormModal({
                    editItemId: 'new',
                    item: { locationId }
                  })
                )
              : setEditItemId('new')
          }
        >
          <Plus />
          <span>Add Space</span>
        </Styled.Control>
        <ConfirmData ids={ids} locationId={locationId} />
      </Flex>
      <Styled.Tabs>
        {types.map(type => (
          <Styled.Tab
            key={type}
            onClick={() => setActiveTab(type)}
            active={type === activeTab}
          >
            {type}
          </Styled.Tab>
        ))}
      </Styled.Tabs>
      <Styled.List>
        <Styled.Item>
          <Styled.Col>
            <Styled.Filter
              active={orderBy === 'name'}
              desc={orderDesc}
              onClick={() => sortBy('name')}
            >
              Name
            </Styled.Filter>
          </Styled.Col>
          <Styled.Col>
            <Styled.Filter
              active={orderBy === 'type'}
              desc={orderDesc}
              onClick={() => sortBy('type')}
            >
              Type
            </Styled.Filter>
          </Styled.Col>
          <Styled.Col>
            <Styled.Filter
              active={orderBy === 'capacity'}
              desc={orderDesc}
              onClick={() => sortBy('capacity')}
            >
              Suitable {<br />} For
            </Styled.Filter>
          </Styled.Col>
          <Styled.Col>
            <Styled.Filter
              active={orderBy === 'price'}
              desc={orderDesc}
              onClick={() => sortBy('price')}
            >
              Price {<br />} per Mo.
            </Styled.Filter>
          </Styled.Col>
          {showPerHour ? (
            <Styled.Col>
              <Styled.Filter
                active={orderBy === 'perHour'}
                desc={orderDesc}
                onClick={() => sortBy('perHour')}
              >
                Price {<br />} per Hour
              </Styled.Filter>
            </Styled.Col>
          ) : (
            <Styled.Col>
              <Styled.Filter
                active={orderBy === 'perDay'}
                desc={orderDesc}
                onClick={() => sortBy('perDay')}
              >
                Price {<br />} per Day
              </Styled.Filter>
            </Styled.Col>
          )}
          <Styled.Col>
            <Styled.Filter
              active={orderBy === 'sqft'}
              desc={orderDesc}
              onClick={() => sortBy('sqft')}
            >
              Sq/Ft
            </Styled.Filter>
          </Styled.Col>
          <Styled.Col>
            <Styled.Filter
              active={orderBy === 'date'}
              desc={orderDesc}
              onClick={() => sortBy('date')}
            >
              Date {<br />} Available
            </Styled.Filter>
          </Styled.Col>
          {showAveragePrice && (
            <Styled.Col>
              <Styled.Filter>Average {<br />}Price</Styled.Filter>
            </Styled.Col>
          )}
          <Styled.Col />
        </Styled.Item>
        {editItemId === 'new' && !mobile && (
          <Styled.Form>
            <Form
              initialValues={{
                isAvailable: true,
                units: 'sqFt',
                locationId
              }}
              onSubmit={data => {
                dispatch(spaceCreate(data)).then(success => {
                  if (success) {
                    setEditItemId(null)
                    dispatch(locationChangeStatus(locationId))
                  }
                })
              }}
              onClose={() => setEditItemId(null)}
            />
          </Styled.Form>
        )}
        {spaces.map(item => (
          <Fragment key={item.id}>
            {editItemId === item.id && !mobile && (
              <Styled.Form key={item.id}>
                <Form
                  initialValues={item}
                  onClose={() => setEditItemId(null)}
                  onRemove={() => {
                    dispatch(spaceDelete(item)).then(() => setEditItemId(null))
                  }}
                  onDuplicate={() => {
                    setEditItemId('duplicate')
                    setDuplicateId(item.id)
                  }}
                  onSubmit={data => {
                    dispatch(spaceUpdate(data)).then(success => {
                      if (success) {
                        setEditItemId(null)
                        dispatch(locationChangeStatus(locationId))
                      }
                    })
                  }}
                />
              </Styled.Form>
            )}
            {duplicateId === item.id && editItemId === 'duplicate' && !mobile && (
              <Styled.Form key="new">
                <Form
                  initialValues={{
                    ...item,
                    name: `${item.name}_copy`,
                    modifiedAt: null
                  }}
                  onSubmit={data => {
                    dispatch(spaceCreate(data)).then(success => {
                      if (success) {
                        setEditItemId(null)
                        dispatch(locationChangeStatus(locationId))
                      }
                    })
                  }}
                  onClose={() => setEditItemId(null)}
                />
              </Styled.Form>
            )}
            {editItemId !== item.id && (
              <Styled.Item key={item.id}>
                <Styled.Col>
                  <span>{item.name}</span>
                </Styled.Col>
                <Styled.Col>
                  <span>{item.spaceType}</span>
                </Styled.Col>
                <Styled.Col>
                  <span>{spaceCapacityFormat(item)}</span>
                </Styled.Col>
                <Styled.Col>
                  <span>{currencyBefore(currencyFormat(item.spacePrice))}</span>
                </Styled.Col>
                {showPerHour ? (
                  <Styled.Col>
                    <span>
                      {currencyBefore(currencyFormat(item.pricePerHour))}
                    </span>
                  </Styled.Col>
                ) : (
                  <Styled.Col>
                    <span>
                      {currencyBefore(currencyFormat(item.pricePerDay))}
                    </span>
                  </Styled.Col>
                )}
                <Styled.Col>
                  <span>{spaceSqFtFormat(item)}</span>
                </Styled.Col>
                <Styled.Col>
                  <span>{spaceAvailabilityFormat(item)}</span>
                </Styled.Col>
                {showAveragePrice && (
                  <Styled.Col>
                    <span>{spaceAvgPriceFormat(item)}</span>
                  </Styled.Col>
                )}
                <Styled.Col>
                  <Styled.Action
                    onClick={() =>
                      mobile
                        ? dispatch(
                            openSpacesFormModal({ editItemId: item.id, item })
                          )
                        : setEditItemId(item.id)
                    }
                  >
                    Edit
                  </Styled.Action>
                </Styled.Col>
              </Styled.Item>
            )}
          </Fragment>
        ))}
      </Styled.List>
    </Styled.Wrapper>
  )
};

export default SpaceList;
