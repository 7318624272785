import isEmpty from 'lodash/isEmpty';
import { createReducer } from '../utils/redux';
import {
  PRICE_PERFORMANCE_GET_REQUEST,
  PRICE_PERFORMANCE_GET_SUCCESS,
  PRICE_PERFORMANCE_GET_FAILURE,
} from '../constants';

const initialState = {
  byRoute: {},
  status: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
  },
  error: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
  },
};

export default createReducer(initialState, {
  [PRICE_PERFORMANCE_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true,
    },
    error: {
      ...initialState.error,
      loading: false,
    },
  }),
  [PRICE_PERFORMANCE_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    byRoute: {
      ...state.byRoute,
      [config]: !isEmpty(payload) ? payload : null,
    },
    status: {
      ...initialState.status,
      loading: false,
    },
    error: {
      ...initialState.error,
      loading: false,
    },
  }),
  [PRICE_PERFORMANCE_GET_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      loading: false,
    },
    error: {
      ...initialState.error,
      loading: payload || true,
    },
  }),
});
