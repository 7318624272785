import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getProposal = (token, locationId, flexleadid) =>
  fetch(
    `${apiUrl}${apiBase}/flex/proposal?locationId=${locationId}&flexleadid=${flexleadid}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )

export const updateProposal = (token, data) =>
  fetch(`${apiUrl}${apiBase}/flex/proposal`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const getLeadProposal = (token, flexleadid) =>
  fetch(
    `${apiUrl}${apiBase}/flex/proposal/lead?flexleadid=${flexleadid}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
