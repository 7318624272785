import fetch from 'isomorphic-fetch';
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey,
} from '../settings/api';

export const getPricePerformance = (token, params) =>
  fetch(`${apiUrl}${apiBase}/flex/priceperformance?${params}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
    },
  });
