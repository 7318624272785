import { SHOW_MODAL, HIDE_MODAL, HIDE_ALL_MODALS } from '../constants'

export const showModal = payload => {
  return {
    type: SHOW_MODAL,
    payload
  }
}

export const hideModal = () => {
  return {
    type: HIDE_MODAL
  }
}

export const hideAllModals = () => {
  return {
    type: HIDE_ALL_MODALS
  }
}

export const openSpacesFormModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'SPACES_FORM',
      modalProps: {
        canClose: true,
        showClose: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openSpaceDeleteModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'SPACE_REMOVE',
      modalProps: {
        canClose: true,
        showClose: false
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openIframeModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'IFRAME',
      modalProps: {
        canClose: true,
        showClose: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openInteractiveTourModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'INTERACTIVE_TOUR',
      modalProps: {
        canClose: true,
        showClose: true,
        iframe: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openImageInfoModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'IMAGE_INFO',
      modalProps: {
        canClose: true,
        showClose: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openImagePreviewModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'IMAGE_PREVIEW',
      modalProps: {
        canClose: true,
        showClose: true,
        isImage: true
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openLeadRejectModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'LEAD_REJECT',
      modalProps: {
        canClose: true,
        showClose: false
      },
      modalOptions: {
        ...options
      }
    })
  )

export const openSuspendedModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'SUSPENDED',
      modalProps: {
        canClose: false,
        showClose: false,
        modalType: 'message'
      },
      modalOptions: {
        ...options
      }
    })
  )


export const openLeadShortlistModal = options => dispatch =>
  dispatch(
    showModal({
      modalType: 'LEAD_SHORTLIST',
      modalProps: {
        canClose: true,
        showClose: true
      },
      modalOptions: {
        ...options
      }
    })
  )
