import React from 'react';
import Flex from '../../components/flex';
import Breadcrumbs from '../../containers/Breadcrumbs';

const Proposals = () => (
  <Flex fullWidth column full>
    <Breadcrumbs childRoute="Proposals" />
    <Flex fullWidth center middle>
      <h1>Proposals</h1>
    </Flex>
  </Flex>
);

export default Proposals;
