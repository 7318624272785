export const getAvailableLocations = (markets, locations, marketsById, locationsByMarketId, marketId) => {
  if (marketId === 'all') {
    return locations;
  }

  const isSubmarket = (marketsById[marketId] || {}).isSubmarket;
  const parentMarketId = (marketsById[marketId] || {}).parentMarketId;
  const id = isSubmarket && parentMarketId ? parentMarketId : marketId;
  const marketLocations = locationsByMarketId[id] || [];
  const submarkets = (markets || []).filter(submarket => submarket.isSubmarket && submarket.parentMarketId === id);
  const submarketLocations = submarkets.reduce((acc, submarket) => {
    const subLocations = locationsByMarketId[(submarket || {}).id] || [];

    return [...acc, ...subLocations];
  }, []);

  return [...marketLocations, ...submarketLocations];
}

export const getActiveMarket = (marketsById, marketId) => {
  if (!marketsById[marketId]) {
    return null;
  }

  const isSubmarket = (marketsById[marketId] || {}).isSubmarket;

  if (isSubmarket) {
    const parentMarketId = (marketsById[marketId] || {}).parentMarketId;

    return ({
      value: (marketsById[parentMarketId] || {}).id,
      label: (marketsById[parentMarketId] || {}).name,
    });
  }

  return ({
    value: marketsById[marketId].id,
    label: marketsById[marketId].name,
  });
}
