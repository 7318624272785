import React, { useState } from 'react'
import { Body } from '../../components/modal'
import { imageUrl } from '../../helpers/location-services'
import styled from 'styled-components/macro'
import { ImgArrow } from '../../components/icons'

const ImagePreview = ({ index, ids }) => {
  const [imgIndex, setImgIndex] = useState(index)

  return (
    <Body data-images="true">
      {index >= 0 && (
        <>
          {ids.length > 1 && (
            <BtnArrow
              data-prev={true}
              onClick={() => {
                const newIndex = imgIndex === 0 ? ids.length - 1 : imgIndex - 1

                setImgIndex(newIndex)
              }}
            >
              <ImgArrow />
            </BtnArrow>
          )}
          <ImgWrap>
            {ids.map((id, index) => (
              <Img
                key={`${id}-${index}`}
                src={imageUrl(id)}
                alt=""
                data-visible={index === imgIndex}
              />
            ))}
          </ImgWrap>
          {ids.length > 1 && (
            <BtnArrow
              data-next={true}
              onClick={() => {
                const newIndex = imgIndex === ids.length - 1 ? 0 : imgIndex + 1

                setImgIndex(newIndex)
              }}
            >
              <ImgArrow />
            </BtnArrow>
          )}
        </>
      )}
    </Body>
  )
}

const ImgWrap = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`

const Img = styled.img`
  max-height: calc(100vh - 90px);
  max-width: calc(100vw - 110px);
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  &:not([data-visible='true']) {
    opacity: 0;
    z-index: 0;
  }

  &[data-visible='true'] {
    opacity: 1;
    z-index: 1;
    position: relative;
  }
`

const BtnArrow = styled.button.attrs({ type: 'button' })`
  position: absolute;
  z-index: 2;
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 50%;
  height: 100%;
  top: 0;
  padding: 0;
  bottom: 0;

  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    color: #000;
    opacity: 0.5;
    transition: opacity 0.3s ease;
  }

  &[data-prev='true'] {
    left: 0;

    svg {
      transform: rotate(180deg);
      left: 10px;
    }

    &:hover svg {
      opacity: 0.8;
    }
  }

  &[data-next='true'] {
    right: 0;

    svg {
      right: 10px;
    }

    &:hover svg {
      opacity: 0.8;
    }
  }
`

export default ImagePreview
