import capitalize from 'lodash/capitalize'
import { getCopyrightText } from './copyright'

export const getPriceChart = (data = [], marketName = '', unit = '') => {
  const [quarter] = data

  return {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Asking Price Trends'
    },
    subtitle: {
      text: getCopyrightText(),
      style: {
        color: 'rgb(51, 51, 51)',
        fontFamily: '"Euclid Circular B", sans-serif',
        fontSize: '14px',
        padding: '5px 0 10px 0'
      },
      useHTML: true
    },
    xAxis: {
      categories: data.map(item => item.Label),
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0
      },
      series: {
        dataLabels: {
          enabled: true,
          allowOverlap: true,
          // eslint-disable-next-line no-template-curly-in-string
          format: '${y}'
        }
      }
    },
    series: [
      {
        color: 'rgb(231, 72, 61)',
        name: `${marketName} Asking Price Per ${capitalize(unit)}`,
        data: data.map(item => item.PricePerSeat)
      },
      {
        name: `${
          quarter?.Country
            ? quarter?.Country === 'US'
              ? quarter?.Country
              : 'Canadian'
            : 'US'
        } Average`,
        color: 'rgb(191, 191, 191)',
        data: data.map(item => item.NationalAverage)
      }
    ]
  }
}

export const getInventoryChart = (data = []) => {
  const quartersToExclude = ['2020 Q1', '2020 Q2', '2020 Q3', '2020 Q4']

  return {
    title: {
      text: 'Inventory & Vacancy Trends'
    },
    subtitle: {
      text: getCopyrightText(),
      style: {
        color: 'rgb(51, 51, 51)',
        fontFamily: '"Euclid Circular B", sans-serif',
        fontSize: '14px',
        padding: '5px 0 10px 0'
      },
      useHTML: true
    },
    xAxis: {
      categories: data.map(item => item.Label),
      crosshair: true
    },
    yAxis: [
      {
        min: 0,
        title: {
          text: null
        }
      },
      {
        min: 0,
        title: {
          text: null
        },
        opposite: true,
        labels: {
          formatter: (args) => `${args?.value || 0}%`
        }
      }
    ],
    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0
      },
      series: {
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        color: 'rgb(231, 72, 61)',
        name: 'Total Seats',
        data: data.map(item => item.TotalSeats),
        type: 'column',
        yAxis: 0
      },
      {
        color: 'rgb(87, 171, 131)',
        name: 'Vacant Seats ',
        data: data.map(item => item.VacantSeats),
        type: 'column',
        yAxis: 0
      },
      {
        name: 'Vacancy Rate',
        data: data.map(item => {
          if (quartersToExclude.includes(item.Label)) {
            return null
          }

          return Math.round(item.VacancyRate * 100)
        }),
        type: 'spline',
        label: {
          enabled: false,
          style: { color: 'black' }
        },
        tooltip: {
          valueSuffix: '%'
        },
        yAxis: 1
      }
    ]
  }
}

export const getDynamixChart = (data = [], marketName, marketCountry) => {
  return {
    chart: {
      type: 'line'
    },
    title: {
      text: 'Demand Trends'
    },
    subtitle: {
      text: getCopyrightText(),
      style: {
        color: 'rgb(51, 51, 51)',
        fontFamily: '"Euclid Circular B", sans-serif',
        fontSize: '14px',
        padding: '5px 0 10px 0'
      },
      useHTML: true
    },
    xAxis: {
      categories: data.map(item => item.label),
      crosshair: true
    },
    yAxis: {
      min: 0,
      title: {
        text: null
      }
    },
    plotOptions: {
      column: {
        pointPadding: 0.1,
        borderWidth: 0
      },
      series: {
        dataLabels: {
          enabled: false
        }
      }
    },
    series: [
      {
        name: `${marketName || ''} Demand`,
        data: data.map(item => Math.round(item.searches))
      },
      {
        name: marketCountry === 'Canada' ? 'Canadian Average' : 'US Average',
        color: 'rgb(231, 72, 61)',
        data: data.map(item => Math.round(item.average))
      }
    ]
  }
}
