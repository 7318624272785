import React, { useState, lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import Tooltip from '../../components/tooltip'
import Select from 'react-select'
import { getOptions } from './options'
import Flex from '../flex'
import Spinner from '../spinner'

const Chart = lazy(() => import('../chart'))

const selectDataNames = ['Views in Search ', '# of Leads', '# of Seats']
const xAxis = ['# of Views', '# of Leads', '# of Seats']
const tooltips = [
  'Views in Search shows the average number of times for the market that locations were viewed in the Upsuite platform.',
  'Demand Trends - Number of Leads compares the total number of leads that were introduced to operators for the selected area against the market total.',
  'Demand Trends - Number of Seats compares the total number of lead seats for the selected area against the market total.'
]

const MarketDemandTrends = ({ data, marketData, type }) => {
  const [selectIndex, setSelectIndex] = useState(0)
  const typeValues = data[selectIndex]
  const marketValues = marketData[selectIndex]
  const categories = (marketValues.length > 0 ? marketValues : typeValues).map(
    (item, index) => (index % 4 === 0 ? 2020 + (index % 3) : '')
  )
  const options = getOptions({ selected: xAxis[selectIndex], type, typeValues, marketValues, categories })

  return (
    <Wrap>
      <Title>
        <span>Demand Trends</span>
        <span>{selectDataNames[selectIndex]}{' '}<Tooltip
          overlay={
            <div>
              <span>{tooltips[selectIndex]}</span>
            </div>
          }
        >
          <Info>(i)</Info>
        </Tooltip></span>
      </Title>
      <Container>
        <Row>
          <Select
            options={selectDataNames.map((item, index) => ({
              label: item,
              value: index
            }))}
            value={{
              value: selectIndex,
              label: selectDataNames[selectIndex]
            }}
            onChange={({ value }) => {
              setSelectIndex(value)
            }}
            isClearable={false}
            isSearchable={false}
            styles={{
              container: provided => ({
                ...provided,
                width: 155
              }),
              indicatorsContainer: (provided, state) => {
                return {
                  ...provided,
                  minWidth: 20,
                  '> div': {
                    padding: 6
                  },
                  svg: {
                    display: 'none'
                  },
                  '&::after': {
                    content: '""',
                    borderStyle: 'solid',
                    borderColor: `${
                      state.hasValue || state.selectProps.menuIsOpen
                        ? '#e7483d'
                        : 'hsl(0,0%,80%)'
                    } transparent transparent`,
                    borderWidth: '5px 5px 2.5px',
                    display: 'inline-block',
                    height: 0,
                    width: 0,
                    position: 'absolute',
                    right: '10px',
                    top: '14px'
                  }
                }
              },
              indicatorSeparator: provided => ({
                ...provided,
                display: 'none'
              }),
              valueContainer: provided => ({
                ...provided,
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                fontSize: 15
              }),
              control: (provided) => {
                return {
                  ...provided,
                  minHeight: 35,
                  borderRadius: 3,
                  borderColor: '#e7483d',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: '#e7483d'
                  }
                }
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected && 'transparent',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                fontSize: 15,
                color: '#000',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'hsl(0,0%,90%)'
                }
              }),
              menu: (provided) => ({
                ...provided,
                border: '1px solid #e7483d',
                boxShadow: 'none'
              })
            }}
          />
        </Row>
        <ChartWrap>
          <Suspense
            fallback={
              <Flex fullWidth fullHeight center>
                <Spinner elSize={40} />
              </Flex>
            }
          >
            <Chart options={options} />
          </Suspense>
        </ChartWrap>
      </Container>
    </Wrap>
  )
}

const ChartWrap = styled.div`
  width: 100%;

  .highcharts-credits {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`

const Info = styled.span`
  margin-right: 3px;
  font-weight: 700;
  cursor: pointer;
`

const Wrap = styled.div`
  width: 100%;
  border: 1px solid #57ab83;
  border-radius: 10px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  background-color: white;
  max-width: 600px;
  margin-bottom: 10px;
`

const Title = styled.span`
  color: #e7483d;
  font-weight: 700;
  text-align: center;
  display: block;
  font-size: 20px;
  margin-bottom: 14px;

  > span {
    display: block;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export default MarketDemandTrends
