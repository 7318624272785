export const AUTHCHECK_REQUEST = 'AUTHCHECK_REQUEST'
export const AUTHCHECK_SUCCESS = 'AUTHCHECK_SUCCESS'
export const AUTHCHECK_FAILURE = 'AUTHCHECK_FAILURE'
export const PROFILE_PIC_GET_REQUEST = 'PROFILE_PIC_GET_REQUEST'
export const PROFILE_PIC_GET_SUCCESS = 'PROFILE_PIC_GET_SUCCESS'
export const PROFILE_PIC_GET_FAILURE = 'PROFILE_PIC_GET_FAILURE'
export const PROFILE_PIC_UPDATE_REQUEST = 'PROFILE_PIC_UPDATE_REQUEST'
export const PROFILE_PIC_UPDATE_SUCCESS = 'PROFILE_PIC_UPDATE_SUCCESS'
export const PROFILE_PIC_UPDATE_FAILURE = 'PROFILE_PIC_UPDATE_FAILURE'
export const AUTH_SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUEST'
export const AUTH_SIGNIN_SUCCESS = 'AUTH_SIGNIN_SUCCESS'
export const AUTH_SIGNIN_FAILURE = 'AUTH_SIGNIN_FAILURE'
export const AUTH_SESSION_REQUEST = 'AUTH_SESSION_REQUEST'
export const AUTH_SESSION_SUCCESS = 'AUTH_SESSION_SUCCESS'
export const AUTH_SESSION_FAILURE = 'AUTH_SESSION_FAILURE'
export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const PASSWORD_RECOVERY_REQUEST = 'PASSWORD_RECOVERY_REQUEST'
export const PASSWORD_RECOVERY_SUCCESS = 'PASSWORD_RECOVERY_SUCCESS'
export const PASSWORD_RECOVERY_FAILURE = 'PASSWORD_RECOVERY_FAILURE'

// Locations
export const LOCATIONS_GET_REQUEST = 'LOCATIONS_GET_REQUEST'
export const LOCATIONS_GET_SUCCESS = 'LOCATIONS_GET_SUCCESS'
export const LOCATIONS_GET_FAILURE = 'LOCATIONS_GET_FAILURE'
export const LOCATIONS_BY_MARKET_ID_GET_REQUEST = 'LOCATIONS_BY_MARKET_ID_GET_REQUEST'
export const LOCATIONS_BY_MARKET_ID_GET_SUCCESS = 'LOCATIONS_BY_MARKET_ID_GET_SUCCESS'
export const LOCATIONS_BY_MARKET_ID_GET_FAILURE = 'LOCATIONS_BY_MARKET_ID_GET_FAILURE'
export const LOCATION_UPDATE_REQUEST = 'LOCATION_UPDATE_REQUEST'
export const LOCATION_UPDATE_SUCCESS = 'LOCATION_UPDATE_SUCCESS'
export const LOCATION_UPDATE_FAILURE = 'LOCATION_UPDATE_FAILURE'
export const LOCATION_GET_REQUEST = 'LOCATION_GET_REQUEST'
export const LOCATION_GET_SUCCESS = 'LOCATION_GET_SUCCESS'
export const LOCATION_GET_FAILURE = 'LOCATION_GET_FAILURE'
export const LOCATION_CHANGE_STATUS = 'LOCATION_CHANGE_STATUS'
export const LOCATION_BY_IDS_GET_REQUEST = 'LOCATION_BY_IDS_GET_REQUEST'
export const LOCATION_BY_IDS_GET_SUCCESS = 'LOCATION_BY_IDS_GET_SUCCESS'
export const LOCATION_BY_IDS_GET_FAIL = 'LOCATION_BY_IDS_GET_FAIL'

// Spaces
export const SPACES_BY_LOC_ID_GET_REQUEST = 'SPACES_BY_LOC_ID_GET_REQUEST'
export const SPACES_BY_LOC_ID_GET_SUCCESS = 'SPACES_BY_LOC_ID_GET_SUCCESS'
export const SPACES_BY_LOC_ID_GET_FAILURE = 'SPACES_BY_LOC_ID_GET_FAILURE'
export const AVAILABLE_SPACES_REQUEST = 'AVAILABLE_SPACES_REQUEST'
export const AVAILABLE_SPACES_SUCCESS = 'AVAILABLE_SPACES_SUCCESS'
export const AVAILABLE_SPACES_FAILURE = 'AVAILABLE_SPACES_FAILURE'
export const SPACES_CREATE_REQUEST = 'SPACES_CREATE_REQUEST'
export const SPACES_CREATE_SUCCESS = 'SPACES_CREATE_SUCCESS'
export const SPACES_CREATE_FAILURE = 'SPACES_CREATE_FAILURE'
export const SPACES_UPDATE_REQUEST = 'SPACES_UPDATE_REQUEST'
export const SPACES_UPDATE_SUCCESS = 'SPACES_UPDATE_SUCCESS'
export const SPACES_UPDATE_FAILURE = 'SPACES_UPDATE_FAILURE'
export const SPACES_DELETE_REQUEST = 'SPACES_DELETE_REQUEST'
export const SPACES_DELETE_SUCCESS = 'SPACES_DELETE_SUCCESS'
export const SPACES_DELETE_FAILURE = 'SPACES_DELETE_FAILURE'

// Leads
export const LEADS_BY_LOC_ID_GET_REQUEST = 'LEADS_BY_LOC_ID_GET_REQUEST'
export const LEADS_BY_LOC_ID_GET_SUCCESS = 'LEADS_BY_LOC_ID_GET_SUCCESS'
export const LEADS_BY_LOC_ID_GET_FAILURE = 'LEADS_BY_LOC_ID_GET_FAILURE'
export const LEAD_PROPOSAL_SHOW = 'LEAD_PROPOSAL_SHOW'
export const LEAD_PROPOSAL_HIDE = 'LEAD_PROPOSAL_HIDE'
export const LEAD_REJECT_REQUEST = 'LEAD_REJECT_REQUEST'
export const LEAD_REJECT_SUCCESS = 'LEAD_REJECT_SUCCESS'
export const LEAD_REJECT_FAILURE = 'LEAD_REJECT_FAILURE'

// Location services
export const LOCATION_SERVICES_GET_REQUEST = 'LOCATION_SERVICES_GET_REQUEST'
export const LOCATION_SERVICES_GET_SUCCESS = 'LOCATION_SERVICES_GET_SUCCESS'
export const LOCATION_SERVICES_GET_FAILURE = 'LOCATION_SERVICES_GET_FAILURE'
export const LOCATION_SERVICES_LANDLORDS_REQUEST = 'LOCATION_SERVICES_LANDLORDS_REQUEST'
export const LOCATION_SERVICES_LANDLORDS_SUCCESS = 'LOCATION_SERVICES_LANDLORDS_SUCCESS'
export const LOCATION_SERVICES_LANDLORDS_FAILURE = 'LOCATION_SERVICES_LANDLORDS_FAILURE'
export const LOCATION_SERVICES_FILTER = 'LOCATION_SERVICES_FILTER'
export const LOCATION_SERVICES_CLEAR = 'LOCATION_SERVICES_CLEAR'
export const LOCATION_SERVICES_REMOVE = 'LOCATION_SERVICES_REMOVE'
export const LOCATION_SERVICES_OPEN = 'LOCATION_SERVICES_OPEN'
export const LOCATION_SERVICES_CLOSE = 'LOCATION_SERVICES_CLOSE'
export const LOCATION_SERVICES_CLOSED_GET_REQUEST = 'LOCATION_SERVICES_CLOSED_GET_REQUEST'
export const LOCATION_SERVICES_CLOSED_GET_SUCCESS = 'LOCATION_SERVICES_CLOSED_GET_SUCCESS'
export const LOCATION_SERVICES_CLOSED_GET_FAILURE = 'LOCATION_SERVICES_CLOSED_GET_FAILURE'
export const HIGHLIGHT_MARKER = 'HIGHLIGHT_MARKER'
export const MAP_PINS_TO_FEED = 'MAP_PINS_TO_FEED'
export const LOCATION_SERVICES_TOGGLE_VIEW = 'LOCATION_SERVICES_TOGGLE_VIEW'
export const LEAD_OWNERS_GET_REQUEST = 'LEAD_OWNERS_GET_REQUEST'
export const LEAD_OWNERS_GET_SUCCESS = 'LEAD_OWNERS_GET_SUCCESS'
export const LEAD_OWNERS_GET_FAILURE = 'LEAD_OWNERS_GET_FAILURE'
export const LEAD_LOCATIONS_GET_REQUEST = 'LEAD_LOCATIONS_GET_REQUEST'
export const LEAD_LOCATIONS_GET_SUCCESS = 'LEAD_LOCATIONS_GET_SUCCESS'
export const LEAD_LOCATIONS_GET_FAILURE = 'LEAD_LOCATIONS_GET_FAILURE'

// All location services
export const LOCATION_SERVICES_ALL_GET_REQUEST = 'LOCATION_SERVICES_ALL_GET_REQUEST'
export const LOCATION_SERVICES_ALL_GET_SUCCESS = 'LOCATION_SERVICES_ALL_GET_SUCCESS'
export const LOCATION_SERVICES_ALL_GET_FAILURE = 'LOCATION_SERVICES_ALL_GET_FAILURE'
export const LOCATION_SERVICES_ALL_FILTER = 'LOCATION_SERVICES_ALL_FILTER'
export const LOCATION_SERVICES_ALL_CLEAR = 'LOCATION_SERVICES_ALL_CLEAR'
export const LOCATION_SERVICES_ALL_REMOVE = 'LOCATION_SERVICES_ALL_REMOVE'
export const LOCATION_SERVICES_ALL_OPEN = 'LOCATION_SERVICES_ALL_OPEN'
export const LOCATION_SERVICES_ALL_CLOSE = 'LOCATION_SERVICES_ALL_CLOSE'
export const LOCATION_SERVICES_ALL_CENTER = 'LOCATION_SERVICES_ALL_CENTER'
export const HIGHLIGHT_MARKER_ALL = 'HIGHLIGHT_MARKER_ALL'
export const MAP_PINS_TO_FEED_ALL = 'MAP_PINS_TO_FEED_ALL'
export const LOCATION_SERVICES_ALL_TOGGLE_VIEW = 'LOCATION_SERVICES_ALL_TOGGLE_VIEW'

// Market summary
export const MARKET_SUMMARY_GET_REQUEST = 'MARKET_SUMMARY_GET_REQUEST'
export const MARKET_SUMMARY_GET_SUCCESS = 'MARKET_SUMMARY_GET_SUCCESS'
export const MARKET_SUMMARY_GET_FAIL = 'MARKET_SUMMARY_GET_FAIL'
export const MARKET_LOCATIONS_DATA_REQUEST = 'MARKET_LOCATIONS_DATA_REQUEST'
export const MARKET_LOCATIONS_DATA_SUCCESS = 'MARKET_LOCATIONS_DATA_SUCCESS'
export const MARKET_LOCATIONS_DATA_FAIL = 'MARKET_LOCATIONS_DATA_FAIL'
export const MARKET_LOCATIONS_ALL_DATA_REQUEST = 'MARKET_LOCATIONS_ALL_DATA_REQUEST'
export const MARKET_LOCATIONS_ALL_DATA_SUCCESS = 'MARKET_LOCATIONS_ALL_DATA_SUCCESS'
export const MARKET_LOCATIONS_ALL_DATA_FAIL = 'MARKET_LOCATIONS_ALL_DATA_FAIL'

export const MAP_SUMMARY_GET_REQUEST = 'MAP_SUMMARY_GET_REQUEST'
export const MAP_SUMMARY_GET_SUCCESS = 'MAP_SUMMARY_GET_SUCCESS'
export const MAP_SUMMARY_GET_FAILURE = 'MAP_SUMMARY_GET_FAILURE'

// Broker Tools
export const MONTHLY_LEADS_TOTAL_REQUEST = 'MONTHLY_LEADS_TOTAL_REQUEST'
export const MONTHLY_LEADS_TOTAL_SUCCESS = 'MONTHLY_LEADS_TOTAL_SUCCESS'
export const MONTHLY_LEADS_TOTAL_FAIL = 'MONTHLY_LEADS_TOTAL_FAIL'
export const LATEST_LEADS_REQUEST = 'LATEST_LEADS_REQUEST'
export const LATEST_LEADS_SUCCESS = 'LATEST_LEADS_SUCCESS'
export const LATEST_LEADS_FAIL = 'LATEST_LEADS_FAIL'
export const SET_NORTH_AMERICA = 'SET_NORTH_AMERICA'
export const WORK_LEADS_REQUEST = 'WORK_LEADS_REQUEST'
export const WORK_LEADS_SUCCESS = 'WORK_LEADS_SUCCESS'
export const WORK_LEADS_FAIL = 'WORK_LEADS_FAIL'
export const LEAD_UPDATE_REQUEST = 'LEAD_UPDATE_REQUEST'
export const LEAD_UPDATE_SUCCESS = 'LEAD_UPDATE_SUCCESS'
export const LEAD_UPDATE_FAIL = 'LEAD_UPDATE_FAIL'
export const LEAD_ADD_REQUEST = 'LEAD_ADD_REQUEST'
export const LEAD_ADD_SUCCESS = 'LEAD_ADD_SUCCESS'
export const LEAD_ADD_FAIL = 'LEAD_ADD_FAIL'
export const EDIT_LEAD_SHOW = 'EDIT_LEAD_SHOW'
export const EDIT_LEAD_HIDE = 'EDIT_LEAD_HIDE'
export const ADD_LEAD_SHOW = 'ADD_LEAD_SHOW'
export const ADD_LEAD_HIDE = 'ADD_LEAD_HIDE'
export const SHOW_LEAD_SHORTLIST = 'SHOW_LEAD_SHORTLIST'
export const HIDE_LEAD_SHORTLIST = 'HIDE_LEAD_SHORTLIST'
export const SHOW_LEAD_PROPOSALS = 'SHOW_LEAD_PROPOSALS'
export const HIDE_LEAD_PROPOSALS = 'HIDE_LEAD_PROPOSALS'
export const ADD_TO_SHORTLIST = 'ADD_TO_SHORTLIST'
export const BUNCH_ADD_TO_SHORTLIST = 'BUNCH_ADD_TO_SHORTLIST'
export const REMOVE_FROM_SHORTLIST = 'REMOVE_FROM_SHORTLIST'

// Modal
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS'

// Pipeline
export const PIPELINE_GET_REQUEST = 'PIPELINE_GET_REQUEST'
export const PIPELINE_GET_SUCCESS = 'PIPELINE_GET_SUCCESS'
export const PIPELINE_GET_FAILURE = 'PIPELINE_GET_FAILURE'

// Agreements and Fees
export const AGREEMENTS_AND_FEES_GET_REQUEST = 'AGREEMENTS_AND_FEES_GET_REQUEST'
export const AGREEMENTS_AND_FEES_GET_SUCCESS = 'AGREEMENTS_AND_FEES_GET_SUCCESS'
export const AGREEMENTS_AND_FEES_GET_FAILURE = 'AGREEMENTS_AND_FEES_GET_FAILURE'

// Markets
export const MARKETS_GET_REQUEST = 'MARKETS_GET_REQUEST'
export const MARKETS_GET_SUCCESS = 'MARKETS_GET_SUCCESS'
export const MARKETS_GET_FAILURE = 'MARKETS_GET_FAILURE'

export const MARKETS_ALL_GET_REQUEST = 'MARKETS_ALL_GET_REQUEST'
export const MARKETS_ALL_GET_SUCCESS = 'MARKETS_ALL_GET_SUCCESS'
export const MARKETS_ALL_GET_FAILURE = 'MARKETS_ALL_GET_FAILURE'

// Views in Search
export const VIEWS_IN_SEARCH_GET_REQUEST = 'VIEWS_IN_SEARCH_GET_REQUEST'
export const VIEWS_IN_SEARCH_GET_SUCCESS = 'VIEWS_IN_SEARCH_GET_SUCCESS'
export const VIEWS_IN_SEARCH_GET_FAILURE = 'VIEWS_IN_SEARCH_GET_FAILURE'

// Lead Performance
export const LEAD_PERFORMANCE_GET_REQUEST = 'LEAD_PERFORMANCE_GET_REQUEST'
export const LEAD_PERFORMANCE_GET_SUCCESS = 'LEAD_PERFORMANCE_GET_SUCCESS'
export const LEAD_PERFORMANCE_GET_FAILURE = 'LEAD_PERFORMANCE_GET_FAILURE'

export const LEADS_AND_VIEWS_GET_REQUEST = 'LEADS_AND_VIEWS_GET_REQUEST'
export const LEADS_AND_VIEWS_GET_SUCCESS = 'LEADS_AND_VIEWS_GET_SUCCESS'
export const LEADS_AND_VIEWS_GET_FAILURE = 'LEADS_AND_VIEWS_GET_FAILURE'

// Price Performance
export const PRICE_PERFORMANCE_GET_REQUEST = 'PRICE_PERFORMANCE_GET_REQUEST'
export const PRICE_PERFORMANCE_GET_SUCCESS = 'PRICE_PERFORMANCE_GET_SUCCESS'
export const PRICE_PERFORMANCE_GET_FAILURE = 'PRICE_PERFORMANCE_GET_FAILURE'

// HOODS
export const HOODS_GET_REQUEST = 'HOODS_GET_REQUEST'
export const HOODS_GET_SUCCESS = 'HOODS_GET_SUCCESS'
export const HOODS_GET_FAILURE = 'HOODS_GET_FAILURE'
export const HOODS_ALL_GET_REQUEST = 'HOODS_ALL_GET_REQUEST'
export const HOODS_ALL_GET_SUCCESS = 'HOODS_ALL_GET_SUCCESS'
export const HOODS_ALL_GET_FAILURE = 'HOODS_ALL_GET_FAILURE'

// Market Reports
export const INVENTORY_VACANCY_REQUEST = 'INVENTORY_VACANCY_REQUEST'
export const INVENTORY_VACANCY_SUCCESS = 'INVENTORY_VACANCY_SUCCESS'
export const INVENTORY_VACANCY_FAILURE = 'INVENTORY_VACANCY_FAILURE'

export const FORECAST_REQUEST = 'FORECAST_REQUEST'
export const FORECAST_SUCCESS = 'FORECAST_SUCCESS'
export const FORECAST_FAILURE = 'FORECAST_FAILURE'

export const SUPPLYANDDEMAND_REQUEST = 'SUPPLYANDDEMAND_REQUEST'
export const SUPPLYANDDEMAND_SUCCESS = 'SUPPLYANDDEMAND_SUCCESS'
export const SUPPLYANDDEMAND_FAILURE = 'SUPPLYANDDEMAND_FAILURE'

export const SUMMERY_TEXT_REQUEST = 'SUMMERY_TEXT_REQUEST'
export const SUMMERY_TEXT_SUCCESS = 'SUMMERY_TEXT_SUCCESS'
export const SUMMERY_TEXT_FAILURE = 'SUMMERY_TEXT_FAILURE'

export const ASKING_PRICE_REQUEST = 'ASKING_PRICE_REQUEST'
export const ASKING_PRICE_SUCCESS = 'ASKING_PRICE_SUCCESS'
export const ASKING_PRICE_FAILURE = 'ASKING_PRICE_FAILURE'

export const ASKING_PRICE_PER_REQUEST = 'ASKING_PRICE_PER_REQUEST'
export const ASKING_PRICE_PER_SUCCESS = 'ASKING_PRICE_PER_SUCCESS'
export const ASKING_PRICE_PER_FAILURE = 'ASKING_PRICE_PER_FAILURE'

export const DEMAND_DYNAMIX_REQUEST = 'DEMAND_DYNAMIX_REQUEST'
export const DEMAND_DYNAMIX_SUCCESS = 'DEMAND_DYNAMIX_SUCCESS'
export const DEMAND_DYNAMIX_FAILURE = 'DEMAND_DYNAMIX_FAILURE'

export const NEW_CLOSED_LOC_REQUEST = 'NEW_CLOSED_LOC_REQUEST'
export const NEW_CLOSED_LOC_SUCCESS = 'NEW_CLOSED_LOC_SUCCESS'
export const NEW_CLOSED_LOC_FAILURE = 'NEW_CLOSED_LOC_FAILURE'

// Market Summaries
export const LOCATIONS_WITH_LEADS_REQUEST = 'LOCATIONS_WITH_LEADS_REQUEST'
export const LOCATIONS_WITH_LEADS_SUCCESS = 'LOCATIONS_WITH_LEADS_SUCCESS'
export const LOCATIONS_WITH_LEADS_FAILURE = 'LOCATIONS_WITH_LEADS_FAILURE'

export const MARKETS_WITH_SEARCH_REQUEST = 'MARKETS_WITH_SEARCH_REQUEST'
export const MARKETS_WITH_SEARCH_SUCCESS = 'MARKETS_WITH_SEARCH_SUCCESS'
export const MARKETS_WITH_SEARCH_FAILURE = 'MARKETS_WITH_SEARCH_FAILURE'

// Proposal
export const PROPOSAL_GET_REQUEST = 'PROPOSAL_GET_REQUEST'
export const PROPOSAL_GET_SUCCESS = 'PROPOSAL_GET_SUCCESS'
export const PROPOSAL_GET_FAILURE = 'PROPOSAL_GET_FAILURE'
export const PROPOSAL_UPDATE_REQUEST = 'PROPOSAL_UPDATE_REQUEST'
export const PROPOSAL_UPDATE_SUCCESS = 'PROPOSAL_UPDATE_SUCCESS'
export const PROPOSAL_UPDATE_FAILURE = 'PROPOSAL_UPDATE_FAILURE'
export const PROPOSAL_UPDATE_STATE = 'PROPOSAL_UPDATE_STATE'
export const PROPOSAL_LEAD_CREATED = 'PROPOSAL_LEAD_CREATED'
export const PROPOSAL_LEAD_GET_REQUEST = 'PROPOSAL_LEAD_GET_REQUEST'
export const PROPOSAL_LEAD_GET_SUCCESS = 'PROPOSAL_LEAD_GET_SUCCESS'
export const PROPOSAL_LEAD_GET_FAILURE = 'PROPOSAL_LEAD_GET_FAILURE'
