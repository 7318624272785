import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { filter as lodashFilter, chunk } from 'lodash'
import ReactPaginate from 'react-paginate'
import styled from 'styled-components/macro'
import Flex from '../../components/flex'
import Spinner from '../../components/spinner'
import GoogleMapReact from 'google-map-react'
import orderBy from 'lodash/orderBy'
import { MAPS_API_KEY as mapsKey } from '../../settings/api'
import { filterLocationsAll, getFilterList } from '../../helpers/location-services'
import { locationServicesAllClose, locationServicesAllOpen } from '../../actions/location-services-all'
import { mdUp } from '../../styles/breakpoints'
import { locationServicesAllGet } from '../../actions/location-services-all'

const List = ({ onGoogleApiLoaded = () => {} }) => {
  const dispatch = useDispatch()
  const { filter } = useSelector(state => state.locationServicesAll)
  const filterList = getFilterList(filter)
  const allTotal = useSelector(state => state.locationServicesAll.total)
  const allLoading = useSelector(state => state.locationServicesAll.status.all)
  const loading = useSelector(state => {
    const marketId = state.locationServicesAll.filter.marketId
    const search = state.locationServicesAll.filter.search

    if (!(marketId || search)) {
      return !allTotal && state.locationServicesAll.status.all
    }

    return state.locationServicesAll.status.loading
  })
  const locations = useSelector(state => {
    const marketId = state.locationServicesAll.filter.marketId
    const search = state.locationServicesAll.filter.search

    if (marketId) {
      return state.locationServicesAll.byMarketId[marketId]
    }

    if (search) {
      return state.locationServicesAll.bySearch[search]
    }

    if (!(search || marketId)) {
      return state.locationServicesAll.allLocations
    }
  })
  const filtered = filterLocationsAll(filter, locations)
  const pinIds = useSelector(state => state.locationServicesAll.pinIds)
  const filteredWithPins = lodashFilter(
    filtered,
    item => pinIds.length === 0 || pinIds.some(id => item.id === id)
  )
  const [order, setOrderBy] = useState(undefined)
  const [orderDesc, setOrderDesc] = useState(true)
  const [page, setPage] = useState(0)
  const sortBy = by => {
    setPage(0)
    const isDesc = by === order && !orderDesc ? !orderDesc : false
    setOrderBy(by)
    setOrderDesc(isDesc)
  }
  const ordered = orderBy(
    filteredWithPins,
    item => {
      if (order === 'total_square_footage') {
        return parseInt(item.total_square_footage, 10)
      }

      if (order === 'ls_total') {
        return parseInt(item.ls_total, 10)
      }

      return item[order]
    },
    orderDesc ? 'desc' : 'asc'
  )
  const perPage = 20
  const totalVisible = (filter.marketId || filter.search) || filterList.length > 0 ? ordered.length : allTotal
  const total = ordered.length
  const pageCount = Math.ceil((total || 0) / perPage)
  const paginated = chunk(ordered, perPage)

  useEffect(() => {
    return () => dispatch(locationServicesAllClose())
  }, [dispatch])

  useEffect(() => {
    setPage(0)
  }, [filter])

  useEffect(() => {
    async function fetchData() {
      await dispatch(locationServicesAllGet({ page: 0, pagesize: 40, initial: true, all: true }))
      await dispatch(locationServicesAllGet({ page: 0, pagesize: 2000, all: true }))
      await dispatch(locationServicesAllGet({ page: 1, pagesize: 2000, all: true }))
      await dispatch(locationServicesAllGet({ page: 2, pagesize: 2000, all: true }))
    }

    if (filter.marketId || filter.search) {
      dispatch(
        locationServicesAllGet({
          marketId: filter.marketId,
          search: filter.search
        })
      )
    }
    if (!(filter.marketId || filter.search)) {
      fetchData()
    }
  }, [dispatch, filter.marketId, filter.search])

  return (
    <HiddenWrap>
      <MainWrap fullWidth column>
        <Wrap>
          {!loading && (
            <FilterSticky>
              <NumberOfLocations>
                <span>{`${totalVisible} Location${
                  totalVisible > 1 || totalVisible === 0 ? 's' : ''
                } Found`}</span>
              </NumberOfLocations>
            </FilterSticky>
          )}
          <TableWrap data-loading={total === 0}>
            <Table data-stretch="true" data-loading={total === 0}>
              <Row data-locked="true">
                <Col data-locked="true">
                  <FilterText>Location Name</FilterText>
                </Col>
                <Col>
                  <FilterBtn
                    onClick={() => sortBy('op_name')}
                    data-desc={orderDesc && order === 'op_name'}
                  >
                    Operator
                  </FilterBtn>
                </Col>
                <Col>
                  <FilterText>Market</FilterText>
                </Col>
                <Col>
                  <FilterText>Neighborhood</FilterText>
                </Col>
                <Col>
                  <FilterBtn
                    onClick={() => sortBy('ls_total')}
                    data-desc={orderDesc && order === 'ls_total'}
                  >
                    Location Score Total
                  </FilterBtn>
                </Col>
                {/* <Col>
                  <FilterText>Size Class</FilterText>
                </Col> */}
                <Col>
                  <FilterBtn
                    onClick={() => sortBy('total_square_footage')}
                    data-desc={orderDesc && order === 'total_square_footage'}
                  >
                    Square Feet
                  </FilterBtn>
                </Col>
                <Col>
                  <FilterText>Status</FilterText>
                </Col>
                <Col>
                  <FilterText>Type</FilterText>
                </Col>
                {/* <Col>
                  <FilterText>Landlord Company Name</FilterText>
                </Col> */}
              </Row>
              {(paginated[page] || []).map(item => (
                <Row key={item.id}>
                  <Col data-locked="true">
                    {item.name && (
                      <LocationNameBtn
                        onClick={() => dispatch(locationServicesAllOpen(item))}
                      >
                        {item.name}
                      </LocationNameBtn>
                    )}
                  </Col>
                  <Col>
                    <span>{item.op_name || ''}</span>
                  </Col>
                  <Col>
                    <span>{item.market_name || ''}</span>
                  </Col>
                  <Col>
                    <span>{item.nbh_name || ''}</span>
                  </Col>
                  <Col>
                    <span>{item.ls_total || ''}</span>
                  </Col>
                  {/* <Col>
                    {item.size_class === 'Over 15000' ? (
                      <span>Over 15,000</span>
                    ) : item.size_class === '5000 - 15000' ||
                      item.size_class === '5000 to 15000' ? (
                      <span>5,000 - 15,000</span>
                    ) : item.size_class === 'Under 5000' ? (
                      <span>Under 5,000</span>
                    ) : (
                      <span></span>
                    )}
                  </Col> */}
                  <Col>
                    <span>
                      {item.total_square_footage
                        ? parseInt(
                            item.total_square_footage,
                            10
                          ).toLocaleString()
                        : ''}
                    </span>
                  </Col>
                  <Col>
                    <span>{item.health_status || ''}</span>
                  </Col>
                  <Col>
                    <span>{item.type || ''}</span>
                  </Col>
                  {/* <Col>
                    <span>{item.ldl_company_name || ''}</span>
                  </Col> */}
                </Row>
              ))}
            </Table>
          </TableWrap>
          {(loading || allLoading) && (
            <Loading>
              <Spinner elSize={30} />
            </Loading>
          )}
          {total > 20 && (
            <Pagination>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'is-break'}
                pageCount={pageCount}
                initialPage={0}
                forcePage={page}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => [setPage(selected)]}
                disableInitialCallback
                containerClassName={'pagination-leads'}
                activeClassName={'is-active'}
              />
            </Pagination>
          )}
        </Wrap>
        <GoogleMapReact
          style={{ visibility: 'hidden' }}
          bootstrapURLKeys={{ key: mapsKey }}
          defaultCenter={{ lat: 38, lng: -91 }}
          zoom={4}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ maps }) => maps && onGoogleApiLoaded(true)}
        ></GoogleMapReact>
      </MainWrap>
    </HiddenWrap>
  )
}

const HiddenWrap = styled.div`
  overflow: hidden;
  height: 100%;
  position: relative;
`

const Wrap = styled.div`
  border-left: 1px solid #bdbdbd;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`

const Table = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  &:not([data-loading='true']) {
    overflow: auto;
  }
`

const Col = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  min-height: 54px;
  font-size: 14px;
  background-color: #fff;
  min-width: 160px;

  @media ${mdUp} {
    flex: 0 0 160px;
  }

  &[data-locked='true'] {
    @media ${mdUp} {
      min-width: 200px;
      flex: 0 0 200px;
      position: sticky;
      left: 0;
      background-color: #fff;
      z-index: 2;
    }
  }
`

const Row = styled.li`
  display: flex;

  &:first-child > ${Col} {
    background-color: lightgray;
  }

  &:not(:first-child) ${Col} {
    border-bottom: 1px solid #bdbdbd;
  }

  &[data-locked='true'] {
    position: sticky;
    top: 0;
    z-index: 3;
  }
`

const MainWrap = styled(Flex)`
  height: 100%;
`

const FilterBtn = styled.button.attrs({ type: 'button' })`
  border: none;
  padding: 0 5px 0 0;
  margin: 0;
  font-weight: 600;
  color: black;
  background: none;
  cursor: pointer;
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 14px;

  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-bottom: 3px;
    margin-left: 7px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid gray;
  }

  &[data-desc='false']::after {
    border-bottom: unset;
    border-top: 5px solid gray;
  }
`

const FilterText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: black;
`

const LocationNameBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  padding: unset;
  text-align: left;
  display: inline-block;
  text-decoration: underline;
  color: blue;
  cursor: pointer;
`

const Pagination = styled.div`
  width: 100%;

  .pagination-leads {
    list-style: none;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    font-size: 1.3rem;

    a {
      min-width: 30px;
      width: 100%;
      display: block;
      text-align: center;
      cursor: pointer;
      padding: 5px;
    }

    .previous.disabled,
    .next.disabled {
      display: none;
    }

    .is-active a {
      color: red;
    }
  }
`

const Loading = styled.div`
  width: 100%;
  padding-top: 15px;
  display: flex;
  justify-content: center;

  & + ${Pagination} .pagination-leads {
    margin-top: 7px;
  }
`

const TableWrap = styled.div`
  display: flex;
  overflow: hidden;
`

const NumberOfLocations = styled.div`
  span {
    display: block;
    color: #2e2c2b;
    font-weight: 600;
    font-size: 16px;
  }
`

const FilterSticky = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #f8f8f8;
  padding-bottom: 10px;
  padding-top: 10px;
`

export default List
