import React from 'react';
import { useDispatch } from 'react-redux';
import { Body } from '../../components/modal';
import Form from '../SpaceForm';
import { spaceCreate, spaceUpdate } from '../../actions/spaces';
import { locationChangeStatus } from '../../actions/locations';

import { hideModal, openSpacesFormModal, openSpaceDeleteModal } from '../../actions/modal';

const SpacesForm = (props) => {
  const dispatch = useDispatch();

  return (
    <Body>
      {props.editItemId === 'new' && (
        <Form
          initialValues={{
            isAvailable: true,
            units: 'sqFt',
            locationId: props.item.locationId
          }}
          onSubmit={data => {
            dispatch(spaceCreate(data)).then(success => {
              if (success) {
                dispatch(hideModal())
                dispatch(locationChangeStatus(props.item.locationId))
              }
            })
          }}
          onClose={() => dispatch(hideModal())}
        />
      )}
      {props.editItemId === props.item.id && (
        <Form
          initialValues={props.item}
          onClose={() => dispatch(hideModal())}
          onRemove={() => {
            dispatch(openSpaceDeleteModal(props.item))
          }}
          onDuplicate={() => {
            dispatch(hideModal())
            dispatch(
              openSpacesFormModal({ editItemId: 'duplicate', item: props.item })
            )
          }}
          onSubmit={data => {
            dispatch(spaceUpdate(data)).then(success => {
              if (success) {
                dispatch(hideModal())
                dispatch(locationChangeStatus(props.item.locationId))
              }
            })
          }}
        />
      )}
      {props.editItemId === 'duplicate' && (
        <Form
          initialValues={{
            ...props.item,
            name: `${props.item.name}_copy`,
            modifiedAt: null
          }}
          onClose={() => dispatch(hideModal())}
          onSubmit={data => {
            dispatch(spaceCreate(data)).then(success => {
              if (success) {
                dispatch(hideModal())
                dispatch(locationChangeStatus(props.item.locationId))
              }
            })
          }}
        />
      )}
    </Body>
  )
};

export default SpacesForm;
