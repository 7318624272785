export const loadScriptFooter = (scriptUrl, id, async = true, defer = true) => {
  const script = document.createElement('script')
  script.src = scriptUrl
  script.id = id
  script.async = async
  script.defer = defer
  document.body.appendChild(script)

  return new Promise(res => {
    script.onLoad = () => res()
  })
}
