import React, { useState, lazy, Suspense } from 'react'
import styled from 'styled-components/macro'
import Tooltip from '../../components/tooltip'
import Select from 'react-select'
import { getOptions } from './options'
import Flex from '../flex'
import Spinner from '../spinner'

const Chart = lazy(() => import('../chart'))

const selectDataNames = ['Views in Search', '# of Leads', '# of Seats']
const tooltips = [
  'Views in Search per Operator shows the demand in the market based on the average amount of listing views each operator has received.',
  'Leads Introduced per Operator shows the demand in the market based on the total amount of lead introductions each operators has received from Upsuite.',
  'Number of Seats to Operator shows the demand in the market based on the total amount of lead seats each operators has received from Upsuite lead introductions.'
]

const quarters = ['2022 Q4', '2022 Q3', '2022 Q2', '2022 Q1', '2021 Q4', '2021 Q3', '2021 Q2', '2021 Q1', '2020 Q4', '2020 Q3', '2020 Q2', '2020 Q1']

const MarketDemandShare = ({ data }) => {
  const [selectIndex, setSelectIndex] = useState(0)
  const [quarterIndex, setQuarterIndex] = useState(0)
  const options = getOptions({
    name: selectDataNames[selectIndex],
    data: data[`${selectIndex}-${(quarters.length - 1) - quarterIndex}`]
  })

  return (
    <Wrap>
      <Title>
        <span>Demand Share</span>
        <span>{selectDataNames[selectIndex]} to Operators{' '}<Tooltip
          overlay={
            <div>
              <span>{tooltips[selectIndex]}</span>
            </div>
          }
        >
          <Info>(i)</Info>
        </Tooltip></span>
      </Title>
      <Container>
        <Row>
          <Select
            options={selectDataNames.map((item, index) => ({
              label: item,
              value: index
            }))}
            value={{
              value: selectIndex,
              label: selectDataNames[selectIndex]
            }}
            onChange={({ value }) => {
              setSelectIndex(value)
            }}
            isClearable={false}
            isSearchable={false}
            styles={{
              container: provided => ({
                ...provided,
                width: 155
              }),
              indicatorsContainer: (provided, state) => {
                return {
                  ...provided,
                  minWidth: 20,
                  '> div': {
                    padding: 6
                  },
                  svg: {
                    display: 'none'
                  },
                  '&::after': {
                    content: '""',
                    borderStyle: 'solid',
                    borderColor: `${
                      state.hasValue || state.selectProps.menuIsOpen
                        ? '#e7483d'
                        : 'hsl(0,0%,80%)'
                    } transparent transparent`,
                    borderWidth: '5px 5px 2.5px',
                    display: 'inline-block',
                    height: 0,
                    width: 0,
                    position: 'absolute',
                    right: '10px',
                    top: '14px'
                  }
                }
              },
              indicatorSeparator: provided => ({
                ...provided,
                display: 'none'
              }),
              valueContainer: provided => ({
                ...provided,
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                fontSize: 15
              }),
              control: (provided) => {
                return {
                  ...provided,
                  minHeight: 35,
                  borderRadius: 3,
                  borderColor: '#e7483d',
                  boxShadow: 'none',
                  '&:hover': {
                    borderColor: '#e7483d'
                  }
                }
              },
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected && 'transparent',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                fontSize: 15,
                color: '#000',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'hsl(0,0%,90%)'
                }
              }),
              menu: (provided) => ({
                ...provided,
                border: '1px solid #e7483d',
                boxShadow: 'none'
              })
            }}
          />
          <Select
            options={quarters.map((item, index) => ({
              value: index,
              label: item
            }))}
            value={{ value: quarterIndex, label: quarters[quarterIndex] }}
            onChange={({ value }) => setQuarterIndex(value)}
            isSearchable={false}
            styles={selectStyles}
          />
        </Row>
        <ChartWrap>
          <Suspense
            fallback={
              <Flex fullWidth fullHeight center>
                <Spinner elSize={40} />
              </Flex>
            }
          >
            <Chart options={options} />
          </Suspense>
        </ChartWrap>
      </Container>
    </Wrap>
  )
}

const selectStyles = {
  container: provided => ({
    ...provided,
    width: 135
  }),
  valueContainer: provided => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    fontSize: 15
  }),
  option: provided => ({
    ...provided,
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 500,
    fontSize: 15
  })
}

const ChartWrap = styled.div`
  width: 100%;

  .highcharts-credits {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2px;
`

const Info = styled.span`
  margin-right: 3px;
  font-weight: 700;
  cursor: pointer;
`

const Wrap = styled.div`
  width: 100%;
  border: 1px solid #57ab83;
  border-radius: 10px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  background-color: white;
  max-width: 600px;
  margin-bottom: 10px;
`

const Title = styled.span`
  color: #e7483d;
  font-weight: 700;
  text-align: center;
  display: block;
  font-size: 20px;
  margin-bottom: 14px;

  > span {
    display: block;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export default MarketDemandShare
