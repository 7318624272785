import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Wrapper, SubItem } from '../../components/sidebar'
import { marketReports, marketSummaries } from '../../settings/routes'

const Sidebar = () => {
  const { marketId } = useParams()
  const { flexOfficePro } = useSelector(state => state.user)

  return (
    <Wrapper data-width="md">
      {flexOfficePro !== 'Advisor' && (
        <>
          <SubItem>
            <NavLink
              to={`/${marketReports.main}/${marketReports.inventory}/${marketId}/`}
            >
              Inventory & Vacancy Trends
            </NavLink>
          </SubItem>
          <SubItem>
            <NavLink
              to={`/${marketReports.main}/${marketReports.price}/${marketId}/`}
            >
              Asking Price Trends
            </NavLink>
          </SubItem>
          <SubItem>
            <NavLink
              to={`/${marketReports.main}/${marketReports.monitor}/${marketId}/`}
            >
              Supply, Demand & Pricing Trends
            </NavLink>
          </SubItem>
          <SubItem>
            <NavLink
              to={`/${marketReports.main}/${marketReports.supply}/${marketId}/`}
            >
              New & Closed Locations
            </NavLink>
          </SubItem>
          <SubItem>
            <NavLink
              to={`/${marketReports.main}/${marketReports.demand}/${marketId}/`}
            >
              Demand Trends
            </NavLink>
          </SubItem>
          {flexOfficePro !== 'Investor' && (
            <SubItem>
              <NavLink
                to={`/${marketReports.main}/${marketReports.forecast}/${marketId}/`}
              >
                Supply, Demand & Pricing Forecast
              </NavLink>
            </SubItem>
          )}
        </>
      )}
      {flexOfficePro === 'Advisor' && (
        <>
          <SubItem>
            <NavLink
              to={`/${marketSummaries.main}/${marketSummaries.summary}/${marketId}/`}
            >
              Market Summaries
            </NavLink>
          </SubItem>
          <SubItem>
            <span>Market Reports</span>
          </SubItem>
          <SubItem data-indent>
            <NavLink
              to={`/${marketReports.main}/${marketReports.inventory}/${marketId}/`}
            >
              Inventory & Vacancy Trends
            </NavLink>
          </SubItem>
          <SubItem data-indent>
            <NavLink
              to={`/${marketReports.main}/${marketReports.price}/${marketId}/`}
            >
              Asking Price Trends
            </NavLink>
          </SubItem>
          <SubItem data-indent>
            <NavLink
              to={`/${marketReports.main}/${marketReports.monitor}/${marketId}/`}
            >
              Supply, Demand & Pricing Trends
            </NavLink>
          </SubItem>
          <SubItem data-indent>
            <NavLink
              to={`/${marketReports.main}/${marketReports.supply}/${marketId}/`}
            >
              New & Closed Locations
            </NavLink>
          </SubItem>
          <SubItem data-indent>
            <NavLink
              to={`/${marketReports.main}/${marketReports.demand}/${marketId}/`}
            >
              Demand Trends
            </NavLink>
          </SubItem>
          {/* <SubItem data-indent>
            <NavLink
              to={`/${marketReports.main}/${marketReports.forecast}/${marketId}/`}
            >
              Supply, Demand & Pricing Forecast
            </NavLink>
          </SubItem> */}
          <SubItem>
            <span>Demand Maps</span>
          </SubItem>
          <SubItem data-indent>
            <NavLink
              to={`/${marketSummaries.main}/${marketSummaries.trends}/${marketId}/`}
            >
              Lead Volume
            </NavLink>
          </SubItem>
          <SubItem data-indent>
            <NavLink
              to={`/${marketSummaries.main}/${marketSummaries.views}/${marketId}/`}
            >
              Views in Search
            </NavLink>
          </SubItem>
        </>
      )}
    </Wrapper>
  )
}

export default Sidebar
