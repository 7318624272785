import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

const endpoint = process.env.REACT_APP_API_URL ? 'LandlordData' : 'Landlords'

export const getLandlordData = token =>
  fetch(`${apiUrl}${apiBase}/${endpoint}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    }
  })

export const addLandlordData = (token, data) =>
  fetch(`${apiUrl}${apiBase}/${endpoint}`, {
    method: 'post',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })


export const getLandlordsAll = (offset, token) =>
  fetch(`${apiUrl}${apiBase}/${endpoint}?offset=${offset}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token,
      'Content-Type': 'application/json'
    }
  })
