import React from 'react'
import ViewToggle from '../../components/view-toggle'
import styled from 'styled-components/macro'
import { useDispatch, useSelector } from 'react-redux'
import { toggleView } from '../../actions/location-services-all'

const ViewToggleWrap = ({ spaceBelow }) => {
  const dispatch = useDispatch()
  const activeView = useSelector(state => state.locationServicesAll.activeView)
  const loading = useSelector(state => state.locationServicesAll.status.loading)

  return (
    <Wrap>
      <ViewToggle
        active={activeView}
        onClick={view => dispatch(toggleView(view))}
        disabled={loading}
        spaceBelow={spaceBelow && loading}
      />
    </Wrap>
  )
}

const Wrap = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
`

export default ViewToggleWrap
