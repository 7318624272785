import styled from 'styled-components/macro';
import { Heading } from '../landing';

export const AgreemetCard = styled.div`
  width: 100%;
  background-color: #e6483d;
  max-width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #c4c4c4;
  padding: 30px 10px;
  margin-bottom: 50px;

  ${Heading} {
    color: #fff;
    font-size: 32px;
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 30px 0 30px;
    border-color: #c4c4c4 transparent transparent transparent;
    top: 100%;
    left: 30px;
  }

  &::after {
    border-color: #e6483d transparent transparent transparent;
    top: calc(100% - 1px);
  }
`;

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
  padding-left: 25px;
  padding-right: 25px;
`;
