import React from 'react'
import Flex from '../../components/flex'
import Breadcrumbs from '../../containers/Breadcrumbs'
import SpaceList from '../../containers/SpaceList'
import Sidebar from '../../containers/SidebarSpaces'
import { useMediaQuery } from 'react-responsive'
import { smOnly } from '../../styles/breakpoints'

const Spaces = props => {
  const { locationId } = props.match.params
  const mobile = useMediaQuery({ query: smOnly })

  return (
    <Flex fullWidth fullHeight middle column>
      <Flex fullWidth column full>
        <Breadcrumbs childRoute="Location and Spaces" />
        <Flex fullWidth fullHeight alignStart column={mobile}>
          <Sidebar />

          <Flex fullWidth center middle>
            <SpaceList locationId={locationId} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Spaces
