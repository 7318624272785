import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro';

import { Wrapper, SubItem } from '../../components/sidebar'
import { locationServices } from '../../settings/routes'

const Sidebar = () => {
  return (
    <Wrapper data-width="sm">
      {/* <Title>
        <span>Acquisitions & Takeovers</span>
      </Title>
      <SubItem data-indent>
        <NavLink
          to={`/${locationServices.main}/${locationServices.list}/`}
        >
          List
        </NavLink>
      </SubItem>
      <SubItem data-indent>
        <NavLink
          to={`/${locationServices.main}/${locationServices.map}/`}
        >
          Map
        </NavLink>
      </SubItem> */}
      <Title data-space-above={false}>
        <span>All Locations</span>
      </Title>
      <SubItem data-indent>
        <NavLink
          to={`/${locationServices.main}/${locationServices.listAll}/`}
        >
          List
        </NavLink>
      </SubItem>
      <SubItem data-indent>
        <NavLink
          to={`/${locationServices.main}/${locationServices.mapAll}/`}
        >
          Map
        </NavLink>
      </SubItem>
    </Wrapper>
  )
}

const Title = styled(SubItem)`
  span {
    padding: 0;
  }

  &[data-space-above="true"] {
    margin-top: 30px;
  }
`

export default Sidebar
