import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import { createReducer } from '../utils/redux'
import {
  LOCATIONS_GET_REQUEST,
  LOCATIONS_GET_SUCCESS,
  LOCATIONS_GET_FAILURE,
  LOCATION_CHANGE_STATUS
} from '../constants'

const initialState = {
  byId: {},
  byMarketId: {},
  list: [],
  ids: [],
  status: {
    error: false,
    errorText: null,
    loading: false,
    completed: false
  }
}

export default createReducer(initialState, {
  [LOCATIONS_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true
    }
  }),
  [LOCATIONS_GET_SUCCESS]: (state, payload) => ({
    ...state,
    list: payload,
    byId: {
      ...state.byId,
      ...keyBy(payload, 'id')
    },
    byMarketId: {
      ...state.byMarketId,
      ...groupBy(payload, 'marketId')
    },
    ids: payload.map(loc => loc.id),
    status: {
      ...initialState.status,
      loading: false,
      completed: true
    }
  }),
  [LOCATIONS_GET_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      completed: true,
      error: true,
      errorText: payload
    }
  }),
  [LOCATION_CHANGE_STATUS]: (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload]: {
        ...state.byId[payload],
        listingStatus: 'Active'
      }
    }
  })
})
