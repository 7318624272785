import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format, subMonths } from 'date-fns'
import styled from 'styled-components/macro'
import { leadsAndViewsGet } from '../../actions/lead-performance'
import * as Styled from '../widget'
import Flex from '../flex'
import Spinner from '../spinner'

const getBarWidth = (a, b) => {
  if (!a || !b) return null

  if (a >= b) return '100%'

  return `${(a / b) * 100}%`
}

const WidgetPerformance = ({ id }) => {
  const dispatch = useDispatch()
  const performance = useSelector(state => state.leadPerformance.leadsAndViews.byId[id])
  const { loading, error } = useSelector(state => state.leadPerformance.leadsAndViews)
  const location = useSelector(state => state.locations.byId[id])

  useEffect(() => {
    if (id) {
      dispatch(leadsAndViewsGet(id))
    }
  }, [dispatch, id])

  return (
    <Styled.Wrap>
      <Styled.Title>{`Listing Performance - ${format(
        subMonths(new Date(), 1),
        'MMMM yyyy'
      )}`}</Styled.Title>
      <Styled.Box>
        {loading && (
          <Flex fullWidth center middle style={{ marginTop: 20 }}>
            <Spinner elSize={30} />
          </Flex>
        )}
        {!loading && !error && (
          <Wrap>
            <Container>
              <span>Views in Search</span>
              <Row>
                {performance?.LocationSearches > 0 && (
                  <Col data-bar>
                    <Bar
                      data-red
                      barWidth={getBarWidth(
                        performance?.LocationSearches,
                        performance?.VicinitySearches
                      )}
                    />
                  </Col>
                )}
                <Col>
                  <span>{`${performance?.LocationSearches} - ${
                    location?.name || ''
                  } listing`}</span>
                </Col>
              </Row>
              <Row>
                {performance?.VicinitySearches > 0 && (
                  <Col data-bar>
                    <Bar
                      data-gray
                      barWidth={getBarWidth(
                        performance?.VicinitySearches,
                        performance?.LocationSearches
                      )}
                    />
                  </Col>
                )}
                <Col>
                  <span>{`${performance?.VicinitySearches} - Average of 3 closest locations`}</span>
                </Col>
              </Row>
            </Container>
            <Container>
              <span>Leads</span>
              <Row>
                {performance?.LocationLeads > 0 && (
                  <Col data-bar>
                    <Bar
                      data-red
                      barWidth={getBarWidth(
                        performance?.LocationLeads,
                        performance?.VicinityLeads
                      )}
                    />
                  </Col>
                )}
                <Col>
                  <span>{`${performance?.LocationLeads} - ${
                    location?.name || ''
                  } listing`}</span>
                </Col>
              </Row>
              <Row>
                {performance?.VicinityLeads > 0 && (
                  <Col data-bar>
                    <Bar
                      data-gray
                      barWidth={getBarWidth(
                        performance?.VicinityLeads,
                        performance?.LocationLeads
                      )}
                    />
                  </Col>
                )}
                <Col>
                  <span>{`${
                    performance?.VicinityLeads
                  } - Average of 3 closest locations`}</span>
                </Col>
              </Row>
            </Container>
          </Wrap>
        )}
      </Styled.Box>
    </Styled.Wrap>
  )
}

const Wrap = styled.div`
  padding: 20px 30px;
`

const Container = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  > span {
    font-size: 19px;
    display: block;
    margin-bottom: 10px;
  }
`

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`

const Col = styled.div`
  &[data-bar="true"] {
    width: 100px;
    flex: 0 0 100px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
  }
`

const Bar = styled.div`
  height: 20px;
  width: ${(props) => `${props.barWidth}`};

  &[data-red="true"] {
    background-color: #E7483D;
  }

  &[data-gray="true"] {
    background-color: lightgray;
  }
`

export default WidgetPerformance
