import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import reduce from 'lodash/reduce'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import * as Styled from '../../components/location'
import Spinner from '../../components/spinner'
import { smOnly } from '../../styles/breakpoints'
import { locationUpdate } from '../../actions/locations'
import Attachment from '../../components/attachment'
import { openImageInfoModal } from '../../actions/modal'

const fields = [
  {
    field: 'listingCardImage',
    label: 'Listing Card Image'
  },
  {
    field: 'interiorImage1',
    label: 'Interior 1'
  },
  {
    field: 'interiorImage2',
    label: 'Interior 2'
  },
  {
    field: 'interiorImage3',
    label: 'Interior 3'
  },
  {
    field: 'image6',
    label: 'Interior 4'
  },
  {
    field: 'image7',
    label: 'Interior 5'
  },
  {
    field: 'image8',
    label: 'Interior 6'
  },
  {
    field: 'image9',
    label: 'Interior 7'
  }
]

const LocationImg = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)
  const getInitialValues = location && reduce(fields, (acc, { field }) => ({ ...acc, [`${field}Id`]: location[`${field}Id`] }), {})

  const formik = useFormik({
    initialValues: {...getInitialValues, virtualTour: location?.virtualTour },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      dispatch(locationUpdate(locationId, values))
    }
  })
  const mobile = useMediaQuery({ query: smOnly })
  const handleResetForm = () =>
    formik.resetForm({ values: {...getInitialValues, virtualTour: location?.virtualTour } })

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.Header>
            <Styled.Note>
              <p>
                <b>Note:</b> Upload all imagery for your location below. The
                virtual tour must be a public facing Url.
              </p>
            </Styled.Note>

            <Styled.Actions mobile={mobile}>
              {updating && <Spinner elSize={20} />}
              <>
                <Styled.Action
                  disabled={updating}
                  type="button"
                  onClick={() => handleResetForm()}
                >
                  Cancel
                </Styled.Action>
                <Styled.Action disabled={updating} type="submit">
                  Save
                </Styled.Action>
              </>
            </Styled.Actions>
          </Styled.Header>
          <Styled.Section>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  <Styled.Title>Imagery & Virtual Tours</Styled.Title>
                </Styled.Col>
              </Styled.Row>
              <Flex flexWrap fullWidth>
                {location &&
                  fields.map(item => (
                    <Styled.Col
                      key={item.field}
                      maxWidth={!mobile ? '25%' : '100%'}
                    >
                      <Attachment
                        labelHighlight
                        imageId={formik.values[`${item.field}Id`]}
                        label={item.label}
                        name={item.field}
                        onRemove={() =>
                          formik.setFieldValue(`${item.field}Id`, null)
                        }
                        onChange={({ id, name }) => {
                          formik.setFieldValue(`${item.field}Id`, id)
                          formik.setFieldValue(`${item.field}Name`, name)
                        }}
                        onImageInfo={() =>
                          dispatch(
                            openImageInfoModal({ fieldName: item.field })
                          )
                        }
                      />
                    </Styled.Col>
                  ))}
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="virtualTour">
                    <Styled.LabelText data-invalid={!formik.values.virtualTour}>
                      Virtual Tour Url
                    </Styled.LabelText>
                    <Styled.Input
                      type="url"
                      id="virtualTour"
                      name="virtualTour"
                      placeholder="_"
                      value={formik.values.virtualTour || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                </Styled.Col>
              </Flex>
            </Flex>
          </Styled.Section>
        </Styled.Form>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationImg
