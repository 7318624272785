import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  LEADS_BY_LOC_ID_GET_REQUEST,
  LEADS_BY_LOC_ID_GET_SUCCESS,
  LEADS_BY_LOC_ID_GET_FAILURE,
  LEAD_PROPOSAL_SHOW,
  LEAD_PROPOSAL_HIDE,
  LEAD_REJECT_REQUEST,
  LEAD_REJECT_SUCCESS,
  LEAD_REJECT_FAILURE
} from '../constants'
import * as api from '../api/leads'
import { getTokenWithExpiration } from './auth'

const leadsGetRequest = payload => ({
  type: LEADS_BY_LOC_ID_GET_REQUEST,
  payload
})

const leadsGetSuccess = (payload, config) => ({
  type: LEADS_BY_LOC_ID_GET_SUCCESS,
  payload,
  config
})

const leadsGetFailure = () => ({
  type: LEADS_BY_LOC_ID_GET_FAILURE
})

export const leadsGet = params => (dispatch, getState) => {
  dispatch(leadsGetRequest(params))

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .getLeadsPaginated(token, params)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(leadsGetSuccess(response, params))
        })
        .catch(error => {
          if (error) {
            dispatch(leadsGetFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

export const leadProposalShow = payload => ({
  type: LEAD_PROPOSAL_SHOW,
  payload
})

export const leadProposalHide = () => ({
  type: LEAD_PROPOSAL_HIDE
})

const leadRejectRequest = () => ({
  type: LEAD_REJECT_REQUEST
})

const leadRejectSuccess = payload => ({
  type: LEAD_REJECT_SUCCESS,
  payload
})

const leadRejectFailure = () => ({
  type: LEAD_REJECT_FAILURE
})

export const leadReject = (email, reason, locationId) => (dispatch, getState) => {
  dispatch(leadRejectRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      return api
        .rejectLead(token, email, reason, locationId)
        .then(checkHttpStatus)
        .then(() => {
          dispatch(leadRejectSuccess({ email, locationId }))
        })
        .catch(error => {
          if (error) {
            dispatch(leadRejectFailure())
            // eslint-disable-next-line no-alert
            window.alert(error.status)
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
