import React, { useState, useEffect, useRef } from 'react'
import debounce from 'lodash/debounce'
import styled from 'styled-components/macro'
import { Pencil } from '../../components/icons'

const delayed = debounce((name, onChange) => onChange(name), 300)

const ProposalName = ({ title, onChange = () => {} }) => {
  const [name, setName] = useState()
  const [isEditMode, setIsEditMode] = useState(false)
  const inputRef = useRef()

  useEffect(() => {
    setName(title || '')
  }, [title])

  useEffect(() => {
    if (isEditMode && inputRef?.current) {
      inputRef.current.focus()
    }
  }, [isEditMode])

  useEffect(() => {
    if (name && name !== title) {
      delayed(name, onChange)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])

  return (
    <Wrap>
      <TitleWrap data-visible={!isEditMode}>
        <Heading>{name || ''}</Heading>
        <Toggle onClick={() => setIsEditMode(true)}>
          <Icon />
        </Toggle>
      </TitleWrap>
      <InputWrap data-visible={isEditMode}>
        <Input
          ref={inputRef}
          value={name || ''}
          onChange={e => setName(e.target.value)}
          onBlur={e => {
            if (!e.target.value) {
              setName(title)
            }
            setIsEditMode(false)
          }}
        />
      </InputWrap>
    </Wrap>
  )
}

const Heading = styled.span`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  line-height: 1.1;
`

const Wrap = styled.div`
  position: relative;
  max-width: 100%;
`

const TitleWrap = styled.div`
  position: relative;
  max-width: 100%;
  padding: 0 20px;
  text-align: center;

  &[data-visible='false'] {
    display: none;
  }
`

const InputWrap = styled.div`
  max-width: 100%;
  width: 100%;

  &[data-visible='false'] {
    display: none;
  }
`

const Input = styled.input.attrs({
  type: 'text'
})`
  max-width: 100%;
  width: 100%;
  min-width: 280px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border: none;
  padding: 0;
  box-shadow: none;
  background: transparent;
  line-height: 1.1;
  width: 100%;
  top: 0;
  text-align: center;
`

const Toggle = styled.button.attrs({
  type: 'button'
})`
  position: absolute;
  position: absolute;
  top: -10px;
  right: 0px;
  padding: 0;
  border: 0;
  background: none;
  outline: none;
  color: #828180;
  cursor: pointer;

  &:hover {
    color: #2e2c2b;
  }
`

const Icon = styled(Pencil)`
  width: 15px;
  color: white;
`

export default ProposalName
