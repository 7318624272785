import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.span`
  font-size: 16px;
  margin-right: 10px;
`;

const Img = styled.div`
  width: 42px;
  height: 42px;
  background-color: lightgray;
  ${props => props.img && `
    background-image: url(${props.img});
    background-size: cover;
  `}
  border-radius: 42px;
  flex-shrink: 0;
`;

export const UserAv = (props) => {
  const { user } = props;

  return (
    <Wrapper>
      <Name>{`${(user.firstName || '')} ${(user.lastName || '')}`}</Name>
      <Img img={user.pic} />
    </Wrapper>
  );
};
