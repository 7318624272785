export const getSetupInitialValues = location => {
  return {
    name: location?.name,
    neighborhoodName: location?.neighborhoodName,
    neighborhoodId: location?.neighborhoodId,
    addressStreet: location?.addressStreet,
    addressCity: location?.addressCity,
    addressState: location?.addressState,
    addressCountry: location?.addressCountry,
    addressPostalCode: location?.addressPostalCode,
    latitude: location?.latitude,
    longitude: location?.longitude,
    description: location?.description,
    totalSquareFootage: location?.totalSquareFootage,
    operatorTotalSeats: location?.operatorTotalSeats,
    dateOpened: location?.dateOpened,
    monthlyRentPayment: location?.monthlyRentPayment,
    monthlyRentPaymentCurrency: location?.monthlyRentPaymentCurrency,
    monthlyRevenueGoal: location?.monthlyRevenueGoal,
    monthlyRevenueGoalCurrency: location?.monthlyRevenueGoalCurrency,
    monthlyOperatingCostPayment: location?.monthlyOperatingCostPayment,
    monthlyOperatingCostPaymentCurrency: location?.monthlyOperatingCostPaymentCurrency,
    freeRentStartDate: location?.freeRentStartDate,
    freeRentEndDate: location?.freeRentEndDate,
    landlordDataName: location?.landlordDataName,
    landlordDataId: location?.landlordDataId
  }
}

export const getEnvInitialValues = location => ({
  environmentQuietEnergetic: location?.environmentQuietEnergetic,
  environmentPrivateOpen: location?.environmentPrivateOpen,
  environmentLuxuryBudget: location?.environmentLuxuryBudget,
  environmentProfessionalCasual: location?.environmentProfessionalCasual,
  environmentDimBright: location?.environmentDimBright
})

export const currencyOptions = [
  { label: 'USD', value: 'USD' },
  { label: 'CAD', value: 'CAD' }
]
