import React from 'react';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import { Switch, Route } from 'react-router-dom';

import Flex from '../../components/flex';
import Breadcrumbs from '../../containers/Breadcrumbs';
import PipelineContainer from '../../containers/Pipeline';
import AgreementsAndFees from '../../containers/AgreementsAndFees';
import Sidebar from '../../containers/Pipeline/sidebar';

import { smOnly } from '../../styles/breakpoints';

import {
  PIPELINE_ROUTE,
  AGREEMENTS_AND_FEES_ROUTE,
} from '../../settings/routes';

const Pipeline = () => {
  const mobile = useMediaQuery({ query: smOnly });
  return (
    <Flex fullWidth fullHeight column>
      <Breadcrumbs
        childRoute={`Pipeline (Dec. 1 2019 - ${format(
          new Date(),
          'LLL. d yyyy',
        )})`}
      />
      <Flex fullWidth fullHeight alignStart column={mobile}>
        <Sidebar />
        <Switch>
          <Route exact path={`/${PIPELINE_ROUTE}/:marketId/:locationId?`}>
            <PipelineContainer />
          </Route>
          <Route
            exact
            path={`/${PIPELINE_ROUTE}/${AGREEMENTS_AND_FEES_ROUTE}/:marketId/:locationId?`}
          >
            <AgreementsAndFees />
          </Route>
        </Switch>
      </Flex>
    </Flex>
  );
};

export default Pipeline;
