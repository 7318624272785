import styled from 'styled-components/macro';

export const Input = styled.input`
  height: 40px;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border: 1px solid #d5d5d5;
  border-radius: 4px;
  box-sizing: border-box;
  background: #fff;
  padding: 10px 12px;
  margin-bottom: 10px;
  font-size: 14px;

  :disabled {
    background: #e5e5e5;
    cursor: not-allowed;
  }
`;

export const LabelText = styled.span`
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
`;

export const Button = styled.button`
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  line-height: 1.1;
  width: 100%;
  background-color: #1779ba;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 25px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;

  :hover {
    background-color: #115b8d;
  }

  :disabled {
    background-color: #cacaca;
    color: black;
    cursor: not-allowed;
  }

  ${(props) => props.withSpinner && `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const ErrorText = styled.span`
  font-size: 16px;
  color: red;
`;
