import { createReducer } from '../utils/redux'
import {
  PROPOSAL_GET_REQUEST,
  PROPOSAL_GET_SUCCESS,
  PROPOSAL_GET_FAILURE,
  PROPOSAL_UPDATE_REQUEST,
  PROPOSAL_UPDATE_SUCCESS,
  PROPOSAL_UPDATE_FAILURE,
  PROPOSAL_UPDATE_STATE,
  PROPOSAL_LEAD_GET_REQUEST,
  PROPOSAL_LEAD_GET_SUCCESS,
  PROPOSAL_LEAD_GET_FAILURE
} from '../constants'

const initialState = {
  byLocLeadId: {},
  byLeadId: {},
  status: {
    loading: false,
    updating: false,
    byLeadLoading: false
  },
  error: {
    loading: false,
    updating: false,
    byLeadError: false
  }
}

export default createReducer(initialState, {
  [PROPOSAL_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: true
    },
    error: {
      ...state.status,
      loading: false
    }
  }),
  [PROPOSAL_GET_SUCCESS]: (state, payload, { locationId, leadId }) => ({
    ...state,
    byLocLeadId: {
      ...state.byLocLeadId,
      [`${locationId}${leadId}`]: payload
    },
    status: {
      ...state.status,
      loading: false
    },
    error: {
      ...state.status,
      loading: false
    }
  }),
  [PROPOSAL_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: false
    },
    error: {
      ...state.status,
      loading: true
    }
  }),
  [PROPOSAL_UPDATE_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      updating: true
    },
    error: {
      ...state.status,
      updating: false
    }
  }),
  [PROPOSAL_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    byLocLeadId: {
      ...state.byLocLeadId,
      [`${payload.Result?.LocationId}${payload.Result?.FlexLeadId}`]: payload.Result
    },
    status: {
      ...state.status,
      updating: false
    },
    error: {
      ...state.status,
      updating: false
    }
  }),
  [PROPOSAL_UPDATE_FAILURE]: (state) => ({
    ...state,
    status: {
      ...state.status,
      updating: false
    },
    error: {
      ...state.status,
      updating: true
    }
  }),
  [PROPOSAL_UPDATE_STATE]: (state, { locationId, leadId, prop, value}) => ({
    ...state,
    byLocLeadId: {
      ...state.byLocLeadId,
      [`${locationId}${leadId}`]: {
        ...(state.byLocLeadId[`${locationId}${leadId}`] || {}),
        [prop]: value
      }
    }
  }),
  [PROPOSAL_LEAD_GET_REQUEST]: (state) => ({
    ...state,
    status: {
      ...state.status,
      byLeadLoading: true
    },
    error: {
      ...state.error,
      byLeadError: false
    }
  }),
  [PROPOSAL_LEAD_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    byLeadId: {
      ...state.byLeadId,
      [config]: payload
    },
    status: {
      ...state.status,
      byLeadLoading: false
    },
    error: {
      ...state.error,
      byLeadError: false
    }
  }),
  [PROPOSAL_LEAD_GET_FAILURE]: (state) => ({
    ...state,
    status: {
      ...state.status,
      byLeadLoading: false
    },
    error: {
      ...state.error,
      byLeadError: true
    }
  })
})
