import trim from 'lodash/trim';
import { sortSpacesMain, orderSpacesBy } from '../../helpers/spaces';

export const mapByLocationId = (ids, byId) => {
  if (!ids) return [];

  return ids.filter(id => byId[id]).map(id => byId[id]);
};

export const mapVisible = (spaces, { activeTab, orderBy, orderDesc }) => {
  const sorted = orderBy ? orderSpacesBy(spaces, orderBy, orderDesc) : sortSpacesMain(spaces);

  return sorted.filter(space => trim(space.spaceType) === activeTab || activeTab === 'All');
};
