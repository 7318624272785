export const API_URL =
  process.env.REACT_APP_API_URL || 'https://api.upsuite.com'
export const API_BASE = '/v1'
export const API_KEY =
  process.env.REACT_APP_API_KEY || 'CSTVVt38LV4wl8IW5Os5x6z6MOc7hFYxGOCR26S5'
export const API_HEAD = 'x-api-key'
export const USER_POOL_ID =
  process.env.REACT_APP_POOL_ID || 'us-east-2_vDdIe8NlA'
export const USER_CLIENT_ID =
  process.env.REACT_APP_CLIENT_ID || '7a7a54ehg0baogu7n8d2s6jmkl'

// Maps
export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY_DEV ? `${process.env.REACT_APP_MAPS_API_KEY_DEV}&libraries=places` : 'AIzaSyDjJUreIBxSPMI4UaDwnUhNrVabfwvyNqY&libraries=places'

// Main
export const MAIN_APP_URL = process.env.REACT_APP_MAIN_APP_URL || 'https://www.upsuite.com'
