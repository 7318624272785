import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Close } from '../../components/icons'
import { useSelector, useDispatch } from 'react-redux'
import { locationServicesAllClose } from '../../actions/location-services-all'
import { mdUp, smOnly } from '../../styles/breakpoints'
import { ImgArrow } from '../../components/icons'
import { openImagePreviewModal } from '../../actions/modal'
import { imageUrl } from '../../helpers/location-services'

const Panel = () => {
  const dispatch = useDispatch()
  const location = useSelector(state => state.locationServicesAll.panel)
  const [imgIndex, setImgIndex] = useState(0)

  if (!location) {
    return null
  }

  const info = [
    location.adr_street,
    location.adr_city,
    location.adr_state,
    location.adr_pc,
    location.adr_country
  ]
    .filter(Boolean)
    .join(', ')

  const fields = [
    { label: 'Market', value: location.market_name },
    { label: 'Neighborhood', value: location.nbh_name },
    { label: 'Type', value: location.type },
    { label: 'Status', value: location.health_status },
    {
      label: 'Size Class',
      value:
        location.size_class === 'Over 15000'
          ? 'Over 15,000'
          : location.size_class === '5000 - 15000' || location.size_class === '5000 to 15000'
          ? '5,000 - 15,000'
          : location.size_class === 'Under 5000'
          ? 'Under 5,000' :
          ''
    },
    { label: 'Square Feet', value: location.total_square_footage ? parseInt(location.total_square_footage, 10).toLocaleString() : '' },
    { label: 'Location Score: Total', value: location.ls_total },
    {
      label: 'Location Score: Overall Demand',
      value: location.ls_overall_dmd
    },
    {
      label: 'Location Score: Demand Views',
      value: location.ls_dmd_views
    },
    {
      label: 'Location Score: # of Leads',
      value: location.ls_dmd_lead
    },
    {
      label: 'Location Score: # of Seats',
      value: location.ls_dmd_lead_seats_avg
    },
    {
      label: 'Location Score: Competition',
      value: location.ls_comp
    },
    {
      label: 'Location Score: Pricing Differential',
      value: location.ls_prc_diff
    },
    {
      label: 'Location Score: Demographic Suitability',
      value:
        location.ls_dem_suitability && Math.round(location.ls_dem_suitability)
    },
    {
      label: 'Price per Desk',
      value:
        location.last_months_price_per_seat_suites1_to9 &&
        `$${Math.round(parseFloat(location.last_months_price_per_seat_suites1_to9)).toLocaleString()}`
    },
    {
      label: 'Potential Yearly Revenue Per SF',
      value:
        location.potential_yearly_revenue_per_SF &&
        `$${Math.round(
          parseFloat(location.potential_yearly_revenue_per_SF)
        ).toLocaleString()}`
    },
    { label: 'Landlord Company Name', value: location.ldl_company_name },
    { label: 'Landlord First Name', value: location.ldl_first_name },
    { label: 'Landlord Last Name', value: location.ldl_last_name },
    { label: 'Landlord Email', value: location.ldl_email },
    { label: 'Landlord Phone', value: location.ldl_phone },
    { label: 'Sales Contact First Name', value: location.operator_exec?.first_name },
    { label: 'Sales Contact Last Name', value: location.operator_exec?.last_name },
    { label: 'Sales Contact Email', value: location.operator_exec?.email },
    { label: 'Sales Contact Phone', value: location.operator_exec?.phone }
  ]
  const images = [
    location?.lcimgId,
    location?.IImg1Id,
    location?.IImg2Id,
    location?.IImg3Id,
    location?.img6_id,
    location?.img7_id,
    location?.img8_id,
    location?.img9_id,
    location?.img10_id
  ].filter(item => !!item)

  return (
    <Wrap>
      <Header>
        <CloseBtn onClick={() => dispatch(locationServicesAllClose())}>
          <CloseIcon />
        </CloseBtn>
        <Title>{location.name}</Title>
      </Header>
      <Info>
        <p>{info}</p>
        {images.length > 0 && (
        <ImgWrap>
          {images.length > 1 && (
            <BtnArrow
              data-prev={true}
              onClick={() => {
                const newIndex =
                  imgIndex === 0 ? images.length - 1 : imgIndex - 1

                setImgIndex(newIndex)
              }}
            >
              <ImgArrow />
            </BtnArrow>
          )}
          {images.map((imgId, index) => (
            <img
              key={`${imgId}-${location.id}-${index}`}
              loading="lazy"
              src={imageUrl(imgId)}
              alt=""
              data-visible={index === imgIndex}
              onClick={() =>
                dispatch(
                  openImagePreviewModal({ index: imgIndex, ids: images })
                )
              }
            />
          ))}
          {images.length > 1 && (
            <BtnArrow
              data-next={true}
              onClick={() => {
                const newIndex =
                  imgIndex === images.length - 1 ? 0 : imgIndex + 1

                setImgIndex(newIndex)
              }}
            >
              <ImgArrow />
            </BtnArrow>
          )}
        </ImgWrap>
      )}
      </Info>
      <Fields>
        <List>
          {fields.filter(item => !!item.value && item.value !== 'none').map(item => (
            <Item key={item.label}>
              <Label>{item.label}</Label>
              {item.label === 'Operator Email' ? (
                <>
                  {item.value.split(',').map(email => (
                    <a key={email} href={`mailto:${email}`}>
                      {email}
                    </a>
                  ))}
                </>
              ) : (
                <Text>{item.value}</Text>
              )}
            </Item>
          ))}
        </List>
      </Fields>
    </Wrap>
  )
}

const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  right: 0;
  z-index: 9999;
  background-color: white;
  border-left: 1px solid #828282;

  @media ${mdUp} {
    z-index: 6;
    position: absolute;
    width: 300px;
    height: calc(100vh - 65px);
  }

  @media ${smOnly} {
    top: 0;
  }
`

const Header = styled.div`
  width: 100%;
  background-color: #e7483d;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  flex-shrink: 0;
`

const Title = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 600;
  color: white;
  margin-right: 22px;
  text-align: left;
`

const CloseBtn = styled.button.attrs({ type: 'button' })`
  background: none;
  box-shadow: none;
  border: none;
  color: white;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`

const CloseIcon = styled(Close)`
  width: 20px;
`

const Info = styled.div`
  padding: 10px;
  border-bottom: 1px solid #828282;

  p {
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    color: #2e2c2b;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Fields = styled.div`
  padding: 15px 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Item = styled.li`
  margin-bottom: 12px;

  a {
    display: block;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
  }
`

const Label = styled.span`
  display: block;
  color: #585756;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 5px;
`

const Text = styled.span`
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  word-break: break-word;
`

const BtnArrow = styled.button.attrs({ type: 'button' })`
  position: absolute;
  z-index: 2;
  top: calc(50% - 11px);
  transition: opacity 0.3s ease;
  width: 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 3px 0;

  &[data-prev='true'] {
    left: 10px;
    transform: rotate(180deg);
  }

  &[data-next='true'] {
    right: 10px;
  }

  svg {
    color: #fff;
    vertical-align: middle;
  }
`

const ImgWrap = styled.div`
  height: 150px;
  width: 100%;
  position: relative;

  img {
    object-fit: cover;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;

    &:not([data-visible='true']) {
      z-index: 0;
      opacity: 0;
    }

    &[data-visible='true'] {
      z-index: 1;
      opacity: 1;
    }
  }
`

export default Panel
