import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import Flex from '../../components/flex'
import Breadcrumbs from '../../containers/Breadcrumbs'
import { workLeadsGet, leadAddShow } from '../../actions/broker-tools'
import * as Styled from '../../components/leads'
import Spinner from '../../components/spinner'
import { leadsDate, workLeadsDate } from '../../helpers/leads'
import LeadActions from '../../components/work-leads-actions'
import EditLead from './EditLead'
import AddLead from './AddLead'
import { Plus } from '../../components/icons'
import Shortlist from './Shortlist'
import Proposal from './Proposal'
import { leadOwnersGet } from '../../actions/location-services'

const WorkLeads = () => {
  const dispatch = useDispatch()
  const colRef = useRef()
  const tableRef = useRef()
  const [orderBy, setOrderBy] = useState(undefined)
  const [orderDesc, setOrderDesc] = useState(true)
  const {
    list: leads,
    total,
    status: { loading },
    error: { loading: error }
  } = useSelector(state => state.brokerTools.leads)
  const shortlistActive = useSelector(state => state.brokerTools.leads.shortlist.active)
  const editLeadActive = useSelector(state =>  state.brokerTools.leads.edit.active)
  const addLeadActive = useSelector(state => state.brokerTools.leads.add.active)

  const count = 20
  const [page, setPage] = useState(1)
  const showActionsCol = true

  useEffect(() => {
    const order = orderDesc ? 'desc' : 'asc'

    dispatch(workLeadsGet({ page, count, sortBy: orderBy ? orderBy : 'created_at', order: orderBy ? order : 'desc' }))
  }, [dispatch, page, count, orderBy, orderDesc])

  const sortBy = by => {
    const isDesc = by === orderBy && !orderDesc ? !orderDesc : false
    setOrderBy(by)
    setOrderDesc(isDesc)
  }

  const fixActionColumns = scrollPos => {
    if (colRef.current) {
      colRef.current.style.right = `${scrollPos - 1}px`
    }

    tableRef.current.querySelectorAll('[data-attach-sticky-ref]').forEach(e => {
      e.style.right = `${scrollPos - 1}px`
    })
  }

  // Fixed right column implementation
  useEffect(() => {
    let lastKnownScrollPosition = 0
    let ticking = false

    if ((loading || total > 0) && !error && showActionsCol) {
      const element = tableRef.current
      const handleScrollPosition = e => {
        lastKnownScrollPosition =
          e.target.scrollWidth - e.target.clientWidth - e.target.scrollLeft

        if (!ticking) {
          window.requestAnimationFrame(() => {
            fixActionColumns(lastKnownScrollPosition)
            ticking = false
          })

          ticking = true
        }
      }

      const handleResizePosition = () => {
        lastKnownScrollPosition =
          element.scrollWidth - element.clientWidth - element.scrollLeft

        if (!ticking) {
          window.requestAnimationFrame(() => {
            fixActionColumns(lastKnownScrollPosition)
            ticking = false
          })

          ticking = true
        }
      }

      // initial set
      lastKnownScrollPosition =
        element.scrollWidth - element.clientWidth - element.scrollLeft
      fixActionColumns(lastKnownScrollPosition)

      // on scroll
      element.addEventListener('scroll', handleScrollPosition)

      // on resize
      window.addEventListener('resize', handleResizePosition)

      return () => [
        element.removeEventListener('scroll', handleScrollPosition),
        window.removeEventListener('resize', handleResizePosition)
      ]
    }
  }, [loading, total, error, showActionsCol])

  useEffect(() => {
    dispatch(leadOwnersGet())
  }, [dispatch])

  return (
    <Flex fullWidth column full>
      {editLeadActive && (
        <EditLead />
      )}
      {addLeadActive && (
        <AddLead />
      )}
      {shortlistActive && (
        <Shortlist />
      )}
      <Proposal />
      <Breadcrumbs childRoute="Work Leads" />
      <Flex fullWidth column center middle>
        <Flex fullWidth flexEnd>
          <AddLeadBtn onClick={() => dispatch(leadAddShow())}>
            <span>Add Lead</span>
            <AddIcon />
          </AddLeadBtn>
        </Flex>
        {(loading || total > 0) && !error && (
          <Styled.Wrapper>
            <Styled.List ref={tableRef}>
              <Styled.Item sticky>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'contact_owner'}
                    desc={orderDesc}
                    onClick={() => sortBy('contact_owner')}
                  >
                    Lead Owner
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'first_name'}
                    desc={orderDesc}
                    onClick={() => sortBy('first_name')}
                  >
                    Name
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'email'}
                    desc={orderDesc}
                    onClick={() => sortBy('email')}
                  >
                    Email
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'phone_number'}
                    desc={orderDesc}
                    onClick={() => sortBy('phone_number')}
                  >
                    Phone
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'company'}
                    desc={orderDesc}
                    onClick={() => sortBy('company')}
                  >
                    Company
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'created_at'}
                    desc={orderDesc}
                    onClick={() => sortBy('created_at')}
                  >
                    Date registered
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'seats'}
                    desc={orderDesc}
                    onClick={() => sortBy('seats')}
                  >
                    Seats needed
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'market'}
                    desc={orderDesc}
                    onClick={() => sortBy('market')}
                  >
                    Market
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'proposal_count'}
                    desc={orderDesc}
                    onClick={() => sortBy('proposal_count')}
                  >
                    # of Proposals Received
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'proposal_rejected_count'}
                    desc={orderDesc}
                    onClick={() => sortBy('proposal_rejected_count')}
                  >
                    # of Proposals Rejected
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'move_in_date'}
                    desc={orderDesc}
                    onClick={() => sortBy('move_in_date')}
                  >
                    Move in Date
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'contact_channel'}
                    desc={orderDesc}
                    onClick={() => sortBy('contact_channel')}
                  >
                    Contact Channel
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col>
                  <Styled.Filter
                    active={orderBy === 'location_first_converted_on'}
                    desc={orderDesc}
                    onClick={() => sortBy('location_first_converted_on')}
                  >
                    Location converted on
                  </Styled.Filter>
                </Styled.Col>
                <Styled.Col data-fixed data-mobile-hidden></Styled.Col>
                <Styled.Col
                  data-fixed
                  data-mobile-hidden
                  data-fixed-cell-actions
                ></Styled.Col>
                <Styled.Fixed data-header ref={colRef}>
                  <Styled.Col data-fixed-actions data-fixed data-fixed-header>
                    <span>Actions</span>
                  </Styled.Col>
                  <Styled.Col data-fixed-actions data-fixed data-fixed-header>
                    <span>Status</span>
                  </Styled.Col>
                </Styled.Fixed>
              </Styled.Item>
              {leads.map((item, index) => (
                <Styled.Item key={`${item.email}-${index}`}>
                  <Styled.Col data-column-prop="contact_owner">
                    <span>
                      {(item.contact_owner || '').replace('Managed', '')}
                    </span>
                  </Styled.Col>
                  <Styled.Col data-column-prop="first_name last_name">
                    {(!isEmpty(item.first_name) ||
                      !isEmpty(item.last_name)) && (
                      <span>{`${item.first_name || ''} ${
                        item.last_name || ''
                      }`}</span>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="email">
                    {item.email && (
                      <a data-email href={`mailto:${item.email}`}>
                        <span>{item.email}</span>
                      </a>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="phone_number">
                    {item.phone_number && (
                      <a data-phone href={`tel:${item.phone_number}`}>
                        <span>{item.phone_number}</span>
                      </a>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="company">
                    {!isEmpty(item.company) && (
                      <span>{item.company || ''}</span>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="created_at">
                    {!isEmpty(item.created_at) && (
                      <span>{workLeadsDate(item.created_at)}</span>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="seats">
                    {isNumber(item.seats) && <span>{item.seats || ''}</span>}
                  </Styled.Col>
                  <Styled.Col data-column-prop="market">
                    <span>{item.market || ''}</span>
                  </Styled.Col>
                  <Styled.Col data-column-prop="proposal_count">
                    <span>{item.proposal_count || ''}</span>
                  </Styled.Col>
                  <Styled.Col data-column-prop="proposal_rejected_count">
                    <span>{item.proposal_rejected_count || ''}</span>
                  </Styled.Col>
                  <Styled.Col data-column-prop="move_in_date">
                    {!isEmpty(item.move_in_date) && (
                      <span>{leadsDate(item.move_in_date)}</span>
                    )}
                  </Styled.Col>
                  <Styled.Col data-column-prop="contact_channel">
                    <span>{item.contact_channel || ''}</span>
                  </Styled.Col>
                  <Styled.Col data-column-prop="location_first_converted_on">
                    <span>{item.location_first_converted_on || ''}</span>
                  </Styled.Col>
                  <Styled.Col
                    data-fixed
                    data-hidden
                    data-mobile-hidden
                  ></Styled.Col>
                  <Styled.Col
                    data-fixed
                    data-fixed-cell-actions
                    data-hidden
                    data-mobile-hidden
                  ></Styled.Col>
                  <Styled.Fixed data-attach-sticky-ref>
                    <Styled.Col
                      data-fixed
                      data-fixed-cell
                      data-fixed-cell-actions
                      data-column-prop="actions"
                    >
                      <LeadActions lead={item} />
                    </Styled.Col>
                    <Styled.Col
                      data-fixed
                      data-fixed-cell
                      data-fixed-cell-actions
                      data-column-prop="actions"
                    >
                      <span>{getStatus(item.notification_type, item.proposal)}</span>
                    </Styled.Col>
                  </Styled.Fixed>
                </Styled.Item>
              ))}
            </Styled.List>
          </Styled.Wrapper>
        )}
      </Flex>

      <Loading>{loading && <Spinner elSize={30} />}</Loading>

      {total > count && (
        <Pagination>
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'is-break'}
            pageCount={Math.ceil((total || 0) / count)}
            initialPage={0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => [setPage(selected + 1)]}
            disableInitialCallback
            containerClassName={'pagination-leads'}
            activeClassName={'is-active'}
          />
        </Pagination>
      )}
    </Flex>
  )
}

const getStatus = (type, proposal) => {
  if (proposal) {
    return 'Proposal Received'
  }

  if (type && type !== 'None') {
    return 'Registered'
  }

  return 'Not Registered'
}

const AddLeadBtn = styled.button.attrs({ type: 'button' })`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 10px;
  fill: white;
`

const AddIcon = styled(Plus)`
  margin-left: 10px;
  margin-bottom: 1px;
  width: 12px;
  height: 12px;
`

const Loading = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
`

const Pagination = styled.div`
  width: 100%;

  .pagination-leads {
    list-style: none;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    font-size: 1.3rem;

    a {
      min-width: 30px;
      width: 100%;
      display: block;
      text-align: center;
      cursor: pointer;
      padding: 5px;
    }

    .previous.disabled,
    .next.disabled {
      display: none;
    }

    .is-active a {
      color: red;
    }
  }
`

export default WorkLeads

