import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import reduce from 'lodash/reduce'
import * as Styled from '../../components/location'
import Spinner from '../../components/spinner'
import SlideToggle from '../../components/slide-toggle'
import { smOnly } from '../../styles/breakpoints'
import { locationUpdate } from '../../actions/locations'
import AttachmentPdf from '../../components/attachment-pdf'

const fields = {
  Coworking: [
    {
      name: 'amenityPublicTransportation',
      label: '5 Minutes from Transportation'
    },
    { name: 'amenityBikeStorage', label: 'Bike Storage' },
    { name: 'amenity247BuildingAccess', label: '24/7 Building Access' },
    { name: 'amenityEducationalProgramming', label: 'Educational Programming' },
    { name: 'amenityDogFriendly', label: 'Dog Friendly' },
    { name: 'amenityFreeBeer', label: 'Free Beer' },
    { name: 'amenityEventSpace', label: 'Event Space' },
    { name: 'amenityFreePrinting', label: 'Free Printing' },
    { name: 'amenityParking', label: 'Free Parking' },
    { name: 'amenityFrontDeskReceiption', label: 'Front Desk Reception' },
    { name: 'amenityFreeSnacks', label: 'Free Snacks' },
    { name: 'amenityMailPackageHandling', label: 'Mail & Package Handling' },
    {
      name: 'amenityHighTechConferenceRooms',
      label: 'High Tech Conference Rooms'
    },
    { name: 'amenityMeetingRooms', label: 'Meeting Rooms' },
    { name: 'amenityMakerspace', label: 'Makerspace' },
    { name: 'amenityFitnessCenter', label: 'On-Site Fitness Center' },
    { name: 'amenityMothersRoom', label: 'Mother’s Room' },
    { name: 'amenityShortTermLeaseOptions', label: 'Short Term Lease Options' },
    { name: 'amenityPrivatePhoneBooths', label: 'Private Phone Booths' },
    {
      name: 'amenityFreeMultiLocationAccess',
      label: 'Free Multi-Location Access'
    },
    { name: 'amenityShowers', label: 'Showers' },
    { name: 'amenityCleaningPolicies', label: 'Cleaning Policies', pdf: { name: 'cleaningPoliciesPDFLink' } },
    { name: 'amenitySafe6Floorplan', label: 'Floor Plan' },
    { name: 'amenityHvac', label: '24/7 HVAC' },
    { name: 'amenityPodcasting', label: 'Podcasting Booth' }
  ],
  Workplace: [
    { name: 'amenityKitchenInUnit', label: 'Kitchen in the Unit' },
    { name: 'amenityPrivateConferenceRooms', label: 'Private Conference Rooms in the Unit' },
    { name: 'amenityFurnished', label: 'Furnished' },
    { name: 'amenityFurnitureAvailableToRentOrPurchase', label: 'Furniture Available to Rent or Buy' },
    { name: 'cleaningServicesAvailableRollover', label: 'Cleaning Services Available' },
    { name: 'cleaningServicesProvidedRollover', label: 'Cleaning Services Provided' },
    { name: 'monthToMonthLeaseTermRollover', label: 'Month to Month Lease Term Available' },
    { name: 'sixMonthTermAvailableRollover', label: '6 Month Lease Term Available' },
    { name: 'twelveMonthTermAvailableRollover', label: '12 Month Lease Term Available' },
    { name: 'privateRollover', label: 'Private to the Team' },
    { name: 'secureRollover', label: 'Secure Access' }
  ]
}

const getFieldsByType = location => {
  const types = ['Coworking', 'Workplace']

  return reduce(types, (acc, type) => {
    if (location?.officeType && location.officeType.includes(type)) {
      return [...acc, ...fields[type]]
    }
    return acc
  }, [])
}

const getInitialValues = location => {
  return reduce(getFieldsByType(location), (acc, { name }) => ({ ...acc, [name]: location[name] }), {})
}

const LocationAmenities = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)
  const formik = useFormik({
    initialValues: {
      ...getInitialValues(location),
      cleaningPoliciesPDFLinkId: location?.cleaningPoliciesPDFLinkId,
      cleaningPoliciesPDFLinkName: location?.cleaningPoliciesPDFLinkName
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      dispatch(locationUpdate(locationId, values))
    }
  })
  const mobile = useMediaQuery({ query: smOnly })
  const handleResetForm = () =>
    formik.resetForm({
      values: {
        ...getInitialValues(location),
        cleaningPoliciesPDFLinkId: location?.cleaningPoliciesPDFLinkId,
        cleaningPoliciesPDFLinkName: location?.cleaningPoliciesPDFLinkName
      }
    })

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.Header>
            <Styled.Note>
              <p>
                <b>Note:</b> Designate the available amenities your location
                offers.
              </p>
            </Styled.Note>
            <Styled.Actions mobile={mobile}>
              {updating && <Spinner elSize={20} />}
              <>
                <Styled.Action
                  disabled={updating}
                  type="button"
                  onClick={() => handleResetForm()}
                >
                  Cancel
                </Styled.Action>
                <Styled.Action disabled={updating} type="submit">
                  Save
                </Styled.Action>
              </>
            </Styled.Actions>
          </Styled.Header>
          <Styled.Section>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  {location?.name && (
                    <Styled.Heading>
                      Amenities at {location.name}
                    </Styled.Heading>
                  )}
                </Styled.Col>
              </Styled.Row>
              <Flex flexWrap fullWidth>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <SlideToggle
                    onChange={formik.handleChange}
                    label="1 GB WiFi"
                    id="amenity1GBWiFi"
                    name="amenity1GBWiFi"
                    value={formik.values.amenity1GBWiFi}
                  />
                </Styled.Col>
                {getFieldsByType(location).map(item => (
                  <Styled.Col
                    key={item.name}
                    maxWidth={!mobile ? '25%' : '100%'}
                  >
                    {item.pdf ? (
                      <>
                        <label htmlFor={item.name}>
                          <Styled.LabelText>{item.label}</Styled.LabelText>
                        </label>
                        <Flex fullWidth>
                          <SlideToggle
                            onChange={formik.handleChange}
                            id={item.name}
                            name={item.name}
                            value={formik.values[item.name]}
                          />
                          <AttachmentPdf
                            initialFileName={
                              formik.values[`${item.pdf.name}Name`]
                            }
                            name={item.pdf.name}
                            onRemove={() => {
                              formik.setFieldValue(`${item.pdf.name}Id`, null)
                              formik.setFieldValue(`${item.pdf.name}Name`, null)
                            }}
                            onChange={({ id, name }) => {
                              formik.setFieldValue(`${item.pdf.name}Id`, id)
                              formik.setFieldValue(`${item.pdf.name}Name`, name)
                            }}
                          />
                        </Flex>
                      </>
                    ) : (
                      <SlideToggle
                        onChange={formik.handleChange}
                        label={item.label}
                        id={item.name}
                        name={item.name}
                        value={formik.values[item.name]}
                      />
                    )}
                  </Styled.Col>
                ))}
              </Flex>
            </Flex>
          </Styled.Section>
        </Styled.Form>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationAmenities
