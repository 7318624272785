import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'
import { stringify } from 'qs'

export const spacesByLocationIdGet = locationId =>
  fetch(
    `${apiUrl}${apiBase}/space?where[1][type]=in&where[1][attribute]=locationId&where[1][value]=${locationId}&alter=true`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey
      }
    }
  )

export const spaceCreate = (token, data) =>
  fetch(`${apiUrl}${apiBase}/space`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const spaceUpdate = (token, id, data) =>
  fetch(`${apiUrl}${apiBase}/space/${id}`, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token
    },
    body: JSON.stringify(data)
  })

export const spaceDelete = (token, id) =>
  fetch(`${apiUrl}${apiBase}/space/${id}`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const availableSpacesByLocIdGet = id => {
  const params = stringify(
    {
      sortBy: 'spaceCapacity',
      asc: true,
      'where[0][type]': 'equals',
      'where[0][attribute]': 'locationId',
      'where[0][value]': id,
      'where[1][type]': 'isTrue',
      'where[1][attribute]': 'isAvailable'
    },
    { encode: false }
  )

  return fetch(`${apiUrl}${apiBase}/space?${params}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey
    }
  })
}
