import fetch from 'isomorphic-fetch'
import { stringify } from 'qs'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getMonthlyLeadsTotal = (token) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/monthlyleadstotal`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const getLatestLeads = (token, count) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/latestleads?count=${count || 25}&sortBy=created_at&order=desc`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const getWorkLeads = (token, params) => {
  const query = stringify(params)

  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/latestleads?${query}`,
    {
      method: 'get',
      headers: {
        'x-api-key': apiKey,
        Authorization: token
      }
    }
  )
}

export const updateLead = (token, data) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/updatelead`,
    {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        Authorization: token
      },
      body: JSON.stringify(data)
    }
  )
}

export const addLead = (token, data) => {
  return fetch(
    `${apiUrl}${apiBase}/flex/locationservices/updatelead`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
        Authorization: token
      },
      body: JSON.stringify(data)
    }
  )
}
