import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth'
import user from './user'
import locations from './locations'
import spaces from './spaces'
import leads from './leads'
import modal from './modal'
import pipeline from './pipeline'
import markets from './markets'
import viewsInSearch from './views-in-search'
import leadPerformance from './lead-performance'
import pricePerformance from './price-performance'
import agreementsAndFees from './agreements-and-fees'
import location from './location'
import hoods from './hoods'
import reports from './reports'
import summaries from './summaries'
import proposal from './proposal'
import locationServices from './location-services'
import locationServicesAll from './location-services-all'
import brokerTools from './broker-tools'

const rootReducer = {
  auth,
  user,
  locations,
  location,
  hoods,
  spaces,
  modal,
  leads,
  pipeline,
  markets,
  viewsInSearch,
  leadPerformance,
  pricePerformance,
  agreementsAndFees,
  reports,
  summaries,
  proposal,
  locationServices,
  locationServicesAll,
  brokerTools
}

export default history =>
  combineReducers({
    router: connectRouter(history),
    ...rootReducer
  })
