import React from 'react'
import Flatpickr from 'react-flatpickr'
import { Wrapper, PickrStyles } from './styles'
import { FlatpickrLightCss } from './lightcss'

const DatePickerLeads = props => {
  const { disabled, onChange, name, bordered, minDate, bgInput, altInput = true } = props

  return (
    <Wrapper bordered={bordered} bgInput={bgInput}>
      <FlatpickrLightCss />
      <PickrStyles />
      {disabled && (
        <Flatpickr
          id={name}
          name={name}
          options={{
            minDate,
            dateFormat: 'm/d/Y',
            altInput
          }}
          value={props.value}
          onChange={(dateString, formatedDate) => {
            onChange(formatedDate === '' ? null : formatedDate)
          }}
          placeholder="MM/dd/yyyy"
          disabled
        />
      )}
      {!disabled && (
        <Flatpickr
          id={name}
          name={name}
          options={{
            minDate,
            dateFormat: 'm/d/Y',
            altInput
          }}
          value={props.value}
          onChange={(dateString, formatedDate) => {
            onChange(formatedDate === '' ? null : formatedDate)
          }}
          placeholder="MM/dd/yyyy"
        />
      )}
    </Wrapper>
  )
}

export default DatePickerLeads
