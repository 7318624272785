import { checkHttpStatus, parseJSON } from '../utils/api'
import {
  PROPOSAL_GET_REQUEST,
  PROPOSAL_GET_SUCCESS,
  PROPOSAL_GET_FAILURE,
  PROPOSAL_UPDATE_REQUEST,
  PROPOSAL_UPDATE_SUCCESS,
  PROPOSAL_UPDATE_FAILURE,
  PROPOSAL_LEAD_CREATED,
  PROPOSAL_LEAD_GET_REQUEST,
  PROPOSAL_LEAD_GET_SUCCESS,
  PROPOSAL_LEAD_GET_FAILURE
} from '../constants'
import * as api from '../api/proposal'
import { getTokenWithExpiration } from './auth'

export const proposalLeadCreated = (payload) => ({
  type: PROPOSAL_LEAD_CREATED,
  payload
})

const proposalGetRequest = () => ({
  type: PROPOSAL_GET_REQUEST
})

const proposalGetSuccess = (payload, config) => ({
  type: PROPOSAL_GET_SUCCESS,
  payload,
  config
})

const proposalGetFailure = () => ({
  type: PROPOSAL_GET_FAILURE
})

export const proposalGet = (locationId, leadId) => (dispatch, getState) => {
  if (getState().proposal.byLocLeadId[`${locationId}${leadId}`]) {
    return null
  }

  dispatch(proposalGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      api
        .getProposal(token, locationId, leadId)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(proposalGetSuccess(response, { locationId, leadId }))
        })
        .catch(err => {
          if (err) {
            parseJSON(err).then(() => {
              // eslint-disable-next-line no-alert
              window.alert(err.status)
              dispatch(proposalGetFailure())
            })
          } else {
            dispatch(proposalGetFailure())
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const proposalUpdateRequest = () => ({
  type: PROPOSAL_UPDATE_REQUEST
})

const proposalUpdateSuccess = payload => ({
  type: PROPOSAL_UPDATE_SUCCESS,
  payload
})

const proposalUpdateFailure = () => ({
  type: PROPOSAL_UPDATE_FAILURE
})

export const proposalUpdate = data => (dispatch, getState) => {
  dispatch(proposalUpdateRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      api
        .updateProposal(token, data)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(proposalUpdateSuccess(response))
        })
        .catch(err => {
          if (err) {
            parseJSON(err).then(() => {
              // eslint-disable-next-line no-alert
              window.alert(err.status)
              dispatch(proposalUpdateFailure())
            })
          } else {
            dispatch(proposalUpdateFailure())
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}

const proposalLeadGetRequest = () => ({
  type: PROPOSAL_LEAD_GET_REQUEST
})

const proposalLeadGetSuccess = (payload, config) => ({
  type: PROPOSAL_LEAD_GET_SUCCESS,
  payload,
  config
})

const proposalLeadGetFailure = () => ({
  type: PROPOSAL_LEAD_GET_FAILURE
})

export const proposalLeadGet = id => (dispatch, getState) => {
  if (getState().proposal.byLeadId[id]) {
    return null
  }

  dispatch(proposalLeadGetRequest())

  return dispatch(getTokenWithExpiration(getState()))
    .then(token => {
      api
        .getLeadProposal(token, id)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {
          dispatch(proposalLeadGetSuccess(response, id))
        })
        .catch(err => {
          if (err) {
            parseJSON(err).then(() => {
              // eslint-disable-next-line no-alert
              window.alert(err.status)
              dispatch(proposalLeadGetFailure())
            })
          } else {
            dispatch(proposalLeadGetFailure())
          }
        })
    })
    .catch(() => {
      // eslint-disable-next-line no-alert
      window.alert('Token issue. Please reload the page')
    })
}
