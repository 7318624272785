import { checkHttpStatus, parseJSON } from '../utils/api';
import {
  PIPELINE_GET_REQUEST,
  PIPELINE_GET_SUCCESS,
  PIPELINE_GET_FAILURE,
} from '../constants';
import * as api from '../api/pipeline';

const pipelineGetRequest = () => ({
  type: PIPELINE_GET_REQUEST,
});

const pipelineGetSuccess = payload => ({
  type: PIPELINE_GET_SUCCESS,
  payload,
});

const pipelineGetFailure = payload => ({
  type: PIPELINE_GET_FAILURE,
  payload,
});

export const pipelineGet = (token, params) => dispatch => {
  dispatch(pipelineGetRequest());

  return api
    .getPipeline(token, params)
    .then(checkHttpStatus)
    .then(parseJSON)
    .then(response => {
      dispatch(pipelineGetSuccess({ params, data: response }));
    })
    .catch(err => {
      if (err) {
        parseJSON(err).then(message => {
          dispatch(pipelineGetFailure(`${err.status}: ${message}`));
        });
      } else {
        dispatch(pipelineGetFailure('Pipeline get unknown error'));
      }
    });
};
