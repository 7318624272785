import isFinite from 'lodash/isFinite'
import chunk from 'lodash/chunk'

export const roundDecimals = num =>
  isFinite(num) ? Math.round((num + Number.EPSILON) * 100) / 100 : null

export const getPct = (left, right) => {
  if (!isFinite(left) || !isFinite(right) || right === 0) {
    return null
  }

  return (left / right) * 100
}

export const getPctWithZero = (left, right) => {
  if (!isFinite(left) || !isFinite(right)) {
    return 0
  }

  if (left === right) return 0

  if (right === 0) {
    return left * 100
  }

  return (left / right) * 100 - 100
}

export const addPctSign = (num, showZero = false) => {
  if (!isFinite(num) || (!showZero && num === 0)) {
    return null
  }

  return `${num}%`
}

export const getQuorters = () => {
  const today = new Date()
  const quarter = Math.floor((today.getMonth() + 3) / 3)
  const year = today.getFullYear()
  const startYear = 2020
  const numberOfQuarters = (year - startYear) * 4 + (quarter - 1)
  const toChunk = Array.from(
    { length: numberOfQuarters },
    (_, i) => (i + 1) % 4 || 4
  )

  return chunk(toChunk, 4)
    .map((q, i) => q.map(n => `${startYear + i} Q${n}`))
    .flat()
}
