import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { logout } from '../../actions/auth';
import { LOGIN_ROUTE as logoutRoute } from '../../settings/routes';

const Logout = () => {
  const dispatch = useDispatch()
  dispatch(logout()).then(() => dispatch(push(`/${logoutRoute}`)));

  return <></>
}

export default Logout
