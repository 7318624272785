import React, { useState, lazy, Suspense } from 'react'
import findIndex from 'lodash/findIndex'
import styled from 'styled-components/macro'
import Tooltip from '../../components/tooltip'
import { getPriceOptions } from './options'
import Flex from '../flex'
import Spinner from '../spinner'
import Selectors from './selectors'

const Chart = lazy(() => import('../chart'))

const spaceTypeOptions = [
  { value: 'Floating Desk', label: 'Floating Desk', isDesk: true },
  { value: 'Dedicated Desk', label: 'Dedicated Desk', isDesk: true },
  { value: 'Private Office', label: 'Private Office' },
  { value: '2 person Suite', label: '2 person Suite' },
  { value: '3 person Suite', label: '3 person Suite' },
  { value: '4 person Suite', label: '4 person Suite' },
  { value: '5 person Suite', label: '5 person Suite' },
  { value: '6 person Suite', label: '6 person Suite' },
  { value: '10+ person Suite', label: '10+ person Suite' }
]

const measureOptions = [
  { value: 'Starting Price', label: 'Starting Price' },
  { value: 'Average Price', label: 'Average Price' }
]

const MarketAskingPrices = ({ selectedType, data, marketData, locationsNumber }) => {
  const [spaceType, setSpaceType] = useState(spaceTypeOptions[0].value)
  const [measure, setMeasure] = useState(measureOptions[0].value)
  const typeValue = data[`${spaceType} ${measure}`]
  const spaceTypeIndex = findIndex(spaceTypeOptions, item => item.value === spaceType)
  const selectedValue = (marketData[measure] || [])[spaceTypeIndex]
  const options = getPriceOptions({ spaceType, selectedType, typeValue, selectedValue })

  return (
    <Wrap data-show-notification={locationsNumber < 3}>
      <Title>
        Asking Prices by Space Type{' '}
        <Tooltip
          overlay={
            <div>
              <span>Asking Prices by Space Type shows the average price per space for the selected option. This is an average of all locations in the selected market, neighborhood or area.</span>
            </div>
          }
        >
          <Info>(i)</Info>
        </Tooltip>
      </Title>
      {locationsNumber > 2 ? (
        <Container>
          <Row>
            <Selectors
              spaceTypeOptions={spaceTypeOptions}
              measureOptions={measureOptions}
              spaceType={spaceType}
              measure={measure}
              setSpaceType={setSpaceType}
              setMeasure={setMeasure}
            />
          </Row>
          <ChartWrap>
            <Suspense
              fallback={
                <Flex fullWidth fullHeight center>
                  <Spinner elSize={40} />
                </Flex>
              }
            >
              <Chart options={options} />
            </Suspense>
          </ChartWrap>
        </Container>
      ) : (
        <NotificationWrap>
          <span>Pricing results cannot be shown for fewer than three locations. Please adjust your filters to show more than three locations.</span>
        </NotificationWrap>
      )}
    </Wrap>
  )
}

const NotificationWrap = styled.div`
  display: flex;
  height: calc(100% - 37px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
  line-height: 1.4;
`

const ChartWrap = styled.div`
  width: 100%;

  .highcharts-credits {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`

const Info = styled.span`
  margin-right: 3px;
  font-weight: 700;
  cursor: pointer;
`

const Wrap = styled.div`
  width: 100%;
  border: 1px solid #57ab83;
  border-radius: 10px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  background-color: white;
  max-width: 600px;
  margin-bottom: 10px;

  &[data-show-notification='true'] {
    height: 407px;
  }
`

const Title = styled.span`
  color: #e7483d;
  font-weight: 700;
  text-align: center;
  display: block;
  font-size: 20px;
  margin-bottom: 14px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export default MarketAskingPrices
