import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { push } from 'connected-react-router';
import Select from 'react-select';

import { TopSelectors, SelectorWrap } from '../../components/price-performance';

import { DEMAND_AND_PRICING_ROUTE } from '../../settings/routes';

const spaceTypeOptions = [
  { value: 'Floating Desk', label: 'Floating Desk' },
  { value: 'Dedicated Desk', label: 'Dedicated Desk' },
  { value: 'Suite', label: 'Suite' },
];

const measureOptions = [
  { value: 'Starting Price', label: 'Starting Price' },
  { value: 'Average Price', label: 'Average Price' },
];

const getUnitOptions = measure => {
  let unitOptions = [];

  if (measure === 'Starting Price' || measure === 'Average Price') {
    unitOptions = [
      { value: '$ per person', label: '$ per person' },
      { value: '$ per sqft', label: '$ per sqft' },
    ];
  } else {
    unitOptions = [{ value: 'sqft per person', label: 'sqft per person' }];
  }

  return unitOptions;
};

const Selectors = () => {
  const {
    locationId,
    marketId,
    spaceType: spaceTypeParam,
    measure: measureParam,
    unit: unitParam,
  } = useParams();
  const dispatch = useDispatch();
  const [spaceType, setSpaceType] = useState(spaceTypeParam || 'Floating Desk');
  const [measure, setMeasure] = useState(measureParam || 'Starting Price');
  const [unit, setUnit] = useState(
    unitParam
      ? { value: unitParam, label: unitParam }
      : { value: '$ per suite', label: '$ per suite' },
  );

  const unitOptions = getUnitOptions(measure);

  useEffect(() => {
    if (
      spaceType &&
      measure &&
      unit &&
      !unitOptions.find(item => item.value === unit.value)
    ) {
      setUnit(unitOptions[0]);
    }

    if (spaceType && measure && unit) {
      dispatch(
        push(
          `/${DEMAND_AND_PRICING_ROUTE}/price-performance/${marketId}/${locationId}/${spaceType}/${measure}/${unit.value}`,
        ),
      );
    }
  }, [locationId, marketId, spaceType, measure, unit]); // eslint-disable-line

  return (
    <TopSelectors>
      <SelectorWrap>
        <Select
          options={spaceTypeOptions}
          value={spaceType ? { value: spaceType, label: spaceType } : null}
          placeholder="Space Type"
          onChange={({ value }) => setSpaceType(value)}
        />
      </SelectorWrap>
      <SelectorWrap>
        <Select
          options={measureOptions}
          value={measure ? { value: measure, label: measure } : null}
          placeholder="Measure"
          onChange={({ value }) => setMeasure(value)}
        />
      </SelectorWrap>
      <SelectorWrap>
        <Select
          options={unitOptions}
          value={unit}
          placeholder="Unit"
          onChange={value => setUnit(value)}
        />
      </SelectorWrap>
    </TopSelectors>
  );
};

export default Selectors;
