import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Flex from '../../components/flex'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import * as Styled from '../../components/location'
import Spinner from '../../components/spinner'
import { smOnly } from '../../styles/breakpoints'
import { locationUpdate } from '../../actions/locations'
import Attachment from '../../components/attachment'
import SlideToggle from '../../components/slide-toggle'
import WifiSystemSelect from '../../components/wifisystems-select'

const LocationNetwork = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)
  const getInitialValues = {
    networkProvider: location?.networkProvider,
    fiber: location?.fiber,
    wifiProvider: location?.wifiProvider,
    serverRoomSize: location?.serverRoomSize,
    serverRoomImageId: location?.serverRoomImageId,
    serverRoomImageName: location?.serverRoomImageName,
    mMSSoftwareUsed: location?.mMSSoftwareUsed,
    wifiSystemName: location?.wifiSystemName,
    wifiSystemId: location?.wifiSystemId
  }

  const formik = useFormik({
    initialValues: getInitialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: values => {
      dispatch(locationUpdate(locationId, values))
    }
  })
  const mobile = useMediaQuery({ query: smOnly })
  const handleResetForm = () =>
    formik.resetForm({ values: getInitialValues })

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Form onSubmit={formik.handleSubmit}>
          <Styled.Header>
            <Styled.Actions mobile={mobile}>
              {updating && <Spinner elSize={20} />}
              <>
                <Styled.Action
                  disabled={updating}
                  type="button"
                  onClick={() => handleResetForm()}
                >
                  Cancel
                </Styled.Action>
                <Styled.Action disabled={updating} type="submit">
                  Save
                </Styled.Action>
              </>
            </Styled.Actions>
          </Styled.Header>
          <Styled.Section>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  <Styled.Title>Location Network & IT</Styled.Title>
                </Styled.Col>
              </Styled.Row>
              <Flex flexWrap fullWidth>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="networkProvider">
                    <Styled.LabelText>Network Provider</Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="networkProvider"
                      name="networkProvider"
                      placeholder="_"
                      value={formik.values?.networkProvider || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <SlideToggle
                    onChange={formik.handleChange}
                    name="fiber"
                    label="Fiber"
                    value={formik.values?.fiber}
                    disabled={updating}
                  />
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="wifiProvider">
                    <Styled.LabelText>WiFi Provider</Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="wifiProvider"
                      name="wifiProvider"
                      placeholder="_"
                      value={formik.values?.wifiProvider || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  {location && (
                    <WifiSystemSelect
                      value={
                        formik.values?.wifiSystemId && {
                          label: formik.values?.wifiSystemName,
                          value: formik.values?.wifiSystemId
                        }
                      }
                      onChange={({ id, name }) => {
                        formik.setFieldValue('wifiSystemId', id)
                        formik.setFieldValue('wifiSystemName', name)
                      }}
                    />
                  )}
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label htmlFor="serverRoomSize">
                    <Styled.LabelText>Server Room Size</Styled.LabelText>
                    <Styled.Input
                      type="number"
                      step="any"
                      id="serverRoomSize"
                      name="serverRoomSize"
                      placeholder="_"
                      value={formik.values?.serverRoomSize || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Attachment
                    imageId={formik.values?.serverRoomImageId}
                    label="Server Room Image"
                    name="serverRoomImage"
                    onRemove={() => {
                      formik.setFieldValue('serverRoomImageId', null)
                      formik.setFieldValue('serverRoomImageName', null)
                    }}
                    onChange={({ id, name }) => {
                      formik.setFieldValue('serverRoomImageId', id)
                      formik.setFieldValue('serverRoomImageName', name)
                    }}
                  />
                </Styled.Col>
                <Styled.Col maxWidth={!mobile ? '25%' : '100%'}>
                  <Styled.Label>
                    <Styled.LabelText>MMS Software Used</Styled.LabelText>
                    <Styled.Input
                      type="text"
                      id="mMSSoftwareUsed"
                      name="mMSSoftwareUsed"
                      placeholder="_"
                      value={formik.values?.mMSSoftwareUsed || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={updating}
                    />
                  </Styled.Label>
                </Styled.Col>
              </Flex>
            </Flex>
          </Styled.Section>
        </Styled.Form>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationNetwork
