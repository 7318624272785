import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Flex from '../../components/flex';
import { Wrapper } from '../../components/profile';
import Breadcrumbs from '../../containers/Breadcrumbs';
import PasswordChangeForm from '../../containers/PasswordChangeForm';
import PictureForm from '../../containers/ProfilePicture';
import { changePassword } from '../../actions/auth';
import { profilePicUpdate } from '../../actions/user';

const Profile = () => {
  const dispatch = useDispatch();
  const [passwordChage, setPasswordMessage] = useState(null);

  const pictureUpdateHandler = file => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = loadEvent => {
        dispatch(profilePicUpdate(loadEvent.target.result, file.type));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Flex fullWidth column full>
      <Breadcrumbs childRoute="Profile" />
      <Wrapper>
        <PictureForm
          onSubmit={values => pictureUpdateHandler(values.picture)}
        />

        <PasswordChangeForm
          onSubmit={values =>
            dispatch(changePassword(values))
              .then(() => {
                setPasswordMessage('Password сhanged successfully');
                setTimeout(() => setPasswordMessage(null), 3000);
              })
              .catch(() => {
                setPasswordMessage('Password change error');
                setTimeout(() => setPasswordMessage(null), 3000);
              })
          }
          message={passwordChage}
        />
      </Wrapper>
    </Flex>
  );
};

export default Profile;
