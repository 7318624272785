import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import Flex from '../../components/flex';
import { Container, Top, Heading, SubHeading } from '../../components/login';
import Spinner from '../../components/spinner';
import {
  Input,
  LabelText,
  Button,
  ErrorText,
} from '../../components/form-fields';
import Logo from '../../components/logo';
import PoweredBy from '../../components/powered-by';
import { LOGIN_ROUTE } from '../../settings/routes';

const ConfirmationCode = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(null);
  const [validation, setValidation] = useState(null);
  const { recoveryEmail } = useSelector(state => state.auth);

  useEffect(() => {
    if (!recoveryEmail) {
      dispatch(push(`/${LOGIN_ROUTE}`));
    }
  }, [dispatch, recoveryEmail]);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      confirmationCode: '',
    },
    onSubmit: ({ confirmationCode, password }) => {
      setLoading(true);
      setError(false);
      setErrorText(null);

      Auth.forgotPasswordSubmit(recoveryEmail, confirmationCode, password)
        .then(() => {
          setLoading(false);
          setError(false);
          setErrorText(null);

          dispatch(push(`/${LOGIN_ROUTE}`));
        })
        .catch(err => {
          setLoading(false);
          setError(true);
          setErrorText(err.message);
        });
    },
  });

  const validateMatch = (value, confirmValue) => {
    setError(false);
    setErrorText(null);

    if (value !== confirmValue) {
      setValidation("Passwords don't match");
    }

    if (value === confirmValue || !value || !confirmValue) {
      setValidation(null);
    }
  };

  return (
    <Flex fullWidth fullScreenHeight middle column spaceBetween>
      <Flex fullWidth full middle center>
        <Container>
          <Top>
            <Logo />
          </Top>
          <Heading fontSize="28px">Change password</Heading>
          <SubHeading>
            Please enter your confirmation code and new password below
          </SubHeading>
          <form onSubmit={formik.handleSubmit}>
            <label htmlFor="confirmationCode">
              <LabelText>Confirmation Code</LabelText>
              <Input
                id="confirmationCode"
                name="confirmationCode"
                type="text"
                required
                disabled={loading}
                onChange={formik.handleChange}
              />
            </label>

            <label htmlFor="password">
              <LabelText>New Password</LabelText>
              <Input
                id="password"
                name="password"
                type="password"
                onChange={e => {
                  formik.handleChange(e);
                  validateMatch(formik.values.confirmPassword, e.target.value);
                }}
                onBlur={e => {
                  formik.handleBlur(e.target.value);
                  validateMatch(formik.values.confirmPassword, e.target.value);
                }}
                value={formik.values.password}
                required
                disabled={loading}
              />
            </label>

            <label htmlFor="confirmPassword">
              <LabelText>Enter new password again</LabelText>
              <Input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                onChange={e => {
                  formik.handleChange(e);
                  validateMatch(formik.values.password, e.target.value);
                }}
                onBlur={e => {
                  formik.handleBlur(e.target.value);
                  validateMatch(formik.values.password, e.target.value);
                }}
                value={formik.values.confirmPassword}
                required
                disabled={loading}
              />
            </label>
            {error && <ErrorText>{errorText}</ErrorText>}
            {validation && <ErrorText>{validation}</ErrorText>}
            <Button type="submit" disabled={loading || validation} withSpinner>
              {loading && <Spinner elSize={18} />}
              <span>Send</span>
            </Button>
          </form>
        </Container>
      </Flex>
      <Flex fullWidth flexEnd>
        <PoweredBy />
      </Flex>
    </Flex>
  );
};

export default ConfirmationCode;
