import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import * as Styled from '../widget'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../../components/spinner'
import { mdUp, mdDown } from '../../styles/breakpoints'
import { Link } from 'react-router-dom'
import { latestLeadsGet } from '../../actions/broker-tools'

const WidgetNewLeads = () => {
  const dispatch = useDispatch()
  const { payload: latestLeads, loading } = useSelector(state => state.brokerTools.latestLeads)

  useEffect(() => {
    dispatch(latestLeadsGet())
  }, [dispatch])

  return (
    <Styled.Wrap>
      <Styled.Box>
        <Styled.InsideTitle>New Leads</Styled.InsideTitle>
        {latestLeads?.length > 0 && (
          <List>
            <Item data-sticky>
              <Col data-header data-name>
                <span>Name</span>
              </Col>
              <Col data-header>
                <span>Seats</span>
              </Col>
              <Col data-header>
                <span>Market</span>
              </Col>
              <Col data-header data-center>
                <span>Date Registered</span>
              </Col>
              <Col data-header data-center>
                <span>Proposal Received</span>
              </Col>
            </Item>
            {latestLeads.map(item => (
              <Item key={item.id}>
                <Col data-name data-underline>
                  <Link
                    to={window.location.pathname.replace(
                      'dashboard',
                      'work-leads'
                    )}
                  >
                    {item.first_name || ''} {item.last_name || ''}
                  </Link>
                </Col>
                <Col>
                  <span>{`${item.seats} seat${
                    item.seats === 0 || item.seats > 1 ? 's' : ''
                  }`}</span>
                </Col>
                <Col data-break-all>
                  <span>{item.market}</span>
                </Col>
                <Col data-center>
                  <span>{`${format(
                    new Date(item.created_at),
                    'M/d'
                  )}`}</span>
                </Col>
                <Col data-center>
                  <span>{item.proposal ? 'Yes' : 'No'}</span>
                </Col>
              </Item>
            ))}
          </List>
        )}
        {loading && (
          <Loading>
            <Spinner elSize={40} />
          </Loading>
        )}
      </Styled.Box>
    </Styled.Wrap>
  )
}

const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
`

const List = styled.ul`
  padding-left: 0;
  font-size: 14px;
  color: black;
  margin: 0;
  height: 260px;
  overflow: auto;

  @media ${mdUp} {
    font-size: 13px;
    border-left: none;
    border-right: none;
  }
`

const Item = styled.li`
  display: flex;
  justify-content: space-around;
  padding: 3px 5px;
  margin: 0;
  overflow: hidden;

  &[data-sticky='true'] {
    background-color: #fff;
    position: sticky;
    top: 0;
    align-items: center;
  }

  @media ${mdDown} {
    padding: 5px;
    align-items: center;
    width: 1023px;
  }
`

const Col = styled.div`
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  flex:

  &[data-center='true'] {
    text-align: center;
  }

  &[data-break-all="true"] {
    word-break: break-all;
  }

  &[data-name='true'] {
    flex: 0 0 125px;
  }

  &[data-header='true'] {
    span {
      display: block;
      color: black;
      font-weight: 600;
    }
  }

  &[data-underline='true'] {
    a {
      text-decoration: underline;
    }
  }
`

export default WidgetNewLeads
