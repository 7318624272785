import React from 'react'
import Select, { components } from 'react-select'
import styled from 'styled-components/macro'
import { CheckboxChecked, CheckboxUnchecked } from '../icons'

const Option = props => {
  return (
    <components.Option {...props}>
      <span>{props.label}</span>
      {props.isSelected ? <CheckboxChecked /> : <CheckboxUnchecked />}
    </components.Option>
  )
}

const ValueContainer = ({ children, ...props }) => {
  const { getValue } = props
  const value = getValue()
  const hasMultiple = value.length > 1

  return (
    <components.ValueContainer {...props}>
      <span>{hasMultiple ? 'Multiple Selected' : value[0]?.label}</span>
      {children}
    </components.ValueContainer>
  )
}

const IndicatorSeparator = () => null

const MultiSelect = ({
  placeholder,
  options,
  loading,
  value,
  disabled,
  onChange = () => {}
}) => {
  return (
    <Wrap>
      <Select
        options={options}
        isMulti
        isLoading={loading}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
          ValueContainer,
          IndicatorSeparator
        }}
        styles={{
          container: provided => ({
            ...provided,
            width: 190
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && 'transparent',
            color: state.isSelected && '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '> svg': {
              flexShrink: 0
            }
          }),
          menu: provided => ({
            ...provided,
            zIndex: 6,
            '@media (max-width: 63.9375em)': {
              position: 'relative'
            }
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            color: state.menuIsOpen || state.hasValue ? '#e7483d' : '#000',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            paddingRight: 0,
            '> span': {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              display: 'block'
            }
          }),
          multiValue: provided => ({
            ...provided,
            display: 'none'
          }),
          control: (provided, state) => {
            return {
              ...provided,
              minHeight: 35,
              borderRadius: 3,
              borderColor:
                state.hasValue || state.menuIsOpen
                  ? '#e7483d'
                  : 'hsl(0,0%,80%)',
              boxShadow: 'none',
              '&:hover': {
                borderColor: state.menuIsOpen ? '#e7483d' : 'hsl(0,0%,80%)'
              }
            }
          },
          indicatorsContainer: (provided, state) => {
            return {
              ...provided,
              minWidth: 20,
              '> div': {
                padding: 6
              },
              svg: {
                display: 'none'
              },
              '&::after': {
                content: '""',
                borderStyle: 'solid',
                borderColor: `${
                  state.hasValue || state.selectProps.menuIsOpen
                    ? '#e7483d'
                    : 'hsl(0,0%,80%)'
                } transparent transparent`,
                borderWidth: '5px 5px 2.5px',
                display: 'inline-block',
                height: 0,
                width: 0,
                position: 'absolute',
                right: '10px',
                top: '14px'
              }
            }
          }
        }}
        placeholder={placeholder}
        onChange={value => onChange(value || [])}
        isClearable={false}
        isDisabled={disabled}
        value={value}
      />
    </Wrap>
  )
}

const Wrap = styled.div``

export default MultiSelect
