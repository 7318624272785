import { createReducer } from '../utils/redux'
import {
  LOCATION_SERVICES_ALL_GET_REQUEST,
  LOCATION_SERVICES_ALL_GET_SUCCESS,
  LOCATION_SERVICES_ALL_GET_FAILURE,
  LOCATION_SERVICES_ALL_FILTER,
  LOCATION_SERVICES_ALL_CLEAR,
  LOCATION_SERVICES_ALL_REMOVE,
  LOCATION_SERVICES_ALL_OPEN,
  LOCATION_SERVICES_ALL_CLOSE,
  LOCATION_SERVICES_ALL_CENTER,
  HIGHLIGHT_MARKER_ALL,
  MAP_PINS_TO_FEED_ALL,
  MAP_SUMMARY_GET_REQUEST,
  MAP_SUMMARY_GET_SUCCESS,
  MAP_SUMMARY_GET_FAILURE,
  LOCATION_SERVICES_ALL_TOGGLE_VIEW,
  MARKET_LOCATIONS_ALL_DATA_SUCCESS
} from '../constants'

const initialState = {
  byMarketId: {},
  bySearch: {},
  status: {
    loading: false,
    all: false,
    mapSummary: false
  },
  error: {
    loading: false,
    all: false,
    mapSummary: false
  },
  filter: {
    marketId: null,
    marketName: null,
    search: null,
    neighborhood: [],
    healthStatus: [],
    type: [],
    sizeClass: [],
    landlord: [],
    operator: [],
    isOpen: []
  },
  panel: null,
  highlightedId: null,
  pinIds: [],
  mapSummary: [],
  byPage: {},
  allLocations: [],
  allLanding: [],
  infinite: [],
  infinitePage: -1,
  total: null,
  addressCenter: { lat: null, lng: null },
  activeView: 0,
  marketLocationsDataById: {}
}

export default createReducer(initialState, {
  [MAP_SUMMARY_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      mapSummary: true
    },
    error: {
      ...state.error,
      mapSummary: false
    }
  }),
  [MAP_SUMMARY_GET_SUCCESS]: (state, payload) => ({
    ...state,
    mapSummary: payload,
    status: {
      ...state.status,
      mapSummary: false
    },
    error: {
      ...state.error,
      mapSummary: false
    }
  }),
  [MAP_SUMMARY_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      mapSummary: false
    },
    error: {
      ...state.error,
      mapSummary: true
    }
  }),
  [LOCATION_SERVICES_ALL_GET_REQUEST]: (state, payload) => {
    const byPage = !payload.infinite
      ? {
          byPage: {
            ...state.byPage,
            [payload.page === 0 && payload.initial
              ? 'initial'
              : payload.page]: true
          }
        }
      : {}

    return {
      ...state,
      status: {
        ...state.status,
        loading: !payload.all ? true : state.status.loading,
        all: payload.all ? true : state.status.all
      },
      error: {
        ...state.error,
        loading: false
      },
      infinitePage: payload.page >= 0 && payload.infinite ? payload.page : state.infinitePage,
      ...byPage
    }
  },
  [LOCATION_SERVICES_ALL_GET_SUCCESS]: (state, payload, config) => {
    const byQuery = config.marketId
      ? {
          byMarketId: {
            ...state.byMarketId,
            [config.marketId]: payload.list
          }
        }
      : config.search
      ? {
          bySearch: {
            ...state.bySearch,
            [config.search]: payload.list
          }
        }
      : (config.page === 0 || config.page > 0) && !config.infinite
      ? {
          allLocations:
            config.page === 0 && config.initial
              ? payload.list
              : config.page === 0
              ? payload.list
              : [...state.allLocations, ...payload.list],
          allLanding:
            config.page === 0 && config.initial ? payload.list : state.allLanding,
          total: payload.total
        }
      : config.infinite
      ? {
          infinite: [...state.infinite, ...payload.list]
        }
      : {}

    return {
      ...state,
      ...byQuery,
      status: {
        ...state.status,
        loading: !config.all ? false : state.status.loading,
        all: config.all ? false : state.status.all
      },
      error: {
        ...state.error,
        loading: false
      }
    }
  },
  [LOCATION_SERVICES_ALL_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: false
    },
    error: {
      ...state.error,
      loading: true
    }
  }),
  [LOCATION_SERVICES_ALL_FILTER]: (state, payload, config) => {
    const filter = config
      ? {
          ...state.filter,
          [config]: payload
        }
      : {
          ...state.filter,
          ...payload
        }

    return {
      ...state,
      filter
    }
  },
  [LOCATION_SERVICES_ALL_CLEAR]: state => ({
    ...state,
    filter: initialState.filter
  }),
  [LOCATION_SERVICES_ALL_REMOVE]: (state, payload) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        [payload.key]: state.filter[payload.key].filter(
          item => item.value !== payload.value
        )
      }
    }
  },
  [LOCATION_SERVICES_ALL_OPEN]: (state, payload) => ({
    ...state,
    panel: payload
  }),
  [LOCATION_SERVICES_ALL_CLOSE]: state => ({
    ...state,
    panel: null
  }),
  [HIGHLIGHT_MARKER_ALL]: (state, payload, config) => ({
    ...state,
    highlightedId: payload || null,
    highlightedMarket: config || null
  }),
  [MAP_PINS_TO_FEED_ALL]: (state, payload) => ({
    ...state,
    pinIds: payload
  }),
  [LOCATION_SERVICES_ALL_CENTER]: (state, payload) => ({
    ...state,
    addressCenter: payload
  }),
  [LOCATION_SERVICES_ALL_TOGGLE_VIEW]: (state, payload) => ({
    ...state,
    activeView: payload
  }),
  [MARKET_LOCATIONS_ALL_DATA_SUCCESS]: (state, payload, config) => ({
    ...state,
    marketLocationsDataById: {
      ...state.marketLocationsDataById,
      [config]: payload
    }
  })
})
