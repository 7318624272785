import { MAIN_APP_URL } from '../settings/api'

export const leadInitialValues = {
  company: null,
  contact_channel: null,
  email: null,
  first_name: null,
  job_title: null,
  last_name: null,
  market: null,
  market_id: null,
  move_in_date: null,
  notification_type: null,
  phone_number: null,
  seats: 0,
  shortlist_url: null
}

export const parseShortlistUrl = (url) => {
  return ((url || '').split('/').filter(item => !!item)[4] || '').split(',').filter(item => !!item)
}

export const getSpaceName = (space) => {
  if (space.spaceType !== 'Suite') {
    return (space.spaceType || '')
  }

  return `${space.spaceCapacity || 1} person Suite`
}

export const getProposalSpaceName = (space) => {
  if (space.space_type !== 'Suite') {
    return (space.space_type || '')
  }

  return `${space.space_capacity || 1} person Suite`
}

export const getShortlistUrl = list => {
  const ids = list.map(item => item.id).join(',')

  return ids ? `${MAIN_APP_URL}/coworking/compare/${ids}` : null
}
