import { createReducer } from '../utils/redux'
import {
  LOCATION_GET_REQUEST,
  LOCATION_GET_SUCCESS,
  LOCATION_GET_FAILURE,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_FAILURE,
  LOCATION_CHANGE_STATUS
} from '../constants'

const initialState = {
  byId: {},
  status: {
    error: false,
    loading: false,
    updating: false
  }
}

export default createReducer(initialState, {
  [LOCATION_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: true,
      error: false
    }
  }),
  [LOCATION_GET_SUCCESS]: (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: payload
    },
    status: {
      ...state.status,
      loading: false,
      error: false
    }
  }),
  [LOCATION_GET_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      loading: false,
      error: true
    }
  }),
  [LOCATION_UPDATE_REQUEST]: state => ({
    ...state,
    status: {
      ...state.status,
      updating: true
    }
  }),
  [LOCATION_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: payload
    },
    status: {
      ...state.status,
      updating: false
    }
  }),
  [LOCATION_UPDATE_FAILURE]: state => ({
    ...state,
    status: {
      ...state.status,
      updating: false
    }
  }),
  [LOCATION_CHANGE_STATUS]: (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload]: {
        ...state.byId[payload],
        listingStatus: 'Active'
      }
    }
  })
})
