import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter } from 'connected-react-router'
import Root from './containers/Root'
import configureStore from './store/configureStore'
import { configureAmplify, getAuthCurrentSession } from './actions/auth'
import './index.css'
import { loadScriptFooter } from './utils/script-loader'

const history = createBrowserHistory()
const initialState = {}
const store = configureStore(initialState, history)
configureAmplify()
store.dispatch(getAuthCurrentSession())

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Root />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

if (!process.env.REACT_APP_NO_ANALYTICS) {
  loadScriptFooter('//js.hs-scripts.com/4175778.js', 'hs-script-loader')
}
