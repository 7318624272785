import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Select from 'react-select'
import range from 'lodash/range'
import reduce from 'lodash/reduce'
import Flex from '../../components/flex'
import { useMediaQuery } from 'react-responsive'
import { useFormik } from 'formik'
import * as Styled from '../../components/location'
import Spinner from '../../components/spinner'
import { smOnly } from '../../styles/breakpoints'
import { locationUpdate } from '../../actions/locations'
import TextareaCounter from '../../components/textarea-counter'
import { Plus } from '../../components/icons'

const LocationReviews = () => {
  const dispatch = useDispatch()
  const { locationId } = useParams()
  const [editIndex, setEditIndex] = useState(null)
  const location = useSelector(state => state.location.byId[locationId])
  const {
    status: { loading, error, updating }
  } = useSelector(state => state.location)
  const reviewItem = {
    tenantReviewName: null,
    tenantReviewStarRating: null,
    tenantReviewText: null
  }

  useEffect(() => {
    if (!updating) {
      setEditIndex(null)
    }
  }, [updating])

  const initialValues = range(3)
    .map(item => {
      if (
        !location ||
        (!location[`tenantReviewName${item + 1}`] &&
          !location[`tenantReviewStarRating${item + 1}`] &&
          !location[`tenantReviewText${item + 1}`])
      )
        return null

      return {
        name: location[`tenantReviewName${item + 1}`],
        rating: location[`tenantReviewStarRating${item + 1}`],
        text: location[`tenantReviewText${item + 1}`]
      }
    })
    .filter(item => item)

  const handleSubmit = reviews => {
    const normalize = reduce(
      range(3),
      (result, curr) => {
        return {
          ...result,
          [`tenantReviewName${curr + 1}`]:
            (reviews[curr] || {}).name || null,
          [`tenantReviewStarRating${curr + 1}`]:
            (reviews[curr] || {}).rating || null,
          [`tenantReviewText${curr + 1}`]:
            (reviews[curr] || {}).text || null
        }
      },
      {}
    )
    dispatch(locationUpdate(locationId, normalize))
  }

  const formik = useFormik({
    initialValues: { reviews: initialValues },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => handleSubmit(values.reviews)
  })
  const mobile = useMediaQuery({ query: smOnly })

  const handleResetForm = () => [
    formik.resetForm({ values: { reviews: initialValues } })
  ]

  if (error) {
    return (
      <Flex fullWidth center>
        <Styled.Title>Not found</Styled.Title>
      </Flex>
    )
  }

  if (loading && !error) {
    return (
      <Flex fullWidth center>
        <Spinner elSize={40} />
      </Flex>
    )
  }

  return (
    <Flex fullWidth alignStart>
      <Styled.Wrap>
        <Styled.Note withBtn>
          <p>
            <b>Note:</b> Enter in member reviews of the location below. Hit “Add
            Review” to create a new review.
          </p>
          <Styled.Control
            onClick={() => {
              formik.setFieldValue('reviews', [
                ...formik.values.reviews,
                reviewItem
              ])
              setEditIndex(formik.values.reviews.length)
            }}
            disabled={formik.values.reviews.filter(item => item).length >= 3}
          >
            <Plus />
            <span>Add review</span>
          </Styled.Control>
        </Styled.Note>
        <Styled.Section noPad>
          <Styled.Form onSubmit={formik.handleSubmit}>
            <Flex flexWrap fullWidth>
              <Styled.Row>
                <Styled.Col noMar maxWidth="100%">
                  <Styled.Title>Member/Tenant Reviews</Styled.Title>
                </Styled.Col>
              </Styled.Row>
              <Flex flexWrap fullWidth>
                {formik.values.reviews.map((item, index) => (
                  <Styled.ReviewWrap key={`review-item-${index}`}>
                    {editIndex === index && (
                      <Styled.Footer mobile={mobile} mb={20}>
                        {updating && <Spinner elSize={20} />}
                        <>
                          <Styled.Action
                            disabled={updating}
                            type="button"
                            onClick={() => {
                              const values = formik.values.reviews.filter(
                                (item, removeIndex) => removeIndex !== index
                              )
                              formik.setFieldValue('reviews', values)
                              handleSubmit(values)
                            }}
                          >
                            Remove
                          </Styled.Action>
                          <Styled.Action
                            disabled={updating}
                            type="button"
                            onClick={() => [
                              handleResetForm(),
                              setEditIndex(null)
                            ]}
                          >
                            Cancel
                          </Styled.Action>
                          <Styled.Action disabled={updating} type="submit">
                            Save
                          </Styled.Action>
                        </>
                      </Styled.Footer>
                    )}
                    <Styled.Col maxWidth={!mobile ? '30%' : '100%'}>
                      <Styled.Label htmlFor={`reviews[${index}].text`}>
                        <Styled.LabelText>Review</Styled.LabelText>
                        <TextareaCounter
                          id={`reviews[${index}].text`}
                          name={`reviews[${index}].text`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          required
                          placeholder="_"
                          value={
                            (formik.values.reviews[index] || {}).text || ''
                          }
                          rows={5}
                          disabled={updating || editIndex !== index}
                        />
                      </Styled.Label>
                    </Styled.Col>
                    <Styled.Col maxWidth={!mobile ? '30%' : '100%'}>
                      <Styled.Label>
                        <Styled.LabelText>Star Rating</Styled.LabelText>
                        <Select
                          components={{ Option: Styled.Stars, SingleValue: Styled.Stars }}
                          placeholder="_"
                          styles={Styled.StarsSelect}
                          options={range(5).map(item => ({ value: item + 1, label: item + 1 }))}
                          isSearchable={false}
                          isClearable={false}
                          value={
                            (formik.values.reviews[index] || {}).rating && {
                              label: (formik.values.reviews[index] || {}).rating,
                              value: (formik.values.reviews[index] || {}).rating
                            }
                          }
                          onChange={value => {
                            formik.setFieldValue(
                              `reviews[${index}].rating`,
                              value?.value
                            )
                          }}
                          mobile={mobile}
                          isDisabled={updating || editIndex !== index}
                        />
                      </Styled.Label>
                    </Styled.Col>
                    <Styled.Col maxWidth={!mobile ? '30%' : '100%'}>
                      <Styled.Label htmlFor={`reviews[${index}].name`}>
                        <Styled.LabelText>
                          Reviewer First Name, Last Initial
                        </Styled.LabelText>
                        <Styled.Input
                          type="text"
                          id={`reviews[${index}].name`}
                          name={`reviews[${index}].name`}
                          placeholder="_"
                          value={
                            (formik.values.reviews[index] || {}).name || ''
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={updating || editIndex !== index}
                        />
                      </Styled.Label>
                    </Styled.Col>
                    {editIndex !== index && (
                      <Styled.Col maxWidth={!mobile ? '10%' : '100%'}>
                        <Styled.ReviewEdit onClick={() => setEditIndex(index)}>
                          Edit
                        </Styled.ReviewEdit>
                      </Styled.Col>
                    )}
                  </Styled.ReviewWrap>
                ))}
              </Flex>
            </Flex>
          </Styled.Form>
        </Styled.Section>
      </Styled.Wrap>
    </Flex>
  )
}

export default LocationReviews
