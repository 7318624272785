import { getPctWithZero, roundDecimals } from '../utils/math'
import isFinite from 'lodash/isFinite'
import { MAIN_APP_URL } from '../settings/api'

export const getDemandValues = ({ data, activeQuarter, label = 'Change from' }) => {
  if (!data || !activeQuarter) return
  const currentIndex = data.findIndex(item => item?.quarter === activeQuarter)
  const previousIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1
  const currentQ = data[currentIndex]
  const previosQ = data[previousIndex]
  if (!isFinite(currentQ?.searches) || !isFinite(previosQ?.searches)) return
  const isPositive = currentQ?.searches > previosQ?.searches
  const numValue = getPctWithZero(currentQ?.searches, previosQ?.searches)

  return ({
    text: `${isPositive ? '+' : ''}${roundDecimals(numValue)}% ${label} ${previosQ?.quarter || ''}`,
    isPositive
  })
}

export const getSupplyValues = ({ data, activeQuarter }) => {
  if (!data || !activeQuarter) return
  const currentIndex = data.findIndex(item => item?.quarter === activeQuarter)
  const previousIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1
  const currentQ = data[currentIndex]
  const previosQ = data[previousIndex]
  if (!isFinite(currentQ?.locations_new) || !isFinite(previosQ?.locations_new)) return
  // const newLoc = currentQ?.locations_new - previosQ?.locations_new
  // const closedLoc = currentQ?.locations_closed - previosQ?.locations_closed
  const newLoc = currentQ?.locations_new
  const closedLoc = currentQ?.locations_closed
  const isPositive = newLoc > closedLoc

  return ({
    newLoc,
    closedLoc,
    isPositive
  })
}

export const getPriceValues = ({ data, activeQuarter, label = 'Change from' }) => {
  if (!data || !activeQuarter) return
  const currentIndex = data.findIndex(item => item?.quarter === activeQuarter)
  const previousIndex = currentIndex === data.length - 1 ? 0 : currentIndex + 1
  const currentQ = data[currentIndex]
  const previosQ = data[previousIndex]
  if (!isFinite(currentQ?.average_seat_price) || !isFinite(previosQ?.average_seat_price)) return
  const isPositive = currentQ?.average_seat_price > previosQ?.average_seat_price
  const numValue = getPctWithZero(currentQ?.average_seat_price, previosQ?.average_seat_price)

  return ({
    text: `${isPositive ? '+' : ''}${roundDecimals(numValue)}% ${label} ${previosQ?.quarter || ''}`,
    isPositive
  })
}

export const getDemandString = (data, marketName) => {
  if (!data) return
  const current = data[data.length - 1]
  const previous = data[data.length - 2]

  if (!isFinite(current?.searches) || !isFinite(previous?.searches)) return
  const isPositive = current?.searches > previous?.searches
  const numValue = getPctWithZero(current?.searches, previous?.searches)

  return {
    value: `${isPositive ? '+' : ''}${roundDecimals(numValue)}%`,
    text: `change versus ${previous?.label || ''} in ${marketName || ''}`,
    percent: current?.percent ? Math.round(current.percent) : 0
  }
}

export const getNewLoc = (data, activeQuarter) => {
  const currentIndex = data.findIndex(item => item?.quarter === activeQuarter)

  return {
    newLoc: (data[currentIndex] || {}).locations_new_list,
    openLoc: (data[currentIndex] || {}).locations_open_list,
    closedLoc: (data[currentIndex] || {}).locations_closed_list
  }
}

export const getLocationAppLink = (item) => {
  const officeType = (item?.office_type || []).includes('Coworking') ? 'coworking' : 'workspace'
  const marketSlug = item?.market_slug || ''
  const locSlug = item?.marketplace_slug || ''

  return `${MAIN_APP_URL}/${officeType}/${marketSlug}/${locSlug}/`
}
