import styled from 'styled-components/macro';

export const FlexStyled = styled.div`
  display: flex;

  ${props => props.fullWidth && 'width: 100%;'}
  ${props => props.fullScreenHeight && 'height: 100vh;'}
  ${props => props.fullHeight && 'height: 100%;'}
  ${props => props.shrink && 'flex-shrink: 0;'}
  ${props => props.alignStart && 'align-items: flex-start;'}
  ${props => props.middle && 'align-items: center;'}
  ${props => props.center && 'justify-content: center;'}
  ${props => props.flexEnd && 'justify-content: flex-end;'}
  ${props => props.column && 'flex-direction: column;'}
  ${props => props.spaceBetween && 'justify-content: space-between;'}
  ${props => props.full && 'flex: 1;'}
  ${props => props.flexWrap && 'flex-wrap: wrap;'}
  ${props => props.spaceAround && 'justify-content: space-around;'}

  &[data-relative="true"] {
    position: relative;
  }

  &[data-suspended="true"] {
    height: calc(100vh - 64px);
    overflow: hidden;
  }

  &[data-status="Suspended"] {
    filter: blur(5px);
    user-select: none;
    pointer-events: none;
  }
`;
