import React from 'react';
import styled from 'styled-components/macro';
import Logo from '../upsuite-logo';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`;

const Text = styled.span`
  font-size: 18px;
  margin-right: 15px;
`;

const PoweredBy = () => {
  return (
    <Container>
      <Text>Powered by</Text>
      <Logo url="https://www.upsuite.com" />
    </Container>
  );
};

export default PoweredBy;
