import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import round from 'lodash/round'
import { imageUrl } from '../../helpers/location-services'
import { ImgArrow } from '../../components/icons'
import { VirtualTourButton } from '../../components/virtual-tour-button'
import { openImagePreviewModal } from '../../actions/modal'
import { leadsDate } from '../../helpers/leads'
import { openInteractiveTourModal } from '../../actions/modal'
import { highlightMarkerAll } from '../../actions/location-services-all'

const getDistance = (distance, country) => {
  if (!distance) return ''

  if (country !== 'United States') {
    return `${round(distance / 1000, 1)} km`
  }

  return `${round(distance / 1.609344 / 1000, 1)} mi`
}

const LocationCard = ({ location }) => {
  const dispatch = useDispatch();
  const images = [
    location?.lcimgId,
    location?.IImg1Id,
    location?.IImg2Id,
    location?.IImg3Id,
    location?.img6_id,
    location?.img7_id,
    location?.img8_id,
    location?.img9_id,
    location?.img10_id
  ].filter(item => !!item)
  const [imgIndex, setImgIndex] = useState(0)
  const address = [
    location?.adr_street,
    location?.adr_city,
    location?.adr_state,
    location?.adr_pc,
    location?.adr_country
  ]
    .filter(Boolean)
    .join(', ')
  const leadName = [
    location?.lead_reg_name,
    location?.lead_reg_last_name
  ]
    .filter(Boolean)
    .join(' ')

  if (!location) return null;

  const distance = getDistance(location.distance, location.adr_country)

  return (
    <Wrap
      onMouseEnter={() =>
        dispatch(highlightMarkerAll(location.id, location.market_id))
      }
      onMouseLeave={() => dispatch(highlightMarkerAll())}
    >
      {images.length > 0 && (
        <ImgWrap>
          {images.length > 1 && (
            <BtnArrow
              data-prev={true}
              onClick={() => {
                const newIndex =
                  imgIndex === 0 ? images.length - 1 : imgIndex - 1

                setImgIndex(newIndex)
              }}
            >
              <ImgArrow />
            </BtnArrow>
          )}
          {location.v_tour && (
            <VirtualTourButton
              onClick={() =>
                dispatch(
                  openInteractiveTourModal({
                    url: location.v_tour
                  })
                )
              }
            />
          )}
          {images.map((imgId, index) => (
            <img
              key={`${imgId}-${location.id}-${index}`}
              loading="lazy"
              src={imageUrl(imgId)}
              alt=""
              data-visible={index === imgIndex}
              onClick={() =>
                dispatch(
                  openImagePreviewModal({ index: imgIndex, ids: images })
                )
              }
            />
          ))}
          {images.length > 1 && (
            <BtnArrow
              data-next={true}
              onClick={() => {
                const newIndex =
                  imgIndex === images.length - 1 ? 0 : imgIndex + 1

                setImgIndex(newIndex)
              }}
            >
              <ImgArrow />
            </BtnArrow>
          )}
        </ImgWrap>
      )}
      <TextWrap>
        <TextRow>
          <TextCol data-size="1/2">
            {location.name && <p data-bold="true">{location.name}</p>}
            {address && <p>{address}</p>}
          </TextCol>
          <TextCol data-size="1/2">
            {location.market_name && (
              <p data-align="right">{location.market_name}</p>
            )}
            {location.nbh_name && <p data-align="right">{location.nbh_name}</p>}
          </TextCol>
        </TextRow>
        <TextRow>
          <TextCol data-size="1/3">
            {location.type && (
              <p>
                <span data-bold="true">Type: </span>
                <span>{location.type}</span>
              </p>
            )}
            {location.health_status && (
              <p>
                <span data-bold="true">Status: </span>
                <span>{location.health_status}</span>
              </p>
            )}
            {location.size_class && (
              <p>
                <span data-bold="true">Size Class: </span>
                {location.size_class === 'Over 15000' ? (
                  <span>Over 15,000</span>
                ) : location.size_class === '5000 - 15000' ||
                  location.size_class === '5000 to 15000' ? (
                  <span>5,000 - 15,000</span>
                ) : location.size_class === 'Under 5000' ? (
                  <span>Under 5,000</span>
                ) : (
                  <span></span>
                )}
              </p>
            )}
            {location.last_months_price_per_seat_suites1_to9 && (
              <p>
                <span data-bold="true">Average price per desk: </span>
                <span>
                  $
                  {Math.round(
                    parseFloat(location.last_months_price_per_seat_suites1_to9)
                  ).toLocaleString()}
                </span>
              </p>
            )}
            {location.potential_yearly_revenue_per_SF && (
              <p>
                <span data-bold="true">Potential Yearly Revenue Per SF: </span>
                <span>
                  $
                  {Math.round(
                    parseFloat(location.potential_yearly_revenue_per_SF)
                  ).toLocaleString()}
                </span>
              </p>
            )}
          </TextCol>
          <TextCol data-size="1/3">
            <p>
              <span data-bold="true">Location Scores:</span>
            </p>
            {location.ls_total && (
              <p>
                <span data-bold="true">Total: </span>
                <span>{location.ls_total}</span>
              </p>
            )}
            {location.ls_overall_dmd && (
              <p>
                <span data-bold="true">Demand Overall: </span>
                <span>{location.ls_overall_dmd}</span>
              </p>
            )}
            {location.ls_prc_diff && (
              <p>
                <span data-bold="true">Pricing Differential: </span>
                <span>{location.ls_prc_diff}</span>
              </p>
            )}
            {location.ls_dem_suitability && (
              <p>
                <span data-bold="true">Demographic Suitability: </span>
                <span>{location.ls_dem_suitability}</span>
              </p>
            )}
            {location.ls_dmd_lead && (
              <p>
                <span data-bold="true"># of Leads: </span>
                <span>{location.ls_dmd_lead}</span>
              </p>
            )}
          </TextCol>
          <TextCol data-size="1/3">
            {leadName && <p data-bold="true">{leadName}</p>}
            {location.lead_reg_email && location.lead_reg_email !== 'none' && (
              <p data-word-break="true">{location.lead_reg_email}</p>
            )}
          </TextCol>
        </TextRow>
        {location.mod_at && (
          <TextRow>
            <TextCol data-size="1/2">
              <p>
                <span data-bold="true">Last Updated: </span>
                <span>{leadsDate(location.mod_at)}</span>
              </p>
            </TextCol>
            <TextCol data-size="1/2">
              <p data-align="right">
                <span>{distance}</span>
              </p>
            </TextCol>
          </TextRow>
        )}
      </TextWrap>
    </Wrap>
  )
}

const Wrap = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #ced0da;
  position: relative;
  transition: border 0.3s ease, box-shadow 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    top: -2px;
    bottom: -2px;
    left: -2px;
    right: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border: 2px solid transparent;
    z-index: 0;
    transition: border 0.3s ease;
  }

  &:hover {
    border-color: #58aa83;
    box-shadow: 0 0 7px hsl(0deg 0% 72% / 60%);

    &::after {
      border-color: #58aa83;
    }
  }
`

const BtnArrow = styled.button.attrs({ type: 'button' })`
  position: absolute;
  z-index: 2;
  top: calc(50% - 11px);
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 15px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 3px 0;

  &[data-prev='true'] {
    left: 10px;
    transform: rotate(180deg);
  }

  &[data-next='true'] {
    right: 10px;
  }

  svg {
    color: #fff;
    vertical-align: middle;
  }
`

const ImgWrap = styled.div`
  height: 220px;
  width: 100%;
  position: relative;

  img {
    object-fit: cover;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.3s ease;

    &:not([data-visible='true']) {
      z-index: 0;
      opacity: 0;
    }

    &[data-visible='true'] {
      z-index: 1;
      opacity: 1;
    }
  }

  &:hover {
    ${BtnArrow} {
      opacity: 0.9;
    }
  }
`

const TextWrap = styled.div`
  padding: 12px 11px;
  background-color: #fff;

  p {
    margin-top: 0;
    font-size: 14px;
  }

  & [data-bold='true'] {
    font-weight: 600;
  }

  & [data-align='right'] {
    text-align: right;
  }

  & [data-word-break='true'] {
    word-break: break-word;
  }
`

const TextRow = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`

const TextCol = styled.div`
  padding-left: 2px;
  padding-right: 2px;

  &[data-size='1/2'] {
    width: 50%;
    flex: 0 0 50%;
  }

  &[data-size='1/3'] {
    width: 33.33%;
    flex: 0 0 33.33%;
  }

  p:last-child {
    margin-bottom: 0;
  }

  p:not(:last-child) {
    margin-bottom: 3px;
  }
`

export default LocationCard
