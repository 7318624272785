import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import filter from 'lodash/filter'
import mapValues from 'lodash/mapValues'
import { mdUp, smOnly } from '../../styles/breakpoints'
import MarketScore from '../../components/market-score'
import MarketShare from '../../components/market-share'
import MarketDemandTrends from '../../components/market-demand-trends'
import MarketAskingPrices from '../../components/market-asking-prices'
import MarketDemandShare from '../../components/market-demand-share'
import Spinner from '../../components/spinner'
import Flex from '../../components/flex'
import {
  getOperatorData,
  getTypeData,
  getSizeData,
  getPriceByIndex,
  getTitle,
  getDemandShareData
} from '../../helpers/market-data'
import { marketSummaryGet } from '../../actions/location-services'
import { marketLocationsDataGet } from '../../actions/location-services-all'

const MarketScoreContainer = ({ locations }) => {
  const query = useSelector(state => state.locationServicesAll.filter)
  const pinIds = useSelector(state => state.locationServicesAll.pinIds)
  const dataTotal = locations.reduce((acc, item) => {
    return {
      ovarall: acc.ovarall + (parseInt(item.ls_total, 10) || 0),
      demandOverall: acc.demandOverall + (parseInt(item.ls_overall_dmd, 10) || 0),
      demandViews: acc.demandViews + (parseInt(item.ls_dmd_views, 10) || 0),
      leads: acc.leads + (parseInt(item.ls_dmd_lead, 10) || 0),
      seats: acc.seats + (parseInt(item.ls_dmd_lead_seats_avg, 10) || 0),
      competition: acc.competition + (parseInt(item.ls_comp, 10) || 0),
      pricingDiff: acc.pricingDiff + (parseInt(item.ls_prc_diff, 10) || 0),
      suitability: acc.suitability + (parseInt(item.ls_dem_suitability, 10) || 0)
    }
  }, {
    ovarall: 0,
    demandOverall: 0,
    demandViews: 0,
    leads: 0,
    seats: 0,
    competition: 0,
    pricingDiff: 0,
    suitability: 0
  })
  const data = mapValues(dataTotal, (item) => Math.round(item / (locations.length || 1)))

  return (
    <MarketScore
      title={getTitle({ query, pinIds })}
      data={data}
      showSuitability={locations[0]?.adr_country === 'United States'}
    />
  )
}

const MarketShareContainer = ({ locations }) => {
  const data = useMemo(() => {
    const operatorData = getOperatorData(
      locations,
      'operator_total_seats',
      'total_square_footage',
      'op_name'
    )
    const typeData = getTypeData(
      locations,
      'operator_total_seats',
      'total_square_footage'
    )
    const sizeData = getSizeData(
      locations,
      'operator_total_seats',
      'total_square_footage'
    )

    return {
      '0-0': {
        name: 'Operator Locations',
        data: operatorData.locations
      },
      '0-1': {
        name: 'Operator Seats',
        data: operatorData.seats
      },
      '0-2': {
        name: 'Operator Sq/Ft',
        data: operatorData.sqft
      },
      '1-0': {
        name: 'Type Locations',
        data: typeData.locations
      },
      '1-1': {
        name: 'Type Seats',
        data: typeData.seats
      },
      '1-2': {
        name: 'Type Sq/Ft',
        data: typeData.sqft
      },
      '2-0': {
        name: 'Size Locations',
        data: sizeData.locations
      },
      '2-1': {
        name: 'Size Seats',
        data: sizeData.seats
      },
      '2-2': {
        name: 'Size Sq/Ft',
        data: sizeData.sqft
      }
    }
  }, [locations])

  return <MarketShare marketData={data} />
}

const MarketPricesContainer = ({ locations, marketSummary }) => {
  const selectedType = 'Market'
  const data = useMemo(() => {
    const typeOptions = [
      'Floating Desk',
      'Dedicated Desk',
      'Private Office',
      '2 person Suite',
      '3 person Suite',
      '4 person Suite',
      '5 person Suite',
      '6 person Suite',
      '10+ person Suite'
    ]

    return typeOptions.reduce((acc, item, index) => {
      return {
        ...acc,
        [`${item} Starting Price`]: getPriceByIndex(
          locations,
          'starting_prices',
          index
        ),
        [`${item} Average Price`]: getPriceByIndex(
          locations,
          'average_prices',
          index
        )
      }
    }, {})
  }, [locations])

  const marketData = {
    'Starting Price': marketSummary?.starting_prices || [],
    'Average Price': marketSummary?.average_prices || []
  }

  return (
    <MarketAskingPrices
      selectedType={selectedType}
      data={data}
      locationsNumber={locations?.length}
      marketData={marketData}
    />
  )
}

const MarketDemandTrendsContainer = ({ locations, marketSummary }) => {
  const query = useSelector(state => state.locationServicesAll.filter)
  const pinIds = useSelector(state => state.locationServicesAll.pinIds)

  const data = {
    0: locations.reduce((acc, item) => {
      return (item.viewsInSearch || []).map(
        (mapItem, index) => (mapItem || 0) + (acc[index] || 0)
      )
    }, []).map((item, index) => {
      const numberOfLocNoZero = locations.filter(location => location.viewsInSearch[index]).length

      return item / numberOfLocNoZero
    }),
    1: locations.reduce((acc, item) => {
      return (item.numberOfLeads || []).map(
        (mapItem, index) => (mapItem || 0) + (acc[index] || 0)
      )
    }, []),
    2: locations.reduce((acc, item) => {
      return (item.numberOfSeats || []).map(
        (mapItem, index) => (mapItem || 0) + (acc[index] || 0)
      )
    }, [])
  }

  const marketData = {
    0: marketSummary?.viewsinSearch || [],
    1: marketSummary?.numberOfLeads || [],
    2: marketSummary?.totalSeats || []
  }

  return (
    <MarketDemandTrends
      marketData={marketData}
      data={data}
      type={getTitle({ query, pinIds })}
    />
  )
}

const MarketDemandShareContainer = ({ locations }) => {
  const data = getDemandShareData(locations, 'operator_name')

  return <MarketDemandShare data={data} />
}

const MarketData = ({ locations }) => {
  const dispatch = useDispatch()
  const activeView = useSelector(state => state.locationServicesAll.activeView)
  const loading = useSelector(state => state.locationServicesAll.status.loading)
  const pinIds = useSelector(state => state.locationServicesAll.pinIds)
  const marketId = (locations || [])[0]?.market_id
  const locationsWithData = useSelector(state => state.locationServicesAll.marketLocationsDataById[marketId])
  const marketSummary = useSelector(
    state => state.locationServices.marketSummaryById[marketId] || {}
  )
  const filtered = useMemo(
    () =>
      filter(
        locations,
        item => pinIds.length === 0 || pinIds.some(id => item.id === id)
      ),
    [locations, pinIds]
  )
  const locationsWithDataFiltered = useMemo(
    () =>
      filter(
        locationsWithData,
        item => (pinIds.length === 0 || pinIds.some(id => item.locationId === id)) && locations.some(loc => loc.id === item.locationId)
      ),
    [locationsWithData, locations, pinIds]
  )

  useEffect(() => {
    if (marketId) {
      dispatch(marketSummaryGet(marketId))
      dispatch(marketLocationsDataGet(marketId))
    }
  }, [dispatch, marketId])

  return (
    <Wrap data-visible={!!activeView}>
      {locations.length > 0 && !loading && (
        <>
          <MarketScoreContainer locations={filtered} />
          <MarketPricesContainer locations={filtered} marketSummary={marketSummary} />
          <MarketShareContainer locations={filtered} />
          <MarketDemandTrendsContainer locations={locationsWithDataFiltered} marketSummary={marketSummary} />
          <MarketDemandShareContainer locations={locationsWithDataFiltered} marketSummary={marketSummary} />
        </>
      )}
      {!locations.length && !loading && <NoData>No Data</NoData>}
      {loading && (
        <SpinnerWrap>
          <Flex fullWidth fullHeight center>
            <Spinner elSize={40} />
          </Flex>
        </SpinnerWrap>
      )}
    </Wrap>
  )
}

const SpinnerWrap = styled.div`
  margin-top: 30px;
`

const NoData = styled.span`
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
`

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #f8f8f8;

  @media ${mdUp} {
    height: calc(100% - 54px);
    top: 44px;
    overflow: hidden;
    position: absolute;
    z-index: 4;
    margin-top: 10px;
  }

  &[data-visible='false'] {
    @media ${smOnly} {
      display: none;
    }

    @media ${mdUp} {
      visibility: hidden;
      z-index: -1;
      transform: translate3d(0, -100%, 0);
    }
  }

  &[data-visible='true'] {
    @media ${smOnly} {
      display: block;
    }

    @media ${mdUp} {
      visibility: visible;
      transform: translate3d(0, 0, 0);
      transition: transform 0.3s ease-in;
    }

    overflow: auto;
  }
`

export default MarketData
