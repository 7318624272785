import React from 'react'
import styled from 'styled-components/macro'

export const getCopyrightText = () => {
  const year = new Date().getFullYear()

  return `Copyright, ${year} Upsuite Corp.`
}

const Copyright = ({ center, right, mb }) => {
  return (
    <Wrap data-center={center} data-right={right} data-mb={mb}>
      <span>{getCopyrightText()}</span>
    </Wrap>
  )
}

const Wrap = styled.div`
  color: #333333;
  font-size: 14px;

  &[data-center='true'] {
    text-align: center;
  }

  &[data-right='true'] {
    text-align: right;
  }

  &[data-mb='true'] {
    margin-bottom: 1.5rem;
  }
`

export default Copyright
