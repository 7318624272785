import { createReducer } from '../utils/redux'
import {
  AUTHCHECK_REQUEST,
  AUTHCHECK_SUCCESS,
  AUTHCHECK_FAILURE,
  PROFILE_PIC_GET_REQUEST,
  PROFILE_PIC_GET_SUCCESS,
  PROFILE_PIC_GET_FAILURE,
  PROFILE_PIC_UPDATE_SUCCESS,
  LOGOUT_SUCCESS
} from '../constants'
import { getSubscription } from '../fixtures/subscriptions'

const initialState = {
  firstName: null,
  lastName: null,
  isAdmin: false,
  isAuthenticated: false,
  isOperator: false,
  isSuperuser: false,
  flexOfficePro: false,
  showLeads: false,
  pic: null,
  status: {
    error: false,
    errorText: null,
    loading: false,
    completed: false
  }
}

export default createReducer(initialState, {
  [AUTHCHECK_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true
    }
  }),
  [AUTHCHECK_SUCCESS]: (state, payload) => ({
    ...state,
    ...payload,
    flexOfficePro: getSubscription(payload.flexOfficePro),
    // flexOfficePro: getSubscription(4),
    status: {
      ...initialState.status,
      loading: false,
      completed: true
    }
  }),
  [AUTHCHECK_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      completed: true,
      error: true,
      errorText: payload
    }
  }),
  [PROFILE_PIC_GET_REQUEST]: state => ({
    ...state,
    pic: null
  }),
  [PROFILE_PIC_GET_SUCCESS]: (state, payload) => ({
    ...state,
    pic: payload
  }),
  [PROFILE_PIC_GET_FAILURE]: state => ({
    ...state,
    pic: null
  }),
  [PROFILE_PIC_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    pic: payload
  }),
  [LOGOUT_SUCCESS]: state => ({
    ...state,
    ...initialState
  })
})
