import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/macro'
import Tooltip from '../../components/tooltip-actions'
import { leadEditShow, showLeadShortlist, showLeadProposals } from '../../actions/broker-tools'

const LeadActions = ({ lead }) => {
  const dispatch = useDispatch()

  return (
    <Wrap>
      <Tooltip
        placement="bottomLeft"
        overlay={
          <List>
            <SecondaryBtn onClick={() => dispatch(showLeadShortlist(lead))}>Create Shortlist</SecondaryBtn>
            <SecondaryBtn onClick={() => dispatch(showLeadProposals(lead))}>Proposals</SecondaryBtn>
            <SecondaryBtn onClick={() => dispatch(leadEditShow(lead))}>Edit lead</SecondaryBtn>
          </List>
        }
      >
        <ToggleButton>
          <span>Actions</span>
          <ArrowDown />
        </ToggleButton>
      </Tooltip>
    </Wrap>
  )
}

const Wrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
`

const SecondaryBtn = styled.button.attrs({ type: 'button' })`
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  white-space: nowrap;
  text-align: left;
  width: 126px;
`

const ToggleButton = styled.button.attrs({ type: 'button' })`
  border: 2px solid #e7483d;
  cursor: pointer;
  padding: 5px 10px;
  background-color: #e7483d;
  font-weight: 600;
  color: white;
  border-radius: 4px;
  white-space: nowrap;
  width: 126px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ArrowDown = styled.div`
  width: 0px;
  height: 0px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
  margin-bottom: 1px;
`

export default LeadActions
