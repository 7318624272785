import React from 'react';
import styled from 'styled-components/macro';
import { Calendar } from '../icons';
import { mdUp, smOnly } from '../../styles/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;

  @media ${smOnly} {
    flex-wrap: wrap;
  }
`;

export const Input = styled.input`
  border: 1px solid transparent;
  padding: 8px;
  height: 36px;
  color: #333;
  max-width: 100%;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  box-sizing: border-box;
  margin-bottom: 5px;
  border: 1px solid #d5d5d5;
`;

export const DateLabel = styled.label`
  display: flex;
  width: 137px;
  max-width: 100%;
  min-width: 137px;
  position: relative;
  margin-right: 10px;

  .form-control.input {
    width: 100%;
    padding: 8px;
    height: 36px;
    border: none;
    padding-right: 42px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Submit = styled.button`
  cursor: pointer;
  font-size: 14px;
  line-height: 1.1;
  margin-bottom: 10px;
  background-color: #57ab83;
  color: #fff;
  padding: 10px 20px;
  margin-left: auto;
  border: none;
`;

export const Error = styled.span`
  display: block;
  font-size: 14px;
  color: #e6473d;
  margin-top: 5px;
`;

export const DateIconWrap = styled.button`
  position: absolute;
  top: 0;
  right: 6px;
  top: 2px;
  border: none;
  padding: 0;
  background-color: transparent;

  svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    fill: #636364;
  }
`;

export const DateIcon = ({ onClick }) => (
  <DateIconWrap type="button" onClick={onClick}>
    <Calendar />
  </DateIconWrap>
);

export const Label = styled.label`
  display: inline-block;
  width: 100%;
  position: relative;

  @media ${mdUp} {
    margin-right: 10px;
  }
`;
