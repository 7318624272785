import React, { useEffect } from 'react'
import styled from 'styled-components'
import { push } from 'connected-react-router'
import { useDispatch, useSelector } from 'react-redux'
import Flex from '../../components/flex'
import { FlipBox } from '../../components/flip-box'
import {
  Col,
  Container,
  Text,
  FreeAccountHeading
} from '../../components/landing'
import { MatrixCard, Item as ListItem } from '../../components/matrix-card'
import {
  Table,
  Pipeline,
  MapLocation
} from '../../components/icons'
import { PageTitle } from '../../utils'
import {
  SPACES_ROUTE,
  marketReports,
  marketSummaries,
  locationServices
} from '../../settings/routes'
import Breadcrumbs from '../../containers/Breadcrumbs'
import { openIframeModal } from '../../actions/modal'
import { marketsAllGet } from '../../actions/markets'
import WidgetPerformance from '../../components/widget-performance'
import WidgetOpportunities from '../../components/widget-opportunities'
import WidgetLeads from '../../components/widget-leads'
import WidgetMarketInsights from '../../components/widget-market-insights'
import WidgetMonthlyLeads from '../../components/widget-monthly-leads'
import WidgetNewLeads from '../../components/widget-new-leads'
import { openSuspendedModal, hideModal } from '../../actions/modal'

const Dashboard = props => {
  const dispatch = useDispatch()
  const { locationId, marketId } = props.match.params
  const { flexOfficePro, subscriptions } = useSelector(state => state.user)
  const {
    status: { completed },
    fulllist,
    all: {
      list,
      status: { completed: allCompleted }
    }
  } = useSelector(state => state.markets)
  const { byId: locationsById } = useSelector(state => state.locations)
  const location = locationsById[locationId]
  const isSuspended = location?.listingStatus === 'Suspended'
  const routeParams = locationId ? `${marketId}/${locationId}` : `${marketId}/`
  const isFreeAccess = flexOfficePro === 'Free'
  const isReportsAccess = ['Investor', 'Advisor'].includes(flexOfficePro)

  useEffect(() => {
    if (isReportsAccess) {
      dispatch(marketsAllGet())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportsAccess])

  useEffect(() => {
    if (isSuspended) {
      dispatch(openSuspendedModal({ marketId, locationId }))
    }

    return () => dispatch(hideModal())
  }, [dispatch, isSuspended, marketId, locationId])

  return (
    <Flex fullWidth fullHeight middle column>
      <PageTitle title="Flex Office Pro - Dashboard" />
      <Flex fullWidth column full>
        <Flex fullWidth spaceBetween flexWrap>
          <Breadcrumbs />
        </Flex>
        <Flex fullWidth center full flexWrap>
          <Container
            data-widgets={!isFreeAccess && !(flexOfficePro === 'Investor')}
            data-status={location?.listingStatus || 'unknown'}
          >
            {isFreeAccess && (
              <FreeAccountHeading>
                You’re on the Free version of Flex Office Pro. <br />
                Upgrade to learn about your leads, market, and competition
                today.
              </FreeAccountHeading>
            )}
            {isFreeAccess && !isReportsAccess && (
              <Col>
                <FlipBox
                  title="Location and Spaces"
                  onClick={() =>
                    dispatch(push(`/${SPACES_ROUTE}/${routeParams}`))
                  }
                  // locked
                  icon={Table}
                  level={flexOfficePro}
                >
                  <Text>
                    Put your availability in front of interested teams
                  </Text>
                </FlipBox>
              </Col>
            )}
            {flexOfficePro === 'Investor' && (
              <>
                {(subscriptions || []).includes('MarketReports') && (
                  <Col>
                    <FlipBox
                      title="Market Reports"
                      onClick={() =>
                        dispatch(
                          push(
                            `/${marketReports.main}/${marketReports.inventory}/${routeParams}`
                          )
                        )
                      }
                      icon={Pipeline}
                    >
                      <Text>
                        Market data, trends and forecasts to win in the Flexible
                        Office Market
                      </Text>
                    </FlipBox>
                  </Col>
                )}
                {(subscriptions || []).includes('MarketSummaries') && (
                  <Col>
                    <FlipBox
                      title="Market Summaries"
                      onClick={() =>
                        dispatch(
                          push(
                            `/${marketSummaries.main}/${marketSummaries.trends}/${routeParams}`
                          )
                        )
                      }
                      icon={Pipeline}
                    >
                      <Text>Market Summaries</Text>
                    </FlipBox>
                  </Col>
                )}
                {(subscriptions || []).includes('LocationServices') && (
                  <Col>
                    <FlipBox
                      title="Location Services"
                      onClick={() =>
                        dispatch(
                          push(
                            `/${locationServices.main}/${locationServices.list}`
                          )
                        )
                      }
                      icon={MapLocationIcon}
                    >
                      <Text>Location Services</Text>
                    </FlipBox>
                  </Col>
                )}
                {completed && fulllist && (
                  <Col>
                    <MatrixCard
                      heading="All Markets Benchmarks"
                      buttonTitle="Schedule a Consultation"
                      onClick={() => {
                        dispatch(
                          openIframeModal({
                            url: 'https://meetings.hubspot.com/benw4',
                            title: 'Schedule a Consultation',
                            width: '900px',
                            height: '800px'
                          })
                        )
                      }}
                    >
                      <ListItem active>Inventory & Vacancy</ListItem>
                      <ListItem active>Asking vs Transacted Price</ListItem>
                      <ListItem active>Supply/Demand Monitor</ListItem>
                      <ListItem active>Supply Dynamics</ListItem>
                      <ListItem active>Demand Dynamics</ListItem>
                      <ListItem active>Forecast</ListItem>
                      <ListItem active>National Average Benchmarks</ListItem>
                      <ListItem active>All Markets Reporting</ListItem>
                    </MatrixCard>
                  </Col>
                )}
                {completed && allCompleted && !fulllist && (
                  <Col>
                    <MatrixCard
                      heading="List of available markets"
                      buttonTitle="Schedule a Consultation"
                      onClick={() => {
                        dispatch(
                          openIframeModal({
                            url: 'https://meetings.hubspot.com/benw4',
                            title: 'Schedule a Consultation',
                            width: '900px',
                            height: '800px'
                          })
                        )
                      }}
                      scrollable
                    >
                      {(list || []).map(market => (
                        <ListItem key={market?.name} active>
                          {market?.name}
                        </ListItem>
                      ))}
                    </MatrixCard>
                  </Col>
                )}
              </>
            )}
            {isFreeAccess && !isReportsAccess && (
              <>
                <Col>
                  <MatrixCard
                    heading="Flex Office Pro Basic"
                    subheading="Best For Single Locations"
                    buttonTitle="Schedule a Consultation"
                    onClick={() => {
                      dispatch(
                        openIframeModal({
                          url: 'https://meetings.hubspot.com/benw4',
                          title: 'Schedule a Consultation',
                          width: '900px',
                          height: '800px'
                        })
                      )
                    }}
                  >
                    <ListItem active>Listings</ListItem>
                    <ListItem active>Leads list</ListItem>
                    <ListItem>API for leads and listings</ListItem>
                    <ListItem>Additional lead data</ListItem>
                    <ListItem>Status updates</ListItem>
                    <ListItem>Competitive pricing</ListItem>
                    <ListItem>Full market data</ListItem>
                    <ListItem>Benchmarking</ListItem>
                  </MatrixCard>
                </Col>
                <Col>
                  <MatrixCard
                    heading="Flex Office Pro Advanced"
                    subheading="Best For Sales People"
                    buttonTitle="Schedule a Consultation"
                    onClick={() => {
                      dispatch(
                        openIframeModal({
                          url: 'https://meetings.hubspot.com/benw4',
                          title: 'Schedule a Consultation',
                          width: '900px',
                          height: '800px'
                        })
                      )
                    }}
                  >
                    <ListItem active>Listings</ListItem>
                    <ListItem active>Leads list</ListItem>
                    <ListItem active>API for leads and listings</ListItem>
                    <ListItem active>Additional lead data</ListItem>
                    <ListItem active>Status updates</ListItem>
                    <ListItem active>Competitive pricing</ListItem>
                    <ListItem>Full market data</ListItem>
                    <ListItem>Benchmarking</ListItem>
                  </MatrixCard>
                </Col>
                <Col>
                  <MatrixCard
                    heading="Flex Office Pro Enterprise"
                    subheading="Best For Leadership"
                    buttonTitle="Schedule a Consultation"
                    onClick={() => {
                      dispatch(
                        openIframeModal({
                          url: 'https://meetings.hubspot.com/benw4',
                          title: 'Schedule a Consultation',
                          width: '900px',
                          height: '800px'
                        })
                      )
                    }}
                  >
                    <ListItem active>Listings</ListItem>
                    <ListItem active>Leads list</ListItem>
                    <ListItem active>API for leads and listings</ListItem>
                    <ListItem active>Additional lead data</ListItem>
                    <ListItem active>Status updates</ListItem>
                    <ListItem active>Competitive pricing</ListItem>
                    <ListItem active>Full market data</ListItem>
                    <ListItem active>Benchmarking</ListItem>
                  </MatrixCard>
                </Col>
              </>
            )}
            {!isFreeAccess && !isReportsAccess && locationId && (
              <>
                <Col data-width="35">
                  <WidgetOpportunities id={locationId} />
                </Col>
                <Col data-width="35">
                  <WidgetPerformance id={locationId} />
                </Col>
                <Col data-width="30">
                  <WidgetLeads id={locationId} marketId={marketId} />
                </Col>
              </>
            )}
            {flexOfficePro === 'Advisor' && (
              <>
                <Col data-width="40">
                  <WidgetMarketInsights />
                </Col>
                {subscriptions?.includes('Leads') && (
                  <>
                    <Col data-width="20">
                      <WidgetMonthlyLeads />
                    </Col>
                    <Col data-width="40">
                      <WidgetNewLeads />
                    </Col>
                  </>
                )}
              </>
            )}
          </Container>
        </Flex>
      </Flex>
    </Flex>
  )
}

const MapLocationIcon = styled(MapLocation)`
  width: 70px !important;
  top: 20px;
  bottom: 0 !important;
  left: 0;
  right: 0;
  margin: auto;
  fill: white;
`

export default Dashboard
