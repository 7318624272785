import keyBy from 'lodash/keyBy';
import { createReducer } from '../utils/redux';
import {
  SPACES_BY_LOC_ID_GET_REQUEST,
  SPACES_BY_LOC_ID_GET_SUCCESS,
  SPACES_BY_LOC_ID_GET_FAILURE,
  SPACES_CREATE_REQUEST,
  SPACES_CREATE_SUCCESS,
  SPACES_CREATE_FAILURE,
  SPACES_UPDATE_REQUEST,
  SPACES_UPDATE_SUCCESS,
  SPACES_UPDATE_FAILURE,
  SPACES_DELETE_REQUEST,
  SPACES_DELETE_SUCCESS,
  SPACES_DELETE_FAILURE,
  AVAILABLE_SPACES_REQUEST,
  AVAILABLE_SPACES_SUCCESS,
  AVAILABLE_SPACES_FAILURE
} from '../constants';

const initialState = {
  byLocationId: {},
  byId: {},
  status: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
  },
  error: {
    loading: false,
    creating: false,
    updating: false,
    deleting: false,
  },
  available: {
    byLocationId: {},
    loadingById: {},
    errorById: {}
  }
};

export default createReducer(initialState, {
  [SPACES_BY_LOC_ID_GET_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      loading: true,
    },
    error: {
      ...initialState.error,
      loading: false,
    },
  }),
  [SPACES_BY_LOC_ID_GET_SUCCESS]: (state, payload, config) => ({
    ...state,
    byLocationId: {
      ...state.byLocationId,
      [config]: payload.total > 0 ? payload.list.map(s => s.id) : null,
    },
    byId: {
      ...state.byId,
      ...keyBy(payload.list, 'id'),
    },
    status: {
      ...initialState.status,
      loading: false,
    },
    error: {
      ...initialState.error,
      loading: false,
    },
  }),
  [SPACES_BY_LOC_ID_GET_FAILURE]: (state, payload) => ({
    ...state,
    status: {
      ...initialState.status,
      loading: false,
    },
    error: {
      ...initialState.error,
      loading: payload || true,
    },
  }),
  [SPACES_CREATE_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      creating: true,
    },
    error: {
      ...initialState.error,
      creating: false,
    },
  }),
  [SPACES_CREATE_SUCCESS]: (state, payload) => ({
    ...state,
    byLocationId: {
      ...state.byLocationId,
      [payload.locationId]: [...state.byLocationId[payload.locationId], payload.id],
    },
    byId: {
      ...state.byId,
      [payload.id]: payload,
    },
    status: {
      ...initialState.status,
      creating: false,
    },
    error: {
      ...initialState.error,
      creating: false,
    },
  }),
  [SPACES_CREATE_FAILURE]: (state) => ({
    ...state,
    status: {
      ...initialState.status,
      creating: false,
    },
    error: {
      ...initialState.error,
      creating: true,
    },
  }),
  [SPACES_UPDATE_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      creating: true,
    },
    error: {
      ...initialState.error,
      creating: false,
    },
  }),
  [SPACES_UPDATE_SUCCESS]: (state, payload) => ({
    ...state,
    byId: {
      ...state.byId,
      [payload.id]: payload,
    },
    status: {
      ...initialState.status,
      creating: false,
    },
    error: {
      ...initialState.error,
      creating: false,
    },
  }),
  [SPACES_UPDATE_FAILURE]: (state) => ({
    ...state,
    status: {
      ...initialState.status,
      creating: false,
    },
    error: {
      ...initialState.error,
      creating: true,
    },
  }),
  [SPACES_DELETE_REQUEST]: state => ({
    ...state,
    status: {
      ...initialState.status,
      deleting: true,
    },
    error: {
      ...initialState.error,
      deleting: false,
    },
  }),
  [SPACES_DELETE_SUCCESS]: (state, payload) => ({
    ...state,
    byLocationId: {
      ...state.byLocationId,
      [payload.locationId]:
        state.byLocationId[payload.locationId].filter(id => id !== payload.id),
    },
    status: {
      ...initialState.status,
      deleting: false,
    },
    error: {
      ...initialState.error,
      deleting: false,
    },
  }),
  [SPACES_DELETE_FAILURE]: state => ({
    ...state,
    status: {
      ...initialState.status,
      deleting: false,
    },
    error: {
      ...initialState.error,
      deleting: true,
    },
  }),
  [AVAILABLE_SPACES_REQUEST]: (state, payload) => ({
    ...state,
    available: {
      ...state.available,
      loadingById: {
        ...state.available.loadingById,
        [payload]: true
      },
      errorById: {
        ...state.available.errorById,
        [payload]: false
      }
    }
  }),
  [AVAILABLE_SPACES_SUCCESS]: (state, payload, config) => ({
    ...state,
    available: {
      ...state.available,
      loadingById: {
        ...state.available.loadingById,
        [config]: false
      },
      errorById: {
        ...state.available.errorById,
        [config]: false
      },
      byLocationId: {
        ...state.available.byLocationId,
        [config]: payload.list
      }
    }
  }),
  [AVAILABLE_SPACES_FAILURE]: (state, payload) => ({
    available: {
      ...state.available,
      loadingById: {
        ...state.available.loadingById,
        [payload]: false
      },
      errorById: {
        ...state.available.errorById,
        [payload]: true
      }
    }
  })
});
