import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'
import { getTokenWithExpiration } from '../../actions/auth'
import { uploadFile } from '../../api/upload-file'
import { parseJSON } from '../../utils/api'
import { Paperclip } from '../icons'
import Flex from '../flex'
import Spinner from '../spinner'

const AttachmentPdf = ({
  initialFileName,
  label,
  onRemove = () => {},
  name,
  onChange,
  relatedType = 'Location',
  alignLeft,
  maxText,
  maxSize,
  smallLabel,
  accept
}) => {
  const dispatch = useDispatch()
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState()
  const token = useSelector(state => state.auth.token)
  const handleUpload = event => {
    if (event && event.currentTarget.files[0]) {
      setFile(event.currentTarget.files[0])
    }
  }

  useEffect(() => {
    setFileName(initialFileName)
  }, [initialFileName])

  useEffect(() => {
    if (file) {
      setUploading(true)
      setError(null)

      if (file.size > 1024 * (maxSize || 5000)) {
        setError(maxText || 'Max PDF size is 5 Mbs')
        setUploading(false)
        return
      }
      // eslint-disable-next-line no-undef
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const formData = {
          name: file.name,
          type: file.type,
          size: file.size,
          role: 'Attachment',
          relatedType,
          field: name,
          file: reader.result
        }

        dispatch(getTokenWithExpiration({ auth: { token } })).then(
          updatedToken => {
            uploadFile(updatedToken, formData)
              .then(response => {
                if (response.status === 200) {
                  parseJSON(response).then(data => {
                    onChange({
                      id: data?.id,
                      name: data?.name
                    })
                    setFileName(file.name)
                  })
                } else {
                  setError(`Error ${response.status}`)
                }
                setUploading(false)
              })
              .catch(() => {
                setError('Unknow server error')
                setUploading(false)
              })
          }
        )
      }
      reader.onerror = () => [
        setError('Error reading file'),
        setUploading(false)
      ]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return (
    <Wrap>
      {label && <Label data-align-left={alignLeft} data-font-small={smallLabel}>{label}</Label>}
      {fileName ? (
        <Preview>
          <span>{fileName}</span>
          <RemoveBtn onClick={() => onRemove()} data-font-small={smallLabel}>Remove</RemoveBtn>
        </Preview>
      ) : (
        <>
          {uploading ? (
            <Flex fullWidth fullHeight center>
              <Spinner elSize={30} />
            </Flex>
          ) : (
            <>
              <FileLabel htmlFor={name}>
                <Flex fullWidth center={!alignLeft} middle>
                  <Icon>
                    <Paperclip />
                  </Icon>
                </Flex>
                <File
                  id={name}
                  type="file"
                  accept={accept || 'application/pdf'}
                  onChange={handleUpload}
                ></File>
              </FileLabel>
              {error && <ErrorText>{error}</ErrorText>}
            </>
          )}
        </>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
`

const ErrorText = styled.span`
  color: red;
  text-align: center;
  font-size: 14px;
  display: block;
`

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  svg {
    width: 32px;
    height: 32px;
  }
`

const File = styled.input`
  opacity: 0;
  width: 1px;
  position: absolute;
  left: 0;
  top: 0;
`

const FileLabel = styled.label`
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 0;
  position: relative;
`

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  color: #585756;
  font-weight: 600;
  text-align: center;

  &[data-align-left='true'] {
    text-align: left;
  }

  &[data-font-small='true'] {
    font-size: 13px;
  }
`

const Preview = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const RemoveBtn = styled.button.attrs({ type: 'button' })`
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
  padding: 0;
  font-weight: 600;
  margin-top: 5px;
  color: #585756;
  font-size: 15px;

  :focus {
    outline: none;
  }

  :hover {
    color: black;
  }

  &[data-font-small='true'] {
    font-size: 13px;
  }
`

export default AttachmentPdf
