import React from 'react'
import styled, { createGlobalStyle } from 'styled-components/macro'
import { CloseModal } from '../icons'
import { smOnly, lgUp, mdUp } from '../../styles/breakpoints'

export const Wrapper = props => {
  const {
    hideModal,
    onClose,
    canClose,
    showClose,
    children,
    modalType,
    iframe,
    isImage
  } = props

  const handleOutsideClick = e => {
    if (e.target === e.currentTarget && canClose) {
      onClose()
      hideModal()
    }
  }

  return (
    <Overlay
      onClick={handleOutsideClick}
      data-images={isImage}
      data-modal-type={modalType || false}
    >
      <ModalWindow data-iframe={iframe} data-images={isImage}>
        {showClose && (
          <Close
            onClick={() => {
              onClose()
              hideModal()
            }}
            data-images={isImage}
          >
            <Icon />
          </Close>
        )}
        {children}
      </ModalWindow>
      <DisableBodyScroll />
    </Overlay>
  )
}

Wrapper.defaultProps = {
  showClose: false,
  canClose: false,
  onClose: () => {}
}

const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const Overlay = styled.div`
  display: flex;
  overflow-x: hidden;
  align-items: center;
  background-color: rgba(46, 44, 43, 0.8);
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  &[data-modal-type='message'] {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 19;
    align-items: flex-start;
    background-color: transparent;
    padding: 0 20px;
    max-width: 500px;
    height: 260px;
  }

  &[data-images='true'] {
    padding: 15px;
  }
`

const ModalWindow = styled.div`
  position: relative;
  z-index: 25;
  max-height: 100vh;
  text-align: center;
  width: 100%;

  @media ${lgUp} {
    &:not([data-iframe='true']) {
      width: auto;
    }
  }

  &:not([data-iframe='true']) {
    iframe {
      margin: auto;
    }
  }

  &[data-iframe='true'] {
    width: 100%;

    @media ${mdUp} {
      height: 100%;
    }
  }

  &[data-images='true'] {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
`

const Icon = styled(CloseModal)`
  height: 12px;
  width: 12px;
  fill: #979797;
  transition: fill 0.3s ease;
`

const Close = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 20px;
  background: none;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  border: 3px solid #979797;
  cursor: pointer;
  padding: 0;
  text-align: center;
  line-height: 25px;
  transition: border 0.3s ease;
  right: 16px;

  :hover {
    border: 3px solid #585756;

    ${Icon} {
      fill: #585756;
    }
  }

  &[data-images='true'] {
    top: 6px;
    right: 6px;
    z-index: 4;
  }
`

export const Body = styled.div`
  padding: 50px 68px 35px 68px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0;
  margin-left: auto;
  margin-right: auto;
  min-height: ${props => props.height || 'unset'};

  @media ${smOnly} {
    padding: 20px;
  }

  &[data-iframe='true'] {
    @media ${mdUp} {
      height: 100%;
    }
  }

  &[data-images='true'] {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    padding: 30px 40px;
  }
`
