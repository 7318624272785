import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  padding: 20px;
`;

export const Top = styled.div`
  margin-bottom: 50px;
`;

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
`;

export const Heading = styled.h1`
  font-size: ${props => props.fontSize || '18px'};
  font-weight: 400;
`;

export const SubHeading = styled.h2`
  font-size: ${props => props.fontSize || '18px'};
  font-weight: 400;
`;

export const Text = styled.p`
  font-size: 29px;
  text-align: center;
  color: gray;
  margin-bottom: 0;
`;

export const Link = styled.a`
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
`;

