import fetch from 'isomorphic-fetch'
import {
  API_URL as apiUrl,
  API_BASE as apiBase,
  API_KEY as apiKey
} from '../settings/api'

export const getLeadPerformance = (token, params) =>
  fetch(`${apiUrl}${apiBase}/flex/leadperformance?${params}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })

export const getLeadsAndViews = (token, locationId) =>
  fetch(`${apiUrl}${apiBase}/flex/leadsandviews?locationId=${locationId}`, {
    method: 'get',
    headers: {
      'x-api-key': apiKey,
      Authorization: token
    }
  })
