import groupBy from 'lodash/groupBy'
import reduce from 'lodash/reduce'
import sumBy from 'lodash/sumBy'
import { createSelector } from 'reselect'
import { sortByName } from '../../helpers/sort'

const marketsList = state => state.markets.list

const getSubmarkets = markets =>
  groupBy(
    markets.filter(market => market.is_submarket),
    'market_parent_id'
  )

export const groupSubmarkets = markets => {
  const mapMarkets = sortByName(markets.map(market => ({
    ...market,
    is_submarket: market.isSubmarket || market.is_submarket,
    market_parent_id: market.parentMarketId || market.market_parent_id
  })))
  const parent = mapMarkets.filter(market => !market.is_submarket)
  const submarkets = getSubmarkets(mapMarkets)

  return reduce(
    parent,
    (groupedMarkets, parentMarket) => {
      if (submarkets[parentMarket.id]) {
        const parentMarketFlag = {
          ...parentMarket,
          isParent: true,
          submarkets: submarkets[parentMarket.id]
        }
        return [
          ...groupedMarkets,
          parentMarketFlag,
          ...submarkets[parentMarket.id]
        ]
      }

      return [...groupedMarkets, parentMarket]
    },
    []
  )
}

export const selectMarketsWithCoords = createSelector(marketsList, markets =>
  groupSubmarkets(markets || []).map(market => ({
    label: market.name,
    location: { lat: market.centerLat, lng: market.centerLon },
    slug: market.marketSlug,
    isSubmarket: market.is_submarket,
    id: market.id,
    marketParentId: market.market_parent_id,
    isFixture: true,
    lat: market.center_lat,
    lng: market.center_lon
  }))
)

export const updateSuggests = (suggests = [], fixtures = []) => {
  if (!suggests || !fixtures) return []
  const firstSuggest = suggests[0]

  if (firstSuggest && firstSuggest.isSubmarket && firstSuggest.isFixture) {
    const injectSuggest = fixtures.find(
      el => el.id === firstSuggest.marketParentId
    )
    return [{ ...injectSuggest, placeId: injectSuggest.label }, ...suggests]
  }

  if (
    !suggests.every(el => el.isFixture) &&
    firstSuggest &&
    firstSuggest.isFixture &&
    getSubmarkets(fixtures)[firstSuggest.id]
  ) {
    return [
      firstSuggest,
      ...getSubmarkets(fixtures)[firstSuggest.id].map(el => ({
        ...el,
        placeId: el.label
      })),
      ...suggests.slice(1)
    ]
  }

  return suggests
}

export const selectParentMarketsWithCount = createSelector(
  marketsList,
  markets => {
    if (!markets) return null

    const submarketsLocCountByParent = reduce(
      groupBy(
        markets.filter(market => market.is_submarket && market.market_parent_id),
        'market_parent_id'
      ),
      (result, submarkets, parentMarketId) => ({
        ...result,
        [parentMarketId]: sumBy(submarkets, 'locationCount')
      }),
      {}
    )

    return markets
      .filter(market => !market.is_submarket)
      .map(market => {
        if (submarketsLocCountByParent[market.id]) {
          return {
            ...market,
            locationCount:
              (market.locationCount || 0) +
              submarketsLocCountByParent[market.id]
          }
        }

        return market
      })
  }
)
