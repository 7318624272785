import { createReducer } from '../utils/redux'
import {
  INVENTORY_VACANCY_REQUEST,
  INVENTORY_VACANCY_SUCCESS,
  INVENTORY_VACANCY_FAILURE,
  FORECAST_REQUEST,
  FORECAST_SUCCESS,
  FORECAST_FAILURE,
  SUPPLYANDDEMAND_REQUEST,
  SUPPLYANDDEMAND_SUCCESS,
  SUPPLYANDDEMAND_FAILURE,
  SUMMERY_TEXT_REQUEST,
  SUMMERY_TEXT_SUCCESS,
  SUMMERY_TEXT_FAILURE,
  ASKING_PRICE_REQUEST,
  ASKING_PRICE_SUCCESS,
  ASKING_PRICE_FAILURE,
  ASKING_PRICE_PER_REQUEST,
  ASKING_PRICE_PER_SUCCESS,
  ASKING_PRICE_PER_FAILURE,
  DEMAND_DYNAMIX_REQUEST,
  DEMAND_DYNAMIX_SUCCESS,
  DEMAND_DYNAMIX_FAILURE,
  NEW_CLOSED_LOC_REQUEST,
  NEW_CLOSED_LOC_SUCCESS,
  NEW_CLOSED_LOC_FAILURE
} from '../constants'

const initialState = {
  forecast: {
    byMarketId: {},
    status: {
      loading: false,
      error: false
    }
  },
  inventory: {
    byMarketId: {},
    status: {
      loading: false,
      error: false
    }
  },
  supplyAndDemand: {
    byMarketId: {},
    status: {
      loading: false,
      error: false
    }
  },
  price: {
    byMarketId: {},
    status: {
      loading: false,
      error: false
    }
  },
  pricePerSpace: {
    byMarketSpace: {},
    status: {
      loading: false,
      error: false
    }
  },
  demanddynamix: {
    byMarketId: {},
    status: {
      loading: false,
      error: false
    }
  },
  locations: {
    byMarketId: {},
    byMarketIdAll: {},
    status: {
      loading: false,
      error: false
    }
  },
  summeryText: {
    byPageMarket: {},
    status: {
      loading: false,
      error: false
    }
  }
}

export default createReducer(initialState, {
  [FORECAST_REQUEST]: state => ({
    ...state,
    forecast: {
      ...state.forecast,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [FORECAST_SUCCESS]: (state, payload, config) => ({
    ...state,
    forecast: {
      ...state.forecast,
      byMarketId: {
        ...state.forecast.byMarketId,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [FORECAST_FAILURE]: state => ({
    ...state,
    forecast: {
      ...state.forecast,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [INVENTORY_VACANCY_REQUEST]: state => ({
    ...state,
    inventory: {
      ...state.inventory,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [INVENTORY_VACANCY_SUCCESS]: (state, payload, config) => ({
    ...state,
    inventory: {
      ...state.inventory,
      byMarketId: {
        ...state.inventory.byMarketId,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [INVENTORY_VACANCY_FAILURE]: state => ({
    ...state,
    inventory: {
      ...state.inventory,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [SUPPLYANDDEMAND_REQUEST]: state => ({
    ...state,
    supplyAndDemand: {
      ...state.supplyAndDemand,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [SUPPLYANDDEMAND_SUCCESS]: (state, payload, config) => ({
    ...state,
    supplyAndDemand: {
      ...state.supplyAndDemand,
      byMarketId: {
        ...state.supplyAndDemand.byMarketId,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [SUPPLYANDDEMAND_FAILURE]: state => ({
    ...state,
    supplyAndDemand: {
      ...state.supplyAndDemand,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [ASKING_PRICE_REQUEST]: state => ({
    ...state,
    price: {
      ...state.price,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [ASKING_PRICE_SUCCESS]: (state, payload, config) => ({
    ...state,
    price: {
      ...state.price,
      byMarketId: {
        ...state.price.byMarketId,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [ASKING_PRICE_FAILURE]: state => ({
    ...state,
    price: {
      ...state.price,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [DEMAND_DYNAMIX_REQUEST]: state => ({
    ...state,
    demanddynamix: {
      ...state.demanddynamix,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [DEMAND_DYNAMIX_SUCCESS]: (state, payload, config) => ({
    ...state,
    demanddynamix: {
      ...state.demanddynamix,
      byMarketId: {
        ...state.demanddynamix.byMarketId,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [DEMAND_DYNAMIX_FAILURE]: state => ({
    ...state,
    demanddynamix: {
      ...state.demanddynamix,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [NEW_CLOSED_LOC_REQUEST]: state => ({
    ...state,
    locations: {
      ...state.locations,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [NEW_CLOSED_LOC_SUCCESS]: (state, payload, config) => ({
    ...state,
    locations: {
      ...state.locations,
      byMarketId: {
        ...state.locations.byMarketId,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [NEW_CLOSED_LOC_FAILURE]: state => ({
    ...state,
    locations: {
      ...state.locations,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [SUMMERY_TEXT_REQUEST]: state => ({
    ...state,
    summeryText: {
      ...state.summeryText,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [SUMMERY_TEXT_SUCCESS]: (state, payload, config) => ({
    ...state,
    summeryText: {
      ...state.summeryText,
      byPageMarket: {
        ...state.summeryText.byPageMarket,
        [`${config.page}-${config.marketId}`]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [SUMMERY_TEXT_FAILURE]: state => ({
    ...state,
    summeryText: {
      ...state.summeryText,
      status: {
        loading: false,
        error: true
      }
    }
  }),
  [ASKING_PRICE_PER_REQUEST]: state => ({
    ...state,
    pricePerSpace: {
      ...state.pricePerSpace,
      status: {
        loading: true,
        error: false
      }
    }
  }),
  [ASKING_PRICE_PER_SUCCESS]: (state, payload, config) => ({
    ...state,
    pricePerSpace: {
      ...state.pricePerSpace,
      byMarketSpace: {
        ...state.pricePerSpace.byMarketSpace,
        [config]: payload
      },
      status: {
        loading: false,
        error: false
      }
    }
  }),
  [ASKING_PRICE_PER_FAILURE]: state => ({
    ...state,
    pricePerSpace: {
      ...state.pricePerSpace,
      status: {
        loading: false,
        error: true
      }
    }
  }),
})
