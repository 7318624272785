import React from 'react';
import { useDispatch } from 'react-redux';
import { Body } from '../../components/modal';
import { hideModal, hideAllModals } from '../../actions/modal';
import { spaceDelete } from '../../actions/spaces';
import { ModalAction } from '../../components/spaces';
import Flex from '../../components/flex';

const SpaceDelete = (props) => {
  const dispatch = useDispatch();

  return (
    <Body small>
      <div>
        <span>Are you sure?</span>
        <br />
        <span>This will permanently remove this space.</span>
      </div>
      <br />

      <Flex fullWidth flexEnd center>
        <ModalAction onClick={() => dispatch(hideModal())}>Cancel</ModalAction>
        <ModalAction
          onClick={() =>
            dispatch(spaceDelete(props))
              .then(() => dispatch(hideAllModals()))
          }
        >
          Remove
        </ModalAction>
      </Flex>
    </Body>
  );
};

export default SpaceDelete;
