import { filter, reduce } from 'lodash'
import { IMAGES_URL_BASE } from '../settings/images-url';

export const imageUrl = (id) => {
  if (!id) return null;

  return `${IMAGES_URL_BASE}/${id}`;
};

export const getFilterList = filters =>
  reduce(
    filters,
    (result, value, key) => {
      const exclude = ['marketId', 'marketName', 'search', 'neighborhood']

      if (exclude.includes(key)) {
        return result
      }

      return [...result, ...value.map(item => ({ ...item, key }))]
    },
    []
  )

export const filterLocations = (filterItems, locations) => {
  return filter(locations, location => {
    const ifNeighborhood =
      filterItems.neighborhood.length === 0 ||
      filterItems.neighborhood.some(
        item => item.value === location.neighborhood_id
      )

    const ifHealthStatus =
      filterItems.healthStatus.length === 0 ||
      filterItems.healthStatus.some(
        item => item.value === location.health_status
      )

    const ifType =
      filterItems.type.length === 0 ||
      filterItems.type.some(item => item.value === location.type)

    const ifOperator =
      filterItems.operator.length === 0 ||
      filterItems.operator.some(item => item.value === location.operator_name)

    const ifSizeClass = filterItems.sizeClass.length === 0 ||
      filterItems.sizeClass.some(item => item.value === (location.size_class || '').replace('to', '-'))

    const ifLandlord = filterItems.landlord.length === 0 ||
      filterItems.landlord.some(item => item.value === location.landlords_id)

    return (
      ifLandlord && ifNeighborhood && ifHealthStatus && ifType && ifSizeClass && ifOperator
    )
  })
}

export const filterLocationsAll = (filterItems, locations) => {
  return filter(locations, location => {
    const ifNeighborhood =
      filterItems.neighborhood.length === 0 ||
      filterItems.neighborhood.some(
        item => item.value === location.nbh_id
      )

    const ifHealthStatus =
      filterItems.healthStatus.length === 0 ||
      filterItems.healthStatus.some(
        item => item.value === location.health_status
      )

    const ifType =
      filterItems.type.length === 0 ||
      filterItems.type.some(item => item.value === location.type)

    const ifOperator =
      filterItems.operator.length === 0 ||
      filterItems.operator.some(item => item.value === location.op_name)

    const ifSizeClass = filterItems.sizeClass.length === 0 ||
      filterItems.sizeClass.some(item => item.value === (location.size_class || '').replace('to', '-'))

    const ifLandlord = filterItems.landlord.length === 0 ||
      filterItems.landlord.some(item => item.value === location.ldl_id)

    const ifIsOpen = filterItems.isOpen.length === 0 ||
      filterItems.isOpen.some(item => item.value === location.is_open)

    return (
      ifLandlord && ifNeighborhood && ifHealthStatus && ifType && ifSizeClass && ifOperator && ifIsOpen
    )
  })
}

export const sortingOptions = [
  {
    label: 'Location Score: High to Low',
    value: 'location_score_total-desc'
  },
  {
    label: 'Location Score: Low to High',
    value: 'location_score_total-asc'
  },
  {
    label: 'Square Feet: High to Low',
    value: 'total_square_footage-desc'
  },
  {
    label: 'Square Feet: Low to High',
    value: 'total_square_footage-asc'
  }
]

export const sortingOptionsAll = [
  {
    label: 'Location Score: High to Low',
    value: 'ls_total-desc'
  },
  {
    label: 'Location Score: Low to High',
    value: 'ls_total-asc'
  },
  {
    label: 'Square Feet: High to Low',
    value: 'total_square_footage-desc'
  },
  {
    label: 'Square Feet: Low to High',
    value: 'total_square_footage-asc'
  }
]
