import React from 'react'
import styled from 'styled-components/macro'
import { Body } from '../../components/modal'

const InteractiveTour = ({ url }) => {
  return (
    <Body data-iframe="true">
      <Iframe src={url} />
    </Body>
  )
}

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

export default InteractiveTour
